import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { PropertyService } from 'src/app/services/property.service';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material';
import { JobCommentDialog } from 'src/app/dashboard/job/job-comments/job-comments.component';
import { JobAssign } from 'src/app/models/job-assign';
import { AssetsService } from 'src/app/services/assets.service';
import { HttpErrorResponse } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { ImageViewerComponent } from '../image-viewer/image-viewer.component';
import { ToastrService } from 'ngx-toastr';
import { success, errorMessage } from 'src/app/constants/notification-messages.constant';

@Component({
  selector: 'app-upload-image-form',
  templateUrl: './upload-image-form.component.html',
  styleUrls: ['./upload-image-form.component.scss']
})
export class UploadImageFormComponent implements OnInit {

  uploadImageForm: FormGroup;
  jobImage: JobCommentDialog;
  isCleaner= false;
  jobAssign: JobAssign;
  showUploadForm = false;
  isLoading = false;
  isImageLoading = false;
  imageToShow: any;
  imageToShowArray = [];
  enlargeImage = false;
  selectedImage: any;
  constructor(private propertyService: PropertyService, private dialog: MatDialogRef<UploadImageFormComponent>,
    @Inject (MAT_DIALOG_DATA) public data: any, private toastrSerice: ToastrService,
      public thisDialog: MatDialog, private assetService: AssetsService, private sanitizer : DomSanitizer) {
     
      this.jobImage= data as JobCommentDialog;
      if(!this.jobAssign){
        this.jobAssign = this.jobImage.jobAssign;
      }     
      this.isCleaner = this.jobImage.isCleaner;
     }

  ngOnInit() {
    
    this.loadImages();
    
  }

  hide(){
    this.dialog.close();
  }
  createForm(){
    this.uploadImageForm = new FormGroup({
      'image': new FormControl(null, [Validators.required]),
    })
  }

  onFileSelect(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.uploadImageForm.get('image').setValue(file);
    }
  }
  showForm() {
    this.createForm();
    this.showUploadForm = true;
    this.enlargeImage = false;
  }
  uploadImage(){
    const formData = new FormData();
    this.isLoading = true;
    const userId =  this.jobAssign.userId;
    const propertyId =  this.jobAssign.propertyId;
    const roomId = this.jobAssign.roomId;
    formData.append('file', this.uploadImageForm.get('image').value);
    this.propertyService.uploadImage(formData, this.jobAssign.id)
      .subscribe(
        (response: any) => {
          this.jobAssign = response.payload;
          this.jobAssign.propertyId = propertyId;
          this.jobAssign.userId = userId;
          this.jobAssign.roomId = roomId;
          this.isLoading = false;
          this.showUploadForm = false;
          this.imageToShowArray = [];
          this.ngOnInit();
          this.toastrSerice.success('Image Saved Successfully', success,{
            timeOut: 3000,
            onActivateTick: true
          })
        },
        (error: HttpErrorResponse) => {
          this.toastrSerice.success('Image Save Failed', errorMessage,{
            timeOut: 3000,
            onActivateTick: true
          })
        }
      );
  }

  loadImages(){
    
    let imageUrls: string []= this.jobAssign.jobImageAddressess;
    if(imageUrls !== null) {
      for(let imageUrl of imageUrls) {
        this.isImageLoading = true;
        this.assetService.getJobImage(this.jobAssign.id, imageUrl)
          .subscribe(
            (response: any) => {
              this.createImageFromBlob(response);
              // setInterval(()=> {
              //   this.isImageLoading = false;
              // }, 1000)
              this.isImageLoading = false;
            }
          )  
       }
    }
  }

  createImageFromBlob(image: Blob) {
    let reader = new FileReader();
    reader.addEventListener("load", () => {
       this.imageToShow = reader.result;
       this.imageToShow = this.sanitizer.bypassSecurityTrustUrl( this.imageToShow);
       this.imageToShowArray.push(this.imageToShow);
    }, false);
 
    if (image) {
       reader.readAsDataURL(image);
    }
 }

 
 showImage(image: any){
  this.selectedImage = image;
  this.enlargeImage = true;
 }

 clear(){
  this.showUploadForm = false;
  this.uploadImageForm.reset();
 }

}
