import {ToastrService} from 'ngx-toastr';
import {HttpErrorResponse} from '@angular/common/http';
import {GenericDto} from '../../../models/generic-dto';
import {
  changePasswordSuccessfully,
  errorMessage,
  inviteMessage,
  success,
  successUserUpdated
} from '../../../constants/notification-messages.constant';
import {ActivatedRoute, Router} from '@angular/router';
import {User} from '../../../models/user';
import {DataService} from '../../../shared/services/data.service';
import {UserService} from '../../../services/user.service';
import {FormGroup, FormControl, FormBuilder, Validators, FormArray} from '@angular/forms';
import {TokenService} from '../../../core/services/token.service';
import {Component, OnInit} from '@angular/core';
import {AddNewPluginComponent} from '../../property/plugins/add-new-plugin/add-new-plugin.component';
import {MatDialog, MatDialogRef} from '@angular/material';
import {ProfileDetailsComponent} from '../profile-details/profile-details.component';


@Component ({
  selector: 'app-change-password',
  templateUrl: './changePassword.component.html',
  styleUrls: ['./changePassword.component.scss']
})

export class ChangePasswordComponent implements OnInit  {
  changePasswordForm: FormGroup;
  user: User;
  isLoading = false;
  hideOld: boolean;
  hideNew: boolean;
  editForm = false;
  emptyState = true;
  constructor(private fb: FormBuilder, private router: Router,
              private toastrService: ToastrService, private userService: UserService,
              private dataService: DataService, private route: ActivatedRoute , private tokenService: TokenService, private dialog: MatDialogRef<ChangePasswordComponent>) {

  }



  hide() {
    this.dialog.close(ChangePasswordComponent);
  }

  ngOnInit() {
         this.editForm = true;
    this.changePasswordForm = this.fb.group({
      oldPassword: new FormControl('', [Validators.required, Validators.minLength(6)]),
      newPassword: new FormControl('', [Validators.required, Validators.minLength(6)]),
    });
  }

  goBack() {
      this.router.navigate(['/dashboard/profile']);
  }

  updatePassword(): void {
    if (this.changePasswordForm.valid) {
      // call cleaner creation API here
      this.isLoading = true;
      {
        // update property
        // this.user.oldPassword = this.changePasswordForm.get('oldPassword').value;
        // this.user.newPassword = this.changePasswordForm.get('newPassword').value;
        this.userService.changePassword(this.changePasswordForm.getRawValue()).subscribe((response: GenericDto<any>) => {
          if (response.code === 200) {
            this.router.navigate(['/dashboard/profile']);
            this.toastrService.success(changePasswordSuccessfully, success, {
              timeOut: 3000,
              onActivateTick: true
            });
            this.dialog.close(ChangePasswordComponent);
          } else {
            this.toastrService.error('Old Password is Incorrect ', errorMessage, {
              timeOut: 4000,
              onActivateTick: true
            });
            }
          this.isLoading = false;
        }, (error: HttpErrorResponse) => {
          this.isLoading = false;
          // show errors here
        });

      }
    } else {
      // throw warning

    }
  }

  userFillForm(): void {
    Object.keys(this.changePasswordForm.controls).forEach((key: string) => {
      if (!!this.changePasswordForm.get(key)) {
        this.changePasswordForm.get(key).setValue(this.user[key]);
      }
    });
  }
}
