import {Component , OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { BillingService } from '../billing.service';
import { SharedService } from 'src/app/services/shared.service';
import * as moment from 'moment';
@Component({
  selector: 'app-billing-checkout',
  templateUrl: './billing-checkout.component.html',
  styleUrls: ['./billing-checkout.component.scss']
})
export class BillingCheckoutComponent implements OnInit {

  stripeCustomerIdexist : boolean=false ;
  showManageSubscription : boolean=false ;
  dataFetch : boolean=false ;
  membership:any;
  reviewSubscription: boolean=false;
  planData: any;
  stripeCustomerId: string;
  isPaymentMethodExist:boolean;
  subscribedPlan: any={};
  subscribeButtonDisabled: boolean=false;
  stripeSubscription: any;
 
  showPaymentMethodAlert:boolean = false;
  showPaymentFaildAlert:boolean = false;

  constructor (private billingService:BillingService,private sharedService :SharedService,
    private userService: UserService) {
  }

  
  ngOnInit() {
    this.userService.getUser().subscribe((res:any) => {
      
      if (res.code === 200) {        
        this.isPaymentMethodExist = res.payload.paymentMethodExist
        this.stripeCustomerId=res.payload.stripeCustomerId;
        this.stripeSubscription=res.payload.stripeSubscription;
        if((this.stripeSubscription == null || this.stripeSubscription.cancelled) ){           
          this.showPaymentFaildAlert = true;
        }else{
          this.showPaymentFaildAlert = false;
        }        

        if(res.payload.stripeSubscription && res.payload.stripeSubscription.status == "active" && !res.payload.paymentMethodExist  ){
          this.showPaymentMethodAlert = true;
        } 
        
        setTimeout(()=>{
          this.getstripeCustomerId();
          this.getSubscription();
        },200)
      }
    })
   
  }

  gotoReview(plan:any){
    if(plan){
      this.reviewSubscription=true;
      this.planData=plan
    }
  }

  cancel(){
    this.reviewSubscription=false;
  }

  onSubmit(priceId:any){
    this.billingService.checkoutPage(priceId).subscribe(res=>{
      if(res){
        window.open(res.payload);
      }
    })
  }

  getSubscription(){     
    this.billingService.getSubscription().subscribe(res=>{
      if(res.code == 200 ){
        this.membership = res.payload
       var checkCancel:any=this.stripeSubscription;
        if(checkCancel != null  ){
          this.subscribeButtonDisabled=true;
          this.subscribedPlan=this.membership.find(x=>
            x.subscribed==true
          );
        }else{
          this.subscribeButtonDisabled=false;
        }
      }else{
        this.membership = []
      }
    })
  }


  toggleManageSubscription(value : boolean){
    this.showManageSubscription = value
  }



  getstripeCustomerId(){     
   this.dataFetch = true;
      if(this.stripeCustomerId != null && this.stripeCustomerId != undefined ){
        
        this.stripeCustomerIdexist = true;
        this.showManageSubscription = false;
      }else{
        this.stripeCustomerIdexist = false;
        this.showManageSubscription = false;
      }
    
  }

  billingPortal(){
      this.billingService.billingPortal(this.stripeCustomerId).subscribe((res2:any)=>{
        if(res2 ){
          window.open(res2.payload);
        }
      })
  }


  createSubscription(plan:any){
    this.billingService.createSubscription(plan).subscribe((res:any)=>{
      if(res.code == 200 ){
        this.subscribeButtonDisabled=true;         
      }
    })
  }  

  formatDate(date){    
    return moment.unix(date).format('MMMM D, yyyy')
  }

  getTooltipMessage() {
     
    if(this.isPaymentMethodExist){
      return;
    }
     
    if(this.stripeSubscription.status === "trialing"){
      var remainingDays = this.sharedService.calculateRemainingDays(this.stripeSubscription.expireDate);
      var message = "Trail period remaining " + remainingDays + " days. To continue using BedPlus after the trial, please add a payment method.";
      return message;
    }
   
}
  

}


