import {Component, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {PasswordMatchingValidator} from '../../validators/passwordMatch.validator';
import {DataService} from '../../shared/services/data.service';
import {AuthService} from '../../services/auth.service';
import {ResetPasswordDto} from '../../models/reset-password-dto';
import {GenericDto} from '../../models/generic-dto';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {errorMessage, success} from '../../constants/notification-messages.constant';
import {HttpErrorResponse} from '@angular/common/http';
import {LoaderService} from '../../shared/services/loader.service';
import {TokenService} from '../../core/services/token.service';
import {UserService} from '../../services/user.service';


@Component({
  selector : 'app-reset-password',
  templateUrl : './reset-password.component.html',
  styleUrls : ['./reset-password.component.scss']
})

export class ResetPasswordComponent implements OnInit, OnDestroy {
  resetPasswordForm: FormGroup;
  hide: boolean;
  isLoading: boolean;
  public path: string;
  constructor(private fb: FormBuilder, private renderer: Renderer2,
              private dataService: DataService, private authService: AuthService, private tokenService: TokenService,
              public router: Router, private toastrService: ToastrService, private loaderService: LoaderService,
              private userService: UserService) {
    this.createResetPasswordForm();
    this.renderer.addClass(document.body, 'login-bg');
  }

  ngOnInit() {
    this.loaderService.stopLoader();

  }
  private createResetPasswordForm(): void {
    this.resetPasswordForm = this.fb.group({
      password: new FormControl('', [Validators.required, Validators.minLength(6)])
    });
  }

  resetPassword(): void {
    this.path = this.router.url;
    if (this.path === '/resetPassword') {
      if (this.resetPasswordForm.valid) {

        this.isLoading = true;
        const data: ResetPasswordDto = {...this.dataService.otp, ...this.resetPasswordForm.getRawValue()};
        this.authService.resetPassword(data)
          .subscribe((response: GenericDto<string>) => {
            if (response.code === 200) {
              this.toastrService.success(response.payload, success, {
                timeOut: 3000,
                onActivateTick: true
              });
              this.router.navigate(['/login']);
            } else {
              this.toastrService.error(response.payload, errorMessage, {
                timeOut: 3000,
                onActivateTick: true
              });
            }
            this.isLoading = false;
          }, (error: HttpErrorResponse) => {
            // show error here
            this.isLoading = false;
          });
      }
    } else {
      if (this.path === '/accountPassword') {
        if (this.resetPasswordForm.valid) {

          this.isLoading = true;
          const data: ResetPasswordDto = {...this.dataService.otp, ...this.resetPasswordForm.getRawValue()};
          this.authService.activeInviteAccount(data)
            .subscribe((response: GenericDto<string>) => {
              if (response.code === 200) {
                this.toastrService.success(response.desc, success, {
                  timeOut: 3000,
                  onActivateTick: true
                });
                this.tokenService.saveToken(response.payload);
                this.userService.getUser().subscribe((result: GenericDto<any>) => {
                  if (result.code === 200) {
                    this.tokenService.saveCurrentUser(result.payload.role);
                    this.loaderService.stopLoader();
                    if (result.payload.role === 'OWNER') {
                      this.router.navigate(['/ownerDashboard']);
                    } else  {
                      this.router.navigate(['/dashboard']);
                    }
                  } else {
                    // show error and redirect to login page
                  }
                });
              } else {
                this.toastrService.error(response.payload, errorMessage, {
                  timeOut: 3000,
                  onActivateTick: true
                });
              }
              this.isLoading = false;
            }, (error: HttpErrorResponse) => {
              // show error here
              this.isLoading = false;
            });
        }
      }
    }
  }

  ngOnDestroy() {
    // clear otp data shared by data service
    this.dataService.otp = null;
  }

}
