import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import {AuthGuard} from '../core/guards/auth.guard';
import { AdminDashboardComponent } from './admin-dashboard.component';
import { AdminDashboardHomeComponent } from './admin-dashboard-home/admin-dashboard-home.component';


@NgModule({
  imports: [
    RouterModule.forChild([
      {
        path: 'adminDashboard', component: AdminDashboardHomeComponent, canActivate: [AuthGuard],
        children: [
          {
            path: '', component: AdminDashboardHomeComponent
          },
          ]},
    ])
  ],
  exports: [RouterModule]
})
export class AdminDashboardRoutingModule {

}
