import {Component, OnInit} from '@angular/core';
import {MatDialogRef} from "@angular/material";

@Component({
  selector: 'app-plugin-help-modal',
  templateUrl: './plugin-help-modal.component.html',
  styleUrls: ['./plugin-help-modal.component.scss']
})
export class PluginHelpModalComponent implements OnInit {
  public pmsName: string = "Booking Automation";

  constructor(public dialogRef: MatDialogRef<PluginHelpModalComponent>) {
  }

  ngOnInit() {
  }

  close() {
    this.dialogRef.close();
  }
}
