import { NgModule } from "@angular/core";
import { AdminDashboardComponent } from "./admin-dashboard.component";
import { RouterModule } from "@angular/router";
import { SharedModule } from "../shared/shared.module";
import { CommonModule } from "@angular/common";
import { AdminDashboardHomeComponent } from './admin-dashboard-home/admin-dashboard-home.component';
import { AssetsService } from "../services/assets.service";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AdminDashboardHomeModule } from "./admin-dashboard-home/admin-dashboard-home.module";

@NgModule({
  declarations : [AdminDashboardComponent],
  imports : [RouterModule, SharedModule, CommonModule, AdminDashboardHomeModule, ReactiveFormsModule, CommonModule, SharedModule, FormsModule],
  providers : [AssetsService],
  exports : [AdminDashboardComponent],
  entryComponents: [AdminDashboardComponent, AdminDashboardHomeComponent]
  })
  export class AdminDashboardModule {
  }
  