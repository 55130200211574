import {NgModule} from '@angular/core';
import {SharedModule} from '../../../shared/shared.module';
import {CommonModule} from '@angular/common';
import {UserService} from '../../../services/user.service';
import {ReactiveFormsModule} from '@angular/forms';
import {ChangePasswordComponent} from './changePassword.component';



@NgModule ({
  imports: [ReactiveFormsModule, CommonModule, SharedModule],
  declarations: [ChangePasswordComponent],
  providers: [UserService],
  exports: [ChangePasswordComponent],
})

export class ChangePasswordModule {}
