import {Component, ViewChild, OnInit, Renderer2, OnDestroy} from '@angular/core';

import {MatSidenav} from '@angular/material';
import {MediaChange, ObservableMedia} from '@angular/flex-layout';
import {Subscription} from 'rxjs/index';
import {Router} from '@angular/router';
import {UserService} from '../services/user.service';
import {GenericDto} from '../models/generic-dto';
import {HttpErrorResponse} from '@angular/common/http';
import {DataService} from '../shared/services/data.service';
import { TourService } from '../services/tour.service';

@Component({
  selector: 'app-owner-dashboard',
  templateUrl: './ownerDashboard.component.html',
  styleUrls: ['./ownerDashboard.component.scss']
})

export class OwnerDashboardComponent implements OnInit, OnDestroy {
  @ViewChild('sideNav') sidebar: MatSidenav;
  @ViewChild('propertiesDrawer') propertiesDrawer: MatSidenav;
  navMode = 'side';
  navOpened: boolean;
  watcher: Subscription;
  activeMediaQuery = '';
  showPropertiesDrawer: boolean;
  constructor(private renderer: Renderer2, private media: ObservableMedia,
              public router: Router, private userService: UserService,
              private dataService: DataService,
              private tourService:TourService) {

  }

  ngOnInit() {
    this.navOpened = true;
    this.renderer.addClass(document.body, 'dashboard-bg');

    // get user object
    this.userService.getUser().subscribe((response: GenericDto<any>) => {
      if (response.code === 200) {
        this.dataService.user = response.payload;
      } else {
        // show error and redirect to login page
      }

    }, (error: HttpErrorResponse) => {
      // show error and redirect to login page
    });

    this.watcher = this.media.subscribe((change: MediaChange) => {
      this.activeMediaQuery = change ? `'${change.mqAlias}' = (${change.mediaQuery})` : '';
      if (change.mqAlias === 'sm' || change.mqAlias === 'xs') {
        this.closeNav();
        this.navMode = 'over';
      } else {
        if (!!this.propertiesDrawer) {
          this.propertiesDrawer.close();
        }
        this.navMode = 'side';
        this.openNav();
      }
    });

  }

  togglePropertiesDrawer(): any {
    this.propertiesDrawer.toggle();
  }

  scrollHandler(event){
    this.tourService.scrollChange(event);
  
  }

  openNav() {
    this.sidebar.open();
  }

  closeNav() {
    this.sidebar.close();
  }

  onOpened() {

  }

  onClosed() {

  }

  ngOnDestroy() {
    this.watcher.unsubscribe();
  }
}
