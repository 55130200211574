import {NgModule } from '@angular/core';
import {LoaderService} from './services/loader.service';
import {LoaderComponent} from './components/loader/loader.component';
import {LoaderDirective} from './directives/loader.directive';
import {CustomMDbModule} from './mdb.module';
import {DataService} from './services/data.service';

import {SidebarComponent} from './components/sidebar/sidebar.component';
import {CommonModule, CurrencyPipe} from '@angular/common';
import {CustomMaterialModule} from './material.module';
import {PropertyListComponent} from './components/property-list/property-list.component';
import {CleanerListComponent} from './components/cleaner-list/cleaner-list.component';
import {RouterModule} from '@angular/router';
import {FilterPipe} from './pipes/filter.pipe';
import {CleanerFilterPipe} from './pipes/cleaner-filter.pipe';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ImageViewerComponent} from './components/image-viewer/image-viewer.component';
import {JobDetailsModalComponent} from './components/job-details-modal/job-details-modal.component';
import {NgSlimScrollModule, SLIMSCROLL_DEFAULTS} from 'ngx-slimscroll';
import {CalendarComponent} from './components/calendar/calendar.component';
import {EventService} from './services/event.service';
import {ScrollDispatchModule} from '@angular/cdk/scrolling';
import {OwnerBookingDialogComponent} from '../owner-booking-dialog/owner-booking-dialog.component';
import {BookingsTableComponent} from '../dashboard/bookings-table/bookings-table.component';
import {RevenueReportComponent} from '../dashboard/revenue-report/revenue-report.component';
import {MaintenanceJobComponent} from '../dashboard/property/property-details/maintenance-job/maintenance-job.component';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { MatDialogModule, MatButtonModule } from '@angular/material';
import { UploadImageFormComponent } from './components/upload-image-form/upload-image-form.component';
import { ExpenseComponent } from '../dashboard/property/expense/expense.component';
import { BillingCheckoutComponent } from '../dashboard/billing/billing-checkout/billing-checkout.component';
import { TolltipTemplateDirective } from './directives/tolltip-template.directive';
import { DateRangePickerComponent } from './date-range-picker/date-range-picker.component';

@NgModule({
  imports: [CommonModule, FormsModule, CustomMaterialModule, CustomMDbModule, RouterModule, NgSlimScrollModule,
    ReactiveFormsModule , ScrollDispatchModule, MatDialogModule, MatButtonModule ],
  declarations: [
    DateRangePickerComponent,
    LoaderComponent,
    LoaderDirective,
    TolltipTemplateDirective,
    SidebarComponent,
    PropertyListComponent,
    CleanerListComponent,
    FilterPipe,
    CleanerFilterPipe,
    ImageViewerComponent,
    JobDetailsModalComponent,
    CalendarComponent,
    OwnerBookingDialogComponent,
    BookingsTableComponent,
    BillingCheckoutComponent,
    RevenueReportComponent,
    ConfirmationDialogComponent,
    UploadImageFormComponent,
  ],
  providers: [LoaderService, EventService, CurrencyPipe, {provide: SLIMSCROLL_DEFAULTS, useValue: {
    alwaysVisible: false,
    gridOpacity: '0.2', barOpacity: '0.5',
    gridBackground: '#e7e7e7',
    gridWidth: '6',
    gridMargin: '2px 2px',
    barBackground: '#acb1b5',
    barWidth: '6',
    barMargin: '2px 2px'
    }
  }],
  exports: [
    DateRangePickerComponent,
    LoaderComponent,
    LoaderDirective,
    TolltipTemplateDirective,
    SidebarComponent,
    PropertyListComponent,
    CleanerListComponent,
    ImageViewerComponent,
    JobDetailsModalComponent,
    CustomMaterialModule,
    CustomMDbModule,
    FilterPipe,
    CleanerFilterPipe,
    CalendarComponent,
    OwnerBookingDialogComponent,
    BookingsTableComponent,
    BillingCheckoutComponent,
    RevenueReportComponent
  ],
  entryComponents: [
    DateRangePickerComponent,
    LoaderComponent, 
    SidebarComponent, 
    ImageViewerComponent,
    JobDetailsModalComponent, 
    OwnerBookingDialogComponent, 
    MaintenanceJobComponent, 
    ConfirmationDialogComponent,
    UploadImageFormComponent,
    ExpenseComponent
  ]
})
export class SharedModule {
}
