/**
 *  Define endpoints here for auth APIs
 */

export const login = '/public/generate-token';

export const logout = '/user/logout';

export const register = '/public/register';

export const emailVerify = '/public/email-verify';

export const generateOTP = '/public/generate-otp';

export const validateOTP = '/public/isValidate-otp';

export const resetPasswordAPI = '/public/resetPassword';

export const activateAccount = '/public/account-active';

export const activeInviteAccount = '/public/activeInvitation';
