import { Component, Inject, OnInit, Renderer2, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as $ from 'jquery';
import moment, { invalid } from 'moment';
import 'fullcalendar';
import { st } from '@angular/core/src/render3';
 

@Component({
  selector: 'app-date-range-picker',
  templateUrl: './date-range-picker.component.html',
  styleUrls: ['./date-range-picker.component.scss']
})
export class DateRangePickerComponent implements OnInit {
  startDate: moment.Moment;
  endDate: moment.Moment;
  containerEl: JQuery;
  date = moment();
  isSelectignStartDate :boolean = true;
  isSelectignEndDate :boolean = true;
  activeBtn :number;
  invalidRange:boolean = false;
  isPredefinedRange :boolean=false;

  constructor(
    public dialogRef: MatDialogRef<DateRangePickerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { startDate: moment.Moment, endDate: moment.Moment, activeBtn : number }
  ) {
    

   }

  ngOnInit(): void {
    // Set initial start and end dates (e.g., current month)     
    this.startDate = moment(this.data.startDate);
    this.endDate = moment(this.data.endDate);
    $('.buttons button').eq(this.data.activeBtn).addClass('btn-primary');

 
    

    // Initialize the calendars
    this.initializeCalendars();
  }
  ngAfterViewInit(): void {
    this.highlightSelectedRange()
  }

  initializeCalendars() {
    const self = this; // Preserve the reference to 'this'

    let startCalendar, endCalendar; // Declare variables to store calendar instances

// ==================Start calendar =========================================
    startCalendar = $('#start-calendar').fullCalendar({
      schedulerLicenseKey: 'GPL-My-Project-Is-Open-Source',
      defaultView: 'month',
      defaultDate: this.startDate,
      editable: false,
      eventLimit: false,
      header: {
          left: 'prev',
          center: 'title',
          right: ''
      },
      selectable: true,
      select: (start, end, jsEvent, view, resource) => {
          this.selectCustomeRange(start)

          this.highlightSelectedRange(); 
          $('.buttons button').removeClass('btn-primary');        
          return true;
      },
      dayPopoverFormat: 'ddd, MMM D, YYYY',
      
      viewRender: function(view, element) {
        var currentDate = $('#end-calendar').fullCalendar('getDate');
        var previousMonth = moment(currentDate).add(-1, 'month');       
        $('#end-calendar').fullCalendar('gotoDate', previousMonth);
    }
     
  });
 
 
  // ==================End calendar =========================================

  const nextMonth = moment(this.startDate).add(1, 'month');

  endCalendar = $('#end-calendar').fullCalendar({
    schedulerLicenseKey: 'GPL-My-Project-Is-Open-Source',
    defaultView: 'month',
    defaultDate: nextMonth,
    editable: false,
    eventLimit: false,
    header: {
        left: '',
        center: 'title',
        right: 'next'
    },
    selectable: true,
    select: (start, end, jsEvent, view, resource) => {

      this.selectCustomeRange(start);
      $('.buttons button').removeClass('btn-primary'); 
      this.highlightSelectedRange();  
      return true;
    },
    dayPopoverFormat: 'ddd, MMM D, YYYY' ,  
    
    
         
});

// Add event listener to the next button of start calendar
endCalendar.find('.fc-next-button').click(() => {
  

   // Get the current date of the end calendar
   const currentEndCalendarDate = endCalendar.fullCalendar('getDate');
   // Increment the date by one month
   let nextMonth = moment(currentEndCalendarDate).add(1, 'month');
   // Change the date of the end calendar to the next month
   endCalendar.fullCalendar('gotoDate', nextMonth);

  // Get the current date of the end calendar
  const currentstartCalendarDate = startCalendar.fullCalendar('getDate');
  // Increment the date by one month
    nextMonth = moment(currentstartCalendarDate).add(1, 'month');
  // Change the date of the end calendar to the next month
  startCalendar.fullCalendar('gotoDate', nextMonth);
});
   
    


    //-----------------css update-----------------------------------------
 

    $('.fc-center h2')
        .addClass('custom-header-title')
        .css({
            'font-size': '18px',
            'padding-top': '14px',
            'color': 'darkblue',
            'padding-left': '5px'
          
        }); 
        
        $('.fc-day-header')
        .addClass('custom-header-title')
        .css({
            'font-size': '18px',
            'padding-top': '14px',
            'color': 'darkblue',
            'padding-left': '5px'
          
        }); 

        //fc-day-header fc-widget-header fc-sun

        $('.fc-toolbar .fc-right') .css({'width': 'auto' });
        $('.fc-toolbar .fc-right button') .css({'width': 'auto' });
        // $('.fc-toolbar ').css('background-color', '#337ab7');

        $('.fc-left button').css('background-color', '#337ab7');
      
}  
   

setToday() {  
    this.resetCalendar(); 
    
    const today = moment(); // Use moment() to get the current date and time
    this.startDate = today;
    this.endDate = today;
    $('#start-calendar').fullCalendar('gotoDate', today); // Use Moment object directly
    const nextMonth = moment().add(1, 'month').startOf('month');
    $('#end-calendar').fullCalendar('gotoDate', nextMonth); // Use Moment object directly
    this.setPrimaryClass(0)
}

setYesterday() {
    this.resetCalendar()
    const yesterday = moment().subtract(1, 'days'); // Use moment() and subtract 1 day
    this.startDate = yesterday;
    this.endDate = yesterday;
    $('#start-calendar').fullCalendar('gotoDate', yesterday);
    const nextMonth = moment().add(1, 'month').startOf('month');
    $('#end-calendar').fullCalendar('gotoDate', nextMonth);
    this.setPrimaryClass(1)
}

setLast7Days() {  
  this.resetCalendar();
  const today = moment(); 
  const lastMonth = moment().subtract(1, 'month').endOf('month'); // Get the last day of the last month

  $('#start-calendar').fullCalendar('gotoDate', lastMonth);
  $('#end-calendar').fullCalendar('gotoDate', today);
    const endDate = moment(); // Use moment() to get the current date and time
    const startDate = moment().subtract(6, 'days'); // Subtract 6 days from today
    this.startDate = startDate;
    this.endDate = endDate;   
    this.setPrimaryClass(2)
}

setLast2Weeks() {  
    this.resetCalendar();  
    const endDate = moment(); // Use moment() to get the current date and time
    const startDate = moment().subtract(13, 'days'); // Subtract 13 days to get 14 days ago
    this.startDate = startDate;
    this.endDate = endDate;
    const today = moment(); // Use moment() to get the current date and tim   
    const lastMonth = moment().subtract(1, 'month').startOf('month');  
    $('#start-calendar').fullCalendar('gotoDate',lastMonth ); // Use Moment object directly
    $('#end-calendar').fullCalendar('gotoDate', today);
    this.setPrimaryClass(3)  
     
}

setThisMonth() {  
    this.resetCalendar();
    const today = moment(); // Use moment() to get the current date and time
    const firstDayOfMonth = moment().startOf('month'); // Get the first day of the current month
    const lastDayOfMonth = moment().endOf('month'); // Get the last day of the current month
    this.startDate = firstDayOfMonth;
    this.endDate = lastDayOfMonth;
    $('#start-calendar').fullCalendar('gotoDate', firstDayOfMonth);
    const lastDayOfLastMonth = moment().add(1, 'month').endOf('month'); // Get the last day of the last month

    $('#end-calendar').fullCalendar('gotoDate', lastDayOfLastMonth);   
    this.setPrimaryClass(5)   
}

setLastMonth() {  
  this.resetCalendar();
  const today = moment(); // Use moment() to get the current date and time
  const firstDayOfLastMonth = moment().subtract(1, 'month').startOf('month'); // Get the first day of the last month
  const lastDayOfLastMonth = moment().subtract(1, 'month').endOf('month'); // Get the last day of the last month

  this.startDate = firstDayOfLastMonth;
  this.endDate = lastDayOfLastMonth;
  
  // Optionally, if you're using FullCalendar, you can set the calendar to display the last month
  $('#start-calendar').fullCalendar('gotoDate', firstDayOfLastMonth);
  $('#end-calendar').fullCalendar('gotoDate', today);
  this.setPrimaryClass(4);  
}


setPrimaryClass(number){
  this.activeBtn = number;
  this.isSelectignEndDate = true;
  this.isSelectignStartDate = true;
  $('#start-calendar .fc-day-top').find('.fc-day-number').css({'color': 'black' });
  $('#start-calendar .fc-day').removeAttr('style');            
  $('#start-calendar .fc-day').removeClass('selected-date') ; 

  $('#end-calendar .fc-day-top').find('.fc-day-number').css({'color': 'black' });
  $('#end-calendar .fc-day').removeAttr('style');            
  $('#end-calendar .fc-day').removeClass('selected-date') ;  
  this.isPredefinedRange =true;

  this.invalidRange = false;
  $('.buttons button').removeClass('btn-primary'); 
  $('.buttons button').eq(number).addClass('btn-primary');

  this.highlightSelectedRange();
}


setCustome(){
  $('.buttons button').removeClass('btn-primary'); 
  this.activeBtn = 99;
  
  this.isPredefinedRange = false;
   
  if(this.startDate>this.endDate){
    this.invalidRange = true;
  }else{
    this.invalidRange = false;
  }
}

onNoClick(): void {
  this.dialogRef.close(false);
}

onApplyClick(): void {
  var  data = {
    startDate: this.startDate.format('YYYY-MM-DD'),
    endDate: this.endDate.format('YYYY-MM-DD'),
    dateRange :this.startDate.format('MMM DD, yyyy')+" - "+this.endDate.format('MMM DD, yyyy'),
    activeBtn:this.activeBtn
  };
  this.dialogRef.close(data);
}

highlightSelectedRange() {

  $('.fc-day-top').find('.fc-day-number').css({'color': 'black' });
  $('.fc-day').removeAttr('style');     
  $('.fc-day').removeClass('selected-date') ;  
 
  $(`.fc-day[data-date="${this.startDate.format('YYYY-MM-DD')}"]`).addClass('selected-date').css({ 'background-color':'green','color':'white' });
  $(`.fc-day-top[data-date="${this.startDate.format('YYYY-MM-DD')}"]`).addClass('selected-date').find('.fc-day-number').css({'color': 'white' });

  var startDateCount = $(`.fc-day-top[data-date="${this.startDate.format('YYYY-MM-DD')}"]`).addClass('selected-date').find('.fc-day-number').length;
 


  $(`.fc-day[data-date="${this.endDate.format('YYYY-MM-DD')}"]`).addClass('selected-date').css({ 'background-color':'green','color':'white' }); 
  $(`.fc-day-top[data-date="${this.endDate.format('YYYY-MM-DD')}"]`).addClass('selected-date').find('.fc-day-number').css({'color': 'white' });
  var endDateCount = $(`.fc-day-top[data-date="${this.endDate.format('YYYY-MM-DD')}"]`).addClass('selected-date').find('.fc-day-number').length;
 
  
  if(endDateCount > 1 || startDateCount > 1){
    $('.fc-other-month').css({ 'background-color':'white','color':'black' });
  }
   
}

selectCustomeRange(start){
 
  if(this.isSelectignStartDate && this.isSelectignEndDate){
    this.isSelectignStartDate = false;
    this.isSelectignEndDate = false;
    this.startDate = null;
    this.endDate = null;
  }

  if(this.isSelectignStartDate){
    this.endDate = start;
    this.isSelectignEndDate = true;
  }else{
    this.isSelectignStartDate = true;
    this.startDate = start
  }
  this.setCustome();
}
resetCalendar(){
    const today = moment(); // Use moment() to get the current date and time
    $('#start-calendar').fullCalendar('gotoDate', today); // Use Moment object directly
    const nextMonth = moment().add(1, 'month').startOf('month');
    $('#end-calendar').fullCalendar('gotoDate', nextMonth); // Use Moment object directly    
}

}
