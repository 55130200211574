import {NgModule} from '@angular/core';
import {MDBBootstrapModule} from 'angular-bootstrap-md';
import {CommonModule} from '@angular/common';
import {ReactiveFormsModule} from '@angular/forms';
import {SharedModule} from '../../shared/shared.module';

import {ResetPasswordComponent} from './reset-password.component';

@NgModule({
  imports: [MDBBootstrapModule, CommonModule, ReactiveFormsModule, SharedModule],
  declarations: [ResetPasswordComponent],
  exports: [ResetPasswordComponent],
  providers: []
})

export class ResetPasswordModule {
}
