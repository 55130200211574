import {NgModule} from '@angular/core';
import {PropertyFormComponent} from './property-form.component';
import {ReactiveFormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {SharedModule} from '../../../shared/shared.module';
import {PropertyService} from '../../../services/property.service';
import { PaymentProcessingFeeComponent } from '../payment-processing-fee/payment-processing-fee.component';
import { CopyPropertyChargesComponent } from './copy-property-charges/copy-property-charges.component';
import {NumberInputComponent} from "../number-input/number-input.component";
import {PropertyDetailsModule} from "../property-details/property-details.module"; 
import { BookingConfirmationComponent } from './booking-confirmation/booking-confirmation.component';

@NgModule ({
  declarations: [PropertyFormComponent, PaymentProcessingFeeComponent, CopyPropertyChargesComponent,  BookingConfirmationComponent],
    imports: [ReactiveFormsModule, CommonModule, SharedModule, PropertyDetailsModule],
  entryComponents: [PaymentProcessingFeeComponent, CopyPropertyChargesComponent, NumberInputComponent, BookingConfirmationComponent],
  providers: [PropertyService],
  exports: [PropertyFormComponent]
})

export class PropertyFormModule {
}
