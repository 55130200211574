import { Component, OnInit, Output, Inject, Input } from '@angular/core';
import { EventEmitter } from 'events';
import { PluginDto } from 'src/app/models/plugin-dto';
import { DataService } from 'src/app/shared/services/data.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { PluginsService } from '../../../../services/plugins.service';
import { GenericDto } from 'src/app/models/generic-dto';
import { Property } from 'src/app/models/property';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { MessageService } from 'src/app/services/MessageService';

@Component({
  selector: 'app-import-errors',
  templateUrl: './import-errors.component.html',
  styleUrls: ['./import-errors.component.scss']
})
export class ImportErrorsComponent implements OnInit {
  errors: any[];
  errorsDisplay: any[] = [];
  synRecord: any[] = [];
  allRecord:any[]=[]
  isLoading :boolean = false;
  bookingSyncStatus:boolean = false;
  propSyncStatus:boolean = true;  
  serachActive:String = "propSyncStatus"; 

  constructor(private pluginsService: PluginsService, private messageService: MessageService,private dataService: DataService, public dialogRef: MatDialogRef<ImportErrorsComponent>, 
    @Inject(MAT_DIALOG_DATA) public plugin: {plugin: PluginDto}) {
     
      console.log(plugin)
      this.errors = this.plugin.plugin.errors;
      if(this.errors != null){
        var keys = Object.keys(this.errors);
        keys.forEach(key => {
         this.errorsDisplay.push(key + ' : ' + this.errors[key]);
        })
      }
    
    }   

    syncProcessTable(){
      this.isLoading =true;
      const result = this.pluginsService.getSynchronousTrackRecord( )
      .subscribe((response: GenericDto<any>) => {    
        this.isLoading =false;     
        this.allRecord = response.payload;  
        this.allRecord.sort(function (x, y) {
          return x.lastPropertySync - y.lastPropertySync;
      });       
        if(this.serachActive == "propSyncStatus"){
          this.propSyncStatus = !this.propSyncStatus;          
        }else{
          this.bookingSyncStatus = !this.bookingSyncStatus;          
        }
        this.shortData(this.serachActive);         
      });
    }
    shortData(shortBy){     
      let flag;     
      this.synRecord = [];      
      let synRecord = this.allRecord;
      this.serachActive = shortBy;    
        
      if(shortBy == "bookingSyncStatus"){             
        flag =this.bookingSyncStatus = !this.bookingSyncStatus; 
      }else{         
        flag = this.propSyncStatus = !this.propSyncStatus;
      }      
             
      synRecord.sort((a, b) => {
        const order = ['SUCCESS', 'IN_PROGRESS', 'DELAYED', 'FAILED',null];
        const indexA = order.indexOf(a[shortBy]);
        const indexB = order.indexOf(b[shortBy]);
      
        // If the indices are the same, use the default sorting order
        if (indexA === indexB) {
          return 0;
        }
      
        // Toggle the comparison by negating the result
        if(flag){
          return indexA < indexB ? -1 : 1;
        }else{
          return indexA > indexB ? -1 : 1;
        }
        
      });

      this.synRecord =  synRecord;   
    }
  ngOnInit() {
    this.syncProcessTable()
  }
  
  singlePropertySync(actionType,synRecord){
    this.messageService.sendMessage('Importing in Progress');
    let payload = {
      actionType: actionType,
      propId: synRecord.itemId      
    }
    this.pluginsService.startSinglePropertySync(payload)
    .subscribe((response: GenericDto<any>) => {
      if (response.code == 200) {        
         console.log(response)
      } 
    })    
  } 
 
}
