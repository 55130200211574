import { Component, EventEmitter, Input, Output, OnInit, OnDestroy } from '@angular/core';
import { PluginDto } from '../../../../models/plugin-dto';
import { GenericDto } from '../../../../models/generic-dto';
import {
  errorMessage,
  importMessage,
  info,
  waitForImportMessage
} from '../../../../constants/notification-messages.constant';
import { ToastrService } from 'ngx-toastr';
import { HttpErrorResponse } from '@angular/common/http';
import { PluginsService } from '../../../../services/plugins.service';
import { DataService } from '../../../../shared/services/data.service';
import { MatDialog, MatDialogRef } from '@angular/material';
import { ImportPropertiesComponent } from '../import-properties/import-properties.component';
import { AddNewPluginComponent } from "../add-new-plugin/add-new-plugin.component";
import { MessageService } from '../../../../services/MessageService';
import {UserService} from '../../../../services/user.service';
import { Router } from '@angular/router';
import { TokenService } from 'src/app/core/services/token.service';
import { ImportIndexService } from 'src/app/services/ImportIndex.service';
import { Subscription } from 'rxjs';
import { ImportErrorsComponent } from '../import-errors/import-errors.component';
import { TourService } from 'src/app/services/tour.service';
import { OwnerService } from 'src/app/services/owner.service';
import { SharedService } from 'src/app/services/shared.service';
import { GeneralErrorComponent } from '../general-error/general-error.component';


@Component({
  selector: 'app-plugins-container',
  templateUrl: './plugins-container.component.html',
  styleUrls: ['./plugins-container.component.scss']
})
export class PluginsContainerComponent implements OnInit, OnDestroy {
  @Input() plugins: PluginDto[];
  @Output() delete = new EventEmitter<PluginDto>();
  @Output() reloadPlugins = new EventEmitter<PluginDto>();
  @Output() editMode = new EventEmitter<PluginDto>();
  showButtons: boolean[] = new Array(2).fill(false);
  selectedPlugin: PluginDto;
  isLoadArr = [];
  userRole: string;
  interval: any;
  apiIndex: string[] = [];
  apiIndexP: string[] = [];
  isPlugingsAdded : boolean;
  message: string;
  subscription: Subscription;
  subscription1: Subscription;
  genericError:boolean = false;
  
  permissions: any[] = [];
  importRunning:boolean = false;


  constructor(public sharedService: SharedService,private router:Router, private toastrService: ToastrService, private pluginsService: PluginsService,
    private dataService: DataService,protected ownerService: OwnerService, private dialog: MatDialog,private tourService: TourService, private messageService: MessageService, private userService:UserService, public tokenService: TokenService, private importIndexService : ImportIndexService) {
      this.subscription1 = this.sharedService.message.subscribe(
        (message) => {
          this.disableSynPropertyAndBooking()
        }
      );

      this.subscription = this.importIndexService.getMessage().subscribe(message => {
        if (message && message.apiIndexes) {          
          message.apiIndexes.forEach(element => {
            console.log(element)
            if(element.flag == true) {
              this.apiIndex.push(element.propertyApiKeyId);
            } else {
              this.apiIndex.filter(api => element !== api);
              // console.log(this.apiIndex)
            }
          });

          for (let index = 0; index < message.apiIndexes.length; index++) {
            const element = message.apiIndexes[index];
            console.log(index,element.flag)
            if(element.flag == true){
              this.isLoadArr[index] = element.flag
              this.apiIndex.push(element.propertyApiKeyId);
            } else {
              this.isLoadArr[index] = element.flag
              this.apiIndex = this.apiIndex.splice(index, 1);
            }
              // console.log(index,",",element)

          }
        }
      });
      this.userRole = this.tokenService.getCurrentUser();
      if (this.userRole === 'ADMINISTRATOR') {
        this.isImporting();
      }

    }

  ngOnInit(){
    
    this.pluginsCheck();



  }
  ngAfterViewInit(): void {
    this.sharedService.shareAndCommunicate.subscribe(
      (data) => {
        this.updateComponent(data)   
      }
    );
  }
  ngOnDestroy(){
    this.subscription.unsubscribe();
  }

  isImporting() {
   
    this.userService.getUser().subscribe( user =>{
      this.importRunning = user.payload.importRunning;     
      this.permissions = user.payload.permissions;       
      for (let index = 0; index < (user.payload.importRunnigStatus && user.payload.importRunnigStatus.length); index++) {
        const apiKeyId = user.payload.importRunnigStatus[index];
        this.pluginsService.getSavedPlugins().subscribe( plugin =>{
          for (let index = 0; index < plugin.payload.length; index++) {
            const propertyKeyId = plugin.payload[index].id;
            if(apiKeyId.propertyApiKeyId == propertyKeyId){
              this.isLoadArr[index] = apiKeyId.flag
            }
          }
        });
      }
    })
  }

  editPlugin(): void {
    this.editMode.emit(this.selectedPlugin);
  }

  deletePlugin(): void {
    this.delete.emit(this.selectedPlugin);
  }

  sendMessage(): void {
    // send message to subscribers via observable subject
    this.messageService.sendMessage('Importing in Progress');
  }

  clearMessages(): void {
    // clear messages
    this.messageService.clearMessages();
    // this.importIndexService.clearMessages();
  }

  startBookingSync(plugin,ind){ 
    this.sendMessage();
    this.isLoadArr[ind] = true;
    this.importRunning = true;
    this.isLoadArr.push('booking')          
    plugin ={propertyApiKeyId:plugin.id}
    this.pluginsService.startBookingSync(plugin)
    .subscribe((response: GenericDto<any>) => {
      if (response.code == 200) {        
         
      }else{
        const importDialogRef = this.dialog.open(ImportPropertiesComponent, {
          width: '50%',
          height: '500px',
          data: {
            plugin: this.plugins,
            properties: response.payload,
            errorResponse: response.desc
          }
        });
        importDialogRef.afterClosed().subscribe(() => {
          this.reloadPlugins.emit();
          this.dataService.customEvent.next();
        })
      }

    })

  }

  startPropertySync(plugin,ind){
    console.log(plugin,ind);
    this.sendMessage();
    this.isLoadArr[ind] = true;
    this.importRunning = true;
    this.apiIndex.push(plugin.id) 
    plugin ={propertyApiKeyId:plugin.id}    
    this.pluginsService.startPropertySync(plugin)
    .subscribe((response: GenericDto<any>) => {
      if (response.code == 200) {        
         
      }else{
        const importDialogRef = this.dialog.open(ImportPropertiesComponent, {
          width: '50%',
          height: '500px',
          data: {
            plugin: this.plugins,
            properties: response.payload,
            errorResponse: response.desc
          }
        });
        importDialogRef.afterClosed().subscribe(() => {
          this.reloadPlugins.emit();
          this.dataService.customEvent.next();
        })
      }

    })

  }


  importPlugin(plugin: PluginDto, ind): void {
    this.message = ind;
   // this.apiIndex.push(ind);
    // this.importIndexService.sendMessage(this.apiIndex);

    /* console.log('Plugin' , plugin);
     console.log('Plugin ind' , ind);*/
     console.log("import booking")

    this.isLoadArr[ind] = true;
    this.apiIndex.push(plugin.id)
    this.sendMessage();
    this.pluginsService.importData(plugin)
      .subscribe((response: GenericDto<any>) => {        
        if (response.code === 200) {
          const importDialogRef = this.dialog.open(ImportPropertiesComponent, {
            width: '50%',
            height: '250px',
            data: {
              plugin: this.plugins,
              properties: response.payload,
              errorResponse: response.desc
            }
          });
          this.isLoadArr[ind] = false;
          this.reloadPlugins.emit();
          this.dataService.customEvent.next();
          this.messageService.sendMessage('Importing Completed');
          // this.importIndexService.sendMessage (null);


        } else if (response.code === 409) {
          const importDialogRef = this.dialog.open(ImportPropertiesComponent, {
            width: '50%',
            height: '500px',
            data: {
              plugin: this.plugins,
              properties: response.payload,
              errorResponse: response.desc
            }
          });
          importDialogRef.afterClosed().subscribe(() => {
            this.reloadPlugins.emit();
            this.dataService.customEvent.next();
          });
        } else {
          // show error here
          this.toastrService.error(response.desc, errorMessage, {
            timeOut: 3000,
            onActivateTick: true
          });
        }
        this.isLoadArr[ind] = false;
        this.messageService.sendMessage('Importing Completed');
        // this.importIndexService.sendMessage(null);
        // this.isImporting();
      },
        (error: HttpErrorResponse) => {
          // show error here
        });
        // this.importIndexService.getMessage().subscribe( message =>{
        //   console.log("FJVMC",message)
        // });
  }

  importBookingAndProperties(plugin: PluginDto, ind): void {
    this.message = ind;
    console.log("import booking..")

    this.isLoadArr[ind] = true;
    this.apiIndexP.push(plugin.id)
    this.sendMessage();
    const result = this.pluginsService.importAllData(plugin)
      .subscribe((response: GenericDto<any>) => {
          console.log(response)
          if (response.code === 200) {
            const importDialogRef = this.dialog.open(ImportPropertiesComponent, {
              width: '50%',
              height: '250px',
              data: {
                plugin: this.plugins,
                properties: response.payload,
                errorResponse: response.desc
              }
            });
            this.isLoadArr[ind] = false;
            this.reloadPlugins.emit();
            this.dataService.customEvent.next();
            this.messageService.sendMessage('Importing Completed');

          } else if (response.code === 409) {
            const importDialogRef = this.dialog.open(ImportPropertiesComponent, {
              width: '50%',
              height: '500px',
              data: {
                plugin: this.plugins,
                properties: response.payload,
                errorResponse: response.desc
              }
            });
            importDialogRef.afterClosed().subscribe(() => {
              this.reloadPlugins.emit();
              this.dataService.customEvent.next();
            });
          } else if (response.code === 504) {
            // timeout for larger imports - tell user to continue to wait, we re still working on importing your data
            console.log("Received a timeout - this is likely due to a larger data set requiring more time to sync",
              response);
            this.toastrService.info(waitForImportMessage , info , {
              timeOut: 3000,
              onActivateTick: true
            });

          } else {
            // show error here
            console.log("error response", response);
            this.toastrService.error(response.desc, errorMessage, {
              timeOut: 3000,
              onActivateTick: true
            });
          }
          this.isLoadArr[ind] = false;
          this.messageService.sendMessage('Importing Completed');
        },
        (error: HttpErrorResponse) => {
          console.log("importAllData returns with Error", error);
        });

    console.log("this.pluginsService.importAllData result: ", result);
  }

  pluginsCheck(){
    this.userService.redirectUser()
    .subscribe(
      (response: GenericDto<boolean>) => {
        if(response.payload === false) {
          this.isPlugingsAdded = false;
          if(this.router.url === '/dashboard/property'){
            this.isPlugingsAdded = true;
          }
        } else {
          this.isPlugingsAdded = true;
        }
      }
    )
  }
  generalErrors(generalError){
    const importDialogRef = this.dialog.open(GeneralErrorComponent, {
      width: '40%',
      height: '250px',
      data: { 
        generalError: generalError        
      }
    });
  }
  errors(plugin: PluginDto, i){
    this.pluginsService.getSavedPlugins().subscribe(plugin => {
      if(plugin.payload[i].errors !== null){
        const importDialogRef = this.dialog.open(ImportErrorsComponent, {
          width: '80%',
          height: '500px',
          data: {
            plugin: this.plugins[i]
          }
        });
      }
      if(plugin.payload[i].errors === null || plugin.payload[i].errors.length < 1 ) {
        const importDialogRef = this.dialog.open(ImportErrorsComponent, {
          width: '80%',
          height: '500px',
        data: {
            plugin: this.plugins[i]
          }
        });
      }
    })
  }
  disableSynPropertyAndBooking(){     
    this.importRunning = true;
  }
  cancelSyn(plugin){ 
    this.pluginsService.cancelSync(plugin.accountId)
    .subscribe((response: GenericDto<any>) => {
      if (response.code == 200) {         
        this.reset();        
      } 
    })
    }
    reset(){
      this.isLoadArr=[]
      this.apiIndex=[]        
      this.importRunning = false;
      this.messageService.sendMessage('Importing Completed');
    }

    updateComponent(obj){       
      if(obj.code == "resetPropertySynButton"){        
        this.reset();
      }  
      if(obj.code == "showGenricError"){      
        this.genericError =  true;
      }

    }
}
