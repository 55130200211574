import {NgModule} from '@angular/core';
import {
  MatButtonModule, MatCardModule, MatIconModule, MatTabsModule, MatMenuModule,
  MatToolbarModule, MatSidenavModule, MatExpansionModule, MatBadgeModule, MatTooltipModule, MatInputModule,
  MatFormFieldModule,
  MatCheckboxModule, MatRadioModule, MatProgressSpinnerModule, MatDividerModule, MatListModule, MatRippleModule,
  MatSelectModule, MatTableModule, MatDialogModule, MatSnackBarModule, MatChipsModule, MatDatepickerModule,
  MatDatepickerIntl, MatNativeDateModule, MatPaginator, MatPaginatorModule, MatSortModule,
} from '@angular/material';
import {MatGridListModule} from '@angular/material/grid-list';
import {FlexLayoutModule} from '@angular/flex-layout';
/**
 *  define all MDB dependent modules here
 */
@NgModule({
  exports : [
    MatButtonModule,
    MatTabsModule,
    MatCardModule,
    MatIconModule,
    MatMenuModule,
    FlexLayoutModule,
    MatToolbarModule,
    MatSidenavModule,
    MatExpansionModule,
    MatBadgeModule,
    MatTooltipModule,
    MatInputModule,
    MatFormFieldModule,
    MatCheckboxModule,
    MatRadioModule,
    MatProgressSpinnerModule,
    MatDividerModule,
    MatListModule,
    MatRippleModule,
    MatSelectModule,
    MatTableModule,
    MatPaginatorModule,
    MatDialogModule,
    MatSnackBarModule,
    MatChipsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSortModule,
    MatDialogModule,
    MatGridListModule

  ]
})
export class CustomMaterialModule {

}
