import {NgModule} from '@angular/core';
import {MDBBootstrapModule} from 'angular-bootstrap-md';

/**
 *  define all MDB dependent modules here
 */
@NgModule({
  exports : [MDBBootstrapModule]
})
export class CustomMDbModule {

}
