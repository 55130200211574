import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';


@Component({
  selector: 'app-delete-property-popup',
  templateUrl: './delete-property-popup.component.html',
  styleUrls: ['./delete-property-popup.component.scss']
})
export class DeletePropertyPopupComponent implements OnInit {
  isBookingDeleted: boolean = false;
  deletePropertyType:any ="unit-item";
  deleteUnitItemChecked:boolean = false;
  deleteRoomTypeChecked:boolean = false;
  public deletePropertyChecked = false;
  public showError: boolean = false;
  

  constructor(private dialog: MatDialogRef<DeletePropertyPopupComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { 

  }

  ngOnInit() {
    
  }
  hide(isdeleted) {
    if(this.isBookingDeleted &&   !this.deleteUnitItemChecked && !this.deleteRoomTypeChecked && !this.deletePropertyChecked){
      this.deletePropertyType ="only-bookings"; 
    }
    if(!this.isBookingDeleted &&   !this.deleteUnitItemChecked && !this.deleteRoomTypeChecked && !this.deletePropertyChecked){
      this.showError = true; 
    }

    var data = {      
      deletePropertyType:this.deletePropertyType,
      isPropertyDeleted:isdeleted
    };   
    this.dialog.close(data);
  }

  unCheced(){
    this.isBookingDeleted = false;    
    this.deleteUnitItemChecked = false;  
    this.deleteRoomTypeChecked = false;
    this.deletePropertyType = false;  
    this.deletePropertyChecked = false; 
  }

  selectOption(type) {
    this.deletePropertyType = type;
    if(type == 'property'){
      if (this.deletePropertyChecked) {
        this.showError = false; 
        this.isBookingDeleted = true;    
        this.deleteUnitItemChecked = true;  
        this.deleteRoomTypeChecked = true;      
      } else {
        this.unCheced();        
      }
    }else if(type == 'room-type'){
      if (this.deleteRoomTypeChecked) {         
        this.deleteUnitItemChecked = true;    
        this.showError = false;    
      }else{
        this.unCheced(); 
      } 
    }else if(type == 'unit-item'){
      if (this.deleteUnitItemChecked) {         
        this.deleteRoomTypeChecked =  false ;
        this.showError = false;       
      }else{
        this.unCheced(); 
      } 
    }
   
  }
  

}
