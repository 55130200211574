import { Component, OnInit, Input, Output, EventEmitter, SimpleChange } from '@angular/core';
import { FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-maintenance-job-media',
  templateUrl: './maintenance-job-media.component.html',
  styleUrls: ['./maintenance-job-media.component.scss']
})
export class MaintenanceJobMediaComponent implements OnInit {
  @Input()files;
  @Output() medias = new EventEmitter<any>();
  formData: any = new FormData();

  constructor(private builder: FormBuilder) { }

  ngOnInit() {
    if(!this.files || this.files === null || this.files === undefined) { this.files = new Array(); }
    console.log(this.files);
  }

  onSubmit() {
    
    if(this.files.length > 1) {
      this.files.forEach(file => {
        this.formData.append('invoices', file);
      });
    } else {
      this.formData.append('invoices', this.files[0]);
    }
    this.medias.emit(this.formData);
  }
  uploadFile(event) {
    console.log(this.files);
    
    if(!this.files || this.files === undefined || this.files === 0 || this.files === null) { this.files = new Array(); }
    for (let index = 0; index < event.length; index++) { this.files.push(event[index]); }  
  }
  deleteAttachment(index) {
    this.files.splice(index, 1)
  }
}
