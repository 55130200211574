import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {RegistrationComponent} from './registration.component';
import {CommonModule} from '@angular/common';
import {SharedModule} from '../shared/shared.module';
import {AuthService} from '../services/auth.service';

@NgModule({
  imports: [ReactiveFormsModule, CommonModule, SharedModule ],
  declarations: [RegistrationComponent],
  providers: [AuthService],
  exports: [RegistrationComponent],
  entryComponents: []
})

export class RegistrationModule {
}
