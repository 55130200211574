/**
 *  Feature Utility will convert feature Codes of property & rooms with visible format
 */
import {Feature} from '../models/feature';
import {Subfeature} from '../models/subfeature';

export function getFeatures(featureCodes: Array<Array<string>>) {
  const features: Array<any> = new Array<any>();
  // iterate features codes
  featureCodes.forEach((featureCode: Array<string>) => {
    if (featureCode.length > 0) {

      // replace '_' with <space>' '
      featureCode = replaceUnderscore(featureCode);
      if (featureCode.length === 1) {

        // push to features array
        features.push(featureCode[0]);
        return;
      } else {

        // create new object with key = featureCodes[0]
        features.push(prepareFeature(featureCode));
      }
    }

  });

  return features;
}

/**
 * replace '_' with space ' ' in array of string
 */
function replaceUnderscore(stringArray: Array<string>): Array<string> {
  return stringArray.map((value: string) => {
    return value.replace(/_/g, ' ').toLowerCase();
  });
}

/**
 * countDuplicates will count duplicate values in array
 */

function countDuplicates(index: number, elements: Array<string | number>): number {
  elements = elements.filter((element: string | number) => element === elements[index]);
  return elements.length;
}

/**
 * check key already exists in object
 */
function alreadyExist(key: string , object: Feature): boolean {
  return object.subfeatures.filter((sub) => sub.title === key).length > 0;
}

/**
 * prepare Feature object which will contain following keys
 * title: string , subfeatures: []
 */
function prepareFeature(featureCode: Array<string>): Feature {
  const feature: Feature = {
    title : null,
    subfeatures : []
  };
  featureCode.forEach((value: string, index: number) => {
    if (index !== 0 && !alreadyExist(featureCode[index], feature)) {
      const subfeature: Subfeature = new Object() as Subfeature;
      subfeature.title = value;
      subfeature.quantity = countDuplicates(index, featureCode);
      feature.subfeatures.push(subfeature);
    }
  });
  feature.title = featureCode[0];
  return feature;
}
