import {Component, OnInit, Inject} from '@angular/core';
import {MatDialog, MatDialogRef, MatDialogModule, DateAdapter, MAT_DATE_FORMATS} from '@angular/material';
import {MAT_DIALOG_DATA} from '@angular/material';
import {Platform} from '@angular/cdk/platform';
import {FormGroup, FormBuilder, Validators, FormArray, FormControl} from '@angular/forms';
import {percentageValidator, numbersOnlyValidator} from 'src/app/validators/number.validator';
import {NgForm} from '@angular/forms';
import {PropertyService} from 'src/app/services/property.service';
import {ToastrService} from 'ngx-toastr';
import {errorMessage} from 'src/app/constants/notification-messages.constant';
import {response} from "express";


export interface paymentProcessingFees {
  channelName: string;
  // platformProcessingFeesInAmount: number;
  platformProcessingFeesInPercentage: number;
}


@Component({
  selector: 'app-payment-processing-fee',
  templateUrl: './payment-processing-fee.component.html',
  styleUrls: ['./payment-processing-fee.component.scss']

})


export class PaymentProcessingFeeComponent implements OnInit {

  form: FormGroup;
  showForm = true;
  lastIndex = 0;
  paymentProcessingFeesDS:any

  formSubmitted = false;
  propertyId: string;
  type: string;
  isFormInitialized: boolean;
  isUpdateLoading: boolean;
  title: string;


  platformsList: any[] = [
    {value: 'Direct'}, {value: 'Bookit'}, {value: 'NZAA'}, {value: 'Laterooms'}, {value: 'Airbnb Ical'},
    {value: 'Flipkey'}, {value: 'Guestlink'}, {value: 'Google'}, {value: 'Expedia'}, {value: 'Wimdu'},
    {value: 'iCal Export'}, {value: 'Agoda'}, {value: 'Travelocity'}, {value: 'Booking.com'}, {value: 'Tripadvisor'},
    {value: 'iCal import 1'}, {value: 'Budgetplaces'}, {value: 'Tablethotels'}, {value: 'Hostelworld'}, {value: 'Visitscotland'},
    {value: 'Holidaylettings'}, {value: 'Bedandbreakfast EU'}, {value: 'iCal import 2'}, {value: 'iCal import 3'}, {value: 'Homeaway XML'},
    {value: 'Bedandbreakfast NL'}, {value: 'Atraveo'}, {value: 'Feratel'}, {value: 'Webrooms NZ'}, {value: 'Lastminute'},
    {value: 'Hotelbeds'}, {value: 'Housetrip'}, {value: 'Nineflats'}, {value: 'Homeaway iCal'}, {value: 'OTA'},
    {value: 'Trivago'}, {value: 'Hostellinginternational'}, {value: 'Airbnb XML'}, {value: 'Airbnb'}, {value: 'Tomas'}, {value: 'Ostrovok'},
    {value: 'Bookeasy AU'}, {value: 'Ctrip'}, {value: 'Asiatravel'}, {value: 'Tripadvisor Rentals'}, {value: 'Traveloka'},
    {value: 'HRS'}
  ];

  constructor(
    private formBuilder: FormBuilder,
    private propertyService: PropertyService,
    public thisdialogRef: MatDialogRef<PaymentProcessingFeeComponent>,
    private toastrService: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.propertyId = data.propertyId;
    this.type = data.type;
    this.title = data.title;
    this.paymentProcessingFeesDS = data.paymentProcessingFeesDS;
    // console.log(this.propertyId);

    var newPlatformList = JSON.parse(localStorage.getItem("platform"));

    for (let i = 0; i < newPlatformList.length; i++) {
      if (!this.platfromExist(newPlatformList[i])) {
        this.platformsList.push({value: newPlatformList[i]});
      }
    }
    this.platformsList = this.platformsList.sort((obj1, obj2) => {
      if (obj1.value > obj2.value) {
        return 1;
      }

      if (obj1.value < obj2.value) {
        return -1;
      }

      return 0;
    });
  }


  platfromExist(name): boolean {
    for (let i = 0; i < this.platformsList.length; i++) {
      if (name.toLowerCase() === this.platformsList[i].value.toLowerCase()) {
        return true;
      }
    }
    return false;
  }

  ngOnInit() {   
    this.getPaymentProcessing();
    this.formSubmitted = true;

  }

  buildForm(addDefault: boolean) {
    let feesGroup = new FormArray([]);

    if (addDefault) {
      feesGroup.push(
        new FormGroup({
          channelName: new FormControl('', [Validators.required]),
          // platformProcessingFeesInAmount: new FormControl(0, [ Validators.compose([numbersOnlyValidator])]),
          platformProcessingFeesInPercentage: new FormControl(0, [Validators.compose([percentageValidator])])
        })
      )

    }
    this.form = new FormGroup({
      channels: feesGroup,
    })
    this.isFormInitialized = true;


  }

  onAddFilter() {
    console.log((<FormArray>this.form.get('channels')).value);
    if (this.form.value.channels.length < this.platformsList.length) {
      (<FormArray>this.form.get('channels')).push(
        new FormGroup({
          channelName: new FormControl(null, [Validators.required]),
          // platformProcessingFeesInAmount: new FormControl(null, [Validators.compose([numbersOnlyValidator])]),
          platformProcessingFeesInPercentage: new FormControl(null, Validators.compose([percentageValidator]))
        })
      )
    } else {
      this.showForm = false
    }

    this.lastIndex = this.form.value.channels.length - 1;
  }

  removeFilter(index: number) {
    // console.log(index);
    (<FormArray>this.form.get('channels')).removeAt(index);

    this.lastIndex = this.form.value.channels.length - 1;

    if (this.form.value.channels.length <= 1) {
      this.showForm = true;
    }
    if (index == 0) {
      this.onAddFilter();
    }
  }


  getPaymentProcessing() {
    let paymentProcessingFees = null;
          
            if(this.paymentProcessingFeesDS){
              paymentProcessingFees =   this.paymentProcessingFeesDS//response.payload;
              this.buildForm(false)
              paymentProcessingFees.forEach(paymentProcessingFee => {
                this.populateDefaultValues(paymentProcessingFee);
                this.lastIndex = this.form.value.channels.length - 1;
              })
          
            } 
            else{
              this.buildForm(true);
            }
            return ;
    
    this.propertyService.getPlatformFees(this.propertyId, this.type,)
      .subscribe(
        (response) => {
          if (response.code === 200) {
            let paymentProcessingFees = null;
          
            if(this.paymentProcessingFeesDS){
              paymentProcessingFees =   this.paymentProcessingFeesDS//response.payload;
          
            } 
            paymentProcessingFees =    response.payload;
          
              
             
            this.buildForm(false)
            paymentProcessingFees.forEach(paymentProcessingFee => {
              this.populateDefaultValues(paymentProcessingFee);
              this.lastIndex = this.form.value.channels.length - 1;
            })
          } else {
            this.buildForm(true);
          }
        }
      );
  }


  populateDefaultValues(paymentProcessingFee: paymentProcessingFees) {

    (<FormArray>this.form.get('channels')).push(
      new FormGroup({
        channelName: new FormControl(paymentProcessingFee.channelName, [Validators.required]),
        // platformProcessingFeesInAmount: new FormControl(paymentProcessingFee.platformProcessingFeesInAmount, [Validators.compose([numbersOnlyValidator])]),
        platformProcessingFeesInPercentage: new FormControl(paymentProcessingFee.platformProcessingFeesInPercentage, Validators.compose([percentageValidator]))
      })
    )
    // console.log(this.form.getRawValue());
  }


  onSubmit($event) {
    this.formSubmitted = true;
    // console.log(this.form.getRawValue())

  }

  onCloseUpdate() {
    this.isUpdateLoading = true;
    var channels = this.form.getRawValue().channels;
    console.log(this.form.getRawValue().channels)
    let sendRequest = true;
    if (sendRequest) {
      if (channels.length == 1 && channels[0].channelName == null) {
        channels = null;
      }
      this.propertyService.saveProcessingFees(this.propertyId, this.type, channels)
        .subscribe(
          (response: any) => {
            this.isUpdateLoading = false;
            // console.log(response);
            const channelsObj = {
              channels,
              isUpdated: true
            };
            this.thisdialogRef.close(channelsObj);
            this.toastrService.success('Platform Commission Added Successfuly', 'Success', {
              timeOut: 3000,
              onActivateTick: true
            })
          }
        );

    } else {
      this.toastrService.error(errorMessage);
    }

  }


  onCloseCancel() {
    this.thisdialogRef.close('Cancel');
  }
 disablePlatform(channelName){
  const exists = (<FormArray>this.form.get('channels')).value.some(channel => channel.channelName === channelName);
  if (exists) {
    return true;
 } else {
    return false;
 }

 }

}
