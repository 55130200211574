import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {PropertyService} from '../../../services/property.service';
import {Property} from '../../../models/property';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {User} from '../../../models/user';
import {JobAssign} from '../../../models/job-assign';
import {EventService} from '../../../shared/services/event.service';
import {MatDialog, MatDialogRef, MatPaginator, MatSelectChange, MatSort, PageEvent, MatTableDataSource} from '@angular/material';
import {Router} from '@angular/router';
import {UserService} from '../../../services/user.service';
import {GenericDto} from '../../../models/generic-dto';
import {HttpErrorResponse} from '@angular/common/http';
import {ToastrService} from 'ngx-toastr';
import {
  info,
  jobAssignSuccessfully,
  success,
} from '../../../constants/notification-messages.constant';
import {MatDatepickerInputEvent} from '@angular/material/typings/esm5/datepicker';
import {JobDetailsModalComponent} from '../../../shared/components/job-details-modal/job-details-modal.component';
import * as moment from 'moment';
import {DataService} from '../../../shared/services/data.service';
import {JobManageComponent} from '../job-manage/job-manage.component';
import {Notification} from '../../../models/notification';
import {Subscription} from 'rxjs';
import {NotificationService} from '../../../services/notification.service';
import {TokenService} from '../../../core/services/token.service';
import {JobInstructionComponent} from '../job-instruction/job-instruction.component';
import {UpdateJobComponent} from '../update-job/update-job.component';
import { JobInstructions } from './job-instruction.model';
import { JobCommentsComponent } from '../job-comments/job-comments.component';
import { UploadImageFormComponent } from 'src/app/shared/components/upload-image-form/upload-image-form.component';


@Component({
  selector: 'app-job-assign',
  templateUrl: './job-assign.component.html',
  styleUrls: ['./job-assign.component.scss'],
  providers: [PropertyService, NotificationService]
})
export class JobAssignComponent implements OnInit, OnDestroy {
  displayEvent: any;
  properties: Array<Property>;
  cleaners: Array<User>;
  selectedProperty: Property;
  user: User;
  isLoading = false;
  jobForm: FormGroup;
  @Input() jobAssigns: JobAssign[];
  displayColumns: any;
  adminForm: boolean;
  dialogStatus: boolean;
  cleanerForm: boolean;
  jobAssign: JobAssign;
  emptyState = false;
  jobLength: boolean;
  notification: Notification[];
  subscription: boolean;
  events: any;
  endDate = 0;
  roomId = '';
  propertyId = 'all';
  thirdSubscription: Subscription;
  jobMessage: string;
  dataSource;
  message: any;
  hasJobs = false;
  jobInstructions: JobInstructions;

  jobId: string;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  constructor(/*public dialogRef: MatDialogRef<JobAssignComponent>,*/ private fb: FormBuilder, protected eventService: EventService, public dialog: MatDialog, private router: Router,
              private propertyService: PropertyService, private userService: UserService, private toastrService: ToastrService, private dataService: DataService, private notificationService: NotificationService, public tokenService: TokenService) {
  }

  ngOnInit() {

    if (this.tokenService.getCurrentUser() === ('ADMINISTRATOR')) {
      this.dialogStatus = true;
      this.adminForm = true;
      this.cleanerForm = false;
    } else {
      this.dialogStatus = false;
      this.adminForm = false;
      this.cleanerForm = true;

    }
    this.thirdSubscription = this.notificationService.getMultiValueObservable().subscribe((response1: number) => {
      this.load();
    });

    this.loadJobdetails();
    this.resetProperty();
    this.createJob();

  }

  ngOnDestroy() {
    this.thirdSubscription.unsubscribe();
  }

  load(): void {
    this.notificationService.getPendingNotifications()
      .subscribe((response: GenericDto<Notification[]>) => {
        if (response.code === 200) {
          this.notification = response.payload;
          this.notification.forEach((notifify: Notification) => {
            if (this.tokenService.getCurrentUser() === ('ADMINISTRATOR')) {
              if (response.code === 200) {
                if (response.payload.length > 0) {
                  this.loadJobdetails();
                }
              }
            } else {
              if (notifify.type === 'JOB' && notifify.status === 'PLANNED') {
                if (response.code === 200) {
                  if (response.payload.length > 0) {
                    this.loadJobdetails();
                  }
                }
              }
            }
          });
        }
      });
  }


  loadJobdetails(): void {
    this.propertyService.getJobAssignDetails()
      .subscribe((response: GenericDto<JobAssign[]>) => {
          if (response.code === 200) {
            if (response.payload.length > 0) {
              this.hasJobs = true;
              this.jobAssigns = response.payload;
              this.jobLength = true;
              this.jobAssigns.forEach((jobAssignable: JobAssign) => {
                if (this.tokenService.getCurrentUser() === 'CLEANER') {
                  if (jobAssignable.jobRequest === 'PLANNED') {
                    jobAssignable.statusCheck = false;
                    jobAssignable.statuscheck1 = true;
                    jobAssignable.statuscheck2 = false;
                  } else if (jobAssignable.jobRequest === 'COMPLETED') {
                    jobAssignable.statusCheck = true;
                    jobAssignable.statuscheck1 = false;
                    jobAssignable.statuscheck2 = false;
                  } else if (jobAssignable.jobRequest === 'REJECTED') {
                    jobAssignable.statusCheck = true;
                    jobAssignable.statuscheck1 = false;
                    jobAssignable.statuscheck2 = false;
                  } else {
                    jobAssignable.statusCheck = false;
                    jobAssignable.statuscheck1 = false;
                    jobAssignable.statuscheck2 = true;
                  }
                  jobAssignable.jobInstructions = this.setJobInstuctions(jobAssignable);
                } else {
                  jobAssignable.statusCheck = true;
                }
              });

            } else {
              this.jobLength = false;
              this.emptyState = true;
            }
            if (this.adminForm) {

              this.displayColumns = ['propertyId', 'unitName', 'endingDate', 'jobTime', 'cleanerName', 'jobRequest', 'deleteJob', 'comments'];
              
              this.dataSource = new MatTableDataSource(this.jobAssigns);
              this.dataSource.sort = this.sort;
              this.dataSource.paginator = this.paginator;

            }

          } else {
            this.hasJobs = false;
            // show errors here
            this.jobMessage = 'No Jobs';
          }
        },
        (error: HttpErrorResponse) => {
          // show errors here
        });
  }

  getInstruction(instruction: any): void {
    const dialogRef = this.dialog.open(JobInstructionComponent, {
      width: '70%',
      height: '70%',
      data: {message: instruction}
    });
    dialogRef.afterClosed().subscribe(() => {
    });

  }


  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  createJob() {
    this.jobForm = this.fb.group({
      property: [null, [Validators.required]],
      roomId: [null, [Validators.required]],
      checkInDate: [null, [Validators.required]],
      checkOutDate: [null, [Validators.required]],
      cleanerId: [null, [Validators.required]],
      instructions: [null, [Validators.required]],
    });
  }


  fetchProperties(event: boolean): void {
    // const propertyInfo = this.jobForm.get('property').value;
    // const roomInfo = this.jobForm.get('room').value;
    if (event) {
      this.propertyService.getAll().subscribe((response: GenericDto<Property[]>) => {
        if (response.code === 200) {
          this.properties = response.payload || [];
          console.log('Property: ' + this.properties);
        } else {
          // show errors
        }
      }, (error: HttpErrorResponse) => {
        // handle errors here
      });
    }
    /*if (propertyInfo === null ? roomInfo : this.jobForm.get('room').value) {
      this.jobForm.get('room').patchValue(null);
    }*/
  }


  getCleaners(event): void {
    if (event) {
      this.userService.getUserByRole(['CLEANER'])
        .subscribe((data: GenericDto<User[]>) => {
          // console.log('get role', this.tokenService.getCurrentUser());
          if (data.code === 200) {
            this.cleaners = data.payload || [];
          } else {
            // show errors
          }
        }, (error: HttpErrorResponse) => {
          // handle errors here
        });
    }
  }


  acceptJob(jobAssign: JobAssign, status: any): void {
    this.dialogStatus = false;
    if (status === 'Accepted') {
      jobAssign.jobRequest = 'ACCEPTED';
    } else if (status === 'Rejected') {
      jobAssign.jobRequest = 'REJECTED';
    } else {
      jobAssign.jobRequest = 'COMPLETED';
    }

    this.propertyService.updateJob(jobAssign.id, jobAssign.cleanerId, jobAssign.jobRequest)
      .subscribe((response1: GenericDto<JobAssign>) => {
        if (response1.code === 200) {
          this.toastrService.success(' Job ' + status + ' Successfully ', success, {
            timeOut: 3000,
            onActivateTick: true
          });
          this.loadJobdetails();
          this.dialogStatus = false;
        }

      }, (error: HttpErrorResponse) => {
        this.isLoading = false;
        // show errors here
      });
  }

  uploadImage(jobAssign: JobAssign) {
    //console.log('here');
    const dialogRef = this.dialog.open(UploadImageFormComponent, {
      width: '70%',
      height: '90%',
      data: { jobAssign: jobAssign, isCleaner: this.cleanerForm }
    });
    dialogRef.afterClosed().subscribe(() => {
      console.log('closed')
      this.ngOnInit();
    });

  }
  commentJob(jobAssign: JobAssign){
    // console.log(jobAssign)
    const dialogRef = this.dialog.open(JobCommentsComponent, {
      width: '50%',
      height: '50%',
      data: { jobAssign: jobAssign, isCleaner: this.cleanerForm }
    });
    dialogRef.afterClosed().subscribe(() => {
      console.log('closed')
    });

  }

  viewImageAdmin(jobAssigns: JobAssign[], id: string){
    let job: JobAssign;
    for(let i= 0; i < jobAssigns.length; i++){
      if(jobAssigns[i].id === id){
        job = jobAssigns[i];
        break;
      }
    }
    
    const dialogRef = this.dialog.open(UploadImageFormComponent, {
      width: '70%',
      height: '90%',
      data: { jobAssign: job, isCleaner: this.cleanerForm }
    });

  }

  commentAdminJob(jobAssigns: JobAssign[], id: string){
    let job: JobAssign;
    for(let i= 0; i < jobAssigns.length; i++){
      if(jobAssigns[i].id === id){
        job = jobAssigns[i];
        break;
      }
    }
    const dialogRef = this.dialog.open(JobCommentsComponent, {
      width: '50%',
      height: '50%',
      data: { jobAssign: job, isCleaner: this.cleanerForm }
    });
    dialogRef.afterClosed().subscribe(() => {
     // console.log('closed')
    });

  }


  createNewJobDialog() {
    const dialogRef = this.dialog.open(JobManageComponent, {
      width: '200%',
      height: '50%'
    });
    dialogRef.afterClosed().subscribe(() => {
      this.loadJobdetails();
    });
  }

  dialogBox(): void {
    this.dialogStatus = false;
    this.createNewJobDialog();
  }


  fetchAllPropertyBookings(event: MatSelectChange): void {
    if (event.value === 'all') {
      this.propertyId = 'all';
      this.createJob();
    }
  }


  setMinDate(value: any): Date {
    return new Date(value);
  }

  lastDate(): Date {
    return new Date(this.jobForm.get('toDate').value);
  }

  resetProperty() {
    this.selectedProperty = new Object() as Property;
  }

  /**
   *  On `fromDate` change reset `toDate` if it is before `fromDate`
   * @param {MatDatepickerInputEvent<Date>} event
   */
  fromDateChanged(event: MatDatepickerInputEvent<Date>): void {
    const changedDate = new Date(event.value);
    this.jobForm.get('checkInDate').patchValue(new Date(this.jobForm.get('checkInDate').value));
  }

  clickButton(model: any) {
    this.displayEvent = model;
  }

  eventClick(model: any) {
    this.jobDetails(model.event);
  }

  updateEvent(model: any) {
    model = {
      event: {
        id: model.event.id,
        start: model.event.start,
        end: model.event.end,
        title: model.event.title
      },
      duration: {
        _data: model.duration._data
      }
    };
    this.displayEvent = model;
  }

  loadEvents(bookings: Array<any>): Array<any> {
    /* const myDate: any = new Date();

     const date = myDate * 1;

     const date1 = new Date(date).toUTCString();
    */

    if (!!bookings) {
      return bookings.map(item => {
        /* const date11 = new Date(item.firstNight).toUTCString();
    */
        const dataItemInfo = {
          title: item.guestFirstName + ' ' + item.guestName,
          start: moment.unix(item.firstNight).format('YYYY-MM-DD HH:mm:ss'),
          end: moment.unix(item.lastNight).format('YYYY-MM-DD HH:mm:ss'),
          item
        };
        return Object.assign({}, item, dataItemInfo);
      });
    }
    return [];
  }

  /*
  * Show event in dialog
  */

  jobDetails(event: any) {
    const dialogRef = this.dialog.open(JobDetailsModalComponent, {
      data: {
        event: event
      },
    });

    dialogRef.afterClosed().subscribe(() => {
      dialogRef.close();
    });
  }

  deleteJob(id: any) {
    this.propertyService.deleteMultipleJobs(id)
      .subscribe((response1: GenericDto<JobAssign>) => {
        if (response1.code === 200) {
          this.toastrService.success(' Job Successfully Deleted!', success, {
            timeOut: 3000,
            onActivateTick: true
          });
        }
      }, (error: HttpErrorResponse) => {
        this.isLoading = false;
        // show errors here
      });
    this.loadJobdetails();
  }

  updateJobDialog(currentJobObject: any, id: any) {
    const dialogRef = this.dialog.open(UpdateJobComponent, {
      width: '50%',
      height: '25%',
      data: {'jobId': id, 'currentJobObject': currentJobObject}
    });
    dialogRef.afterClosed().subscribe(() => {
      this.loadJobdetails();
    });
  }

  setJobInstuctions(jobAssign: JobAssign): JobInstructions {
    return new JobInstructions(jobAssign.accessInstructions, jobAssign.garbageInstructions,
        jobAssign.parkInstructions, jobAssign.otherInformations, jobAssign.cleaningChecklist);
  }


}
