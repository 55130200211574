import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
// Component
import {ProfileDetailsComponent} from './profile-details.component';
import {SharedModule} from '../../../shared/shared.module';
import {UpdateProfileModule} from '../update-profile/update-profile.module';
import {ChangePasswordModule} from '../change-password/changePassword.module';
import {ChangePasswordComponent} from '../change-password/changePassword.component';
import { FormsModule } from '@angular/forms';

@NgModule ({
  imports: [SharedModule, CommonModule, RouterModule , UpdateProfileModule , ChangePasswordModule, FormsModule],
  declarations: [ProfileDetailsComponent],
  exports: [ProfileDetailsComponent],
  entryComponents: [ChangePasswordComponent]
})

export class ProfileDetailsModule {}
