import {Component} from '@angular/core';
import {Router} from '@angular/router';

@Component ({
  selector: 'app-dashboard-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})

export class ProfileComponent {
  constructor (public router: Router) {}
}
