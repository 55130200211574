import {Router} from '@angular/router';
import {Component} from '@angular/core';

@Component ({
  selector: 'app-dashboard-owner',
  templateUrl: './owner.component.html',
  styleUrls: ['./owner.component.scss']
})

export class OwnerComponent {
  constructor (public router: Router) {}
}
