import { NgModule } from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
// Dashboard Component
import {DashboardComponent} from './dashboard.component';
// Import Other Necessary Module
import {SharedModule} from '../shared/shared.module';
import {DashboardHomeModule} from './home/dashboard-home.module';
import {DashboardRoutingModule} from './dashboard-routing.module';
import {DashboardCalendarModule} from './dashboard-calendar/dashboard-calendar.module';
import {PropertyDashboardModule} from './property/property.module';
import {CleanerModule} from './owner/cleaner.module';
import {AssetsService} from '../services/assets.service';
import {ProfileModule} from './profile/profile.module';
import {JobModule} from './job/job.module';
import {OwnerDashboardHomeModule} from '../ownerDashboardRouting/ownerDashboardHome/owner-dashboard-home.module';
import {OwnerDetailsModule} from "./owner/owner/owner-details/owner-details.module";
import {OwnerModule} from "./owner/owner/owner.module";
import { PayoutReportComponent } from './payout-report/payout-report.component';
import { FormsModule } from '@angular/forms';
import { PayoutReportModule } from './payout-report/payout-report.module';
import { RevenueExpenseComponent } from './revenue-report/revenue-expense/revenue-expense.component';
import { BillingCheckoutFailureComponent } from './billing/billing-checkout-failure/billing-checkout-failure.component';
import { BillingCheckoutSuccessComponent } from './billing/billing-checkout-success/billing-checkout-success.component';
import { BillingService } from './billing/billing.service';

import {  ReactiveFormsModule } from '@angular/forms';
import { StaffComponent } from './staff/staff.component';
import { StaffDetailsComponent } from './staff/staff-details/staff-details.component';
import { StaffListComponent } from './staff/staff-list/staff-list.component';
import { StaffModule } from './staff/staff.module';
import { ConfirmDialougeComponent } from './confirm-dialouge/confirm-dialouge.component';
import { StatementComponent } from './statement/statement.component';
import { StatementDetailsComponent } from './statement-details/statement-details.component';
import { StatementMediaComponent } from './statement-media/statement-media.component';
import { DragDropDirective } from './statement-media/drag-drop.directive';
import { GeneralRequestsComponent } from './general-requests/general-requests.component';
import { ShowDetailsPopupComponent } from './show-details-popup/show-details-popup.component';
import { BookingDetailsComponent } from './booking-details/booking-details.component';


@NgModule({
  declarations : [DragDropDirective, DashboardComponent, RevenueExpenseComponent,  BillingCheckoutFailureComponent, BillingCheckoutSuccessComponent, ConfirmDialougeComponent, StatementComponent, StatementDetailsComponent, StatementMediaComponent, GeneralRequestsComponent, ShowDetailsPopupComponent, BookingDetailsComponent],
  imports : [RouterModule,  ReactiveFormsModule, SharedModule, CommonModule, DashboardHomeModule, DashboardRoutingModule, DashboardCalendarModule,
              PropertyDashboardModule, CleanerModule, StaffModule,ProfileModule , JobModule , OwnerDashboardHomeModule , OwnerModule, PayoutReportModule,FormsModule],
  providers : [AssetsService,BillingService],
  exports : [DashboardComponent , ConfirmDialougeComponent,ShowDetailsPopupComponent, BookingDetailsComponent],
  entryComponents: [DashboardComponent, RevenueExpenseComponent,ConfirmDialougeComponent, BookingDetailsComponent, ShowDetailsPopupComponent]
})
export class DashboardModule {
}
