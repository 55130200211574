import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { SharedModule } from "src/app/shared/shared.module";
import { PayoutReportComponent } from "./payout-report.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { MatSortModule } from "@angular/material";
import { MatPaginatorModule } from '@angular/material';

@NgModule({
    imports: [RouterModule, SharedModule, FormsModule, CommonModule, MatSortModule, MatPaginatorModule, ReactiveFormsModule],
    declarations: [PayoutReportComponent],
    exports: [PayoutReportComponent]
})
export class PayoutReportModule{

}