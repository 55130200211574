import { Injectable } from '@angular/core';
import * as Stomp from 'stompjs';
import SockJS from 'sockjs-client/dist/sockjs.min.js';
import {environment} from '../../environments/environment'; 
import {ToastrService } from 'ngx-toastr';
import { SharedService } from 'src/app/services/shared.service';
import { AuthGuard } from '../core/guards/auth.guard';
import { TokenService } from '../core/services/token.service';
@Injectable({
  providedIn: 'root'
})
export class WebSocketService {
  stompClient = null;
  constructor( public tokenService: TokenService,public authGuard: AuthGuard, public sharedService: SharedService,private toaster: ToastrService) { }
  connect() {   
    const socket = new SockJS(environment.API+'/public/ws');
    this.stompClient = Stomp.over(socket);
   
    this.stompClient.connect({}, (frame) => {   
        this.stompClient.subscribe('/topic/push-notification', (message) => {
        const notification = JSON.parse(message.body);         
        if(notification.toUserType == "TO_ACCOUNT_USERS" && localStorage.getItem("accountId") != notification.toUser ){
          return false;
        }else if(!(this.authGuard.isPreviewLogin()) && notification.toUserType == "TO_USER_ID" && localStorage.getItem("userId") != notification.toUser) {
          return false;
        }else if(notification.toUserType == "TO_CHAT_ID" && localStorage.getItem("chatId") != notification.toUser){
          return false;
        }else if(notification.toUserType == "TO_USER_ID" &&
        this.authGuard.isPreviewLogin() && this.tokenService.getPreviewOwner().id   != notification.toUser) {
          return false;
        }
            
        if(notification.subject == "INFO"){
          this.sharedService.setshareAndCommunicate({code:"resetPropertySynButton",data:null});   
          this.sharedService.setshareAndCommunicate({code:"updateLastSynTime",data:null}); 
         
          this.toaster.info(notification.content);
        }else if(notification.subject == "SUCCESS"){
          this.toaster.success(notification.content);
        }else if(notification.subject == "CHAT"){
          this.sharedService.setshareAndCommunicate({code:"CHAT",data:{message:notification.content}}); 
        }else if(notification.subject == "GENERAL_REQUEST"){         
          this.sharedService.setshareAndCommunicate({code:"GENERAL_REQUEST",data:{message:notification.content}});           
        }else if(notification.subject == "checkPaymentMethodsStatus"){         
          localStorage.setItem('paymentMethodExist','true');        
        }else{           
          if (notification.content.includes("Generic Error Occured")) {
            this.sharedService.setshareAndCommunicate({code:"showGenricError",data:null}); 
          } 
          this.toaster.error(notification.content);
        }
      });
    });
  }


  
}
