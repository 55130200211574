import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormGroup, FormControl, Validators, FormBuilder, NgForm } from '@angular/forms';
import { numbersOnlyValidator } from 'src/app/validators/number.validator';
import { PropertyService } from 'src/app/services/property.service';
import { ToastrService } from 'ngx-toastr';
import { success, errorMessage } from 'src/app/constants/notification-messages.constant';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-import-booking',
  templateUrl: './import-booking.component.html',
  styleUrls: ['./import-booking.component.scss']
})
export class ImportBookingComponent implements OnInit {

  monthsForm: FormGroup;
  fromMonths = new FormControl('', [Validators.required, Validators.compose([numbersOnlyValidator]), Validators.max(36), Validators.min(3)]);
  currentPropertyId: string;
  isLoading = false;

  @ViewChild('f') shoppingListForm: NgForm;
  constructor(private dialog: MatDialogRef<ImportBookingComponent>, @Inject(MAT_DIALOG_DATA) data: any,
    private formBuilder: FormBuilder, private propertyService: PropertyService, private toastrService: ToastrService) {
    this.currentPropertyId = data.currentPropertyId;
   }

  ngOnInit() {
    // this.monthsForm = this.formBuilder.group({
    //   fromMonths: [3, [Validators.required, Validators.compose([numbersOnlyValidator]), Validators.max(36), Validators.min(3)]],
    // })
    this.monthsForm = new FormGroup({
      'fromMonths': new FormControl("", [Validators.required, Validators.max(24), Validators.min(3)])
    })
  }

  importBooking(){
    if (this.monthsForm.invalid) {
      return;
    }
    this.isLoading = true;
   this.propertyService.reImportBookings(this.currentPropertyId, this.monthsForm.value.fromMonths)
    .subscribe(
      (response: any) => {
        console.log(response);
        this.isLoading = false;
        this.dialog.close('SUCCESS');
        this.toastrService.success(`Bookings imported for ${this.monthsForm.value.fromMonths} months` ,
        success, {
          timeOut: 3000,
          onActivateTick: true
        });
      },
      (error: HttpErrorResponse) => {
        this.dialog.close('ERROR');
        this.toastrService.error("Bookings Import failed",
        errorMessage, {
          timeOut: 3000,
          onActivateTick: true
        });
      }
    );

  }




}
