import {Component, ViewContainerRef, AfterViewInit, ViewChild, OnInit} from '@angular/core';
import {LoaderService} from './shared/services/loader.service';
import {LoaderDirective} from './shared/directives/loader.directive';
import {StorageService} from './core/services/storage.service';
import {remember} from './constants/storage.constants';
import {NotificationService} from './services/notification.service';
import {GenericDto} from './models/generic-dto';
import {UserService} from './services/user.service';
import {DataService} from './shared/services/data.service';
import { WebSocketService } from 'src/app/services/web-socket.service';
import { environment } from '../environments/environment';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit, OnInit {
  @ViewChild(LoaderDirective) loaderDirective: LoaderDirective;

  constructor(public webSocketService: WebSocketService,
              private loaderService: LoaderService,
              private storageService: StorageService,
              private notificationservice: NotificationService ,
              private userService: UserService ,
              private dataService: DataService,
              private metaService: Meta
  ) {
  }

  ngOnInit() {
    this.webSocketService.connect();;
    if (this.storageService.get(remember, true) === 'true') {
      this.storageService.configureStorage(true);
    } else {
      this.storageService.configureStorage(false);
    }

    this.metaService.updateTag({ name: 'environment', content: environment.environmentName });

    window.analytics.page();

  /*  this.userService.getUser().subscribe((response: GenericDto<any>) => {
      if (response.code === 200) {
        this.dataService.user = response.payload;
        this.dataService.userAsync.next(response.payload);

      } else {
        // show error and redirect to login page
      }

    }, (error: HttpErrorResponse) => {
      // show error and redirect to login page
    });*/
  }

ngAfterViewInit() {
    const viewContainerRef: ViewContainerRef = this.loaderDirective.viewContainerRef;
    this.loaderService.setViewRef(viewContainerRef);
  }
}
