import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { CommonModule } from "@angular/common";
import { AssetsService } from "../../services/assets.service";
import { AdminDashboardHomeComponent } from "./admin-dashboard-home.component";
import { SharedModule } from "src/app/shared/shared.module";
import { UserActionComponent } from "./user-action/user-action.component";
import { ReactiveFormsModule } from "@angular/forms";
import { AdminDashboardRoutingModule } from "../admin-dashboard-routing.module";

@NgModule({
  declarations : [AdminDashboardHomeComponent, UserActionComponent],
  imports : [RouterModule, SharedModule, CommonModule, ReactiveFormsModule, AdminDashboardRoutingModule],
  providers : [AssetsService],
  exports : [AdminDashboardHomeComponent],
  entryComponents: [AdminDashboardHomeComponent, UserActionComponent]
  })
  export class AdminDashboardHomeModule {
  }
  