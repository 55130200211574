import {Component, Input, OnInit, AfterViewInit} from '@angular/core';
import {RoomType} from '../../../../models/RoomType';
import {Property} from 'src/app/models/property';
import {MatDialog, MatTableDataSource} from '@angular/material';
import {CopyPropertyChargesComponent} from '../../property-form/copy-property-charges/copy-property-charges.component';

@Component({
  selector: 'app-room-info',
  templateUrl: './room-info.component.html',
  styleUrls: ['./room-info.component.scss']
})
export class RoomInfoComponent implements AfterViewInit {

  @Input() rooms: Array<RoomType>;
  @Input() property: any;
  @Input() unit: string;
  @Input() unitId: string;
  dataSource: any;
  paymentProcessingDS: any;
  displayedColumns = ['platform', 'fees'];
  cleaningFeeFormula
  managementCommissionFormula
  defaultTaxFormula
  netProfitFormula
   

  constructor(public dialog: MatDialog) {
  }

  ngOnInit() {   
    this.cleaningFeeFormula = this.getUnitFormula(this.property.roomTypes[0].cleaningFeeFormula);
    this.managementCommissionFormula = this.getUnitFormula(this.property.roomTypes[0].managementCommissionFormula);
    this.defaultTaxFormula = this.getUnitFormula(this.property.roomTypes[0].defaultTaxFormula);
    this.netProfitFormula= this.getUnitFormula(this.property.roomTypes[0].netProfitFormula);
    

    if (!this.property.roomTypes[0].cleaningFeeFormula) {
      this.property.roomTypes[0].cleaningFeeFormula = [0];
    }

    if (!this.property.roomTypes[0].managementCommissionFormula) {
      this.property.roomTypes[0].managementCommissionFormula = [0];
    }

    if (!this.property.roomTypes[0].defaultTaxFormula) {
      this.property.roomTypes[0].defaultTaxFormula = [0];
    }

    if(!this.property.roomTypes[0].netProfitFormula){
      this.property.roomTypes[0].netProfitFormula = ["Gross Revenue", "-","Cleaning Fee", "-", "Tax", "-" , "Platform Commission", "-", "Payment Processing Fee", "-", "Management Commission",]
    }

    //console.table(this.property.roomTypes);

   

    //this.dataSource = new MatTableDataSource(this.property.platformProcessingFees);
    if(this.property.roomTypes && this.property.roomTypes.length >0 && this.property.roomTypes[0].paymentProcessingFee){
      this.paymentProcessingDS = new MatTableDataSource(this.getUnitProcessingFee(this.property.roomTypes[0].paymentProcessingFee));
    }
      
    if(this.property.roomTypes && this.property.roomTypes.length >0 && this.property.roomTypes[0].platformProcessingFees){
      this.dataSource = new MatTableDataSource(this.getUnitProcessingFee(this.property.roomTypes[0].platformProcessingFees));
    }
      

  }
  private getUnitProcessingFee(processingFeesList){
    if(!processingFeesList){
      return null;
    }
    for (var i = 0; i < processingFeesList.length; i++) {
      var unitValue = processingFeesList[i];
      if (unitValue.unit == this.unitId) { 
        return unitValue.value;
      } 
    }
   
  }

  private getUnitFormula(formulaList) {
    if (!formulaList) {
      return null;
    }
    for (var i = 0; i < formulaList.length; i++) {
      var unitValue = formulaList[i];
      if (unitValue.unit == this.unitId) {
        if (unitValue.unit == this.unitId) {
          if (Array.isArray( unitValue.formula)) {
            let resultString = unitValue.formula.map(element => element.toString()+"  ").join("");
            return resultString
          }else{
  
            return  unitValue.formula;
          }
         
          
        } 
        return unitValue.formula;
      } 
    }
    for (var i = 0; i < formulaList.length; i++) {
      var unitValue = formulaList[i];
      if (unitValue.unit == this.unit) {
        return unitValue.formula;
      } 
    }
  }

  ngAfterViewInit() {
    // this.dataSource = new MatTableDataSource(this.property.platformProcessingFees);
    // this.paymentProcessingDS = new MatTableDataSource(this.property.paymentProcessingFees);
  }


  isFeature(object: any): boolean {
    return object instanceof Object;
  }

  copy(room: any, copyingFor: any) {
    if (copyingFor === 'netProfit' || copyingFor === 'cleaningFee' || copyingFor === 'propertyManagementCommission' || copyingFor === 'paymentProcessingFees' || copyingFor === 'platformProcessingFees' || copyingFor === 'defaultTax') {
      room = this.property;
    }
    const importDialogRef = this.dialog.open(CopyPropertyChargesComponent, {
      width: '50%',
      height: '400px',
      data: {
        copyingFor: copyingFor,
        property: room,
        unit: this.unit,
        roomId: this.rooms[0].id,
        unitId: this.unitId
      }
    });
  }
}
