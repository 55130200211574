import {Injectable} from '@angular/core';
import {
  HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest
} from '@angular/common/http';
import {catchError} from 'rxjs/internal/operators';
import {Observable, throwError} from 'rxjs/index';
import {Router} from '@angular/router';
import {StorageService} from '../services/storage.service';
import {ToastrService} from 'ngx-toastr';
import {errorMessage} from '../../constants/notification-messages.constant';
import {GenericDto} from '../../models/generic-dto';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private storageService: StorageService, private router: Router, private toastrService: ToastrService) {

  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError( (err: HttpErrorResponse) => {
        console.log("Error intercept", err);
        console.log("URL: ", req.url);
        if (err instanceof HttpErrorResponse) {
          const genericDto: GenericDto<any> = err.error;
          if (this.isUnauthorized(err.status)) {           
            if(localStorage.getItem("accountId") != null ){

              this.toastrService.info("Session has expired. Please log in again.", errorMessage, {
                timeOut: 4000,
                onActivateTick: true
              });
              // remove storage data and redirect to login page
              this.storageService.clearAll();
              this.router.navigate(['/login']);
  
              // show error notification
              return throwError(err);
            }else if(genericDto.desc == "Email or Password is not correct"){
              this.toastrService.error(genericDto.desc || err.error.message, errorMessage, {
                timeOut: 4000,
                onActivateTick: true
              });
              // remove storage data and redirect to login page
              this.storageService.clearAll();
              this.router.navigate(['/login']);
  
              // show error notification
              return throwError(err);
            }
            
          } else if (this.isInactive(err.status)) {
            return throwError(err);
          } else if(this.isLocked(err.status)) {
            let toastr = this.toastrService.error(genericDto.desc || err.error.message, errorMessage, {
              timeOut: 10000,
              disableTimeOut: true,
              positionClass: 'toast-center-center',
              closeButton: true,
            });
          }else {
            // TODO: Clean this up - a workaround since import booking should be async and return right away
            if (req.url.indexOf("/property/importBooking") > 0 || req.url.indexOf("/property/importProperty") > 0) {
              this.toastrService.info(
                "We are still syncing your data, please refresh your browser in a few minutes",
                "Still Working", {
                timeOut: 4000,
                onActivateTick: true
              });
              console.error("Unknown/unexpected error occurred", {url: req.url, err})
            }
            else {
              this.toastrService.error(genericDto.desc || err.error.message, errorMessage, {
                timeOut: 4000,
                onActivateTick: true
              });
              return throwError(err);
            }
          }
        }
        return throwError(err);
      })
);
  }

  private isUnauthorized(status: number): boolean {
    return status === 401;
  }

  private isInactive(status: number): boolean {
    return status === 403;
  }

  private isLocked(status: number): boolean {
    return status === 423;
  }
}
