import {NgModule} from '@angular/core';
import {PluginsComponent} from './plugins.component';
import {SharedModule} from '../../../shared/shared.module';
import {AddNewPluginComponent} from './add-new-plugin/add-new-plugin.component';
import {PluginHelpModalComponent} from "./plugin-help-modal/plugin-help-modal.component";
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {PluginsContainerComponent} from './plugins-container/plugins-container.component';
import {PluginsService} from '../../../services/plugins.service';
import {ImportPropertiesComponent} from './import-properties/import-properties.component';
import {NgSlimScrollModule, SLIMSCROLL_DEFAULTS} from 'ngx-slimscroll';
import {MatDialogModule} from "@angular/material";
import {ImportErrorsComponent} from './import-errors/import-errors.component';
import {SendNotificationComponent} from "../../owner/owner-details/send-notification.component";
import { GeneralErrorComponent } from './general-error/general-error.component';

@NgModule({
  declarations : [PluginsComponent, AddNewPluginComponent, PluginHelpModalComponent, PluginsContainerComponent,
    ImportPropertiesComponent, ImportErrorsComponent, SendNotificationComponent, GeneralErrorComponent],
  imports : [CommonModule, FormsModule, ReactiveFormsModule, SharedModule, NgSlimScrollModule, MatDialogModule],
  exports : [PluginsComponent],
  providers : [PluginsService, {provide: SLIMSCROLL_DEFAULTS, useValue: {
    alwaysVisible: false,
    gridOpacity: '0.2', barOpacity: '0.5',
    gridBackground: '#e7e7e7',
    gridWidth: '6',
    gridMargin: '2px 2px',
    barBackground: '#acb1b5',
    barWidth: '6',
    barMargin: '2px 2px'
    }
  }],
  entryComponents: [AddNewPluginComponent, PluginHelpModalComponent, ImportPropertiesComponent ,
    PluginsContainerComponent, ImportErrorsComponent, SendNotificationComponent,GeneralErrorComponent]
})
export class PluginsModule {

}
