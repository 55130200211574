import { Injectable } from '@angular/core';
import {GenericDto} from '../models/generic-dto';
import {BehaviorSubject, Observable, throwError} from 'rxjs/index';
import { HttpService } from '../core/services/http.service';
import { LoaderService } from '../shared/services/loader.service';
import {catchError, tap} from 'rxjs/internal/operators';
import {environment} from '../../environments/environment';
import {HttpErrorResponse} from '@angular/common/http';
import {
  getUser,markAsRead, getRequests,reply,hasNewMessage, getConversation,getAttachments, generalRequests
} from '../constants/statement-api.constatnts';

@Injectable({
  providedIn: 'root'
})
export class GeneralRequestsService {

  constructor(private httpService: HttpService, private loaderService: LoaderService) {
  }

  getUser(): Observable<GenericDto<any>> {
    return this.httpService.getAuth<GenericDto<any>>(environment.API + getUser);
  }

  getRequests(ownerId,adminId,sendBy,page,pageSize): Observable<GenericDto<any>> {
    return this.httpService.getAuth<GenericDto<any>>(environment.API + getRequests +ownerId+"/"+adminId+"/"+sendBy+"/" +page+"?size="+pageSize);
  }
  getConversation(generalRequestsId,sendBy,page,pageSize): Observable<GenericDto<any>> {
    return this.httpService.getAuth<GenericDto<any>>(environment.API + getConversation +generalRequestsId+"/"+sendBy+"/" +page+"?size="+pageSize);
  }

  

  hasNewMessage(user): Observable<GenericDto<any>> {
    return this.httpService.postAuth<GenericDto<any>>(environment.API + hasNewMessage ,user);
  }

  sendRequest(request): Observable<GenericDto<any>> {
    return this.httpService.postAuth<GenericDto<any>>(environment.API + generalRequests, request);
  }
  sendReply(request): Observable<GenericDto<any>> {
    return this.httpService.postAuth<GenericDto<any>>(environment.API + reply, request);
  }

  markAsRead(request): Observable<GenericDto<any>> {
    return this.httpService.postAuth<GenericDto<any>>(environment.API + markAsRead,request);
  }

  deleteRequest(id: string): Observable<GenericDto<any>> {
    return this.httpService.deleteAuth<GenericDto<any>>(environment.API + generalRequests + id);
  }
  
  
 
}
