import {Injectable, OnInit} from '@angular/core';
import {HttpService} from '../core/services/http.service';
import {interval, Observable} from 'rxjs/index';
import {GenericDto} from '../models/generic-dto';
import {Notification} from '../models/notification';
import {environment} from '../../environments/environment';
import {getAllPendingNotification, sendEmailNotification} from '../constants/notification-api.constants';
import {OwnerDto} from "../models/owner-dto";


@Injectable()
export class NotificationService {
  constructor(private httpService: HttpService) {
  }

  convert(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }


    getPendingNotifications(): Observable<GenericDto<Notification[]>> {
      return this.httpService.getAuth<GenericDto<Notification[]>>(environment.API + getAllPendingNotification);
    }

    getMultiValueObservable() {
      return new Observable<number>(observer => {
        let count = 0;
        const interval1 = setInterval(() => {
          observer.next(count++);
        }, 10000);
        return () => {
          clearInterval(interval1);
        };
        }
      );
    }

    sendOwnerRevenueReportEmail(startDate: Date, endDate: Date, ownerId: string) {
      const payload = {
        "notificationType": "Owner Monthly Notification",
        "notificationMethod": "EMAIL",
        "ownerId": ownerId,
        "startDate": this.convert(startDate),
        "endDate": this.convert(endDate),
        "manualNotification": "true"
      }
      return this.httpService.postAuth<GenericDto<string>>(environment.API + sendEmailNotification, payload);
    }



}
