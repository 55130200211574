import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators, CheckboxRequiredValidator, FormArray } from '@angular/forms';
import { PMSPlugin } from '../../../../models/pms-plugin';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatSelectChange } from '@angular/material';
import { PluginsService } from '../../../../services/plugins.service';
import { HttpErrorResponse } from '@angular/common/http';
import { GenericDto } from '../../../../models/generic-dto';
import { PluginDto } from '../../../../models/plugin-dto';
import { errorMessage } from '../../../../constants/notification-messages.constant';
import { ToastrService } from 'ngx-toastr';
import { PropertyService } from '../../../../services/property.service';
import { DataService } from '../../../../shared/services/data.service';
import { ImportPropertiesComponent } from '../import-properties/import-properties.component';
import { MessageService } from '../../../../services/MessageService';
import {PluginHelpModalComponent} from "../plugin-help-modal/plugin-help-modal.component";
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'app-add-new-plugin',
  templateUrl: './add-new-plugin.component.html',
  styleUrls: ['./add-new-plugin.component.scss']
})
export class AddNewPluginComponent implements OnInit, OnDestroy {
  @Input() plugins: PMSPlugin[];
  @Output() hideCard = new EventEmitter<boolean>();
  @Output() reloadPlugins = new EventEmitter<any>();
  @Input() plugin: PluginDto;
  @Input() editMode = false;

  selectedPlugin: PMSPlugin;
  isformPrepared: boolean;
  isAllValid: boolean;
  isLoading: boolean;
  isImportProgress: boolean;
  saved: boolean;
  errorResponse: string;
  importForm: FormGroup;
  checked: true;
  divSize: number = 40;
  excludeBookingSize = 1;
  excludeBookingDivHeight: string = this.divSize +"px";

  /*defaultCleaningFee: string;*/

  constructor(public sharedService: SharedService,private toastrService: ToastrService, private dataService: DataService, private messageService: MessageService,
    private pluginsService: PluginsService, private dialog: MatDialog, public dialogRef: MatDialogRef<AddNewPluginComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { pluginList: PMSPlugin[], editMode: boolean, plugin: PluginDto }) {
    this.plugins = data.pluginList;
    this.plugin = data.plugin;
    this.editMode = data.editMode;

    //  this.defaultCleaningFee = this.plugin.defaultCleaningFee;
  }


  ngOnInit() {
    this.createForm();
    this.checked=true;
  }

  sendMessage(): void {
    // send message to subscribers via observable subject
    this.messageService.sendMessage('Importing in Progress');
  }

  clearMessages(): void {
    // clear messages
    this.messageService.clearMessages();
  }

  createForm() {
    this.importForm = new FormGroup({
      plugin: new FormControl(this.editMode ? this.getSelectedPlugin() : null, [Validators.required]),
      api: new FormControl(this.editMode ? this.plugin.apiKey : null, [Validators.required]),
      defaultCleaningFee: new FormControl(this.editMode ? this.plugin.defaultCleaningFee : null, [Validators.required]),
      fromMonths: new FormControl(this.editMode ? this.plugin.fromMonths : 3, [Validators.required, , Validators.max(24), Validators.min(3)]),
      apiRateLimit:new FormControl(this.editMode? this.plugin.apiRateLimit : 12, [Validators.required]),
      bookingSources: new FormArray([])
    });

    if (this.editMode) {
      this.appendForm({ source: null, value: this.getSelectedPlugin() });
    }
  }

  appendForm(event: MatSelectChange) {
    this.selectedPlugin = event.value;
    const typeForm = new FormGroup({});
    this.selectedPlugin.propertyType.forEach((key: string) => {
      typeForm.addControl(key, new FormControl(this.editMode ? this.getValue(key) : false, []));
    });
    if(this.plugin !== null && this.plugin.excludeBookingSource !== null){
      const control = <FormArray> this.importForm.controls['bookingSources'];
      this.plugin.excludeBookingSource.forEach((value:string)=>{
        control.push(new FormControl(value));
      });
      this.excludeBookingSize = this.plugin.excludeBookingSource.length == 0 ? 1 : (this.plugin.excludeBookingSource.length + 1);
      this.excludeBookingDivHeight = this.excludeBookingSize * this.divSize + "px";
      console.log(this.excludeBookingDivHeight);
    }

    this.importForm.addControl('propertyType', typeForm);
    this.isformPrepared = true;
  }

  showPluginHelp(pmsName: string) {
    const dialogRef = this.dialog.open(PluginHelpModalComponent,{
      width:'70%',
    });
    dialogRef.componentInstance.pmsName = pmsName;
  }


  importPlugin(): void {
    // this.import.emit(plugin);
    this.isImportProgress = true;
    console.log('import calling');
    this.sendMessage();

    this.pluginsService.startPropertySync({propertyApiKeyId:this.plugin.id})
  //  this.pluginsService.importData(this.plugin)
      .subscribe((response: GenericDto<any>) => {
        if (response.code === 200) {
          // successfully imported
          // this.loadPlugins();
          /*      const importDialogRef = this.dialog.open(ImportPropertiesComponent, {
                   width: '65%',
                   data: {
                     plugin: this.plugin,
                     properties: response.payload
                   }
                 });
                 importDialogRef.afterClosed().subscribe(() => {

                 });*/
          //this.reloadPlugins.emit();
          this.dataService.customEvent.next();
          this.hide();
          this.sharedService.setMessage( "Syn-Button-dsable");
        } else if (response.code === 409) {
          const importDialogRef = this.dialog.open(ImportPropertiesComponent, {
            width: '45%',
            height: 'auto',
            data: {
              plugin: this.plugin,
              properties: response.payload,
              errorResponse: response.desc
            }
          });
          importDialogRef.afterClosed().subscribe(() => {
            this.reloadPlugins.emit();
            this.dataService.customEvent.next();
            this.hide();
          });
        } else {
          // show error here
          this.toastrService.error(response.desc, errorMessage, {
            timeOut: 3000,
            onActivateTick: true
          });

        }
        this.isImportProgress = false;
        this.messageService.sendMessage('Importing Completed');


      },
        (error: HttpErrorResponse) => {
          // show error here
          // show error here
          this.toastrService.error(error.message, errorMessage, {
            timeOut: 3000,
            onActivateTick: true
          });
          this.isImportProgress = false;
          this.messageService.sendMessage('Importing Completed');

        });
  }


  getValue(key: string): boolean {
    return this.plugin.propertyType.filter((type: string) => type.toLowerCase() === key.toLowerCase()).length > 0;
  }

  save() {
    if (this.importForm.valid && !this.saved) {
      this.isLoading = true;
      const plugin: PluginDto = this.preparePluginDto();
      this.savePlugin(plugin);
    } else {
      // show warning message
    }
  }

  edit() {
    if (this.importForm.valid) {
      this.isLoading = true;
      const plugin: PluginDto = this.preparePluginDto();
      plugin.id = this.plugin.id;
      plugin.status = this.plugin.status;
      plugin.userId = this.plugin.userId;
      this.editPlugin(plugin);
    } else {
      // show warning message
    }
  }

  preparePluginDto(): PluginDto {
    const pluginDto: PluginDto = new Object() as PluginDto;
    pluginDto.name = (this.importForm.get('plugin').value as PMSPlugin).name;
    pluginDto.apiKey = this.importForm.get('api').value as string;
    pluginDto.defaultCleaningFee = this.importForm.get('defaultCleaningFee').value as string;
    pluginDto.pluginId = (this.importForm.get('plugin').value as PMSPlugin).id;
    pluginDto.propertyType = this.getPropertyType();
    pluginDto.fromMonths = this.importForm.get('fromMonths').value as number
    pluginDto.apiRateLimit = this.importForm.get('apiRateLimit').value as number
    pluginDto.accountId = localStorage.getItem('accountId');
    pluginDto.excludeBookingSource=this.importForm.get('bookingSources').value
    return pluginDto;
  }

  getPropertyType(): Array<string> {
    const types: Array<string> = [];
    Object.keys((this.importForm.get('propertyType') as FormGroup).controls)
      .forEach((key) => {
        if ((this.importForm.get('propertyType').get(key).value as boolean)) {
          types.push(key);
        }

      });
    return types;
  }


  hide() {
    this.dialogRef.close();
  }

  savePlugin(plugin: PluginDto) {
    this.pluginsService.save(plugin).subscribe((response: GenericDto<any>) => {
      if (response.code === 200) {
        this.isLoading = false;
        this.saved = true;
        this.reloadPlugins.emit();
        this.plugin = response.payload as PluginDto;
      } else {
        // show error here
        this.isLoading = false;
        this.toastrService.error(response.desc, errorMessage, {
          timeOut: 3000,
          onActivateTick: true
        });
      }
    }, (error: HttpErrorResponse) => {
      // show error here
      this.isLoading = false;
    });
  }

  editPlugin(plugin: PluginDto) {
    this.pluginsService.edit(plugin).subscribe((response: GenericDto<any>) => {
      if (response.code === 200) {
        this.isLoading = false;
        this.reloadPlugins.emit();
      } else {
        // show error here
        this.toastrService.error(response.desc, errorMessage, {
          timeOut: 3000,
          onActivateTick: true
        });
      }
    }, (error: HttpErrorResponse) => {
      // show error here
      this.isLoading = false;
    });
  }


  getSelectedPlugin(): PMSPlugin {
    return this.plugins.filter((value: PMSPlugin) => this.plugin.pluginId === value.id)[0];
  }

  ngOnDestroy() {
    this.editMode = false;
  }


  addBookingSource(){
    const control = <FormArray> this.importForm.controls['bookingSources'];
    control.push(new FormControl(""));
    this.excludeBookingSize += 1;
    this.excludeBookingDivHeight = this.divSize * this.excludeBookingSize + "px";
  }

  removeBookingSource(index: any){
    const control = <FormArray> this.importForm.controls['bookingSources'];
    control.removeAt(index);
    this.excludeBookingSize -= 1;
    this.excludeBookingDivHeight = this.divSize * this.excludeBookingSize + "px";
  }

}
