import {Injectable} from '@angular/core';
import {StorageService} from './storage.service';
import {currentUserKey, tokenKey} from '../../constants/storage.constants';
import {User} from '../../models/user';

@Injectable()
export class TokenService {
  constructor(private storageService: StorageService) {
  }

  saveToken(token: string): void {
    this.storageService.set(tokenKey, token);
  }

  removeToken(): void {
    this.storageService.clear(tokenKey);
  }

  getToken(): string {
    return this.storageService.get(tokenKey);
  }

  saveCurrentUser(user: User): void {
    this.storageService.set(currentUserKey, user);
  }

  saveRole(role: any): void {
    this.storageService.set(currentUserKey, role);
  }

  getCurrentUser(): any {
    return this.storageService.get(currentUserKey);
  }

  setUserId(userId: string): void {
    this.storageService.set("userId", userId);
  }

  getUserId(): any {
    return this.storageService.get("userId");
  }

  setPreviewLogin(value: boolean): void {
    this.storageService.set("preview", value);
  }

  getPreviewLogin(): boolean {
    return this.storageService.get("preview") == "true";
  }

  setPreviewUser(object: any): void{
    this.storageService.set("previewUser", object);
  }

  getPreviewUser(): any{
    return this.storageService.get("previewUser");
  }

  setPreviewOwner(object: any): void {
    const serializedObject = JSON.stringify(object);
    this.storageService.set("previewOwner", serializedObject);
  }

  getPreviewOwner(): any {
    const serializedObject = this.storageService.get("previewOwner");
    return JSON.parse(serializedObject);
  }
}
