import {Component, HostListener, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import {PropertyService} from "../../services/property.service";


@Component({
  selector: 'app-property-dashboard',
  templateUrl: './property.component.html',
  styleUrls: ['./property.component.scss']
})
export class PropertyDashboardComponent implements OnInit{
  private screenWidth$ = new BehaviorSubject<number>
    (window.innerWidth);
  showToggle: boolean;
  constructor(private router: Router, private propertyService: PropertyService) {
    propertyService.getPlatformList().subscribe(response => {
     // console.log("updating platform list", response);
      if (response.code == 200) {
        localStorage.setItem("platform", JSON.stringify(response.payload));
      }
    });
  }

  ngOnInit(): void {
    this.getScreenWidth().subscribe(width => {
      if (width < 960) {
        this.showToggle = false;
     }
     else {
      this.showToggle = true;
     }
   });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.screenWidth$.next(event.target.innerWidth);
  }

 getScreenWidth(): Observable<number> {
    return this.screenWidth$.asObservable();
  }

}
