/**
 * Define all notification messages here
 */

export const errorMessage = 'Error';

export const info = 'Info';

export const success = 'Success';

export const warning = 'Warning';

export const somethingWrong = 'Something went wrong';

export const successLogin = 'You are logged in now';

export const successPropertyCreated = 'Rental Created Successfully';

export const successPropertyDeleted = 'Rental Deleted Successfully';

export const maintenanceJobCreate = 'Expense Created!';

export const successPropertyUpdated = 'Rental Updated Successfully';

export const successRoomTypeUpdated = 'RoomType Updated Successfully';

export const importMessage = 'Properties sync is in progress, this process may take longer time.';
export const cancelSyn = 'Properties sync is cancelled.';

export const waitForImportMessage = 'Importing is still in progress, please refresh your screen in a few minutes.';

export const successActivated = 'Account has been activated successfully';

export const inviteMessage = 'Invitation successfully sent';

export const changePasswordSuccessfully = 'Change Password Successfully';

export const jobAssignSuccessfully = 'Job Assign Successfully';

export const successUserDeleted = 'User Deleted Successfully';

export const successUserUpdated = 'User Profile Updated Successfully';
