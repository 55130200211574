import { Component, OnInit, ViewChild, ElementRef, OnDestroy, AfterViewInit, HostListener } from '@angular/core';
import { AuthService } from '../../../services/auth.service';
import { Router } from '@angular/router';
import { GenericDto } from '../../../models/generic-dto';
import { StorageService } from '../../../core/services/storage.service';
import { ToastrService } from 'ngx-toastr';
import { errorMessage, success } from '../../../constants/notification-messages.constant';

import { UserService } from '../../../services/user.service';
import { LoaderDirective } from '../../directives/loader.directive';
import { LoaderService } from '../../services/loader.service';
import { TokenService } from '../../../core/services/token.service';
import { AuthGuard } from '../../../core/guards/auth.guard';
import { Subscription } from 'rxjs';
import { MessageService } from '../../../services/MessageService';
import { ImportIndexService } from 'src/app/services/ImportIndex.service';
import {tokenKey} from "../../../constants/storage.constants";
import {Owner} from "../../../models/owner";
import {OwnerService} from "../../../services/owner.service";
import { TourService } from 'src/app/services/tour.service';
import { SharedService } from 'src/app/services/shared.service';
import { OnChanges } from '@angular/core';
import { SimpleChanges } from '@angular/core';
import { PluginsService } from 'src/app/services/plugins.service';
import { MatDialog } from '@angular/material';
import { ImportErrorsComponent } from 'src/app/dashboard/property/plugins/import-errors/import-errors.component';
import { GeneralRequestsService } from 'src/app/services/general-requests.service';
import { HttpErrorResponse } from '@angular/common/http';


@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})

export class SidebarComponent implements OnInit, OnDestroy,AfterViewInit,OnChanges {
  @ViewChild(LoaderDirective) loaderDirective: LoaderService;
  userRole: string;
  isExpanded: boolean;
  isImportProgress: boolean;
  interval: any;
  logo:any =  "logo-small.png";
  organizationId:any = null; 
  enableRequestInbox:boolean = false;

  messages: any[] = [];
  subscription: Subscription;
  subscription1: Subscription;

  Home_FLAG: any=true;
  current_step: any;
  error_flag: boolean=false;
  plugins: any;
  interval1: any;
  currentUser:any;
  isCustomNotificationRed :boolean = false;
  permissions: any[] = [];
  loginUserType: string;
  hasNewMessage:number;
  // @HostListener('scroll', ['$event']) private onScroll($event:any):void {
  //   console.log($event.srcElement.scrollLeft, $event.srcElement.scrollTop);
  // };
  constructor(private generalRequestsService:GeneralRequestsService, private authService: AuthService, public router: Router, private messageService: MessageService,
    private storageService: StorageService, private toastrService: ToastrService, private ownerService: OwnerService,
    private loaderService: LoaderService, public tokenService: TokenService, public authGuard: AuthGuard,
    private elem: ElementRef, private userService: UserService, private importIndexService: ImportIndexService,
    private tourService: TourService,
    private sharedService :SharedService,
    public pluginsService: PluginsService, private dialog: MatDialog) {
    this.subscription = this.messageService.getMessage().subscribe(message => {
      if (message) {
        this.messages.push(message);
        //console.log('message:--' + this.messages[0].text);
        if (message.text === 'Importing in Progress') {
          this.isImportProgress = true;
        } else if (message.text === 'Importing Completed') {

          this.isImportProgress = false;
        }
      } else {
        // clear messages when empty message received
        this.messages = [];
      }
    });
  }
  ngOnChanges(changes:any): void {
  }
  ngAfterViewInit(): void {

    this.sharedService.shareAndCommunicate.subscribe(
      (data) => {
        this.updateComponent(data)   
      }
    );
  }

  ngOnDestroy() {


    
    // unsubscribe to ensure no memory leaks
    this.subscription.unsubscribe();
  }

  ngOnInit() {
    this.getAccount();
    this.subscription = this.sharedService.message.subscribe(
      (message) => {
         this.isImportProgress = true;
      }
    );

    let sideBarExpand = window.localStorage.getItem('sideBarExpand');
    if (sideBarExpand) {
      this.isExpanded = (sideBarExpand == 'true');
      this.containerResize();
      }
    else {
      window.localStorage.setItem('sideBarExpand', 'false');
      this.isExpanded = false;
     // console.log("KKKKKK1",this.isExpanded)
    }

    this.userRole = this.tokenService.getCurrentUser();
    if (this.userRole === 'ADMINISTRATOR') {
      this.userRole = 'ADMIN';
      this.isImporting();
      this.interval1 = setInterval(() => {
        this.isImporting();
      }, 30000);


    }

    this.pluginsService.getSavedPlugins().subscribe((plugin:any) => {
      this.plugins=plugin.payload;
      if(plugin.payload[0].errors !== null){
        this.error_flag=true;
      }else{
        this.error_flag=false;
      }
    });
    let steps= [
      // {
      //   element: "#dashboard1",
      //   title: "Dashboard1",
      //   content: "owner dashboard allows owners to access and manage various aspects of their hotel's operations through a web-based interface like Room availability and booking management , total Revenue management ,Recently Added Bookings and more",
      //   placement: 'right',
      //   debug: true,
      //    backdrop: true,
      // },
      {
        element: "#dashboard2",
          title: "Dashboard",
          content: "This is the homepage dashboard, where you will get a summary of your bookings and revenue revenue.",
          placement: 'right',
          backdrop: true,
      },
      {
        element: "#rentals",
          title: "Rentals page",
          content: "View your current room and property setup.",
          placement: 'right',
          backdrop: true,
      },
      {
        element: "#calendar",
        title: "Calendar or List view",
        content: "View bookings in a calendar or in a simple list format",
        placement: 'right',
        backdrop: true,
        onShow:(tour)=>{
        document.getElementById('calender1').click();
      },
        onPrev:function (tour) {
          document.getElementById('calender1').click();
        }
      },
      {
        element: "#bookingid",
                  title: "Booking",
                  content: "With the duration drop down you can filter the view to Monthly or Yearly",
                  placement: 'right',
                  backdrop: true,
                  onNext:function (tour) {
                    document.getElementById('calender1').click()
                },
      },
    //   {
    //     element: "#revenueReport",
    // title: "Revenue Report",
    // content: "With the duration drop down you can filter the view to Monthly or Yearly",
    // placement: 'right',
    // backdrop: true,
    // onShow:function (tour) {
    //   document.getElementById('calender1').click()
    // },
    // onNext:function (tour) {
    //   document.getElementById('ddd').scrollTop += 950
    //  }
    // },
    {
        element: "#reports",
        title: "Reports",
        content: "With the duration drop down you can filter the view to Monthly or Yearly",
        backdrop: true,
        placement: 'right',
        onNext: (tour)=> {
          if(this.userRole!='OWNER'){
            document.getElementById('setting').click();
          }
      }

      },
    //     {
    //       element: "#jobManagement",
    // title: "Job Management",
    // content: "With the duration drop down you can filter the view to Monthly or Yearly",
    // backdrop: true,
    // placement: 'right',
    //     },
      {
        element: "#owner",
        title: "Owner settings",
        content: "Owner configuration is where you can configure owner settings as well as invite new owners to your account. ",
        backdrop: true,
        placement: 'right',
        onPrev: (tour) =>{
          if(this.userRole!=='OWNER'){
            document.getElementById('setting').click();
          }
  }
      },{
        element: "#pms",
    title: "PMS settings",
    content: "Import your properties, rooms and bookings by setting up your PMS connection here. ",
    backdrop: true,
    placement: 'right',
      },
      {
        element: "#billing",
    title: "Billing",
    content: "With the duration drop down you can filter the view to Monthly or Yearly",
    backdrop: true,
    placement: 'right',
    onNext: (tour)=> {
      if(this.userRole!='OWNER'){
        document.getElementById('setting').click()
      }
    }
      },
      {
        element: "#profile",
    title: "Account settings",
    content: "Configure your account level information: currency setting, personal contact information and account password.",
    backdrop: true,
    placement: 'right',
    onShow: (tour)=> {
      if(this.userRole!='OWNER'){
    document.getElementById(window.localStorage.getItem('sideBarExpand')==='true'?'myAccount2':'myAccount1').click()
      }
    },
    onPrev: (tour)=> {
      if(this.userRole!='OWNER'){
      document.getElementById('setting').click()
    }
      document.getElementById(window.localStorage.getItem('sideBarExpand')==='true'?'myAccount2':'myAccount1').click()
      }
      },{
        element: "#logout",
    title: "Logout",
    content: "This is to logout from BedPlus",
    backdrop: true,
    placement: 'right',
    onNext :(tour)=> {
    document.getElementById(window.localStorage.getItem('sideBarExpand')==='true'?'myAccount2':'myAccount1').click()
    }
      },{
        element: "#propertyImportInProgress",
    title: "PMS Sync Indicator",
    content: "This is the PMS synchronization indicator. When green, it means there is no active sync process that is ongoing. When it is red, it means we are downloading and processing your PMS data. ",
    backdrop: true,
    placement: 'right',
    },
    {
      element: "#error",
  title: "Error Indicator",
  content: "This is a shorthand error Indicator that help to show if their are soem error on PMS ",
  backdrop: true,
  placement: 'right',
  },
    {
      element: "#ownerDashboard",
      title: "Homepage",
      content: "This is the homepage dashboard, where you will get a summary of your bookings and revenue revenue. ",
      placement: 'bottom',
      debug: true,
       backdrop: true,
    },
    {
      element: "#year",
        title: "Year Filter",
        content: "This is a Year drop down filter , this helps you to filter and display the the report according to the selected year",
        placement: 'bottom',
        backdrop: true,
    },{
      element: "#duration",
        title: "Duration Filter",
        content: "With the duration drop down you can filter the view to Monthly or Yearly",
        placement: 'bottom',
        backdrop: true,
    },
    {
      element: "#totalRevenue",
      title: "Total Revenue",
      content: "You can have a view on your Revenue in a Chart Foramt of the following year Selected",
      placement: 'top',
      backdrop: true,
      onNext: (tour)=> {
        document.getElementById('OwnerParentDiv').scrollTop += 550
       },

    },
    {
      element: "#totalBookings",
                title: "Total bookings",
                content: "The number of bookings for the selected period",
                placement: 'bottom',
                backdrop: true,
                 onPrev: (tour)=> {
                  document.getElementById('OwnerParentDiv').scrollTop -= 950
                 },
    },{
      element: "#bookingAmount",
    title: "Booking amount",
    content: "The total revenue from bookings during the selected period. ",
    placement: 'bottom',
    backdrop: true,

    },
    {
      element: "#personalBookings",
    title: "Recent bookings",
    content: "Most recently added bookings. ",
    backdrop: true,
    placement: 'top',
    onNext: (tour)=> {
      document.getElementById('OwnerParentDiv').scrollTop += 2150
     },

    },
  {
      element: ".recentlyAddedBookings",
    title: "Recent bookings",
    content: "Most recently added bookings. ",
    backdrop: true,
    placement: 'top',
    onPrev: (tour)=> {
      document.getElementById('OwnerParentDiv').scrollTop -= 2150
     },
    }
    ]
    if(window.localStorage.getItem('sideBarExpand')!=='true'){
      steps.unshift({ element: "#isExpanded",
      title: "Menu Bar",
      content: "Help to Navigate",
      backdrop: true,
      placement: 'right',
    });
      const index = steps.indexOf({ element: "#close",
      title: "Close",
      content: "With the duration drop down you can filter the view to Monthly or Yearly",
      backdrop: true,
      placement: 'right',});
      if (index > -1) {
        steps.splice(index, 1);
      }
    }else{
      steps.unshift({ element: "#close",
      title: "Close",
      content: "With the duration drop down you can filter the view to Monthly or Yearly",
      backdrop: true,
      placement: 'right',
    });
      const idx = steps.indexOf({ element: "#isExpanded",
      title: "Expand here",
      content: "With the duration drop down you can filter the view to Monthly or Yearly",
      backdrop: true,
      placement: 'right',});
      if (idx > -1) {
        steps.splice(idx, 1);
      }
    }

    if(this.userRole==='OWNER'){
      steps.splice(6,0,{
            element: "#switchUser",
            title: "Switch User",
            content: "With this you can Switch back to a  Admin View",
            backdrop: true,
            placement: 'right',
      })

    let removeFromOwner=  [
      {
        element: "#owner",
        title: "Owner settings",
        content: "Owner configuration is where you can configure owner settings as well as invite new owners to your account. ",
        backdrop: true,
        placement: 'right',
        onPrev: (tour) =>{
          document.getElementById('setting').click()
        }
      },
      {
        element: "#pms",
    title: "PMS settings",
    content: "Import your properties, rooms and bookings by setting up your PMS connection here. ",
    backdrop: true,
    placement: 'right',
      },
      {
        element: "#billing",
    title: "Billing",
    content: "With the duration drop down you can filter the view to Monthly or Yearly",
    backdrop: true,
    placement: 'right',
    onNext: (tour)=> {
    document.getElementById('setting').click()
    }
      },
      {
        element: "#profile",
    title: "Account settings",
    content: "Configure your account level information: currency setting, personal contact information and account password.",
    backdrop: true,
    placement: 'right',
    onShow: (tour)=> {
    document.getElementById(window.localStorage.getItem('sideBarExpand')==='true'?'myAccount2':'myAccount1').click()
    },
    onPrev: (tour)=> {
      document.getElementById('setting').click()
      document.getElementById(window.localStorage.getItem('sideBarExpand')==='true'?'myAccount2':'myAccount1').click()
      }
      },
      {
        element: "#propertyImportInProgress",
    title: "PMS Sync Indicator",
    content: "This is the PMS synchronization indicator. When green, it means there is no active sync process that is ongoing. When it is red, it means we are downloading and processing your PMS data. ",
    backdrop: true,
    placement: 'right',
      },
      {
        element: "#totalBookings",
                  title: "Total bookings",
                  content: "The number of bookings for the selected period",
                  placement: 'top',
                  backdrop: true,
                  onNext: (tour)=> {
                    document.getElementById('OwnerParentDiv').scrollTop += 950
                   },
                   onPrev: (tour)=> {
                    document.getElementById('OwnerParentDiv').scrollTop -= 950
                   },
      },
      {
        element: "#bookingAmount",
      title: "Booking amount",
      content: "The total revenue from bookings during the selected period. ",
      placement: 'top',
      backdrop: true,
      }
    ]

    removeFromOwner.forEach((element:any)=>{
      const indx= steps.indexOf(element);
      if (indx > -1) {
        steps.splice(indx, 1);
      }
    })

    }


      this.tourService.createTour(steps);

     
this.userService.getUser().subscribe((res:any) => {

  if (res.code === 200) { 
    if (this.authGuard.isPreviewLogin()) {
        this.getUnreadMessages();
    }else if(res.payload.hasNewMessage > 0){
      this.hasNewMessage = res.payload.hasNewMessage;
      this.isCustomNotificationRed = true;

    }
    this.enableRequestInbox = res.payload.enableRequestInbox;
    this.Home_FLAG = (res.payload.tourFlags) ? res.payload.tourFlags.HOME_PAGE : true;
    let user = res.payload
    this.currentUser = user;
    this.permissions = user.permissions;
    this.loginUserType = (user.userType == null || user.userType == "") ? "" : user.userType;
    if(this.Home_FLAG == false){

      this.tourService.startTour();
      setTimeout(()=>{
       let interval = setInterval(()=>{
            if(localStorage.getItem('tour_end')==='yes'){
              localStorage.setItem('tour_end','No')
              clearInterval(interval);
              this.ownerService.changeTourFlag('HOME_PAGE').subscribe((res:any)=>{
                this.Home_FLAG=res.payload.tourFlags.HOME_PAGE;
              })
            }
          },20);
      },500);
    };
    this.hasActiveSubscription(res.payload);
  }});
  }


  isImporting(): void {
    const token: string = this.tokenService.getToken();
    if(token.length > 0) {
      this.userService.getUser().subscribe((response: GenericDto<any>) => {
        if (response.code === 200) {
          this.isImportProgress = response.payload.importRunning;
          // console.log(response.payload.importRunnigStatus);
          this.importIndexService.sendMessage(response.payload.importRunnigStatus);
          clearInterval(this.interval1)
        } else {
          // show error and redirect to login page
        }

      });
    }
  }

  logout(): void {   
       // close chat-boat and clear the public chat
       this.sharedService.setshareAndCommunicate({code:"event-login-logout-occured",data:null});
   
    this.authService.logout()
      .subscribe((response: GenericDto<string>) => {
        this.clearCurrentUserSession(response);
      }, (e) => {
        console.error(e);
        this.clearCurrentUserSession(e);
      });
  }

  clearCurrentUserSession(response: GenericDto<string>): void {
    this.storageService.clearAll();
    this.toastrService.success(response.desc, success, {
      timeOut: 3000,
      onActivateTick: true
    });
    this.router.navigate(['/login']);
  }

  navigate(path: string = ''): void {
    this.router.navigate(['/dashboard' + path]);
  }

  navigation(path: string = ''): void {
    if (this.tokenService.getCurrentUser() === 'CLEANER') {
      this.router.navigate(['/dashboard' + path]);
    } else {
      this.router.navigate(['/ownerDashboard' + path]);
    }

  }
  toggleExpand() {
    this.isExpanded = !this.isExpanded;
    window.localStorage.setItem('sideBarExpand', this.isExpanded.toString());
    this.containerResize();
  }

  containerResize() {
    let matDrawerContent = (document.querySelector('.mat-drawer-content') as HTMLElement);

    matDrawerContent.style.transition = 'all 0.2s';
    matDrawerContent.style.backgroundColor = '#ffffff';
    if (this.isExpanded) {
      matDrawerContent.style.marginLeft = '280px';
    }
    else {
      matDrawerContent.style.marginLeft = '80px';
    }
  }

  switchAccount() {
    this.userService.switchUser().subscribe(response => {
      if (response.code === 200) {
        this.tokenService.saveToken(response.payload.payload);
        this.tokenService.setPreviewUser(JSON.stringify(response.payload.previewOwnerDto));
        this.tokenService.saveRole("ADMINISTRATOR");
        this.tokenService.setPreviewLogin(false);
        localStorage.setItem(tokenKey, response.payload);
        if(response.payload.previewOwnerDto.editUserId != "null"){
          location.replace('dashboard/users/'+ response.payload.previewOwnerDto.editUserId);
          return
        }
        location.replace('dashboard/users/invite');
      }
    });
  }

  error_dialog(){
    const importDialogRef = this.dialog.open(ImportErrorsComponent, {
      width: '80%',
      height: '500px',
      data: {
        plugin: this.plugins[0]
      }
    });
  }

  getAccount(){
    if(localStorage.getItem('logo') != null){
      this.organizationId = localStorage.getItem('organizationId');
      this.logo = localStorage.getItem('logo-'+this.organizationId);
      return;
    }
    const accountId = localStorage.getItem('accountId');
    console.log("accountId: " + accountId);
    if(accountId) {       
      this.userService.getAccount(localStorage.getItem('accountId')).subscribe((res: any) => {
          if (res.code === 200 && res.payload.organizationId && typeof res.payload.organizationId === 'string' && res.payload.organizationId.trim() !== '') {
            this.organizationId = res.payload.organizationId;
            this.logo = this.organizationId + ".png";
            this.organizationId = this.organizationId.replace('-', ' ').toUpperCase();
          } else {
            this.logo = "logo-small.png";
            this.organizationId = null;
          }
          localStorage.setItem('organizationId', this.organizationId);
          localStorage.setItem('logo-'+this.organizationId, this.logo);
        }
      );
    }
  }
  updateComponent(obj) { 
    const currentUrl = this.router.url;        
    if(obj.code == "GENERAL_REQUEST"){       
      this.isCustomNotificationRed = true; 
      } else if(obj.code == "GENERAL_REQUEST_MARK" && currentUrl =="/dashboard/request/inbox"){
        this.isCustomNotificationRed = false;
        } 
    
  } 
  
 getUnreadMessages() {    
     let owner = this.tokenService.getPreviewOwner();    
     this.generalRequestsService.hasNewMessage(owner).subscribe((response: GenericDto<any>) => {
       if (response.code === 200) {                 
         //const unRead  = response.payload.filter(message => message.sendBy === receiver && !message.read);
         if(response.payload > 0){
           this.isCustomNotificationRed = true;
         }
 
       } else {
         this.toastrService.error(response.desc, errorMessage, {
           timeOut: 3000,
           onActivateTick: true
         });
       }
 
     }, (error: HttpErrorResponse) => {
       // show error and redirect to login page
     });
 
   }
 
   hasActiveSubscription(payload: any) {
     
    // Check if user belongs to a special account
    if (payload && payload.freeAccount) {
      localStorage.setItem("alertShow","0"); 
      return;
    }   
         
    //if no payment methord exist
    if (payload.stripeSubscription == null ||
      ((payload.stripeSubscription.status === "active" && !payload.paymentMethodExist) ||
        payload.stripeSubscription.cancelled)) {
      this.subscriptionExpired(payload);
      return;
  } 
  //if payment methord exist hide alert
  if(payload.paymentMethodExist){
    localStorage.setItem("alertShow","0"); 
    return;
  }
    
    if (payload.stripeSubscription.status == "trialing" && !payload.paymentMethodExist) {
        const expireDate = payload.stripeSubscription.expireDate; 
        // If subscription is active, calculate remaining days and log it
        const remainingDays = this.sharedService.calculateRemainingDays(expireDate);
        localStorage.setItem("remainingDays", remainingDays+"");
        const alertShow = payload.role === "ADMINISTRATOR" ? '1' : '0';
        localStorage.setItem("alertShow",alertShow);                          
        this.sharedService.setshareAndCommunicate({code:"show-trial",data:{trial:!(payload.paymentMethodExist),remainingDaysString:remainingDays}});         
    } 
  }

  subscriptionExpired(payload){
    const dashboardRoute = payload.role === "ADMINISTRATOR" ? '/dashboard/billing' : '/ownerDashboard';
    this.router.navigate([dashboardRoute]);
    if( payload.role === "OWNER"){
      this.toastrService.error("You account has temporarily been disabled, please contact your admin to restore access.", errorMessage, {
        timeOut: 3000,
        onActivateTick: true
      });
    }
  }
  

}
