import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {TokenService} from '../services/token.service';
import {DataService} from '../../shared/services/data.service';

@Injectable()
export class ResetGuard implements CanActivate {

  constructor(private router: Router, private dataService: DataService) {
  }


  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    if (!!this.dataService.otp) {
      return true;
    } else {
      this.router.navigate(['/login']);
      return false;
    }


  }

}
