import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {ReactiveFormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {JobManageComponent} from './job-manage.component';
import {PropertyService} from '../../../services/property.service';
import {SharedModule} from '../../../shared/shared.module';

@NgModule ({
  imports: [ReactiveFormsModule, RouterModule, SharedModule, CommonModule ],
  declarations: [JobManageComponent],
  providers: [PropertyService],
  exports: [JobManageComponent],
})

export class JobManageModule {}
