export const getOwnerDetails = '/owner/getMonthRevenue';
export const getOwnerPermissions = '/ownerPermission/getOwnerPermission';
export const getOwnerYearDetails = '/owner/getYearRevenue';
export const getTotalRevenue = '/owner/getRevenue';
export const updateOwner = '/owner/updateOwnerProperty';
export const personalBookingOfOwner = '/owner/savePersonalBookingOfOwner';
export const saveOwnerPermissions = '/ownerPermission/saveOwnerPermission';
export const getOwnerPersonalBooking = '/owner/getOwnerPersonalBooking';
export const getOwnerProperties = '/owner/getOwnerProperties';
export const findOneBy = '/owner/findOneBy';
export const findByOwnerId = '/owner/getOwnerDetail';
export const getOwnerDetailPermissions = '/owner/getOwnerDetailPermissions';
export const payoutReport = '/owner/payoutReport';
export const sendReport = '/owner/sendReport'
export const receiveReport = '/owner/receiveReport'
export const payoutReportFromToDate = '/owner/payoutReportFromToDate';
export const getOwnerBookingsPayoutReport = '/owner/ownerBookingsPayoutReport';
export const getownerPayout = '/owner/getOwnerPayout';
export const getDashboard = '/owner/dashboard'
export const getMonthlyOccupancyRate = '/public/report/monthlyOccupancyRate'
