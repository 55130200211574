import { Component, OnInit, Output, Inject, Input } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-general-error',
  templateUrl: './general-error.component.html',
  styleUrls: ['./general-error.component.scss']
})
export class GeneralErrorComponent implements OnInit {
  error:any  ;

  constructor(@Inject(MAT_DIALOG_DATA) public generalError: {generalError: String}) {
    try {
      const jsonObject = JSON.parse(this.error.generalError);
      this.error =  jsonObject.error; 
    } catch (error) {
      this.error = "Unauthorized";
    } 
  }
  ngOnInit() {
  }
  
}
