import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {OwnerService} from 'src/app/services/owner.service';
import {MatPaginator, MatTableDataSource} from '@angular/material';
import {PayoutReport} from './payout-report.model';
import {GenericDto} from 'src/app/models/generic-dto';
import {HttpErrorResponse} from '@angular/common/http';
import {Month} from '../revenue-report/revenue-report.component';
// import {MatSortModule} from '@angular/material/sort';
// import {Sort} from '@angular/material/sort';
import {MatSort} from '@angular/material/sort'
import * as moment from 'moment';
import {ExcelService} from '../../core/services/excel.service';
import * as jsPDF from 'jspdf';
import { CurrencyPipe } from '@angular/common';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { DataService } from 'src/app/shared/services/data.service';


@Component({
  selector: 'app-payout-report',
  templateUrl: './payout-report.component.html',
  styleUrls: ['./payout-report.component.scss']
})
export class PayoutReportComponent implements OnInit {
  displayColumnsAdminCheckOut: any;
  displayColumnsAdminProRata: any;
  displayColumnsOwnerProRata: any;
  displayColumnsOwnerCheckOut: any;
  dataSourceAdminProRata;
  dataSourceAdminCheckOut;

  hasBookings = false;
  months: Month[] = [
    { value: '01', viewValue: 'January' },
    { value: '02', viewValue: 'February' },
    { value: '03', viewValue: 'March' },
    { value: '04', viewValue: 'April' },
    { value: '05', viewValue: 'May' },
    { value: '06', viewValue: 'June' },
    { value: '07', viewValue: 'July' },
    { value: '08', viewValue: 'August' },
    { value: '09', viewValue: 'September' },
    { value: '10', viewValue: 'October' },
    { value: '11', viewValue: 'November' },
    { value: '12', viewValue: 'December' }
  ];
  month: any;
  x: number;
  y: number;
  startDate = 0;
  endDate = 0;
  index: string;
  monthName: string;
  ownerPayout: PayoutReport[];
  ownerPayoutReport: any;
  owners: any;
  bookings: any;
  proRataBookingList: any;
  checkOutBookingList: any;
  exportReport = new Array();
  currency = 'USD';
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  totalBookingAmount: number;
  totalPlatformCommission: number;
  totalCleaningFee: number;
  totalTax: number;
  totalPaymentProcessingFee: number;
  totalPropertyMngmtComm: number;
  totalExpensesOfMaintenance: number;
  totalNetProfit: number;
  totalBookingAmount1: number;
  totalPlatformCommission1: number;
  totalCleaningFee1: number;
  totalTax1: number;
  totalPaymentProcessingFee1: number;
  totalPropertyMngmtComm1: number;
  totalExpensesOfMaintenance1: number;
  totalNetProfit1: number;
  @Input() event: any;
  toDateValue: any;
  fromDateValue: any;
  dateForm: FormGroup;
  fromDate = new FormControl(moment());
  toDate = new FormControl(moment());
  bookingList: any;
  properties: string[];
  uniqueBooking : any[];
  uniqueBooking1 : any[];
  uniqueBooking2 : any[];
  uniqueBooking3 : any[];
  isOwner: boolean;
  isAdmin: boolean;
  checkOutJSON = [];
  proRataJSON : any;
  isOwnersAvailable : boolean;
  // bookingsList: any[];

  constructor(private ownerService: OwnerService, private excelService: ExcelService, private cp: CurrencyPipe, private formBuilder: FormBuilder,private dataService: DataService) {
  }

  ngOnInit() {
    this.index = moment().format('MM');
    if (this.index === '01') {
      this.onChange(this.months[0]);
    }
    if (this.index === '02') {
      this.onChange(this.months[1]);
    }
    if (this.index === '03') {
      this.onChange(this.months[2]);
    }
    if (this.index === '04') {
      this.onChange(this.months[3]);
    }
    if (this.index === '05') {
      this.onChange(this.months[4]);
    }
    if (this.index === '06') {
      this.onChange(this.months[5]);
    }
    if (this.index === '07') {
      this.onChange(this.months[6]);
    }
    if (this.index === '08') {
      this.onChange(this.months[7]);
    }
    if (this.index === '09') {
      this.onChange(this.months[8]);
    }
    if (this.index === '10') {
      this.onChange(this.months[9]);
    }
    if (this.index === '11') {
      this.onChange(this.months[10]);
    }
    if (this.index === '12') {
      this.onChange(this.months[11]);
    }

    // this.onChange(this.months[this.month - 1]);

    this.dateForm = this.formBuilder.group({
      toDate: [new Date(), Validators.required],
      fromDate: [new Date(), Validators.required]
    });

  }

  onChange(month: any) {
    if (month) {
      this.month = month.value;
      this.monthName = month.viewValue;
      console.log(this.month)
    }
    this.ownerService.getOwnerPayoutReportFromToDate1(this.month)
      .subscribe(
        (response: GenericDto<any>) => {
          console.log(response);
          if (response.code === 200) {
            this.isOwnersAvailable = false;
            this.months[this.month - 1];
            if(response.payload.proRataOwner !== null || response.payload.checkOutOwner !== null){
              this.isOwner = true;
              this.isAdmin = false;
              this.isOwnersAvailable = true;
              if(response.payload.proRataOwner.length >0){
                this.proRataJSON = [];
                this.hasBookings = true;
                this.currency = response.payload.currency;
                this.uniqueBooking = Array();
                this.uniqueBooking1 = Array();
                this.uniqueBooking2 = Array();
                this.uniqueBooking3 = Array();
                this.proRataBookingList = Array();
                for (let roomViseBookings = 0; roomViseBookings < response.payload.proRataOwner.length; roomViseBookings++) {
                  if(response.payload.proRataOwner[roomViseBookings] !== null){
                  const roomVise = response.payload.proRataOwner[roomViseBookings];
                  for (let bookings = 0; bookings < roomVise.bookings.length; bookings++) {
                    const proRataBooking = roomVise.bookings[bookings];
                    this.proRataBookingList.push(proRataBooking);
                    this.uniqueBooking1.push(proRataBooking);
                    this.uniqueBooking[proRataBooking.bookId] = proRataBooking.bookId;
                    proRataBooking.firstNight =  moment.unix(proRataBooking.firstNight).format('YYYY-MM-DD');
                    proRataBooking.lastNight =  moment.unix(proRataBooking.lastNight).add(1, 'days').format('YYYY-MM-DD');
                  }
                  this.totalBookingAmount = 0;
                  this.totalPlatformCommission = 0;
                  this.totalCleaningFee = 0;
                  this.totalTax = 0;
                  this.totalPaymentProcessingFee = 0;
                  this.totalPropertyMngmtComm = 0;
                  this.totalExpensesOfMaintenance = 0;
                  this.totalNetProfit = 0;
                    for (let roomViseBooking = 0; roomViseBooking < roomVise.bookings.length; roomViseBooking++) {
                      this.totalBookingAmount += roomVise.bookings[roomViseBooking].bookingPrice;
                      this.totalPlatformCommission += roomVise.bookings[roomViseBooking].commission;
                      this.totalCleaningFee += roomVise.bookings[roomViseBooking].cleaningFees;
                      this.totalTax += roomVise.bookings[roomViseBooking].tax;
                      this.totalPaymentProcessingFee += roomVise.bookings[roomViseBooking].paymentProcessingFee;
                      this.totalPropertyMngmtComm += roomVise.bookings[roomViseBooking].propertyManagementCommission;
                      this.totalNetProfit += roomVise.bookings[roomViseBooking].netProfit;
                    }
                    var tableJson = [];
                    tableJson['roomName'] =  roomVise.room.name + " - " + roomVise.room.propertyName;
                    tableJson['totalBookingAmount'] = this.totalBookingAmount.toFixed(2);
                    tableJson['totalPlatformCommission'] = this.totalPlatformCommission.toFixed(2);
                    tableJson['totalCleaningFee'] = this.totalCleaningFee.toFixed(2);
                    tableJson['totalTax'] = this.totalTax.toFixed(2);
                    tableJson['totalPaymentProcessingFee'] = this.totalPaymentProcessingFee.toFixed(2);
                    tableJson['totalPropertyMngmtComm'] = this.totalPropertyMngmtComm.toFixed(2);
                    tableJson['totalNetProfit'] = this.totalNetProfit.toFixed(2);
                    tableJson['bookings'] = new MatTableDataSource(roomVise.bookings);
                    tableJson['bookings'].sort = this.sort

                    if(roomVise.bookings.length > 0 ){
                      for (let index = 0; index < this.proRataJSON.length; index++) {
                        const element = this.proRataJSON[index];
                        element.bookings.sort = this.sort;
                      }
                      this.proRataJSON.push(tableJson);
                      for (let index = 0; index < this.proRataJSON.length; index++) {
                        const element = this.proRataJSON[index];
                        element.bookings.sort = this.sort;
                      }
                    }

                  }
                }
                this.displayColumnsOwnerProRata = ['Booking Id','Property Name', 'Date', 'Platform', 'Booking Amount', 'Platform Commission',
                'Cleaning Fee', 'Tax', 'Payment Processing Fee', 'Property Management Commission', 'Net Profit'];
              }
              if(response.payload.checkOutOwner.length > 0){
                this.hasBookings = true;
                this.checkOutJSON = [];
                this.checkOutBookingList = Array();
                for (let roomViseBookings = 0; roomViseBookings < response.payload.checkOutOwner.length; roomViseBookings++) {
                  if(response.payload.checkOutOwner[roomViseBookings] !== null){
                  const roomVise = response.payload.checkOutOwner[roomViseBookings];
                  for (let bookings = 0; bookings < roomVise.bookings.length; bookings++) {
                    if(!this.uniqueBooking[response.payload.checkOutOwner[roomViseBookings].bookId])  {
                      const booking = roomVise.bookings[bookings];
                      this.checkOutBookingList.push(booking);
                      booking.firstNight =  moment.unix(booking.firstNight).format('YYYY-MM-DD');
                      booking.lastNight =  moment.unix(booking.lastNight).add(1, 'days').format('YYYY-MM-DD');

                    }
                  }
                  this.totalBookingAmount = 0;
                  this.totalPlatformCommission = 0;
                  this.totalCleaningFee = 0;
                  this.totalTax = 0;
                  this.totalPaymentProcessingFee = 0;
                  this.totalPropertyMngmtComm = 0;
                  this.totalExpensesOfMaintenance = 0;
                  this.totalNetProfit = 0;
                    for (let roomViseBooking = 0; roomViseBooking < roomVise.bookings.length; roomViseBooking++) {
                      this.totalBookingAmount += roomVise.bookings[roomViseBooking].bookingPrice;
                      this.totalPlatformCommission += roomVise.bookings[roomViseBooking].commission;
                      this.totalCleaningFee += roomVise.bookings[roomViseBooking].cleaningFees;
                      this.totalTax += roomVise.bookings[roomViseBooking].tax;
                      this.totalPaymentProcessingFee += roomVise.bookings[roomViseBooking].paymentProcessingFee;
                      this.totalPropertyMngmtComm += roomVise.bookings[roomViseBooking].propertyManagementCommission;
                      this.totalNetProfit += roomVise.bookings[roomViseBooking].netProfit;
                    }
                    var tableJson = [];
                    tableJson['roomName'] =  roomVise.room.name + " - " + roomVise.room.propertyName;
                    tableJson['totalBookingAmount'] = this.totalBookingAmount.toFixed(2);
                    tableJson['totalPlatformCommission'] = this.totalPlatformCommission.toFixed(2);
                    tableJson['totalCleaningFee'] = this.totalCleaningFee.toFixed(2);
                    tableJson['totalTax'] = this.totalTax.toFixed(2);
                    tableJson['totalPaymentProcessingFee'] = this.totalPaymentProcessingFee.toFixed(2);
                    tableJson['totalPropertyMngmtComm'] = this.totalPropertyMngmtComm.toFixed(2);
                    tableJson['totalNetProfit'] = this.totalNetProfit.toFixed(2);
                    tableJson['bookings'] = new MatTableDataSource(roomVise.bookings);
                    for (let index = 0; index < tableJson.length; index++) {
                      const element = tableJson[index];
                      element.bookings.sort = this.sort;
                      element.bookings.paginator = this.paginator;
                    }
                    if(roomVise.bookings.length > 0 ){
                      this.checkOutJSON.push(tableJson);
                    }
                  }
                }
                this.displayColumnsOwnerCheckOut = ['Booking Id','Property Name', 'Date', 'Platform', 'Booking Amount', 'Platform Commission',
                'Cleaning Fee', 'Tax', 'Payment Processing Fee', 'Property Management Commission', 'Net Profit'];
              }
            }
            else if (response.payload.proRataAdmin !== null || response.payload.checkOutAdmin !== null ){
              this.isOwner = false;
              this.isAdmin = true;
            if (response.payload.proRataAdmin.length > 0) {
              this.hasBookings = true;
              this.proRataBookingList = response.payload.proRataAdmin;
              //console.log("HERE",response.payload.booking)
              this.currency = response.payload.currency;
              this.bookings = new Array<string>();
              this.totalBookingAmount = 0;
              this.totalPlatformCommission = 0;
              this.totalCleaningFee = 0;
              this.totalTax = 0;
              this.totalPaymentProcessingFee = 0;
              this.totalPropertyMngmtComm = 0;
              this.totalExpensesOfMaintenance = 0;
              this.totalNetProfit = 0;
              this.uniqueBooking = Array();
              this.uniqueBooking1 = Array();
              this.uniqueBooking2 = Array();
              this.uniqueBooking3 = Array();
              for (let index = 0; index < response.payload.proRataAdmin.length; index++) {
                if(!this.uniqueBooking[response.payload.proRataAdmin[index].bookId])  {

                this.uniqueBooking1.push(response.payload.proRataAdmin[index]);
                this.uniqueBooking[response.payload.proRataAdmin[index].bookId] = response.payload.proRataAdmin[index];
                response.payload.proRataAdmin[index].lastNight = moment.unix(response.payload.proRataAdmin[index].lastNight).add(1, 'days').format('YYYY-MM-DD');
                response.payload.proRataAdmin[index].firstNight = moment.unix(response.payload.proRataAdmin[index].firstNight).format('YYYY-MM-DD');
                this.totalBookingAmount += response.payload.proRataAdmin[index].bookingPrice;
                this.totalPlatformCommission += response.payload.proRataAdmin[index].commission;
                this.totalCleaningFee += response.payload.proRataAdmin[index].cleaningFees;
                this.totalTax += response.payload.proRataAdmin[index].tax;
                this.totalPaymentProcessingFee += response.payload.proRataAdmin[index].paymentProcessingFee;
                this.totalPropertyMngmtComm += response.payload.proRataAdmin[index].propertyManagementCommission;
                this.totalNetProfit += response.payload.proRataAdmin[index].netProfit;
                // if((response.payload.booking[index] !== null) && (response.payload.ownerList[index] !== null) && (response.payload.ownerList[index].firstName !== null) && (response.payload.ownerList[index].lastName !== null)){
                //   response.payload.booking[index].owner = response.payload.ownerList[index].firstName + " " +response.payload.ownerList[index].lastName;
                //   }else{
                //     response.payload.booking[index].owner = "-";
                //   }
                }
              }
              console.log(response.payload);

              this.dataSourceAdminProRata = new MatTableDataSource(this.uniqueBooking1);
              console.log(this.dataSourceAdminProRata)

              this.dataSourceAdminProRata.sort = this.sort;
              this.dataSourceAdminProRata.paginator = this.paginator;


              this.displayColumnsAdminProRata = ['Booking Id','Property Name', 'Date', 'Platform', 'Booking Amount', 'Platform Commission',
              'Cleaning Fee', 'Tax', 'Payment Processing Fee', 'Property Management Commission', 'Net Profit'];
            }
            if (response.payload.checkOutAdmin.length > 0 ) {
              this.isOwnersAvailable = true;
              this.hasBookings = true;
              this.checkOutBookingList = response.payload.checkOutAdmin;
              //console.log("Not HERE",response.payload.checkOutDates)
              this.currency = response.payload.currency;
              this.bookings = new Array<string>();
              this.totalBookingAmount1 = 0;
              this.totalPlatformCommission1 = 0;
              this.totalCleaningFee1 = 0;
              this.totalTax1 = 0;
              this.totalPaymentProcessingFee1 = 0;
              this.totalPropertyMngmtComm1 = 0;
              this.totalExpensesOfMaintenance1 = 0;
              this.totalNetProfit1 = 0;
              for (let index = 0; index < response.payload.checkOutAdmin.length; index++) {
                if(!this.uniqueBooking2[response.payload.checkOutAdmin[index].bookId])  {
                  this.uniqueBooking2[response.payload.checkOutAdmin[index].bookId] = response.payload.checkOutAdmin[index];
                  this.uniqueBooking3.push(response.payload.checkOutAdmin[index]);
                  response.payload.checkOutAdmin[index].lastNight = moment.unix(response.payload.checkOutAdmin[index].lastNight).add(1, 'days').format('YYYY-MM-DD');
                  response.payload.checkOutAdmin[index].firstNight = moment.unix(response.payload.checkOutAdmin[index].firstNight).format('YYYY-MM-DD');
                  this.totalBookingAmount1 += response.payload.checkOutAdmin[index].bookingPrice;
                  this.totalPlatformCommission1 += response.payload.checkOutAdmin[index].commission;
                  this.totalCleaningFee1 += response.payload.checkOutAdmin[index].cleaningFees;
                  this.totalTax1 += response.payload.checkOutAdmin[index].tax;
                  this.totalPaymentProcessingFee1 += response.payload.checkOutAdmin[index].paymentProcessingFee;
                  this.totalPropertyMngmtComm1 += response.payload.checkOutAdmin[index].propertyManagementCommission;
                  this.totalNetProfit1 += response.payload.checkOutAdmin[index].netProfit;
                }

              }

              this.dataSourceAdminCheckOut = new MatTableDataSource(this.uniqueBooking3);
              //console.log(this.dataSource1);
              this.dataSourceAdminCheckOut.sort = this.sort;
              this.dataSourceAdminCheckOut.paginator = this.paginator;

              this.displayColumnsAdminCheckOut = ['Booking Id','Property Name', 'Date', 'Platform', 'Booking Amount', 'Platform Commission',
              'Cleaning Fee', 'Tax', 'Payment Processing Fee', 'Property Management Commission', 'Net Profit'];
            }
          } else{
            this.isOwnersAvailable = false;
          }
          }
        },
        (errorResponse: HttpErrorResponse) => {
          // error response
        }
      );
  }

  getExcel() {
    this.exportReport = new Array();
    for (let reportData of this.proRataBookingList) {
      let exportReportData = {
        BookingId: reportData.bookId,
        CheckInDate: reportData.firstNight,
        CheckOutDate: reportData.lastNight,
        PlatForm: reportData.referer,
        GrossRevenue: reportData.bookingPrice,
        PlatformCommission: reportData.commission,
        CleaningFee: reportData.cleaningFees,
        Tax: reportData.tax,
        PaymentPeocessingFee: reportData.paymentProcessingFee,
        ManagementCommission: reportData.propertyManagementCommission,
        NetProfit: reportData.netProfit
      };
      this.exportReport.push(exportReportData);
    }
    this.excelService.exportAsExcelFile(this.exportReport, 'MonthlyOwnerPayoutReport');
}

getExcel1() {
  this.exportReport = new Array();
  for (let reportData of this.checkOutBookingList) {
    let exportReportData = {
      BookingId: reportData.bookId,
      CheckInDate: reportData.firstNight,
      CheckOutDate: reportData.lastNight,
      PlatForm: reportData.referer,
      GrossRevenue: reportData.bookingPrice,
      PlatformCommission: reportData.commission,
      CleaningFee: reportData.cleaningFees,
      Tax: reportData.tax,
      PaymentPeocessingFee: reportData.paymentProcessingFee,
      ManagementCommission: reportData.propertyManagementCommission,
      NetProfit: reportData.netProfit
    };
    this.exportReport.push(exportReportData);
  }
  this.excelService.exportAsExcelFile(this.exportReport, 'MonthlyOwnerPayoutReport');
}



  getPDF() {
    const doc = new jsPDF();
    doc.text(60, 13, this.monthName + ' Monthly Owner Payout Report ');
    doc.setFontSize(10);
    this.y = 20;
    this.x = 20;
    doc.text(this.x, this.y, '______________________________________________________________________________________');

    for (var ownerCount = 0; ownerCount < this.proRataBookingList.length; ownerCount++) {

          // for (let i = 0; i < this.proRataBookingList.length; i++) {
            doc.text(this.x, this.y, '______________________________________________________________________________________');
            doc.text(this.x, this.y += 5, 'Booking ID : ');
            doc.text(160, this.y, '' +(this.proRataBookingList[ownerCount].bookId));
            doc.text(this.x, this.y += 5, 'Booking Dates : ');
            doc.text(160, this.y, '' +(this.proRataBookingList[ownerCount].firstNight +' / '+ this.proRataBookingList[ownerCount].lastNight));
            doc.text(this.x, this.y += 5, 'Platform : ');
            doc.text(160, this.y, '' +(this.proRataBookingList[ownerCount].referer));
            doc.text(this.x, this.y += 5, 'Booking Amount : ');
            doc.text(160, this.y, this.cp.transform(this.proRataBookingList[ownerCount].bookingPrice, this.currency, 'symbol-narrow'));
            doc.text(this.x, this.y += 5, 'Platform Commission : ');
            doc.text(160, this.y, this.cp.transform(this.proRataBookingList[ownerCount].commission, this.currency, 'symbol-narrow'));
            doc.text(this.x, this.y += 5, 'Cleaning Fee : ');
            doc.text(160, this.y, this.cp.transform(this.proRataBookingList[ownerCount].cleaningFees, this.currency, 'symbol-narrow'));
            doc.text(this.x, this.y += 5, 'Tax : ');
            doc.text(160, this.y, this.cp.transform(this.proRataBookingList[ownerCount].tax, this.currency, 'symbol-narrow'));
            doc.text(this.x, this.y += 5, 'Payment Processing Fee : ');
            doc.text(160, this.y, this.cp.transform(this.proRataBookingList[ownerCount].paymentProcessingFee, this.currency, 'symbol-narrow'));
            doc.text(this.x, this.y += 5, 'Management Commission : ');
            doc.text(160, this.y, this.cp.transform(this.proRataBookingList[ownerCount].propertyManagementCommission, this.currency, 'symbol-narrow'));
            doc.text(this.x, this.y += 5, 'Net Profit : ');
            doc.text(160, this.y, this.cp.transform(this.proRataBookingList[ownerCount].netProfit, this.currency, 'symbol-narrow'));

            if((ownerCount !== 0 && ownerCount %4 === 0 && ownerCount !== 4) || ownerCount===3) {
              this.y = 20;
              doc.addPage();
            }

            if (this.y === 300) {
              this.y = 20;
              doc.addPage();
            }
            this.y += 6;
          // }
        }
      doc.save(this.monthName + '_OwnerPayoutReport.pdf');
    }
    getPDF1() {
      const doc = new jsPDF();
      doc.text(60, 13, this.monthName + ' Monthly Owner Payout Report ');
      doc.setFontSize(10);
      this.y = 20;
      this.x = 20;
      doc.text(this.x, this.y, '______________________________________________________________________________________');


      // for (var ownerCount = 0; ownerCount < this.checkOutBookingList.length; ownerCount++) {
            for (let i = 0; i < this.checkOutBookingList.length; i++) {
              doc.text(this.x, this.y, '______________________________________________________________________________________');
              doc.text(this.x, this.y += 5, 'Booking ID : ');
              doc.text(160, this.y, '' +(this.checkOutBookingList[i].bookId));
              doc.text(this.x, this.y += 5, 'Booking Dates : ');
              doc.text(160, this.y, '' +(this.checkOutBookingList[i].firstNight +' / '+ this.checkOutBookingList[i].lastNight));
              doc.text(this.x, this.y += 5, 'Platform : ');
              doc.text(160, this.y, '' +(this.checkOutBookingList[i].referer));
              doc.text(this.x, this.y += 5, 'Booking Amount : ');
              doc.text(160, this.y, this.cp.transform(this.checkOutBookingList[i].bookingPrice, this.currency, 'symbol-narrow'));
              doc.text(this.x, this.y += 5, 'Platform Commission : ');
              doc.text(160, this.y, this.cp.transform(this.checkOutBookingList[i].commission, this.currency, 'symbol-narrow'));
              doc.text(this.x, this.y += 5, 'Cleaning Fee : ');
              doc.text(160, this.y, this.cp.transform(this.checkOutBookingList[i].cleaningFees, this.currency, 'symbol-narrow'));
              doc.text(this.x, this.y += 5, 'Tax : ');
              doc.text(160, this.y, this.cp.transform(this.checkOutBookingList[i].tax, this.currency, 'symbol-narrow'));
              doc.text(this.x, this.y += 5, 'Payment Processing Fee : ');
              doc.text(160, this.y, this.cp.transform(this.checkOutBookingList[i].paymentProcessingFee, this.currency, 'symbol-narrow'));
              doc.text(this.x, this.y += 5, 'Management Commission : ');
              doc.text(160, this.y, this.cp.transform(this.checkOutBookingList[i].propertyManagementCommission, this.currency, 'symbol-narrow'));
              doc.text(this.x, this.y += 5, 'Net Profit : ');
              doc.text(160, this.y, this.cp.transform(this.checkOutBookingList[i].netProfit, this.currency, 'symbol-narrow'));

              if((i !== 0 && i%4 === 0 && i !== 4) || i===3) {
                this.y = 20;
                doc.addPage();
              }

              if (this.y === 300) {
                this.y = 20;
                doc.addPage();
              }
              this.y += 6;
            }
          // }
              doc.save(this.monthName + '_OwnerPayoutReport.pdf');
      }

  onMonthChangeBookings(month: any) {
    if (month) {
      this.month = month.value;
      this.monthName = month.viewValue;
    }
  }

  convert(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }

  applyFilter(filterValue: string) {
    this.dataSourceAdminProRata.filter = filterValue.trim().toLowerCase();

  }

  applyFilter1(filterValue: string) {

      this.dataSourceAdminCheckOut.filter = filterValue.trim().toLowerCase();

  }
  applyFilterProRataOwner(filterValue: string){
    for (let index = 0; index < this.proRataJSON.length; index++) {
      const booking = this.proRataJSON[index];
      this.totalBookingAmount = 0;
      this.totalPlatformCommission = 0;
      this.totalCleaningFee = 0;
      this.totalTax = 0;
      this.totalPaymentProcessingFee = 0;
      this.totalPropertyMngmtComm = 0;
      this.totalExpensesOfMaintenance = 0;
      this.totalNetProfit = 0;
      booking.bookings.filter = filterValue.trim().toLowerCase();
      for (let index = 0; index < booking.bookings.filteredData.length; index++) {
        this.totalBookingAmount += booking.bookings.filteredData[index].bookingPrice;
        this.totalPlatformCommission += booking.bookings.filteredData[index].commission;
        this.totalCleaningFee += booking.bookings.filteredData[index].cleaningFees;
        this.totalTax += booking.bookings.filteredData[index].tax;
        this.totalPaymentProcessingFee += booking.bookings.filteredData[index].paymentProcessingFee;
        this.totalPropertyMngmtComm += booking.bookings.filteredData[index].propertyManagementCommission;
        this.totalNetProfit += booking.bookings.filteredData[index].netProfit;
      }
      booking.totalBookingAmount = this.totalBookingAmount;
      booking.totalPlatformCommission = this.totalPlatformCommission;
      booking.totalCleaningFee = this.totalCleaningFee;
      booking.totalTax = this.totalTax;
      booking.totalPaymentProcessingFee = this.totalPaymentProcessingFee;
      booking.totalPropertyMngmtComm = this.totalPropertyMngmtComm;
      booking.totalNetProfit = this.totalNetProfit;
    }
  }
  applyFilterCheckOutOwner(filterValue: string){
    for (let index = 0; index < this.checkOutJSON.length; index++) {
      const booking = this.checkOutJSON[index];
      this.totalBookingAmount = 0;
      this.totalPlatformCommission = 0;
      this.totalCleaningFee = 0;
      this.totalTax = 0;
      this.totalPaymentProcessingFee = 0;
      this.totalPropertyMngmtComm = 0;
      this.totalExpensesOfMaintenance = 0;
      this.totalNetProfit = 0;
      booking.bookings.filter = filterValue.trim().toLowerCase();
      for (let index = 0; index < booking.bookings.filteredData.length; index++) {
        this.totalBookingAmount += booking.bookings.filteredData[index].bookingPrice;
        this.totalPlatformCommission += booking.bookings.filteredData[index].commission;
        this.totalCleaningFee += booking.bookings.filteredData[index].cleaningFees;
        this.totalTax += booking.bookings.filteredData[index].tax;
        this.totalPaymentProcessingFee += booking.bookings.filteredData[index].paymentProcessingFee;
        this.totalPropertyMngmtComm += booking.bookings.filteredData[index].propertyManagementCommission;
        this.totalNetProfit += booking.bookings.filteredData[index].netProfit;
      }
      booking.totalBookingAmount = this.totalBookingAmount;
      booking.totalPlatformCommission = this.totalPlatformCommission;
      booking.totalCleaningFee = this.totalCleaningFee;
      booking.totalTax = this.totalTax;
      booking.totalPaymentProcessingFee = this.totalPaymentProcessingFee;
      booking.totalPropertyMngmtComm = this.totalPropertyMngmtComm;
      booking.totalNetProfit = this.totalNetProfit;
    }
  }


  getBookingPrice()  {
    //console.log(this.dataSource1);
    var totalPrice = 0;
    if(this.dataSourceAdminProRata)  {
    for(var i = 0 ; i < this.dataSourceAdminProRata.filteredData.length ; i++ )  {
      //console.log(this.dataSource1.filteredData[i]);
      totalPrice += this.dataSourceAdminProRata.filteredData[i].bookingPrice;
      }
    }
    return totalPrice;
  }

  getCommisonPrice()  {
   //console.log(this.dataSource1);
    var totalPrice = 0;
    if(this.dataSourceAdminProRata)  {
    for(var i = 0 ; i < this.dataSourceAdminProRata.filteredData.length ; i++ )  {
      //console.log(this.dataSource1.filteredData[i]);
      totalPrice += this.dataSourceAdminProRata.filteredData[i].commission;
    }
    }
    return totalPrice;
  }

  getCleaningPrice()  {
    //console.log(this.dataSource1);
    var totalPrice = 0;
    if(this.dataSourceAdminProRata)  {
    for(var i = 0 ; i < this.dataSourceAdminProRata.filteredData.length ; i++ )  {
      //console.log(this.dataSource1.filteredData[i]);
      totalPrice += this.dataSourceAdminProRata.filteredData[i].cleaningFees;
    }
    }
    return totalPrice;
  }

  getTaxPrice()  {
    //console.log(this.dataSource1);
    var totalPrice = 0;
    if(this.dataSourceAdminProRata)  {
    for(var i = 0 ; i < this.dataSourceAdminProRata.filteredData.length ; i++ )  {
      //console.log(this.dataSource1.filteredData[i]);
      totalPrice += this.dataSourceAdminProRata.filteredData[i].tax;
    }
    }
    return totalPrice;
  }
  getPaymentProcessingFee()  {
    //console.log(this.dataSource1);
    var totalPrice = 0;
    if(this.dataSourceAdminProRata)  {
    for(var i = 0 ; i < this.dataSourceAdminProRata.filteredData.length ; i++ )  {
      //console.log(this.dataSource1.filteredData[i]);
      totalPrice += this.dataSourceAdminProRata.filteredData[i].paymentProcessingFee;
    }
    }
    return totalPrice;
  }

  getCommisonFee()  {
    //console.log(this.dataSource1);
    var totalPrice = 0;
    if(this.dataSourceAdminProRata)  {
    for(var i = 0 ; i < this.dataSourceAdminProRata.filteredData.length ; i++ )  {
      //console.log(this.dataSource1.filteredData[i]);
      totalPrice += this.dataSourceAdminProRata.filteredData[i].propertyManagementCommission;
    }
    }
    return totalPrice;
  }

  getNetProfit()  {
    //console.log(this.dataSource1);
    var totalPrice = 0;
    if(this.dataSourceAdminProRata)  {
    for(var i = 0 ; i < this.dataSourceAdminProRata.filteredData.length ; i++ )  {
      //console.log(this.dataSource1.filteredData[i]);
      totalPrice += this.dataSourceAdminProRata.filteredData[i].netProfit;
    }
    }
    return totalPrice;
  }



  getBookingPrice1()  {
    //console.log(this.dataSource);
    var totalPrice = 0;
    if(this.dataSourceAdminCheckOut)  {
    for(var i = 0 ; i < this.dataSourceAdminCheckOut.filteredData.length ; i++ )  {
      //console.log(this.dataSource.filteredData[i]);
      totalPrice += this.dataSourceAdminCheckOut.filteredData[i].bookingPrice;
    }
    }
    return totalPrice;
  }

  getCommisonPrice1()  {
   //console.log(this.dataSource);
    var totalPrice = 0;
    if(this.dataSourceAdminCheckOut)  {
    for(var i = 0 ; i < this.dataSourceAdminCheckOut.filteredData.length ; i++ )  {
      //console.log(this.dataSource.filteredData[i]);
      totalPrice += this.dataSourceAdminCheckOut.filteredData[i].commission;
    }
    }
    return totalPrice;
  }

  getCleaningPrice1()  {
    //console.log(this.dataSource);
    var totalPrice = 0;
    if(this.dataSourceAdminCheckOut)  {
    for(var i = 0 ; i < this.dataSourceAdminCheckOut.filteredData.length ; i++ )  {
      //console.log(this.dataSource.filteredData[i]);
      totalPrice += this.dataSourceAdminCheckOut.filteredData[i].cleaningFees;
    }
    }
    return totalPrice;
  }

  getTaxPrice1()  {
    //console.log(this.dataSource);
    var totalPrice = 0;
    if(this.dataSourceAdminCheckOut)  {
    for(var i = 0 ; i < this.dataSourceAdminCheckOut.filteredData.length ; i++ )  {
      //console.log(this.dataSource.filteredData[i]);
      totalPrice += this.dataSourceAdminCheckOut.filteredData[i].tax;
    }
    }
    return totalPrice;
  }
  getPaymentProcessingFee1()  {
    //console.log(this.dataSource);
    var totalPrice = 0;
    if(this.dataSourceAdminCheckOut)  {
    for(var i = 0 ; i < this.dataSourceAdminCheckOut.filteredData.length ; i++ )  {
      //console.log(this.dataSource.filteredData[i]);
      totalPrice += this.dataSourceAdminCheckOut.filteredData[i].paymentProcessingFee;
    }
    }
    return totalPrice;
  }

  getCommisonFee1()  {
    //console.log(this.dataSource);
    var totalPrice = 0;
    if(this.dataSourceAdminCheckOut)  {
    for(var i = 0 ; i < this.dataSourceAdminCheckOut.filteredData.length ; i++ )  {
      //console.log(this.dataSource.filteredData[i]);
      totalPrice += this.dataSourceAdminCheckOut.filteredData[i].commission;
    }
    }
    return totalPrice;
  }

  getNetProfit1()  {
    //console.log(this.dataSource);
    var totalPrice = 0;
    if(this.dataSourceAdminCheckOut)  {
    for(var i = 0 ; i < this.dataSourceAdminCheckOut.filteredData.length ; i++ )  {
      //console.log(this.dataSource.filteredData[i]);
      totalPrice += this.dataSourceAdminCheckOut.filteredData[i].netProfit;
    }
    }
    return totalPrice;
  }
}
