/**
 *  Timer is a class to start/stop timer countdown
 */
export class Timer {

  interval: any;
  waitingTime: string;
  isTimerRunning: boolean;

  constructor() {
    this.waitingTime = '1:00';
  }

  startTimer() {

    const timeInMinutes = 1; // time in minutes
    const currentTime = new Date().getTime();
    const deadline = new Date(currentTime + timeInMinutes * 60 * 1000);
    this.interval = setInterval(() => {
      const t = this.timeLeft(deadline);
      this.waitingTime = t.minutes + ':' + (t.seconds > 9 ? t.seconds : '0' + t.seconds);
      if (t.minutes === 0 && t.seconds === 0) {
        this.isTimerRunning = false;
      }
    }, 1000);
    this.isTimerRunning = true;

  }

  private timeLeft(endTime: Date): any {
    const t = endTime.getTime() - new Date().getTime();
    const seconds = Math.floor((t / 1000) % 60);
    const minutes = Math.floor((t / 1000 / 60) % 60);
    return {'total': t, 'minutes': minutes, 'seconds': seconds};
  }

  public resetTimer() {
    this.waitingTime = '1:00';
    clearInterval(this.interval);
  }
}
