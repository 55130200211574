import {Component, Input, OnInit} from '@angular/core';
import {Property} from '../../../../models/property';

@Component ({
  selector: 'app-property-info',
  templateUrl: './property-info.component.html',
  styleUrls: ['./property-info.component.scss']
})

export class PropertyInfoComponent implements OnInit {
  @Input() property: Property;

  constructor() {
  }

  ngOnInit() {
  }

  isFeature(object: any): boolean {
    return object instanceof Object;
  }

}
