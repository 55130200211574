import { BrowserModule } from '@angular/platform-browser';
import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import {ToastrModule} from 'ngx-toastr';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import { AppComponent } from './app.component';
import {CoreModule} from './core/core.module';
import {AppRoutingModule} from './app.routing.module';
import {RegistrationModule} from './registration/registration.module';
import {DashboardModule} from './dashboard/dashboard.module';
import {LoginModule} from './login/login.module';
import {ActivateAccountModule} from './account/activate-account.module';
import {SharedModule} from './shared/shared.module';
import {VerifyAccountModule} from './verify-account/verify-account.module';
import {AccessDeniedModule} from './403/access-denied.module';
import {UserService} from './services/user.service';
import {FormsModule} from '@angular/forms';
import {NotificationService} from './services/notification.service';
import {OwnerDashboardModule} from './ownerDashboardRouting/ownerDashboard.module';
import {DataService} from './shared/services/data.service';
import {JobService} from './services/job.service';
import { AdminDashboardModule } from './admin-dashboard/admin-dashboard.module';
import { ChatBotComponent } from './chat-bot/chat-bot.component';
import { NotificationAlertComponent } from './notification-alert/notification-alert.component';
 

 
@NgModule({
  declarations: [
    AppComponent,
    ChatBotComponent,
    NotificationAlertComponent,
    
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CoreModule,
    SharedModule,
    AppRoutingModule,
    LoginModule,
    RegistrationModule,
    FormsModule,
    VerifyAccountModule,
    ActivateAccountModule,
    DashboardModule,
    OwnerDashboardModule,
    MDBBootstrapModule.forRoot(),
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    AccessDeniedModule,
    AdminDashboardModule,
    
    
  ],
  providers: [UserService, NotificationService, JobService, DataService],
  schemas: [ NO_ERRORS_SCHEMA ],
  bootstrap: [AppComponent]
})

export class AppModule { }

declare global {
  interface Window { analytics: any; }
}
