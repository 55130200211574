import { Component, OnInit } from '@angular/core'; 
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-booking-confirmation',
  templateUrl: './booking-confirmation.component.html',
  styleUrls: ['./booking-confirmation.component.scss']
})
export class BookingConfirmationComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<BookingConfirmationComponent>) {}

  onConfirm(): void {
    this.dialogRef.close(true); // Confirm action
  }

  onCancel(): void {
    this.dialogRef.close(false); // Cancel action
  }

  onClose(): void {
    this.dialogRef.close('close'); // Close the dialog and form not submit
    
  }

  ngOnInit() {
  }

}
