import { Component, OnInit, Inject, Input, AfterViewInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import {formatDate} from '@angular/common';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { percentageValidator, numbersOnlyValidator } from 'src/app/validators/number.validator';
import { MaintenanceJob } from 'src/app/models/maintenanceJob';
import { Property } from 'src/app/models/property';
import { UnitNames } from 'src/app/models/unitNames';
import { RoomType } from 'src/app/models/RoomType';
import { UserService } from 'src/app/services/user.service';
import { GenericDto } from 'src/app/models/generic-dto';
import { PropertyService } from 'src/app/services/property.service';
import { ToastrService } from 'ngx-toastr';
import { maintenanceJobCreate, success } from 'src/app/constants/notification-messages.constant';
import { MaintenanceJobMediaComponent } from '../property-details/maintenance-job-media/maintenance-job-media.component';
import { AssetsService } from 'src/app/services/assets.service';

@Component({
  selector: 'app-expense',
  templateUrl: './expense.component.html',
  styleUrls: ['./expense.component.scss']
})
export class ExpenseComponent implements OnInit {
  @Input() event: any;
  minDate = new Date()
  currentPropertyId: string;
  rentalList: any;
  isScheduleExpense :boolean= false;
  expenseForm: FormGroup;
  expense: MaintenanceJob;
  jobTitle = new FormControl('', [Validators.required, Validators.max(30)]);
  jobDetails = new FormControl('', [Validators.required, Validators.max(120)]);
  amount = new FormControl('', [Validators.required, Validators.compose([numbersOnlyValidator])]);
  taxInPercent = new FormControl('', [Validators.required, Validators.compose([percentageValidator])]);
  roomId =  new FormControl('', [Validators.required]);
 
  
  
  
  includeTax= new FormControl(false, [Validators.required]);
  @ViewChild(MaintenanceJobMediaComponent ) media: MaintenanceJobMediaComponent ;
  propertyNames;
  totalPayable: number;

  constructor(public dialogRef: MatDialogRef<ExpenseComponent>,@Inject(MAT_DIALOG_DATA) public data: { propertyId: any, roomId: any },
  private formBuilder: FormBuilder, protected userService: UserService, public toastr: ToastrService,
  public propertyService: PropertyService, private assetService: AssetsService ) { }

  ngOnInit() {
    this.expenseForm = this.formBuilder.group({
      jobTitle: ['', Validators.required],
      jobDetails: ['', Validators.required],
      amount:  new FormControl('', [Validators.required, Validators.compose([numbersOnlyValidator])]),
      taxInPercent: new FormControl('', [Validators.required, Validators.compose([percentageValidator])]),
      roomId: ['', Validators.required],           
      jobDate: [new Date(), Validators.required],
      includeTax: [false, Validators.required],
      jobType:['',],
      recurringSchedule:[null,],
      recurringStartDate:[],      
      propertyId:['',],
      unitId:['',],
      roomObjectId:['',],
      unitName:['',]
      
      
 
    });
    this.initRentals();
  }
  initRentals(){
    this.userService.getUser().subscribe( user =>{
      if(user.payload.role === 'ADMINISTRATOR') {
        this.propertyNames = new Array();
        this.userService.getAdminPropertiesById(user.payload.id).subscribe ( property =>{       
          
            for (let index = 0; index < property.payload.length; index++) {
              const currentProperty = property.payload[index];       
              let name = currentProperty.name;
                let roomNames = new Array();
                for (let index1 = 0; index1 < currentProperty.roomTypes.length; index1++) {
                  const room = currentProperty.roomTypes[index1];                  
                    if(room.unitItems && room.unitItems !== null && room.unitItems !== undefined && room.unitItems.length > 0) {
                      for (let index2 = 0; index2 < room.unitItems.length; index2++) {
                        let unit = room.unitItems[index2];                        
                        // roomNames.push({name: unit + " - " + room.name, id: room.id , roomId: room.roomId, unit: unit, propertyId: currentProperty.id});
                        roomNames.push({datbaseName:room.id+"-roomId-"+room.roomId+"-unit-"+unit.unitName, name: unit.unitName + " - " + room.name, id: room.id , roomId: room.roomId, unit: room.roomId, propertyId: currentProperty.id,unitName:unit.unitName,unitId:unit.unitId});                        
                      }
                    } else {
                     // roomNames.push({datbaseName:room.id+"-roomId-"+room.roomId+"-unit-"+room.name, name: room.roomId + " - " + room.name, id: room.id, roomId: room.roomId, unit: room.roomId, propertyId: currentProperty.id });
                    } 
                }
                this.propertyNames.push({id: currentProperty.id, name: name, roomTypes: roomNames});
            }
            
            
          this.rentalList = this.propertyNames;
        });
      }
    });

  }


  getErrorMessage() {
    return this.jobTitle.hasError('jobTitle') ? 'Enter a valid maintenance job title' :
      this.jobDetails.hasError('jobDetails') ? 'Enter a valid the Maintenance Job Details' :
        this.amount.hasError('amount') ? 'Enter a valid Maintenance Job Amount' :
        this.taxInPercent.hasError('taxInPercent') ? 'Enter a valid Maintenance Job Amount Tax % ' :
        this.roomId.hasError('roomId') ? 'Room or Unit not selected.' :
        this.includeTax.hasError('includeTax') ? 'Please select IncludeTax.' :
        '';
  }
  hide() {
    this.dialogRef.close(ExpenseComponent);
  }

  createExpense(): void {
    if (this.expenseForm.invalid) {
      return;
    }    
    
    let room  = this.expenseForm.value.roomId;
    let propertyId = room.propertyId;
    let unitId = room.unitId;
    let roomId = room.roomId;
    let roomObjectId = room.id
    let unitName = room.unitName;
    let date = this.expenseForm.value.jobDate;
     date = date.toISOString().slice(0,10);
    
    if(this.isScheduleExpense){       
      this.expenseForm.get('jobType').patchValue("RECURRING");
      this.expenseForm.get('recurringStartDate').patchValue(date);
    }else{
      this.expenseForm.get('jobType').patchValue("REGULAR");    
    }  

    

    this.expenseForm.get('jobDate').patchValue(date);     
    this.expenseForm.get('propertyId').patchValue(propertyId );   
    this.expenseForm.get('unitId').patchValue(unitId );   
    this.expenseForm.get('roomId').patchValue(roomId );   
    this.expenseForm.get('roomObjectId').patchValue(roomObjectId );   
    this.expenseForm.get('unitName').patchValue(unitName );   
    //this.expenseForm.get('roomId').patchValue(this.expenseForm.getRawValue().roomId.datbaseName );    
    this.currentPropertyId = this.expenseForm.getRawValue().roomId.propertyId;
    this.expense = this.expenseForm.getRawValue();
    console.log(this.expense)
    this.expense.totalPayable = this.totalPayable;
    
    this.media.onSubmit();
  }
  addMedia(invoices: any) {
    if(invoices) {      
      this.propertyService.createMaintenanceJob(this.expense).subscribe((response: GenericDto<any>) => {
        if (response.code == 201) {        
          this.assetService.uploadExpenseInvoices(response.payload.id, invoices).subscribe();
          this.toastr.success(maintenanceJobCreate, success, {
            timeOut: 3000,
            onActivateTick: true
          });
          this.hide();
        }else{
          this.toastr.error("Error in Expense Creation!", response.desc, {
            timeOut: 3000,
            onActivateTick: true
          });
          this.hide();
        }
      }, error => {
        
       this.hide();
      });
    }
  }

  public calculateTotalPayable() {
    const amount = this.expenseForm.get('amount').value;
    const taxInPercent = this.expenseForm.get('taxInPercent').value;
    const isTaxInclusive =  this.expenseForm.get('includeTax').value;
    this.totalPayable = isTaxInclusive ? amount : (amount + (amount * taxInPercent / 100)).toFixed(2);
  }
  onScheduleExpenseChange(value){  
    this.isScheduleExpense  = value; 
  }
}
