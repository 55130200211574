import {Injectable} from '@angular/core';
import {ToastrService} from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class BedplusToastrService {

  constructor(private toastr: ToastrService) {
  }

  private DEFAULT_TIMEOUT = 3000;

  showSuccessMessage(message: string, title: string, timeout: number): void {
    this.toastr.success(message, title, {
      timeOut: timeout ? timeout : this.DEFAULT_TIMEOUT,
      onActivateTick: true
    });
  }

  showErrorMessage(message: string, title: string, timeout: number): void {
    this.toastr.error(message, title, {
      timeOut: timeout ? timeout : this.DEFAULT_TIMEOUT,
      onActivateTick: true
    });
  }
}
