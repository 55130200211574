import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { PropertyService } from '../../../../services/property.service';
import { FormBuilder } from '@angular/forms';
import { EventService } from '../../../../shared/services/event.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatPaginator, MatSelectChange } from '@angular/material';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { UserService } from '../../../../services/user.service';
import { ToastrService } from 'ngx-toastr';
import { DataService } from '../../../../shared/services/data.service';
import { Inject } from '@angular/core';
import { Property } from '../../../../models/property';
import { GenericDto } from '../../../../models/generic-dto';
import { HttpErrorResponse } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { FormGroup, Validators } from '@angular/forms';
import { OwnerService } from '../../../../services/owner.service';
import { errorMessage, info, success } from '../../../../constants/notification-messages.constant';
import { RoomType } from '../../../../models/RoomType';
import { UnitNames } from '../../../../models/unitNames';


@Component({
  selector: 'app-owner-reservation',
  templateUrl: './owner-reservation.component.html',
  styleUrls: ['./owner-reservation.component.scss']
})

export class OwnerReservationComponent implements OnInit {
  selectedProperty: any;
  rooms: Array<string>;
  @Input() properties: Array<Property>;
  allProperties:Array<Property>
  isLoading = false;
  watcher: Subscription;
  ownerReservationForm: FormGroup;
  selectedUnit: UnitNames;
  propertyId = 'all';
  isUnitName: boolean;
  newPropList: any = new Set();
  minDate = 'today';
  passedDate: any;
  roomData: any;
  selectedRentals:any;
  rentalsWithId:any []
  booking:any;
  property: any = [];
  roomtype: any = [];
  propertiId: any;
  unitId:any;
  unitName:any;
  roomId: string;
  maxDate: any;
  isForm: any;
  /* maxDate = 'today';*/

  constructor(private dialog1: MatDialogRef<OwnerReservationComponent>, @Inject(MAT_DIALOG_DATA) public data: { rentalsWithId:any, row: any, minDate: any, maxDate: any, passedDate: any,booking:any, roomData: any,isForm:any }, private fb: FormBuilder, protected eventService: EventService, public dialog: MatDialog, private router: Router,
    private propertyService: PropertyService, private userService: UserService, private ownerService: OwnerService, private toastrService: ToastrService, private dataService: DataService) {
    if (data.minDate) {
      this.minDate = data.minDate;
      this.passedDate = data.passedDate;
      this.maxDate=data.maxDate;
      this.isForm=data.isForm;
      this.booking = data.booking;
      /*this.maxDate = data.maxDate;*/
    }


    if(data.roomData){
      this.roomData = data.roomData;
      this.selectedRentals = this.roomData;
      this.roomSelected();
    }else{
      this.roomData = data.booking[0];

    }



    this.rentalsWithId = data.rentalsWithId;
    // this.propertiId =  this.roomData.pro


  }

  ngOnInit() {
    this.selectedProperty = new Object() as Property;
   // this.getProperties();

    this.createNewOwnerReservationForm();

  }

  getProperties(): void {
    this.watcher = this.propertyService.getAllPropertiesWithRoomType()
      .subscribe((data: GenericDto<Array<Property>>) => {
        if (data.code === 200) {
          this.properties = data.payload || [];
          this.allProperties =this.properties
          for (let prop of this.properties) {
            if (this.newPropList.length === 0) {
              this.newPropList.add(prop);
            } else {
              for (let prop1 of this.newPropList) {
                if (prop1.propertyId === prop.propId) {
                } else {
                  prop1.add(prop);
                }
              }
            }
          }
          if (this.properties.length > 0  && this.properties[0].roomTypes ) {
            console.log("property = === ",this.properties[0].roomTypes)
            this.property = this.properties.filter((data) => data.name === this.roomData.building);
            this.roomtype =this.property.length > 0 ? this.property[0].roomTypes.find((data) => data.name === this.roomData.title):[];
            this.propertiId = this.roomtype.propertyId;
            let specificRoom =this.roomtype.unitItems.find((data) => data.unitName == this.roomData.occupancy)
            //this.roomId = specificRoom.unitName +'-'+specificRoom.unitId+'-' + this.roomtype.id;
            this.roomId = this.roomtype.id;
            console.log(specificRoom,"PPP????PP1",this.roomId)
          }
        } else {
          // show error message
        }
        this.isLoading = false;
      },
        (error: HttpErrorResponse) => {
          // show error message
          this.isLoading = false;
        });
  }

  setMinDate(value: any): Date {
    return new Date(value);
  }

  hide() {
    this.dialog1.close(OwnerReservationComponent);
  }

  createNewOwnerReservationForm() {
    let disable = false;
    if(this.passedDate == 0){
      disable =false;
    }

    if(this.isForm=='click'){
      this.ownerReservationForm = this.fb.group({
        firstNight: [{ value: this.passedDate, disabled: disable }, [Validators.required,]],
        checkoutDate: [null, [Validators.required]],
        rentalSearchValue: [null,  ],
        // roomId: [null, [Validators.required]],
      });
    }else{
      this.ownerReservationForm = this.fb.group({
        firstNight: [{ value: this.passedDate, disabled: disable }, [Validators.required,]],
        checkoutDate: [{value: this.maxDate, disabled: disable}, [Validators.required]],
        rentalSearchValue: [null, ],
        // property: [null, [Validators.required]],
        // roomId: [null, [Validators.required]],
      });
    }

  }


  sendRequest(): void {
    
    // update property
    this.isLoading = true;
    let payload ={
      "firstNight": moment(this.ownerReservationForm.get('firstNight').value).set({hour: 12, minute: 0}).utc().unix(),
      //"lastNight":moment(this.ownerReservationForm.get('lastNight').value).subtract('days', 1).unix(),
      "lastNight": moment(this.ownerReservationForm.get('checkoutDate').value).set({hour: 12, minute: 0}).utc().subtract(1,'day').unix(),
      "propertyId": this.propertiId,
      "roomId":  this.roomId,
      "unitId": this.unitId,
      "unitName": this.unitName
  }

    console.log(payload);

      this.ownerService.savePersonalBookingOfOwner(payload).subscribe((response: GenericDto<any>) => {
        if (response.code === 200) {
          this.toastrService.success('Request Send Successfully ', success, {
            timeOut: 3000,
            onActivateTick: true
          });
          this.isLoading = false;
          this.hide();
        } else if (response.code === 404) {
          this.toastrService.info('Already Reserved ', info, {
            timeOut: 3000,
            onActivateTick: true
          });
          this.isLoading = false;
        } else {
          this.toastrService.error(response.desc, errorMessage, {
            timeOut: 3000,
            onActivateTick: true
          });
          this.isLoading = false;
        }

      }, (error: HttpErrorResponse) => {
        this.isLoading = false;
        // show errors here
      });

  }


  getSelectedProperty(): Property {
    return this.properties.filter((value: Property) => this.selectedProperty.id === value.id)[0];

  }

  fetchRooms(event: boolean): void {
    if (event) {
      this.selectedProperty.roomTypes.forEach((property: RoomType) => {
        property.unitArray = new Array();
        for (let i = 0; i < property.units.length; i++) {

          this.selectedUnit = new Object() as UnitNames;
          this.selectedUnit.roomId = property.units[i] + '-' + property.id;
          this.selectedUnit.unitName = property.units[i];
          property.unitArray.push(this.selectedUnit);
        }
      });
    }
  }



  fetchAllProperty(event: MatSelectChange): void {
    this.isUnitName = false;
    if (event.value === 'all') {
      this.propertyId = 'all';
      this.createNewOwnerReservationForm();
    }
    console.log("sssss", this.selectedProperty)
    this.selectedProperty.roomTypes.forEach((property: RoomType) => {
      property.unitArray = [];
      if (property.units.length === 0) {
        this.isUnitName = false;
        this.selectedUnit = {} as UnitNames;
        this.selectedUnit.roomId = property.id;
        this.selectedUnit.unitName = property.roomId;
        property.unitArray.push(this.selectedUnit);
      } else {
        this.isUnitName = true;
        for (let i = 0; i < property.units.length; i++) {
          this.selectedUnit = {} as UnitNames;
          this.selectedUnit.roomId = property.units[i] + '-' + property.id;
          this.selectedUnit.unitName = property.units[i];
          property.unitArray.push(this.selectedUnit);
        }
      }
    });
  }

  roomSelected() {
   this.propertiId = this.selectedRentals.propertyId;
   this.unitId = this.selectedRentals.unitId;
   this.roomId   = this.selectedRentals.roomId
   this.unitName =this.selectedRentals.title;
   this.roomData = this.selectedRentals;
  }
  
  isValidationFailed(){    
    if(this.propertiId == null || this.roomId ==  null || this.unitId == null){
      return true;
    }
  }


}
