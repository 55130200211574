export const createNewProperty = '/property/save';

export const getProperties = '/property/get-all';

export const getPropertiesName = '/property/get-all-name';


export const getAllProperties = '/property/getAllProperties';

export const getProperty = '/property/';

export const searchProperty = getProperty+'searchProperty/';

export const getRooms = '/room/findByPropertyId';

export const createMaintenanceJob = '/maintenanceJob/createMaintenanceJob';

export const deleteMaintenanceJob = '/maintenanceJob/deleteMaintenanceJob/';

export const getAllByPropertyIdMaintenanceJobs = '/maintenanceJob/get-all-by';

export const getAllByRoomObjectIdAndUnitId = '/maintenanceJob/get-all-by-roomObjectId-unitId/';

// export const getAllMaintenanceJobs = '/maintenanceJob/get-all';

export const deleteProperty = '/property/deleteProperty/';

export const deleteRoomUnit = '/property/deleteRoomUnit/';
export const sendMessage = '/chatBot/send';




export const deleteMultipleJobs = '/job/deleteJobsByJobId/';

export const deleteBooking = '/booking/deleteBooking/';

export const updateProperty = '/property/update';

export const updateRoomType = '/room/updateRoom';
export const updateBookingProperty = '/booking/updateBooking/';

export const updatePropertyAsset = '/property/updateAsset/';

export const importProperty = '/property/importProperty/';

export const totalProperties = '/property/getTotalProperties';

export const getBookingsByPropertyId = '/booking/getBookingsByPropertyId';

export const updateNotifyUrl = '/property/notifyUrl';

export const removeNotifyUrl = '/property/disableBookingNotifications'

export const checkNotificationReceiving = '/property/checkNotifyUrl';

export const importBookingForMonth = '/property/importBookingForMonth';

export const saveProcessingFees = '/property/platformProcessingFees';

export const openProcessingFees = '/property/platformProcessingFees';

export const setPropertyPricings = '/property/setPropertyPricings';

export const getAllMaintenanceJobsByRoomId = '/maintenanceJob/get-all-by-roomId';

export const updateMaintenanceJob = '/maintenanceJob/updateMaintenanceJob';

export const cloneExpense = '/maintenanceJob/cloneExpense';
