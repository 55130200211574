import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import {HttpService} from '../../core/services/http.service';
import { environment } from "src/environments/environment";
@Injectable()
export class BillingService {
  
 
  constructor(private httpService: HttpService) {
  }

  // stripe Checkout for billing
  checkoutPage(planData:any): Observable<any> {
    return this.httpService.postAuth(environment.API+ `/payment/create-checkout-session`,planData
    )}
    
  billingPortal(customer_id:any){
    return this.httpService.postAuth(environment.API+ `/payment/create-portal-session?customer_id=${customer_id}`,{}
  )}

  getSubscription(): Observable<any> {
    return this.httpService.getAuth(environment.API + `/payment/products/` )
  }

  createSubscription(payload:any): Observable<any> {
    return this.httpService.postAuth(environment.API + `/payment/create-subscription`,payload )
  }

 

  
}
