import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {ReactiveFormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {OwnerReservationComponent} from './owner-reservation.component';
import {SharedModule} from '../../../../shared/shared.module';
import {OwnerService} from '../../../../services/owner.service';

@NgModule ({
  imports: [ReactiveFormsModule, RouterModule, SharedModule, CommonModule ],
  declarations: [OwnerReservationComponent],
  providers: [OwnerService],
  exports: [OwnerReservationComponent]
})

export class OwnerReservationModule {}
