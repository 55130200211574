import {NgModule} from '@angular/core';
import {SharedModule} from '../../../shared/shared.module';
import {CommonModule} from '@angular/common';
import {UpdateProfileComponent} from './update-profile.component';
import {UserService} from '../../../services/user.service';
import {ReactiveFormsModule} from '@angular/forms';



@NgModule ({
  imports: [ReactiveFormsModule, CommonModule, SharedModule],
  declarations: [UpdateProfileComponent],
  providers: [UserService],
  exports: [UpdateProfileComponent]
})

export class UpdateProfileModule {}
