import {Component, EventEmitter, OnDestroy, OnInit, Output, Renderer2} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {GenericDto} from '../models/generic-dto';
import {HttpErrorResponse} from '@angular/common/http';
import {AuthService} from '../services/auth.service';
import {errorMessage, success} from '../constants/notification-messages.constant';
import {ToastrService} from 'ngx-toastr';
import {ActivatedRoute, Router} from '@angular/router';
import {OtpDto} from '../models/otp-dto';
import {DataService} from '../shared/services/data.service';
import {LoaderService} from '../shared/services/loader.service';
import {restrictText} from '../utils/restrictText';

@Component ({
  selector: 'app-activate-account',
  templateUrl: './activate-account.component.html',
  styleUrls: ['./activate-account.component.scss']
})
  
export class ActivateAccountComponent implements OnInit, OnDestroy {
  verifyOtpForm: FormGroup;
  @Output() hide = new EventEmitter<OtpDto>();
  isLoading: boolean;
  isLoadingResend: boolean;
  email: any;

  constructor (private fb: FormBuilder, private authService: AuthService,
               private toastrService: ToastrService, private router: Router,
               private loaderService: LoaderService, private renderer: Renderer2,
               private route: ActivatedRoute, private dataService: DataService) {
    this.createVerifyOtpForm();
    this.renderer.addClass(document.body, 'login-bg');
  }

  ngOnInit() {
    this.verifyOtp();
    this.route.queryParams.subscribe(params => {
      this.email = params['email'];
    });
  }

  private createVerifyOtpForm() {
    this.verifyOtpForm = this.fb.group({
      otp: new FormControl(null, [Validators.required, Validators.maxLength(4)])
    });
  }

  verifyOtp(): void {
    if (this.verifyOtpForm.valid) {
      this.isLoading = true;             
      var payload = {otp:this.verifyOtpForm.get('otp').value, email:this.email }
      this.authService.verifyOTP(payload)
        .subscribe((otpResponse: GenericDto<any>) => {
          if (otpResponse.code === 200) {
            // 1. show confirm password component
            this.loaderService.startLoader();
            this.resetPassword();
          } else {
            // show error
            this.verifyOtpForm.reset(true);
          }
          this.isLoading = false;
        }, (error: HttpErrorResponse) => {
          // disable isLoading
          // show error
          this.verifyOtpForm.reset(true);
          this.isLoading = false;
        });
    }
  }

  resendOtp(): void {

    // enable loading
    this.isLoadingResend = true;
    // reset otp form
    this.verifyOtpForm.reset(true);

    const userEmail = this.email;
    this.authService.generateOTP({email : userEmail})
      .subscribe((response: GenericDto<string>) => {
          if (response.code === 200) {
            // show success notification
            this.toastrService.success(response.payload, success, {
              timeOut: 3000,
              onActivateTick: true
            });
          } else {

            // show error message
            this.toastrService.error(response.payload, errorMessage, {
              timeOut: 4000,
              onActivateTick: true
            });
          }

          // disable loading
          this.isLoadingResend = false;
        },
        (error: HttpErrorResponse) => {
          this.isLoadingResend = false;
        }
      );
  }

  resetPassword(event: OtpDto = this.verifyOtpForm.getRawValue()): void {

    this.loaderService.stopLoader();
    if ( !!this.email) {

      this.dataService.otp = event;

      this.router.navigate(['/accountPassword']);
    }
  }

  restrictText(event: any) {
    restrictText(event);
  }

  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'login-bg'); // login-bg class remove onDestroy
  }

}
