import { Pipe, PipeTransform } from '@angular/core';
import {User} from '../../models/user';
@Pipe({
  name: 'filterCleaner'
})
export class CleanerFilterPipe implements PipeTransform {
  transform(items: User[], searchText: string): User[] {
    if (!items) {
      return [];
    }
    if (!searchText) {
      return items;
    }
    searchText = searchText.toLowerCase();
    return items.filter(it => {
      return it.firstName.toLowerCase().includes(searchText) || it.lastName.toLowerCase().includes(searchText);
    });
  }
}
