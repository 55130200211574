export class JobInstructions {
    accessInstructions: string;
    garbageInstructions: string;
    parkInstructions: string;
    otherInformations: string;
    cleaningChecklist: string;

    constructor(accessInstructions: string,
        garbageInstructions: string,
        parkInstructions: string,
        otherInformations: string,
        cleaningChecklist: string){
            this.accessInstructions = accessInstructions;
            this.garbageInstructions= garbageInstructions;
            this.parkInstructions = parkInstructions,
            this.otherInformations = otherInformations,
            this.cleaningChecklist = cleaningChecklist
    }
}