import { Injectable } from '@angular/core';
import {HttpService} from '../core/services/http.service';
import {Observable} from 'rxjs/index';
import {environment} from '../../environments/environment';
import {sendMessage} from '../constants/property-api.constants';
import {GenericDto} from '../models/generic-dto';
@Injectable({
  providedIn: 'root'
})
export class ChatBotService {
  constructor(private httpService: HttpService) { }

  sendMessage(payload): Observable<GenericDto<any>> {
    return this.httpService.postAuth<any>(environment.API + sendMessage ,payload); 
       
  }

}
