import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material';

@Component({
  selector: 'app-owner-booking-dialog',
  templateUrl: './owner-booking-dialog.component.html',
  styleUrls: ['./owner-booking-dialog.component.scss']
})
export class OwnerBookingDialogComponent implements OnInit {
dialogRef;
event: any;

  constructor(@Inject(MAT_DIALOG_DATA) public data: {event: any}) {
    this.event = data.event;
  }

  ngOnInit() {

  }
  hide() {
    this.dialogRef.close();
  }

}
