import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {SharedModule} from '../../../shared/shared.module';
import {JobAssignComponent} from './job-assign.component';
import {ReactiveFormsModule} from '@angular/forms';
import {PropertyService} from '../../../services/property.service';
import {CommonModule} from '@angular/common';
import {JobManageComponent} from '../job-manage/job-manage.component';
import {JobManageModule} from '../job-manage/job-manage.module';
import {NotificationService} from '../../../services/notification.service';
import {JobInstructionComponent} from '../job-instruction/job-instruction.component';
import {JobInstructionModule} from '../job-instruction/job-instruction.module';
import {UpdateJobComponent} from '../update-job/update-job.component';

@NgModule ({
  imports: [ReactiveFormsModule, RouterModule, SharedModule, CommonModule , JobManageModule, JobInstructionModule ],
  declarations: [JobAssignComponent, UpdateJobComponent],
  providers: [PropertyService, NotificationService],
  exports: [JobAssignComponent, UpdateJobComponent],
  entryComponents: [JobManageComponent, JobInstructionComponent, UpdateJobComponent]
})

export class JobAssignModule {}
