import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { MaintenanceJob } from 'src/app/models/maintenanceJob';
import { FormControl, Validators, FormGroup, FormBuilder } from '@angular/forms';
import { numbersOnlyValidator, percentageValidator } from 'src/app/validators/number.validator';
import { UserService } from 'src/app/services/user.service';
import { moment } from 'fullcalendar';
import { MaintenanceJobMediaComponent } from '../property-details/maintenance-job-media/maintenance-job-media.component';
import { AssetsService } from 'src/app/services/assets.service';
import { DomSanitizer } from '@angular/platform-browser';
import { PropertyService } from 'src/app/services/property.service';
import { ToastrService } from 'ngx-toastr';
import { success } from 'src/app/constants/notification-messages.constant';
import { ConfirmationDialogComponent } from 'src/app/shared/components/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-edit-expense',
  templateUrl: './edit-expense.component.html',
  styleUrls: ['./edit-expense.component.scss']
})
export class EditExpenseComponent implements OnInit {
  maintenanceJob: MaintenanceJob;
  editMaintenanceJobForm : FormGroup;
  jobTitle = new FormControl('', [Validators.required, Validators.max(30)]);
  jobDetails = new FormControl('', [Validators.required, Validators.max(120)]);
  amount = new FormControl('', [Validators.required, Validators.compose([numbersOnlyValidator])]);
  taxInPercent = new FormControl('', [Validators.required, Validators.compose([percentageValidator])]);
  includeTax= new FormControl(false, [Validators.required]);
  @ViewChild(MaintenanceJobMediaComponent) media: MaintenanceJobMediaComponent ;
  currentRoom;
  jobDate;
  roomName;
  invoices;
  isScheduleExpense : boolean;
  isCurrentDateExpense:boolean = false;
  isButtonDisable : boolean ;
  expenseFrequency: string;
  constructor( public confirmDialog: MatDialog,private dialog: MatDialogRef<EditExpenseComponent>, @Inject(MAT_DIALOG_DATA) private data: any,
  private formBuilder: FormBuilder, private userSerService: UserService, private assetService: AssetsService,
  private sanitizer : DomSanitizer, private propertyService: PropertyService, private toastrService: ToastrService) {
    this.userSerService.getUser().subscribe(user => {
      this.userSerService.getPropertiesById(user.payload.id).subscribe(rooms => {
        console.log(rooms);
        if(rooms.payload != null)
          rooms.payload.forEach(room => { if(room.id === this.data.roomObjectId) { this.roomName = room.name; this.currentRoom = room; } });
       }); 
      });
        console.log(this.jobDate);

    this.maintenanceJob = data;
  }

  ngOnInit() {
    console.log("this.maintenanceJob =", this.maintenanceJob)
    if (this.data.invoiceAddresses && this.data.invoiceAddresses !== null && this.data.invoiceAddresses !== undefined) {
      let index = 0 ;
      this.invoices = new Array();
      this.getJobImages(this.maintenanceJob);
    }
    this.editMaintenanceJobForm = this.formBuilder.group({
      jobTitle: [this.maintenanceJob.jobTitle, Validators.required],
      jobDetails: [this.maintenanceJob.jobDetails, Validators.required],
      amount:  new FormControl(this.maintenanceJob.amount, [Validators.required, Validators.compose([numbersOnlyValidator])]),
      taxInPercent: new FormControl(this.maintenanceJob.taxInPercent, [Validators.required, Validators.compose([percentageValidator])]),
      jobDate: [new Date(this.data.jobDate), Validators.required],
      includeTax: [this.maintenanceJob.includeTax, Validators.required],
      totalPayable: [this.maintenanceJob.totalPayable]
    });
    this.isScheduleExpense = this.maintenanceJob.jobType == "RECURRING" ? true : false ;
    this.expenseFrequency = this.maintenanceJob.recurringSchedule;
    console.log('this.expenseFrequency =' ,    this.expenseFrequency)
    this.isButtonDisable = false ;
  }
  getErrorMessage() {
    return this.jobTitle.hasError('jobTitle') ? 'Enter a valid maintenance job title' :
      this.jobDetails.hasError('jobDetails') ? 'Enter a valid the Maintenance Job Details' :
        this.amount.hasError('amount') ? 'Enter a valid Maintenance Job Amount' :
        '';
  }
  hide() {
    this.dialog.close(EditExpenseComponent);
  }

  confirmDialogOpen() {
    const dialogRef = this.confirmDialog.open(ConfirmationDialogComponent, {
      width: '430px',
      height: '200px',
      data: "This scheduled expense will create a future "+this.expenseFrequency+" expense for this room, would you like to also create one for today?"
    });
    dialogRef.afterClosed().subscribe(result => {          
      if(result=="close" || (typeof result != 'boolean')){
        return false
      }else{
        this.isCurrentDateExpense = result; 
        this.media.onSubmit();
      }             
    });
   }


  updateMaintenaceJob () {     
    const selectedDate = new Date(this.editMaintenanceJobForm.value.jobDate); 
    const currentDate = new Date();
    // Check if the selected date is today's date or in the past
    if (selectedDate <= currentDate && this.isScheduleExpense) {
      this.confirmDialogOpen();      
    }else{
      this.media.onSubmit();
    }   

  }


  onExpenseFrequencyChange(value){
    this.expenseFrequency = value;
    console.log("this.expenseFrequency =", this.expenseFrequency)
  }
  formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
  }
  
  addMedia(invoices: any) {     
    if(invoices) {
      this.maintenanceJob.isCurrentDateExpense = this.isCurrentDateExpense;
      this.maintenanceJob.amount = this.editMaintenanceJobForm.getRawValue().amount;
      this.maintenanceJob.jobDetails = this.editMaintenanceJobForm.getRawValue().jobDetails;
      this.maintenanceJob.jobTitle = this.editMaintenanceJobForm.getRawValue().jobTitle;
      this.maintenanceJob.taxInPercent = this.editMaintenanceJobForm.getRawValue().taxInPercent;
      this.maintenanceJob.includeTax = this.editMaintenanceJobForm.getRawValue().includeTax;
      this.maintenanceJob.totalPayable = this.editMaintenanceJobForm.getRawValue().totalPayable;
      if(this.editMaintenanceJobForm.getRawValue().jobDate !== moment(this.maintenanceJob.jobDate, 'mmm dd yyyy').format("YYYY-MM-DD")) {
        let date = this.formatDate(this.editMaintenanceJobForm.value.jobDate);
        this.editMaintenanceJobForm.get('jobDate').patchValue(date);

      }
      this.maintenanceJob.jobDate = this.editMaintenanceJobForm.getRawValue().jobDate;

      if(this.isScheduleExpense){
          this.maintenanceJob.recurringSchedule = this.expenseFrequency ;
          this.maintenanceJob.recurringLastRun = this.formatDate(this.editMaintenanceJobForm.value.jobDate) ;
          this.maintenanceJob.recurringStartDate = this.formatDate(this.maintenanceJob.recurringStartDate) ;
      }
      this.isButtonDisable = true ;
      this.propertyService.updateMaintenanceJob(this.maintenanceJob).subscribe(response => {
        if (response.code === 200 || response.code === 201) {
          this.isButtonDisable = false ;
          this.assetService.uploadExpenseInvoices(this.maintenanceJob.id, invoices).subscribe();
          this.toastrService.success(response.desc, success, {
            timeOut: 3000,
            onActivateTick: true
          });
          this.hide();
        }
      }, error => {
        this.toastrService.error('Error', error, {
          timeOut: 3000,
          onActivateTick: true
        });
      });
    }
  }

  getJobImages(maintenanceJob) {
    let index = 0;
    for(let invoiceAddress of maintenanceJob.invoiceAddresses) {
      let file;
      this.assetService.getExpenseImages(maintenanceJob.id, invoiceAddress).subscribe(response=> {
          let tempFileName = invoiceAddress.substring(invoiceAddress.lastIndexOf('\\')+1);
          var fileNameArray = new Array (); fileNameArray= tempFileName.split("_expense_invoice_",2);
          let fileName = fileNameArray[1];
          if(!fileName || fileName === undefined || fileName === null) { fileName = '_expense_invoice_' + index + '.png' };
          file = new File([response], fileName, { type: "image/png" });
          this.invoices.push(file);
      });
    }
  }

  public calculateTotalPayable() {
    const amount = this.editMaintenanceJobForm.get('amount').value;
    const taxInPercent = this.editMaintenanceJobForm.get('taxInPercent').value;
    const isTaxInclusive =  this.editMaintenanceJobForm.get('includeTax').value;
    const totalPayable = this.editMaintenanceJobForm.get('totalPayable');
    totalPayable.setValue(isTaxInclusive ? (amount + (amount * taxInPercent / 100)).toFixed(2) : amount);
  }
}
