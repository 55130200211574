import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { info, success, errorMessage } from 'src/app/constants/notification-messages.constant';
import { MatDatepicker,MatDatepickerInputEvent, MatDialogRef } from '@angular/material';
import { StatementService } from 'src/app/services/statement.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog'; 
import { ToastrService } from 'ngx-toastr';
 
 


@Component({
  selector: 'app-create-statement',
  templateUrl: './create-statement.component.html',
  styleUrls: ['./create-statement.component.scss']
})
export class CreateStatementComponent implements OnInit {
   selectedDate:Date; 
   isValidDate:boolean = true; 
   isSelectedFutureDate:boolean = false; 
   owners = [] = this.data.allOwners;
   selectedOwner = this.data.ownerDetail;
   
  @ViewChild('fromPicker') fromPicker: MatDatepicker<any>;  
  
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private toastrService: ToastrService, private statementService: StatementService, private dialog: MatDialogRef<CreateStatementComponent>) {   
  }   

  ngOnInit() {
     
  }

  createStatement(){       
    const monthName = this.selectedDate.toLocaleString('en-US', { month: 'short' });
    const yearName = this.selectedDate.getFullYear();    
    let reportGenerationMonth =  monthName+" 01 "+yearName   
         
    let statement ={
      reportGenerationMonth:reportGenerationMonth ,
      ownerId:this.selectedOwner.id     
    }
    this.statementService.createStatement(statement).subscribe(response => {           
      if (response.code == 201) {       
        this.toastrService.success(response.desc, success, {
          timeOut: 3000,
          onActivateTick: true
        });
        
      }else{
        this.toastrService.error(response.desc, errorMessage, {
          timeOut: 3000,
          onActivateTick: true
        });
      }
      this.hide()

    });

  }

  hide(){   
    this.dialog.close();
  }  
  
  chosenMonthHandler(datepicker: Date) {   
    this.fromPicker.close();  
    this.selectedDate = datepicker;
    this.isValidDate = this.isFutureDate();
    this.isSelectedFutureDate =this.isValidDate;
  }

  isFutureDate(): boolean {
    const currentDate = new Date();
    return this.selectedDate > currentDate;
  }


 
   

}
