import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import {AuthGuard} from '../core/guards/auth.guard';
import {OwnerDashboardHomeComponent} from './ownerDashboardHome/owner-dashboard-home.component';
import {OwnerDashboardComponent} from './ownerDashboard.component';


@NgModule({
  imports: [
    RouterModule.forChild([
      {
        path: 'ownerDashboard', component: OwnerDashboardComponent, canActivate: [AuthGuard],
        children: [
          {
            path: '', component: OwnerDashboardHomeComponent
          },
          ]},
    ])
  ],
  exports: [RouterModule]
})
export class OwnerDashboardRoutingModule {

}
