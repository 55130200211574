import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {SharedModule} from '../../../shared/shared.module';
import {OwnerDetailsModule} from './owner-details/owner-details.module';
import {OwnerComponent} from './owner.component';
import {OwnerReservationModule} from './owner-reservation/owner-reservation.module';

@NgModule ({
  imports: [RouterModule, SharedModule, OwnerDetailsModule , OwnerReservationModule],
  declarations: [OwnerComponent],
  exports: [OwnerComponent]
})

export class OwnerModule {}
