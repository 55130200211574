import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ReactiveFormsModule} from '@angular/forms';
import {SharedModule} from '../../shared/shared.module';
import { HighchartsChartModule } from 'highcharts-angular';
import {OwnerDashboardHomeComponent} from './owner-dashboard-home.component';
import {OwnerDashboardRoutingModule} from '../ownerDashboard-routing.module';
import {OwnerReservationComponent} from '../../dashboard/owner/owner/owner-reservation/owner-reservation.component';

@NgModule ({
  imports: [ReactiveFormsModule, CommonModule, SharedModule , HighchartsChartModule , OwnerDashboardRoutingModule],
  declarations: [OwnerDashboardHomeComponent],
  exports: [OwnerDashboardHomeComponent],
  entryComponents: [OwnerReservationComponent]
})

export class OwnerDashboardHomeModule {}
