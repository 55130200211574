import { Component, OnInit , Inject} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-dialouge',
  templateUrl: './confirm-dialouge.component.html',
  styleUrls: ['./confirm-dialouge.component.scss']
})
export class ConfirmDialougeComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<ConfirmDialougeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { title: string, message: string }
  ) { }

  


  ngOnInit() {
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  onYesClick(): void {
    this.dialogRef.close(true);
  }





}
