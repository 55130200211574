import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {DashboardHomeComponent} from './dashboard-home.component';
import {SharedModule} from '../../shared/shared.module';

@NgModule({
  imports: [CommonModule, FormsModule, SharedModule],
  declarations: [DashboardHomeComponent],
  exports: [DashboardHomeComponent]
})

export class DashboardHomeModule {}
