import {AbstractControl, FormControl, ValidatorFn} from '@angular/forms';

/**
 * Regular Expression for Only number
 * @type {RegExp}
 */

const onlyNumbersRegexp: RegExp = /(^[0][1-9]+)|([1-9]\d*)$/;
const contactNumbersRegexp: RegExp = /^[0-9]{5,13}$/;
const percentageRegexp: RegExp = /^(100([\.][0]{1,})?$|[0-9]{1,2}([\.][0-9]{1,})?)$/;

/**
 * Numbers Only Validator for Forms
 * @param control
 * @returns {any}
 */


export function numbersOnlyValidator(control: FormControl) {
  if (control.value < 0) {
    return {invalidData: true};
  }
}

export function contactNumbersValidator(control: FormControl) {
  if (control.value && !contactNumbersRegexp.test(control.value)) {
    return { invalidData: true };
  }
  return null;
}


export function percentageValidator(control: FormControl) {
  if (control.value && !percentageRegexp.test(control.value)) {
    return { invalidData: true };
  }
  return null;
}
