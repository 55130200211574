import {Injectable} from '@angular/core';
import {Observable, throwError} from 'rxjs/index';
import {HttpService} from '../core/services/http.service';
import {environment} from '../../environments/environment';
import {
  activateAccount, activeInviteAccount,
  emailVerify, generateOTP, login, logout, register, resetPasswordAPI,
  validateOTP
} from '../constants/auth-api.constants';
import {LoginModel} from '../models/login.model';
import {GenericDto} from '../models/generic-dto';
import {RegisterModel} from '../models/register.model';
import {LoaderService} from '../shared/services/loader.service';
import {catchError, tap} from 'rxjs/internal/operators';
import {HttpErrorResponse} from '@angular/common/http';
import {EmailDto} from '../models/email-dto';
import {OtpDto} from '../models/otp-dto';
import {ResetPasswordDto} from '../models/reset-password-dto';
import { Router } from '@angular/router';

@Injectable()
export class AuthService {
  redirectUrl: string;
  isLoggedIn: boolean=false;

  constructor(private loaderService: LoaderService, private httpService: HttpService,private router: Router) {
  }

  /**
   * Login existing user
   * @param {LoginModel} authDetails
   * @returns {Observable<GenericDto<string>>}
   */
  login(authDetails: LoginModel): Observable<GenericDto<string>> {
    this.loaderService.startLoader(); // start loader before generating HTTP request
    return this.httpService.post<any>(environment.API + login, authDetails)
      .pipe(tap(() => {
        this.loaderService.stopLoader();  // stop loader when response received
        this.isLoggedIn = true;
       // console.log("ZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZzzz",this.redirectUrl)
      // if (this.redirectUrl) {
      //   this.router.navigate([this.redirectUrl]);
      //   this.redirectUrl = null;
      // }
      }), catchError((error: HttpErrorResponse) => {
        this.loaderService.stopLoader();  // stop loader if error
        return throwError(error);
      }));

      
  }

  /**
   * Register new user
   * @param {RegisterModel} userDetails
   * @returns {Observable<GenericDto<string>>}
   */
  register(userDetails: RegisterModel): Observable<GenericDto<string>> {
    this.loaderService.startLoader();
    return this.httpService.post<GenericDto<string>>(environment.API + register, userDetails)
      .pipe(tap(() => {
        this.loaderService.stopLoader();
      }), catchError((error: HttpErrorResponse) => {
        this.loaderService.stopLoader();
        return throwError(error);
      }));

  }

  /**
   * Logout user session
   */
  logout(): Observable<GenericDto<string>> {
    this.isLoggedIn = false;
    return this.httpService.postAuth<GenericDto<string>>(environment.API + logout, null);
  }

  /**
   * verify user email
   */
  verifyEmail(emailData: EmailDto): Observable<GenericDto<string>> {
    return this.httpService.post<GenericDto<string>>(environment.API + emailVerify, emailData);
  }

  /**
   * generate 4 digits OTP if email is valid
   */
  generateOTP(emailData: EmailDto): Observable<GenericDto<string>> {
    return this.httpService.post<GenericDto<string>>(environment.API + generateOTP, emailData);
  }

  /**
   *  verify entered OTP validity
   */
  verifyOTP(otpDto: any): Observable<GenericDto<string>> {
    return this.httpService.post<GenericDto<string>>(environment.API + validateOTP, otpDto);
  }

  /**
   *  activate account by OTP
   */
  activateAccount(otp: OtpDto): Observable<GenericDto<string>> {
    return this.httpService.post<GenericDto<string>>(environment.API + activateAccount, otp);
  }

  activeInviteAccount(resetPasswordDto: ResetPasswordDto): Observable<GenericDto<string>> {
    return this.httpService.post<GenericDto<string>>(environment.API + activeInviteAccount, resetPasswordDto);
  }

  /**
   * reset password
   */
  resetPassword(resetPasswordDto: ResetPasswordDto): Observable<GenericDto<string>> {

    // start loader before API call
    this.loaderService.startLoader();
    return this.httpService.post<GenericDto<string>>(environment.API + resetPasswordAPI, resetPasswordDto)
      .pipe(tap((e: any) => {
        // stop loader on success API response
        this.loaderService.stopLoader();
      }), catchError((error: HttpErrorResponse) => {
        // stop loader on any error
        this.loaderService.stopLoader();

        // throw error to be handled further
        return throwError(error);
      }));
  }

}
