import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {SharedModule} from '../../shared/shared.module';
import {JobManageModule} from './job-manage/job-manage.module';
import {JobAssignModule} from './job-assign/job-assign.module';
import {JobComponent} from './job.component';
import { JobCommentsModule } from './job-comments/job-comments.module';

@NgModule ({
  imports: [RouterModule, SharedModule, JobAssignModule , JobManageModule, JobCommentsModule ],
  declarations: [JobComponent],  
  exports: [JobComponent]
})

export class JobModule {}
