import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Component, OnInit , Inject, Renderer2} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';


@Component({
  selector: 'app-booking-details',
  templateUrl: './booking-details.component.html',
  styleUrls: ['./booking-details.component.scss']
})
export class BookingDetailsComponent implements OnInit {
  bookings:any;
  displayColumns:any;
  totalsColumnsToDisplay = [];

  displayedColumns: string[];//  = ["Booking Id", "Date", 'firstNight', 'lastNight', 'guestAddress', 'netProfit' ];
  allColumns: string[] =  this.data.displayColumns;

  constructor(private renderer: Renderer2, private breakpointObserver: BreakpointObserver, public dialogRef: MatDialogRef<BookingDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { bookings: any, displayColumns: any,initialDisplayColums:any }
  ) { }
  

  ngAfterViewInit() {
    
  }

  ngOnInit() {
    this.bookings = this.data.bookings;
    this.displayColumns = this.data.initialDisplayColums;
    this.totalsColumnsToDisplay = this.data.displayColumns;
     
   console.log(this.data.displayColumns)
 

   

  }
  
  isTooltipDisplay(value,formula){}

  showMoreToggle(event: Event,action:string) {
    const toggleButton = event.target as HTMLElement;
    let infoContainer = toggleButton.nextElementSibling as HTMLElement;
    if(action == "hide"){
       infoContainer = toggleButton.previousElementSibling as HTMLElement;
       this.renderer.addClass(infoContainer, 'hidden');
       this.renderer.removeClass(infoContainer.previousElementSibling, 'hidden'); 
              
    }else{
       infoContainer = toggleButton.nextElementSibling as HTMLElement;
       this.renderer.removeClass(infoContainer, 'hidden');  
       this.renderer.removeClass(infoContainer.nextElementSibling, 'hidden');     
    } 
    this.renderer.addClass(toggleButton, 'hidden');    
}

 

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  onYesClick(): void {
    this.dialogRef.close(true);
  }

}
