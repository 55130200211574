import {NgModule} from '@angular/core';
import {DashboardCalendarComponent} from './dashboard-calendar.component';
import {SharedModule} from '../../shared/shared.module';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';

@NgModule ({
  declarations : [DashboardCalendarComponent],
  imports : [SharedModule, CommonModule, FormsModule],
  exports : [DashboardCalendarComponent],
  providers : [],
  entryComponents : [DashboardCalendarComponent],
})


export class DashboardCalendarModule {
}
