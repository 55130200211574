import { Component, OnInit } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { NavigationEnd, Router } from '@angular/router';
import { SharedService } from '../services/shared.service';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-notification-alert',
  templateUrl: './notification-alert.component.html',
  styleUrls: ['./notification-alert.component.scss'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({ transform: 'translateX(100%)' }),
        animate('400ms ease-out', style({ transform: 'translateX(0)' }))
      ]),
      transition(':leave', [
        animate('400ms ease-in', style({ transform: 'translateX(100%)' }))
      ])
    ])
  ]
  
})
export class NotificationAlertComponent implements OnInit {
  showSubscriptionAlert: boolean = false;
  showLastSubscriptionAlert: boolean = false;
  currentUrl:String;
  
  remainingDaysString: string = localStorage.getItem("remainingDays") // Set the number of days until expiry
 
  isShow = localStorage.getItem("alertShow");

  constructor(public router: Router,public sharedService: SharedService,) { }

  ngOnInit() {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
     this.currentUrl =  event.url;
     if(this.currentUrl.includes('login') || this.currentUrl.includes('ownerDashboard')){
      this.dismissAlert();
     }
    });
    
    this.checkSubscriptionStatus();
  }
   

  checkSubscriptionStatus() {   
    // Logic to check subscription status
    // For example, you can check if the subscription is expired or expiring soon
    // For demonstration purposes, let's assume the subscription is expiring in 7 days
    const daysUntilExpiry = parseInt(this.remainingDaysString, 10);
    if(this.isShow == "1" && daysUntilExpiry <2){
      this.showLastSubscriptionAlert = true;
      this.showSubscriptionAlert = false;

    }
    else if (this.isShow == "1" && daysUntilExpiry <= 15) {
      this.showSubscriptionAlert = true;
      this.showLastSubscriptionAlert = false;
    } else {
      this.showSubscriptionAlert = false;
      this.showLastSubscriptionAlert = false;
    }
  }

  dismissAlert() {
    this.showSubscriptionAlert = false;
    this.showLastSubscriptionAlert = false;
  }

  subscribe() {
    this.router.navigate(["/dashboard/billing"]);
  }  

}
