import { Component, OnInit, Output, Inject, Input } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import {OwnerDto} from "../../../models/owner-dto";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {NotificationService} from "../../../services/notification.service";

@Component({
  selector: 'app-send-notifications',
  templateUrl: './send-notification.component.html',
  styleUrls: ['./send-notification.component.scss']
})
export class SendNotificationComponent implements OnInit {
  ownerId: string;
  revenueForm: FormGroup;
  endDate: any;
  startDate: any;

  constructor(public dialogRef: MatDialogRef<SendNotificationComponent>,
              private fb: FormBuilder,
              private notificationService: NotificationService,
              @Inject(MAT_DIALOG_DATA) public owner: OwnerDto) {
    console.log("_owner", owner)
    this.ownerId = owner.ownerId;

    var date = new Date();
    const start_Date=new Date(date.getFullYear(), date.getMonth(), 1);
    const end_Date=new Date(date.getFullYear(), date.getMonth() + 1, 0)

    this.revenueForm = this.fb.group({
      fromDate: [start_Date, [Validators.required]],
      toDate: [end_Date, [Validators.required]]
    });
  }

  convert(string) {
    var date = new Date(string),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }

  setMinDate(value: any): Date {
    return new Date(value);
  }

  fromDateSelected() {
    if (this.revenueForm.value.fromDate !== null) {
      this.startDate = (this.convert(this.revenueForm.value.fromDate));
    }
  }

  toDateSelected() {
    if (this.revenueForm.value.fromDate !== null) {
      this.endDate = (this.convert(this.revenueForm.value.toDate));
    }
  }

  sendNotification(): void {
    console.log("Sending notification: ", {
      owner: this.ownerId,
      sd: this.revenueForm.value.fromDate,
      ed: this.revenueForm.value.toDate,
      revrep: this.revenueForm
    });
    this.notificationService.sendOwnerRevenueReportEmail(
      this.revenueForm.value.fromDate,
      this.revenueForm.value.toDate,
      this.ownerId).subscribe(response => {
      console.log("sendOwnerRevenueReportEmail response: ", response);
      this.dialogRef.close();
    })

  }

  ngOnInit() {}
}
