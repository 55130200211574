import {NgModule} from '@angular/core';
import {AccessDeniedComponent} from './access-denied.component';
import {SharedModule} from '../shared/shared.module';
import {CommonModule} from '@angular/common';

@NgModule ({
  declarations: [AccessDeniedComponent],
  imports: [SharedModule, CommonModule],
  exports: [AccessDeniedComponent]
})

export class AccessDeniedModule {}
