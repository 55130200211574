import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatTableDataSource } from '@angular/material';
import { UserService } from 'src/app/services/user.service';
import { ToastrService } from 'ngx-toastr';
import { success, errorMessage, successLogin } from 'src/app/constants/notification-messages.constant';
import { FormGroup, FormControl, Validators, EmailValidator, FormBuilder } from '@angular/forms';
import { emailValidator } from 'src/app/validators/email.validator';
import { TokenService } from 'src/app/core/services/token.service';
import { Router } from '@angular/router';
import { DataService } from 'src/app/shared/services/data.service';
import { StorageService } from 'src/app/core/services/storage.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-user-action',
  templateUrl: './user-action.component.html',
  styleUrls: ['./user-action.component.scss']
})
export class UserActionComponent implements OnInit {
  action: String;
  userName: String;
  isInvite: Boolean = false;
  isLogin: Boolean = false;
  isFreeAccount: Boolean = false;
  isPMS: Boolean = false;
  inviteForm : FormGroup;
  loginForm : FormGroup;
  userRole: string;
  dataSource;
  displayColumns = ['source', 'apiKey', 'status']; 

  constructor(private tokenService: TokenService, private fb: FormBuilder, public router: Router,
    private dataService: DataService, public userService: UserService, public toastr: ToastrService,
    private storageService: StorageService, private authService: AuthService,
    public dialogRef: MatDialogRef<UserActionComponent>,
    @Inject(MAT_DIALOG_DATA) public data :{Action: String, User:any}) {
      this.router.routeReuseStrategy.shouldReuseRoute = function() {
        return false;
      };
    }

  ngOnInit() {
    this.inviteForm = this.fb.group({
      email :new FormControl('', [Validators.required, Validators.compose([emailValidator])])
    });
    this.loginForm = this.fb.group({
      password: new FormControl('', [Validators.required, Validators.minLength(4)])
    });
    this.action = this.data.Action;
    if(this.data.User) { this.userName = this.data.User.userName; }
    this.checkFlags();
  }
    checkFlags(){       
      if(this.action === 'Invite') {
        this.isInvite = true;
        this.isLogin = false;
        this.isPMS = false;
        this.isFreeAccount = false;
      } else if(this.action === 'Login'){
        this.isInvite = false;
        this.isLogin = true;
        this.isPMS = false;
        this.isFreeAccount = false;
      } else if(this.action === 'PMS') {
        this.dataSource = new MatTableDataSource(this.data.User.pmss);
        this.isInvite = false;
        this.isLogin = false;
        this.isPMS = true;
        this.isFreeAccount = false;
      }else if(this.action === 'freeAccount'){
        this.isInvite = false;
        this.isLogin = false;
        this.isPMS = false;
        this.isFreeAccount = true;
      } else{
        this.isInvite = false;
        this.isLogin = false;
        this.isPMS = false;
        this.isFreeAccount = false;
      }
    }
  submit(){
    if(this.action === 'Delete') {
      this.userService.deleteUser(this.data.User.id).subscribe(response =>{
        if(response.code === 200) {
          this.toastr.success(response.desc, success, {
            timeOut: 3000,
            onActivateTick: true
          });
      } else {
        this.toastr.error(response.desc, errorMessage, {
          timeOut: 3000,
          onActivateTick: true
        });
      }
      this.dialogRef.close();
      });
    } else if((this.action === 'BLOCK') || (this.action == 'UNBLOCK')) {
      this.userService.userBlocking(this.data.User.id, this.action).subscribe(response => {
        if(response.code === 200) {
          this.toastr.success(response.desc, success, {
            timeOut: 3000,
            onActivateTick: true
          });
          this.dialogRef.close();
      } else {
        this.toastr.error(response.desc, errorMessage, {
          timeOut: 3000,
          onActivateTick: true
        });
      }
      });
    } else if (this.action === 'Invite'){
      if(this.inviteForm.getRawValue().email) {
        this.userService.sendInviteByEmail(this.inviteForm.getRawValue().email).subscribe (response => {
          if(response.code === 200) {
            this.toastr.success(response.desc, success, {
              timeOut: 3000,
              onActivateTick: true
            });
          } else {
            this.toastr.error(response.desc, errorMessage, {
              timeOut: 3000,
              onActivateTick: true
            });
          }
          this.dialogRef.close();
        })
      }
    } else if(this.action === 'Login') {
      if(this.loginForm.getRawValue().password) {
        this.userService.loginToAccount(this.data.User.id, this.loginForm.getRawValue().password).subscribe(response => {
          if(response.code === 200) {
            this.storageService.clearAll();
            this.tokenService.removeToken();
            this.tokenService.saveToken(response.payload.token);
            this.dataService.cleaner = response.payload.user;
            this.tokenService.saveCurrentUser(response.payload.user.role);
            this.tokenService.setUserId(this.data.User.id);
            this.userRole = this.tokenService.getCurrentUser();
                this.toastr.success(successLogin, success, {
                  timeOut: 3000,
                  onActivateTick: true
                });
                if(this.userRole === 'ADMINISTRATOR') {
                  this.userService.redirectUser().subscribe(response => {
                    if(response.payload === false) {
                      this.router.navigate(['/dashboard/plugins/']);
                    } else {
                      this.router.navigate(['/ownerDashboard']);
                    }
                  });
                }
            this.toastr.success(response.desc, success, {
              timeOut: 3000,
              onActivateTick: true
            });
            this.dialogRef.close();
          } else {
            this.toastr.error(response.desc, errorMessage, {
              timeOut: 3000,
              onActivateTick: true
            })
          }
        });
      }
    }else  if(this.action === 'freeAccount'){
        this.userService.updateFreeAccountStatus(this.data.User.accountId, this.data.User.freeAccount).subscribe(response => {
          if(response.code === 200) {
            this.toastr.success(response.desc, success, {
              timeOut: 3000,
              onActivateTick: true
            });
            this.dialogRef.close();
        } else {
          this.toastr.error(response.desc, errorMessage, {
            timeOut: 3000,
            onActivateTick: true
          });                                                                                        
        }
        });
    }else{
        //Do Nothing
    }
    // this.dialogRef.close();
  }
}
