import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams, HttpResponse} from '@angular/common/http';
import { Observable } from 'rxjs';
import {AuthService} from '../../services/auth.service';
import {TokenService} from './token.service';
import {RequestOptionsArgs, ResponseContentType} from '@angular/http';

/**
 *  Generic HTTP Request Service.
 *  All requests will be generated through this service.
 */
@Injectable()
export class HttpService {

  /**
   *
   * @param {HttpClient} http
   */
  constructor(private http: HttpClient, private tokenService: TokenService ) {
  }

  /**
   * Handles GET requests for the application
   * @param {string} url
   * @returns {Observable<T>}
   */
  public get<T>(url: string): Observable<T> {
    return this.http.get<T>(url, {headers: this.getHeaders()});
  }

  /**
   * Handles POST request for the applciation
   * @param {string} url
   * @param data
   * @returns {Observable<T>}
   */
  public post<T>(url: string, data: any): Observable<T> {
    return this.http.post<T>(url, data, {headers: this.getHeaders()});
  }

  /**
   *  Handles Authorized POST request for the application
   */
  public postAuth<T>(url: string, data: any): Observable<T> {
    return this.http.post<T>(url, data, {headers: this.getAuthHeaders()});
  }


  public postImage<T>(url: string, data: any): Observable<T> {
    return this.http.post<T>(url, data);
  }

  /**
   *  Handle Authorized DELETE request for the application
   */
  public deleteAuth<T>(url: string): Observable<T> {
    return this.http.delete(url, {headers: this.getAuthHeaders()}) as Observable<T>;
  }

  /**
   *  Handle Authorized PUT requrets for the application
   */
  public putAuth<T>(url: string, data: any): Observable<T> {
    return this.http.put<T>(url, data, {headers: this.getAuthHeaders()});
  }

  /**
   * Handles Authorized GET requests for the application
   * @param {string} url
   * @returns {Observable<T>}
   */
  public getAuth<T>(url: string): Observable<T> {
    return this.http.get<T>(url, {headers: this.getAuthHeaders()});
  }

  /**
   *  Get Asset Requests
   */
  public getAsset(url: string): Observable<Blob> {
    return this.http.get(url, {headers: this.getAssetAuthHeaders(), responseType: 'blob'});
}

  public postAsset(url: string, data: any){
    let headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.tokenService.getToken(),
     // 'Content-Type':  'application/octet-stream',
      //'Accept':'application/octet-stream',
      });
    return this.http.post(url, data,{headers: headers, responseType: 'blob'});
  }
  /**
   * Returns header for HTTP Requests
   * @returns {Headers}
   */
  private getHeaders(): HttpHeaders {
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    return headers;
  }

  private getJWTHeaders(): HttpHeaders {
    return new HttpHeaders({'Authorization': 'Bearer ' + this.tokenService.getToken()});
  }

  private getAssetAuthHeaders(): HttpHeaders{
    const basicHeaders = new HttpHeaders({'Content-Type': 'application/octet-stream', 'Accept': 'application/octet-stream'});
    const headerSettings: {[name: string]: string | string[]; } = {};
    for (const key of basicHeaders.keys()) {
      headerSettings[key] = basicHeaders.getAll(key);
    }
    headerSettings['Authorization'] = 'Bearer ' + this.tokenService.getToken();
    const headers = new HttpHeaders(headerSettings);
    console.log(headers)
    return headers;
  }
  /**
   *  Add JWT token in header
   */
  public getAuthHeaders(): HttpHeaders {
    const headerSettings: {[name: string]: string | string[]; } = {};
    const basicHeaders = new HttpHeaders({});
    for (const key of basicHeaders.keys()) {
      headerSettings[key] = basicHeaders.getAll(key);
    }
    headerSettings['Authorization'] = 'Bearer ' + this.tokenService.getToken();
    const headers = new HttpHeaders(headerSettings);
    return headers;
  }
}

