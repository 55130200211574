import {NgModule} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {RouterModule} from '@angular/router';
import {SharedModule} from '../../shared/shared.module';
import {AddCleanerModule} from './add-owner-form/add-cleaner.module';

import {CleanerComponent} from './cleaner.component';
import {CleanerDetailsModule} from './owner-details/cleaner-details.module';

@NgModule ({
  imports: [RouterModule, SharedModule, AddCleanerModule, CleanerDetailsModule ,BrowserModule],
  declarations: [CleanerComponent],
  exports: [CleanerComponent]
})

export class CleanerModule {}
