import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MatSelect} from "@angular/material/select";
import {NumberInputComponent} from "../../number-input/number-input.component";
import {MatDialog} from "@angular/material";

@Component({
  selector: 'app-formula',
  templateUrl: './formula.component.html',
  styleUrls: ['./formula.component.scss']
})
export class FormulaComponent implements OnInit {

  @Input() title: string;
  @Output() result = new EventEmitter<string[]>();
  @Output() close = new EventEmitter();

  formula: string;
  formulaList: string[]=[];
  oprandValue = "";
  number: any='';
  flag: boolean=false;

  constructor(public dialog: MatDialog) {
  }

  ngOnInit() {
  }

  selectOprand($event) {

    var oprand = $event.value;
    if (oprand == "NUMBER") {

      const matSelect: MatSelect = $event.source;
      matSelect.writeValue('');
      const numberDialogRef = this.dialog.open(NumberInputComponent, {
        width: '30%',
        data: {
          numberInput: "test"
        }
      });
      numberDialogRef.afterClosed().subscribe(data => {

        if (!this.formulaList) {
          this.formulaList = [];
        }
        if (data.numberInput) {
          this.formulaList.push(data.numberInput);
          this.result.emit(this.formulaList);
        }
      });
      return;
    }


    if (!this.formulaList) {
      this.formulaList = [];
    }
    if (oprand) {
      this.formulaList.push(oprand);
    }
    this.result.emit(this.formulaList);
    const matSelect: MatSelect = $event.source;
    matSelect.writeValue('');

  }

  clearFormula() {
    this.formulaList = [];
    this.result.emit(this.formulaList);
    this.number='';
  }

  closeFormula(){
    this.close.emit("");
  }

  createFormula(value){ 
    this.flag=false;
    this.number='';
    var oprand = value;
    if (!this.formulaList) {
      this.formulaList = [];
    }
    if (oprand) {
      this.formulaList.push(oprand);
    }
    this.result.emit(this.formulaList);
  }

  backSpace(){
    this.number='';
    this.flag=false;
    this.formulaList.splice(-1);
    this.result.emit(this.formulaList);
  }

  createFormulaNumber(value){
       if(this.number!=undefined && this.number != null && this.number!=''){
        var concatNum= this.number+value;
        this.number=concatNum;
       }else{
        this.number=value;
       }
       if(this.number!=''){
         if(this.flag===true){
          this.formulaList.splice(-1);
         }
         this.formulaList.push(this.number);
         this.flag=true;
         this.result.emit(this.formulaList);
       }
  }
}
