import {Component} from '@angular/core';

@Component ({
  selector: 'app-dashbord-calendar',
  templateUrl: './dashboard-calender.component.html'
})

export class DashboardCalendarComponent {
  constructor () {}
}
