import { Injectable } from '@angular/core';
import { Observable, Subject, BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ImportIndexService {
    private subject = new BehaviorSubject<any>(null);

    sendMessage(message) {
        // console.log(message)
        this.subject.next({ apiIndexes: message });
    }

    clearMessages() {
        this.subject.next(null)
    }

    getMessage(): Observable<any> {
        return this.subject.asObservable();
    }
}