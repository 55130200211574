import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { Property } from 'src/app/models/property';
import { UserService } from 'src/app/services/user.service';
import { FormGroup, NgModel, FormControl, FormBuilder, Validators, FormArray } from '@angular/forms';
import { PropertyService } from 'src/app/services/property.service';
import { response } from 'express';
import { ToastrService } from 'ngx-toastr';
import { success } from 'src/app/constants/notification-messages.constant';
import { BookingConfirmationComponent } from '../booking-confirmation/booking-confirmation.component';

@Component({
  selector: 'app-copy-property-charges',
  templateUrl: './copy-property-charges.component.html',
  styleUrls: ['./copy-property-charges.component.scss']
})
export class CopyPropertyChargesComponent implements OnInit {
  arrayOfProperties: any[];
  detailHeader: string;
  propertyCharges: FormGroup;
  propertySelect = new FormControl();
  arrayOfForm : FormArray;
  isMinPrice = false;
  isLoading :boolean = false;
  disableCopyToSelectProperty = false ;
  selectedUnit: any[] = [];
  isCalculationUpdate:boolean = true;

  constructor(public dialog: MatDialog,public dialogRef: MatDialogRef<CopyPropertyChargesComponent>,
    @Inject(MAT_DIALOG_DATA) public data :{copyingFor: string, property:any, unit:any, roomId: any,unitId:any,expense:any,jobType:any}, public userService: UserService, private fb: FormBuilder, private propertyService : PropertyService, private toastrService: ToastrService ) {
      if(data.copyingFor == "cleaningFee") {
        this.detailHeader = "Cleaning Fees";
      } else if(data.copyingFor == "propertyManagementCommission") {
        this.detailHeader = "Rental Management Commission";
      } else if(data.copyingFor == "defaultTax") {
        this.detailHeader = "Default Tax";
      } else if(data.copyingFor == "paymentProcessingFee") {
        this.detailHeader = "Payment Processing Fee";
      } else if(data.copyingFor == "minPrice") {
        this.detailHeader = "Minimum Price";
        this.isMinPrice = true;
      } else if(data.copyingFor == "maxAdult") {
        this.detailHeader = "Maximum Adults";
        this.isMinPrice = true;
      }  else if(data.copyingFor == "maxChildren") {
        this.detailHeader = "Maximum Children";
        this.isMinPrice = true;
      }  else if(data.copyingFor == "maxPeople") {
        this.detailHeader = "Maximum People";
        this.isMinPrice = true;
      } else if(data.copyingFor === 'paymentProcessingFees') {
        this.detailHeader = 'Payment Processing Fees'
      } else if(data.copyingFor === 'platformProcessingFees'){
        this.detailHeader = 'Platform Commission'
      } else if(data.copyingFor === 'netProfit'){
        this.detailHeader = 'Net Profit'
      }else if(data.copyingFor === 'expense'){
        this.detailHeader = 'Expense'
      }

      this.propertyCharges = this.fb.group({
        propertySelect: new FormControl()
      })
     }

  ngOnInit() {
    if(!this.isMinPrice) {
      this.userService.getUser().subscribe( user => {
        this.userService.getAdminPropertiesById(user.payload.id).subscribe ( properties => {
          this.arrayOfProperties = new Array();
          properties.payload.forEach( property => {
            this.arrayOfProperties.push(property);
          });
          this.propertyCharges = this.fb.group({
            propertySelect: this.buildForm(this.arrayOfProperties)
          });
        });
      });
    } else if(this.isMinPrice) {
      this.userService.getUser().subscribe( user => {
        this.userService.getAdminPropertiesById(user.payload.id).subscribe ( properties => {
          this.arrayOfProperties = new Array();
          properties.payload.forEach( property => {
            property.roomTypes.forEach( room => {
              if(this.data.property.id == room.id){
              } else {
                room.name = room.name + ' - ' + room.propertyName;
                this.arrayOfProperties.push(room);
              }
            })
          });
          this.propertyCharges = this.fb.group({
            propertySelect: this.buildForm(this.arrayOfProperties)
          });
        });
      });
    }
  }

  buildForm(arrayOfProperties) {
    this.arrayOfForm = new FormArray([]);
    let formArray = new FormArray([]);
    arrayOfProperties.forEach(property => {
      if(this.data.copyingFor == 'cleaningFee') {
        if(this.data.property.defaultCleaningFee == property.defaultCleaningFee) {
            formArray.push( this.fb.group ({
              property: property,
              checked: false
          }));
        } else {
          formArray.push( this.fb.group ({
            property: property,
            checked: false
          }));
        }
      } else if(this.data.copyingFor == 'propertyManagementCommission') {
        if(this.data.property.propertyManagementCommission == property.propertyManagementCommission) {
            formArray.push( this.fb.group ({
              property: property,
              checked: false
          }));
        } else {
          formArray.push( this.fb.group ({
            property: property,
            checked: false
          }));
        }
      } else if(this.data.copyingFor == 'defaultTax') {
        if(this.data.property.defaultTax == property.defaultTax) {
            formArray.push( this.fb.group ({
              property: property,
              checked: false
          }));
        } else {
          formArray.push( this.fb.group ({
            property: property,
            checked: false
          }));
        }
      } else if(this.data.copyingFor == 'paymentProcessingFee') {
        if(this.data.property.paymentProcessingFee == property.paymentProcessingFee) {
            formArray.push( this.fb.group ({
              property: property,
              checked: false
          }));
        } else {
          formArray.push( this.fb.group ({
            property: property,
            checked: false
          }));
        }
      }
      else if(this.data.copyingFor === 'platformProcessingFees') {
        if (this.checkArrayObjectsEquality(this.data.property.platformProcessingFees, property.platformProcessingFees)) {
          formArray.push( this.fb.group ({
            property: property,
            checked: false
          }));
        } else {
          formArray.push( this.fb.group ({
            property: property,
            checked: false
          }));
        }
      } else if(this.data.copyingFor == 'netProfit'){
        if (this.checkArrayObjectsEquality(this.data.property.netProfitFormula, property.netProfitFormula)) {
          formArray.push( this.fb.group ({
            property: property,
            checked: false
          }));
        } else {
          formArray.push( this.fb.group ({
            property: property,
            checked: false
          }));
        }
      }
      else if(this.data.copyingFor === 'paymentProcessingFees') {
        if (this.checkArrayObjectsEquality(this.data.property.paymentProcessingFees, property.paymentProcessingFees)) {
          formArray.push( this.fb.group ({
            property: property,
            checked: false
          }));
        } else {
          formArray.push( this.fb.group ({
            property: property,
            checked: false
          }));
        }
      }else if(this.data.copyingFor == 'maxAdult') {
        if(this.data.property.maxAdult == property.maxAdult) {
            formArray.push( this.fb.group ({
              property: property,
              checked: false
          }));
        } else {
          formArray.push( this.fb.group ({
            property: property,
            checked: false
          }));
        }
      } else if(this.data.copyingFor == 'maxChildren') {
        if(this.data.property.maxChildren == property.maxChildren) {
            formArray.push( this.fb.group ({
              property: property,
              checked: false
          }));
        } else {
          formArray.push( this.fb.group ({
            property: property,
            checked: false
          }));
        }
      } else if(this.data.copyingFor == 'maxPeople') {
        if(this.data.property.maxPeople == property.maxPeople) {
            formArray.push( this.fb.group ({
              property: property,
              checked: false
          }));
        } else {
          formArray.push( this.fb.group ({
            property: property,
            checked: false
          }));
        }
      } else if(this.data.copyingFor == 'minPrice') {
        if(this.data.property.minPrice == property.minPrice) {
            formArray.push( this.fb.group ({
              property: property,
              checked: false
          }));
        } else {
          formArray.push( this.fb.group ({
            property: property,
            checked: false
          }));
        }
      } else if(this.data.copyingFor == 'expense') {
        formArray.push( this.fb.group ({
          property: property,
          checked: false
        }));
      }
      this.arrayOfForm = formArray;
    });
    return this.arrayOfForm;
  }


  select(roomTypes,isChecked): void {    
    for (let roomType of roomTypes) {      
      for ( let unit of roomType.unitItems) {         
      this.updateUnitItemsChecked(roomType.id, unit.unitId, isChecked);
      }
    }   
  }
 

  selectAll() {
    for (let property of this.arrayOfProperties) {  
      this.select(property.roomTypes,true)
    }

    var controls : any;
    controls = this.propertyCharges.controls.propertySelect;    
    controls.controls.forEach(element => {
      element.value.checked = true;
    });
    this.disableCopyToSelectProperty = false ;
  }
  deSelectAll() {
    this.selectedUnit=[]
    var controls : any;
    controls = this.propertyCharges.controls.propertySelect;
    controls.controls.forEach(element => {
      element.value.checked = false;
      console.log(element.value.checked);
    });
    console.log(this.propertyCharges);
    this.disableCopyToSelectProperty = true ;
  }
  onCloseUpdate(){
    // var propertyIds = new Array();
    // this.propertyCharges.value.propertySelect.forEach(property => {
    //   if(property.checked == true) {
    //     propertyIds.push(property.property.id)
    //   }
    // });
     
    this.isLoading = true;
    console.log("unit id",this.data.unitId);
    this.propertyService.setPropertyPricings(this.data.property.id, this.data.copyingFor, this.selectedUnit, this.data.unit, this.data.roomId,this.data.unitId, this.isCalculationUpdate).subscribe (response =>{
      console.log(response)
      if(response.code == 200) {
        this.toastrService.success( "Successfully " + this.detailHeader + " Applied to selected Properties", success, {
          timeOut: 3000,
          onActivateTick: true
        });
        this.isLoading = false;
        this.hide();
      } else {
        //show errors here
        this.isLoading = false;
      }
    });
  }
  hide() {
    this.dialogRef.close(CopyPropertyChargesComponent);
  }

  openConfirmationDialog(): void {
     
    if(this.data.copyingFor == 'expense') {
     this.cloneExpense();
    
    }else{    
    const dialogRef = this.dialog.open(BookingConfirmationComponent);  
    dialogRef.afterClosed().subscribe((result: any) => {
      if(result == "close"){
        return;
      }
      this.isCalculationUpdate = result;
      this.onCloseUpdate()       
    });
  }
  }

  checkArrayObjectsEquality(a: any[], b: any[]) {
    return JSON.stringify(a) === JSON.stringify(b);
  }

  cloneExpense(){     
    console.log("unit id",this.data.unitId);
    let jobType = this.data.jobType;
    this.propertyService.cloneExpense(this.data.expense.id, jobType, this.selectedUnit).subscribe (response =>{
      console.log(response)
      if(response.code == 200 || response.code == 201) {
        this.toastrService.success( "Successfully " + this.detailHeader + " Applied to selected Properties", success, {
          timeOut: 3000,
          onActivateTick: true
        });
        this.hide();
      }  
    });
  }

  updateUnitItemsChecked(roomId, unitId: any, isChecked: boolean) {
    const roomIndex = this.selectedUnit.findIndex((room) => room.roomId === roomId);

    if (isChecked) {
        if (roomIndex === -1) {
            // Create a new room entry
            this.selectedUnit.push({
                roomId: roomId,
                unitIds: [unitId],
            });
        } else {
            // Add the unitId to the existing room
            this.selectedUnit[roomIndex].unitIds.push(unitId);
        }
    } else {
        // If unchecked, remove the unitId from the structure
        if (roomIndex !== -1) {
            const unitIndex = this.selectedUnit[roomIndex].unitIds.indexOf(unitId);

            if (unitIndex !== -1) {
                // Remove the unitId
                this.selectedUnit[roomIndex].unitIds.splice(unitIndex, 1);

                // Check if the room's unitIds array is empty
                if (this.selectedUnit[roomIndex].unitIds.length === 0) {
                    // Remove the room if it's empty
                    this.selectedUnit.splice(roomIndex, 1);
                }
            }
        }
    }
    this.disableCopyToSelectProperty =(this.selectedUnit.length>0)?false:true
    
}
search(keyword) {  
  var filteredList = this.arrayOfProperties.filter(property => {
    // Check if the property name matches the keyword
    if (property.name.toLowerCase().includes(keyword.toLowerCase())) {
      return true;
    }
  
    // Check if any room names within the property match the keyword
    if (property.roomTypes) {
      for (let roomType of property.roomTypes) {
        if (roomType.name.toLowerCase().includes(keyword.toLowerCase())) {
          return true;
        }else{
          if (roomType.unitItems) { 
          for (let unit of roomType.unitItems) {
            if (unit.unitName.toLowerCase().includes(keyword.toLowerCase())) {
              return true;
            }
          }
        }
        }
      }
    }
  
    // If no match was found for this property, return false
    return false;
  });


 
  this.propertyCharges = this.fb.group({
    propertySelect: new FormControl()
  })

  this.propertyCharges = this.fb.group({
    propertySelect: this.buildForm(filteredList)
  });
 
}

}
