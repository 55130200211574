import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { StatementService } from 'src/app/services/statement.service';
import { UserService } from 'src/app/services/user.service';
import { HttpErrorResponse } from '@angular/common/http';
import {GenericDto} from '../../models/generic-dto';
import { TokenService } from 'src/app/core/services/token.service';
import { ToastrService } from 'ngx-toastr';
import { info ,success, successUserDeleted, errorMessage } from '../../constants/notification-messages.constant';
import { AuthGuard } from 'src/app/core/guards/auth.guard';
import { MatDialog } from '@angular/material';
import { CreateStatementComponent } from '../owner/create-statement/create-statement.component';


@Component({
  selector: 'app-statement',
  templateUrl: './statement.component.html',
  styleUrls: ['./statement.component.scss']
})
export class StatementComponent implements OnInit, AfterViewInit {
  owners = [];
  ownerIds = [];
  statementList = []
  statementForm: FormGroup;
  isOwner: boolean = false;
  owner:any;

  constructor(private dialog: MatDialog, public tokenService: TokenService,public authGuard: AuthGuard, private toastrService: ToastrService,  private statementService: StatementService,private fb: FormBuilder, protected userService: UserService) {
    this.statementForm = this.fb.group({
      owner: new FormControl(),
    });
 
    if(this.tokenService.getCurrentUser()!=='ADMINISTRATOR'){
      this.isOwner = true;
    }else{
      this.isOwner =  false;
    }
    
  }

  ngOnInit() {}

  ngAfterViewInit() {
   
    if(!this.isOwner){
      this.getOwnersByAdminId();
    } else{
      let owner;            
      if (this.authGuard.isPreviewLogin()) {     
          owner = this.tokenService.getPreviewOwner();
          this.owner = {firstName:owner.firstName,lastName:owner.lastName,id:owner.id}          
      } else{        
        this.owner = {firstName:localStorage.getItem('userName'),lastName: '',id:localStorage.getItem('userId')}        
      }
      this.owners.push(this.owner);  
      this.ownerSelected();        

    } 
       
  }

  ownerSelected(){ 
    if(this.owner == "all"){
      this.getAllStatements();
      return;

    }
    
    
    this.statementService.getStatementsByOwnerId(this.owner.id).subscribe((response: GenericDto<any>) => {
      if (response.code === 200) {       
      this.statementList = response.payload;       
      } else {        
        // show error and redirect to login page
      }

    }, (error: HttpErrorResponse) => {
      // show error and redirect to login page
    });

  }

  getAllStatements(){       
    this.statementService.getAllStatements(this.ownerIds).subscribe((response: GenericDto<any>) => {
      if (response.code === 201) {       
      this.statementList = response.payload;       
      } else {        
        // show error and redirect to login page
      }

    }, (error: HttpErrorResponse) => {
      // show error and redirect to login page
    });

  }

  getOwnersByAdminId(){
    this.userService.getOwnersByAdminId(localStorage.getItem('userId')).subscribe((response) => { 
       
      let owners = response.payload;         
      owners.forEach((owner) => {
        if(owner.ownerDetails && owner.ownerDetails.automaticStatementEnabled){
          this.ownerIds.push(owner.id);
          this.owners.push(owner);
        }
                 
      });
      if(this.owners.length>0){
        this.owner = 'all';  
        this.ownerSelected(); 
      }
    }); 
  }

sendNotification(statementId){
  this.statementService.sendNotification(statementId).subscribe((response: GenericDto<any>) => {
    if (response.code === 200) {
      this.ownerSelected();       
      this.toastrService.success(response.desc, success, {
        timeOut: 3000,
        onActivateTick: true
      });   
    } else {        
      this.toastrService.error(response.desc, errorMessage, {
        timeOut: 3000,
        onActivateTick: true
      }); 
    }

  }, (error: HttpErrorResponse) => {
    // show error and redirect to login page
  });
}

createStatement(){
  let selectedOwner = this.owner;
  if(this.owner == "all"){
    selectedOwner = this.owners[0];
  }   
  const dialogRef = this.dialog.open(CreateStatementComponent, {
    width: '45%',
    height: 'auto',    
    data: {ownerDetail : selectedOwner,allOwners:this.owners}
  });

  dialogRef.afterClosed().subscribe(() => {    
    this. ownerSelected(); 
  });
   
  
   
}


}
