import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {SharedModule} from '../../shared/shared.module';
import {ProfileDetailsModule} from './profile-details/profile-details.module';
import {UpdateProfileModule} from './update-profile/update-profile.module';
import {ProfileDetailsComponent} from './profile-details/profile-details.component';
import {ProfileComponent} from './profile.component';
import {ChangePasswordModule} from './change-password/changePassword.module';

@NgModule ({
  imports: [RouterModule, SharedModule, ProfileDetailsModule, UpdateProfileModule , ChangePasswordModule],
  declarations: [ProfileComponent],
  exports: [ProfileComponent]
})

export class ProfileModule {}
