import {Component, OnInit} from '@angular/core';
import {PropertyService} from '../../../services/property.service';
import {Property} from '../../../models/property';
import {User} from '../../../models/user';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {EventService} from '../../../shared/services/event.service';
import {MatDialog, MatDialogRef, MatSelectChange, MAT_DIALOG_DATA} from '@angular/material';
import {Router} from '@angular/router';
import {UserService} from '../../../services/user.service';
import {ToastrService} from 'ngx-toastr';
import {DataService} from '../../../shared/services/data.service';
import {GenericDto} from '../../../models/generic-dto';
import {HttpErrorResponse} from '@angular/common/http';
import {jobAssignSuccessfully, success} from '../../../constants/notification-messages.constant';
import {MatDatepickerInputEvent} from '@angular/material/typings/esm5/datepicker';
import {RoomType} from '../../../models/RoomType';
import {UnitNames} from '../../../models/unitNames';
import {Input} from '@angular/core';
import {Inject} from '@angular/core';
import {JobAssign} from '../../../models/job-assign';
import * as moment from 'moment';


@Component({
  selector: 'app-job-manage',
  templateUrl: './job-manage.component.html',
  styleUrls: ['./job-manage.component.scss'],
  providers: [PropertyService]
})

export class JobManageComponent implements OnInit {
  @Input() properties: Array<Property>;
  jobTime: any;
  rooms: Array<string>;
  @Input() event: any;
  statusJob: any;
  jobAssign: JobAssign;
  jobId: any;
  cleaners: Array<User>;
  selectedUnit: UnitNames;
  selectedProperty: Property;
  selectedCleaner: User;
  user: User;
  isLoading = false;
  jobFormManage: FormGroup;
  propertyId = 'all';
  editMode = false;
  newJob = false;
  checkDisablity = false ;
  newUpdateDisablity = false ;
  isUnitName: boolean;
  checkUnitValue: any;
  minDate = 'today';

  constructor( private dialog1: MatDialogRef<JobManageComponent> , @Inject (MAT_DIALOG_DATA) public data: {event: any, status: any, row: any, minDate: any} , private fb: FormBuilder, protected eventService: EventService, public dialog: MatDialog, private router: Router,
              private propertyService: PropertyService, private userService: UserService , private toastrService: ToastrService , private dataService: DataService ) {
    this.event = data.event;
    this.statusJob = data.status;
    this.jobId = data.row;
    if ( this.event.endDate ) {
     
      this.minDate = this.event.endDate;
    }
  }

  ngOnInit() {
    
    if (this.statusJob === 'update') {
      this.checkDisablity = true;
      this.resetProperty();
      this.createJobManage();
      this.getPropertyByJobId();
      this.editMode = true;
      this.newJob = false;
    } else {
      this.newUpdateDisablity = true;
      this.resetProperty();
      this.createJobManage();
      this.getPropertyByJobIdForNewCleaner();
      this.editMode = true;
      this.newJob = false;


 
    }
  }

  setMinDate(value: any): Date {
    return new Date(value);
  }

  updateJob(jobAssign: JobAssign) {
    //console.log(this.event)
    this.jobFormManage = this.fb.group({
      property: [this.getSelectedProperty().name , [Validators.required]],
      propertyId: '',
      id: jobAssign.id,
      userId: jobAssign.userId,
      roomId: [jobAssign.roomId , [Validators.required]],
      jobRequest: jobAssign.jobRequest,
      unitName: [jobAssign.unitName+ ' - ' + this.event.guestRoomName , [Validators.required]],
      bookingId: jobAssign.bookingId,
      checkOutDate: [new Date(jobAssign.endingDate), [Validators.required]], /*.toISOString()*/
      cleanerId: [jobAssign.cleanerId , [Validators.required]],
      instruction: [jobAssign.instruction ],
      jobTime: [jobAssign.jobTime, [Validators.required]],
      accessInstructions: [jobAssign.accessInstructions],
      garbageInstructions: [jobAssign.garbageInstructions],
      parkInstructions: [jobAssign.parkInstructions],
      otherInformations: [jobAssign.otherInformations  ],
      cleaningChecklist: [jobAssign.cleaningChecklist]
    });
  }


  getSelectedProperty(): Property {
    return this.properties.filter((value: Property) => this.selectedProperty.id === value.id)[0];

  }

  getPropertyByJobIdForNewCleaner(): void {
        this.propertyService.get(this.event.guestPropertyId).subscribe((data2: GenericDto<Property>) => {
          this.selectedProperty = data2.payload as Property;
        });
        this.propertyService.getAll().subscribe((data: GenericDto<Property[]>) => {
          if (data.code === 200) {
            this.properties = data.payload || [];
            this.getSelectedProperty();
            this.createJob();
          } else {
            // show errors
          }
        }, (error: HttpErrorResponse) => {
          // handle errors here
        });

  }

  getPropertyByJobId(): void {
    this.propertyService.getJobById(this.jobId).subscribe((response: GenericDto<JobAssign>) => {
      if (response.code === 200) {
        this.jobAssign = response.payload as JobAssign;
        this.propertyService.get(this.jobAssign.propertyId).subscribe((data2: GenericDto<Property>) => {
          this.selectedProperty = data2.payload as Property;
        });
          this.propertyService.getAll().subscribe((data: GenericDto<Property[]>) => {
          if (data.code === 200) {
            this.properties = data.payload || [];
              this.getSelectedProperty();
              this.getSelectedCleaner();
              this.updateJob(this.jobAssign);

          } else {
            // show errors
          }
        }, (error: HttpErrorResponse) => {
          // handle errors here
        });

      }
    });
  }

  getSelectedCleaner(): void {
    this.userService.getUserByRole(['CLEANER'])
      .subscribe((data: GenericDto<User[]>) => {
        // console.log('get role', this.tokenService.getCurrentUser());
        if (data.code === 200) {
          this.cleaners = data.payload || [];
          this.getSelectedCleanerId();
        } else {
          // show errors
        }
      }, (error: HttpErrorResponse) => {
        // handle errors here
      });

  }

  getSelectedCleanerId(): User {
    return this.cleaners.filter((value: User) => this.jobAssign.cleanerId === value.id)[0];

  }

  createJobManage() {
    this.jobFormManage = this.fb.group({
      property: [null, [Validators.required]],
      roomId: [null, [Validators.required]],
      propertyId: ['', [Validators.required]],
      id: ['', [Validators.required]],
      userId: ['', [Validators.required]],
      unitName: ['', [Validators.required]],
      bookingId: ['', [Validators.required]],
      checkInDate: [null, [Validators.required]],
      checkOutDate: [null, [Validators.required]],
      cleanerId: [null, [Validators.required]],
      instruction: [null, ],
      jobTime: [null, [Validators.required]],
      accessInstructions: [''],
      garbageInstructions: [''],
      parkInstructions: [''],
      otherInformations: [''],
      cleaningChecklist: [''],
    });
  }

  createJob() {
    //console.log(this.event)
    //var dateTemp = new Date(moment.unix(this.event.lastNight).add('days', 2 ).format('YYYY-MM-DD'));
    var dateTemp = new Date(moment.unix(this.event.lastNight).add('days', 1 ).format('YYYY-MM-DD'));
    var dt = new Date(this.minDate);
    if(dateTemp < dt){
      dateTemp = dt;
    }
    const property = this.getSelectedProperty();
    //console.log(this.event);
    this.jobFormManage = this.fb.group({
      property: [property.name, [Validators.required]],
      roomId: [this.event.roomId, [Validators.required]],
      propertyId: [this.event.guestPropertyId, [Validators.required]],
      unitName: [this.event.guestRoomName + ' - ' + this.event.guestUnitName, [Validators.required]],
      bookingId: [this.event.id, [Validators.required]],
      checkInDate: [ new Date(moment.unix(this.event.firstNight).format('YYYY-MM-DD')).toISOString() , [Validators.required]],
      checkOutDate: [ dateTemp.toISOString(), [Validators.required]],
      cleanerId: [null, [Validators.required]],
      instruction: [null],
      jobTime: ['12:00', [Validators.required]],
      accessInstructions: [property.accessInstructions ? property.accessInstructions : ''],
      garbageInstructions: [property.garbageInstructions ? property.garbageInstructions : ''],
      parkInstructions: [property.parkInstructions ? property.parkInstructions : ''],
      otherInformations: [property.otherInformations ? property.otherInformations : '' ],
      cleaningChecklist: [property.cleaningChecklist ? property.cleaningChecklist : '']
    });
  }

  resetProperty() {
    this.editMode = false;
    this.newJob = true;
    this.selectedProperty = {} as Property;
  }

    fetchRooms(event: boolean): void {
      if (event) {
      this.selectedProperty.roomTypes.forEach((property: RoomType) => {
           property.unitArray = new Array();
             for ( let i = 0; i < property.units.length; i++) {
              this.selectedUnit = new Object() as UnitNames;
               this.selectedUnit.roomId = property.units[i] + '-' + property.id;
               this.selectedUnit.unitName = property.units[i];
               property.unitArray.push(this.selectedUnit);
        }
      });
    }
  }

    getCleaners(event): void {
    if (event) {
      this.userService.getUserByRole(['CLEANER'])
        .subscribe((data: GenericDto<User[]>) => {
          // console.log('get role', this.tokenService.getCurrentUser());
          if (data.code === 200) {
            this.cleaners = data.payload || [];
          } else {
            // show errors
          }
        }, (error: HttpErrorResponse) => {
          // handle errors here
        });
    }
  }




  hide() {
    this.dialog1.close(JobManageComponent);
  }




  createNewJob(): void {

      this.isLoading = true;
      {
        if (this.editMode === true) {
          this.jobFormManage.get('propertyId').patchValue(this.event.guestPropertyId);
          this.jobFormManage.get('roomId').patchValue(this.event.roomObjectId);
          this.jobFormManage.get('unitName').patchValue(this.event.guestUnitName);
        } else {
          const str = this.jobFormManage.get('roomId').value;
          const strSplit = str.split('-');
          this.jobFormManage.get('roomId').patchValue(strSplit[1].trim());
          this.jobFormManage.get('unitName').patchValue(strSplit[0].trim());
        }
        this.jobTime = (this.jobFormManage.get('jobTime').value);
        this.jobFormManage.get('bookingId').patchValue(this.event.id);
        const checkOutDate: any = new Date(this.jobFormManage.get('checkOutDate').value);
        const outDate = checkOutDate * 1;
        this.jobFormManage.get('checkOutDate').patchValue(outDate);
        this.jobFormManage.get('jobTime').patchValue(this.jobTime);
        if (this.statusJob === 'update') { 
          console.log(this.jobFormManage.getRawValue());
          this.propertyService.updateJobDetail(this.jobFormManage.getRawValue()).subscribe((response: GenericDto<any>) => {
            if (response.code === 200) {
              this.jobFormManage.reset();
              this.toastrService.success('update job successfully ', success, {
                timeOut: 3000,
                onActivateTick: true
              });
              this.hide();
            } else if (response.code === 404) {
              this.toastrService.error('Invalid Data', 'Updation Error', {
                timeOut: 3000,
                onActivateTick: true
              });
              this.isLoading = false;
              this.hide();
            }
          }, (error: HttpErrorResponse) => {
            this.isLoading = false;
            // show errors here
          });
        } else {
          this.propertyService.createJob(this.jobFormManage.getRawValue()).subscribe((response: GenericDto<any>) => {
            if (response.code === 200) {

              this.jobFormManage.reset();
              //   this.tokenService.saveToken(response.payload)
              this.toastrService.success(jobAssignSuccessfully, success, {
                timeOut: 3000,
                onActivateTick: true
              });
              this.hide();
            } else if (response.code === 404) {
              this.toastrService.error('Invalid Data', 'Job Creation Error', {
                timeOut: 3000,
                onActivateTick: true
              });
              this.isLoading = false;
              this.hide();
            }
          }, (error: HttpErrorResponse) => {
            this.isLoading = false;
            // show errors here
          });
        }
      }

  }


    fromDateChanged(event: MatDatepickerInputEvent<Date>): void {
      const changedDate = new Date(event.value);
    this.jobFormManage.get('checkInDate').patchValue(new Date(this.jobFormManage.get('checkInDate').value));
  }


    fetchAllPropertyBookings(event: MatSelectChange): void {
    this.jobFormManage.get('roomId').patchValue('');
    this.isUnitName = false;
    this.checkUnitValue = 0;
    if (event.value === 'all') {
      this.propertyId = 'all';
      this.createJob();
    }
    this.newJob = true;
      this.editMode = false;
      this.selectedProperty.roomTypes.forEach((property: RoomType) => {
      property.unitArray = [];
      if ( property.units.length === 0) {
        this.checkUnitValue = 1;
        this.selectedUnit = {} as UnitNames;
        this.selectedUnit.roomId = property.id;
        this.selectedUnit.unitName = property.roomId;
        property.unitArray.push(this.selectedUnit);
      } else {

        this.isUnitName = true;
        for ( let i = 0; i < property.units.length; i++) {
          this.selectedUnit = {} as UnitNames;
          this.selectedUnit.roomId = property.units[i] + '-' + property.id;
          this.selectedUnit.unitName = property.units[i];
          property.unitArray.push(this.selectedUnit);
        }
      }

    });
  }


}
