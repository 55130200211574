import {Component, HostListener} from '@angular/core';
import {Router} from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';

@Component ({
  selector: 'app-dashboard-cleaner',
  templateUrl: './cleaner.component.html',
  styleUrls: ['./cleaner.component.scss']
})

export class CleanerComponent {
  private screenWidth$ = new BehaviorSubject<number>
  (window.innerWidth);
showToggle: boolean;
  constructor (public router: Router) {}

  ngOnInit(): void {
    this.getScreenWidth().subscribe(width => {
      if (width < 960) {
        this.showToggle = false;
     }
     else {
      this.showToggle = true;
     }
   });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.screenWidth$.next(event.target.innerWidth);
  }

 getScreenWidth(): Observable<number> {
    return this.screenWidth$.asObservable();
  }
}
