import { Component, Inject, OnInit, ViewChild, AfterViewInit, Input } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource, MatDialog, MatGridTileHeaderCssMatStyler } from '@angular/material';
import { EventService } from '../../shared/services/event.service';
import * as moment from 'moment';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

import { HttpErrorResponse } from '@angular/common/http';
import { ExcelService } from '../../core/services/excel.service';
// import * as jsPDF from 'jspdf';
import { UserService } from '../../services/user.service';
import { TokenService } from '../../core/services/token.service';
import { Owner } from '../../models/owner';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { CurrencyPipe } from '@angular/common';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { PayoutReport } from '../payout-report/payout-report.model';
import { RevenueExpenseComponent } from '../revenue-report/revenue-expense/revenue-expense.component';
import { PropertyService } from 'src/app/services/property.service';
import { OwnerService } from 'src/app/services/owner.service';
import { AuthGuard } from "../../core/guards/auth.guard";
import { User } from '../../models/user';
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { TourService } from 'src/app/services/tour.service';
import { filter } from 'rxjs/internal/operators';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { StatementService } from 'src/app/services/statement.service';
import { AssetsService } from 'src/app/services/assets.service';
import { success } from '../../constants/notification-messages.constant';
import { SharedService } from 'src/app/services/shared.service';
import { diffByUnit } from 'fullcalendar/src/util';
import { L } from '@angular/core/src/render3';
import { concat } from 'rxjs';
import { environment } from 'src/environments/environment';



declare let jsPDF;
declare var require: any
require('jspdf-autotable');
(<any>pdfMake).vfs = pdfFonts.pdfMake.vfs;

export interface Month {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-statement-details',
  templateUrl: './statement-details.component.html',
  styleUrls: ['./statement-details.component.scss'],
  providers: [
    { provide: 'Window', useValue: window }
  ],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],

})

export class StatementDetailsComponent implements OnInit, AfterViewInit {
  //displayColumns = ['Booking Id', 'Date', 'Platform', 'Booking Amount', 'Platform Commission', 'Cleaning Fee', 'Tax', 'Payment Processing Fee', 'Property Management Commission', 'Net Profit'];
  displayColumns = [];
  displayColumns1: any;
  dataSource: any;
  currency;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  disable: boolean = false;
  x: number;
  y: number;
  owner: Owner;
  user: User | any;
  totalBookingAmount: number;
  totalPlatformCommission: number;
  totalCleaningFee: number;
  totalTax: number;
  totalPaymentProcessingFee: number;
  totalPropertyMngmtComm: number;
  totalExpenses: any;
  totalNetProfit: number;
  totalsOfTotalBookingAmount: number;
  totalsOfTotalBaseBookingAmount: number;
  totalsOfTotalPlatformCommission: number;
  totalsOfTotalCleaningFee: number;
  totalsOfTotalTax: number;
  totalsOfTotalPaymentProcessingFee: number;
  totalsOfTotalPropertyMngmtComm: number;
  totalsOfTotalExpenses: any;
  totalsOfTotalNetProfit: number;
  bookingDataList = new Array();
  isOwner: boolean;
  totalExpensesOfMaintenance: any;
  columnPermissions: any;
  ownerPermissions: any;
  revenueForm: FormGroup;
  ownerProperties;
  owners = [];
  properties = [];
  reportTypes = [] = [
    "ProRata",
    "CheckIn",
    "CheckOut",
  ];
  startDate: any;
  endDate: any;
  ownerId: string;
  reportType: string;
  // roomId: string;
  propertyNames: any;
  propertysName: string;
  bookings: any;
  rentalName;
  report: any;
  statement: any;
  tempRevenueReport: PayoutReport;
  rentals;
  rentalsWithId;
  // rental: any;
  initialOwnersWithProperties;
  searchReport;
  totalsColumnsToDisplay = [];
  owners1 = [];
  // rentals1 = [];
  rentalsWithId1;
  initialRentalLength;
  rentalFilterValue = "";
  expensesData = [];
  maintenanceJobList = [];
  invoiceList = [];
  invoiceLeval = ""
  pdfInvoiceFile = []
  rawExpenseData = [];
  Home_FLAG_REVENUE: any = true;
  ownerInfo: any;
  additionalDetails: any = { tax: "", address: "" };
  additionalDetail: any;
  ownerFullName: any;
  invoiceAddresses = [];
  invoices = [];
  showMedia: boolean = false;
  showDownloadInvoice: boolean = false;
  enableDownloadInvoiceFlag: any = false;
  propertyResponse: any[];
  url: any;
  end_Date: any;
  start_Date: any;
  lastCell = "lastCell"
  statementId: any;
  ownerDetail: any;
  ownerAllDetails: any;



  constructor(private sharedService: SharedService, private sanitizer: DomSanitizer, private assetService: AssetsService, private route: ActivatedRoute, private statementService: StatementService, private router: Router, private fb: FormBuilder, protected eventService: EventService, protected excelService: ExcelService, protected ownerService: OwnerService,
    private toastrService: ToastrService, private cp: CurrencyPipe, private dialog: MatDialog, private propertyService: PropertyService,
    protected userService: UserService, protected tokenService: TokenService, private authGard: AuthGuard, private tourService: TourService, @Inject('Window') private window: Window) {
    router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        // console.log(event,'prev:this.previousUrl = event.url;', event.url);
        this.url = event.url;
        // console.log("this.url.contains(?)",this.url.includes("?"))
        if (this.url.includes("?")) {
          let temp = this.url.split("?")[1].split("&end=");
          // console.log("date temp: ", temp);
          this.end_Date = new Date(temp[1].replace(/-/g, '\/'));
          this.start_Date = new Date(temp[0].replace('start=', '').replace(/-/g, '\/'))

        } else {
          var date = new Date();
          this.start_Date = new Date(date.getFullYear(), date.getMonth(), 1);
          this.end_Date = new Date(date.getFullYear(), date.getMonth() + 1, 0)
        }
      });



  }

  ngOnInit() {

    this.route.params.subscribe((data: { id: string }) => {
      this.statementId = data.id;

    })

    this.getInitials();

    this.revenueForm = this.fb.group({
      fromDate: [this.start_Date, [Validators.required]],
      toDate: [this.end_Date, [Validators.required]],
      owner: new FormControl(),
      property: new FormControl(),
      reportType: [this.reportTypes[0], [Validators.required]]
    });
    // console.log("calling convert with ", {start: this.start_Date, end: this.end_Date});
    this.startDate = (this.convert(this.start_Date));
    this.endDate = (this.convert(this.end_Date));
    // this.roomId = null;
    this.ownerId = null;
    this.reportType = this.reportTypes[0];


    this.getReport();

    let steps = [
      {
        element: "#fromDateid",
        title: "From Date Picker",
        content: "With this you can Select From date.",
        placement: 'right',
        backdrop: true,
      },
      {
        element: "#toDateid",
        title: "To Date Picker",
        content: "With this you can Select To date.",
        placement: 'right',
        backdrop: true,
      }, {
        element: "#ownerName",
        title: "Owner List",
        content: "With this you select Owner from the DropDown List.",
        placement: 'right',
        backdrop: true,
      },
      {
        element: "#rentallist",
        title: "Rental List",
        content: "With this you select Properties from the DropDown List.",
        placement: 'right',
        backdrop: true,

      },
      {
        element: "#reportType",
        title: "Report Type",
        content: "With this you can choose the type of Report data",
        placement: 'left',
        backdrop: true,

      },
      {
        element: "#runReport",
        title: "Run Report",
        content: "With this button you can Run report",
        placement: 'right',
        backdrop: true,
        // onShow:function (tour) {
        //   document.getElementById('calender1').click()
        // },
        // onNext:function (tour) {
        //   document.getElementById('ddd').scrollTop += 950
        //  }
      },
      {
        element: "#exportAsPDF",
        title: "Export As PDF",
        content: "With this you can report file to Pdf",
        backdrop: true,
        placement: 'right',
        // onShow:function (tour) {
        // document.getElementById('calender1').click()
        // }
      },
      {
        element: "#exportAsExcel",
        title: "Export As Excel",
        content: "With this you can report file to Excel",
        backdrop: true,
        placement: 'right',
      },
      {
        element: "#totalBookingAmount",
        title: "Total Booking Amount",
        content: "With this you will be able to see the total bookings ",
        backdrop: true,
        placement: 'right',
        //     onShow:function (tour) {
        //     document.getElementById('setting').click()
        // }
      },
      {
        element: "#totalPlatformCommission",
        title: "Total Platform Commission",
        content: "With this you will be able to see the Total Platform Commission. ",
        backdrop: true,
        placement: 'right',

      },
      {
        element: "#totalCleaningFee",
        title: "Total Cleaning Fee",
        content: "With this you will be able to see the Total Cleaning Fee.",
        backdrop: true,
        placement: 'right',

      },
      {
        element: "#totalTax",
        title: "Total Tax",
        content: "With this you will be able to see the Total Tax.",
        backdrop: true,
        placement: 'left',
      },
      {
        element: "#totalRentalProcessingFee",
        title: "Total Rental Processing Fee",
        content: "With this you will be able to see the Total Rental Processing Fee",
        backdrop: true,
        placement: 'right',

      },

      {
        element: "#totalManagementCommission",
        title: "Total Management Commission",
        content: "With this you will be able to see the Total Management Commission. ",
        placement: 'right',
        debug: true,
        backdrop: true,
      },
      {
        element: "#totalExpenses",
        title: "Total Expenses",
        content: "With this you will be able to see the Total Expenses.",
        placement: 'right',
        backdrop: true,
      }, {
        element: "#totalNetProfit",
        title: "Total NetProfit",
        content: "With this you will be able to see the Total NetProfit.",
        placement: 'left',
        backdrop: true,
        onNext: function (tour) {
          document.getElementById('revenue-tour').scrollTop += 600
        },
      }, {
        element: "#tableData",
        title: "Table Data",
        content: "With this you can see an entire over-View of your Report in a Table Form",
        placement: 'top',
        backdrop: true,

        onPrev: function (tour) {
          document.getElementById('revenue-tour').scrollTop -= 600
        },
      },

    ]

    this.tourService.createTour(steps);

    this.userService.getUser().subscribe((res: any) => {
      if (res.code === 200 && res.payload.tourFlags) {
        this.Home_FLAG_REVENUE = res.payload.tourFlags.REVENUE_PAGE;
        if (this.Home_FLAG_REVENUE == false) {
          this.tourService.startTour();
          setTimeout(() => {

            let interval = setInterval(() => {
              if (localStorage.getItem('tour_end') === 'yes') {
                localStorage.setItem('tour_end', 'No')
                clearInterval(interval);
                this.ownerService.changeTourFlag('REVENUE_PAGE').subscribe((res: any) => {
                  this.Home_FLAG_REVENUE = res.payload.tourFlags.REVENUE_PAGE;
                })
              }

            }, 50);
          }, 500)
        }
      }
    })
  } /*End Of OnInit*/

  ngAfterViewInit() {
    this.initialOwnersWithProperties = new Array();
    this.userService.getUser().subscribe(user => {
      if (user.payload.role === 'ADMINISTRATOR') {
        this.userService.getOwnersByAdminId(user.payload.id).subscribe(response => {
          response.payload.forEach(owner => {
            this.owners.push(owner);
            this.owners1.push(owner);

          });
        });
      }
    });
  }

  getReport() {

    this.statementService.getStatementsDetails(this.statementId).subscribe(response => {
      this.report = response.payload.report;
      this.statement = response.payload.statement;
      this.invoiceAddresses = this.statement.invoiceAddresses;
      this.ownerDetail = response.payload.ownerDetail;

      this.ownerFullName = this.sharedService.capitalizeFirstLetterEachWord(this.ownerDetail.fullName);

      this.endDate = response.payload.toDate;
      this.startDate = response.payload.fromDate;
      this.maintenanceJobList = response.payload.maintenanceJobList;
      this.ownerAllDetails = response.payload.ownerAllDetails;

      this.getUniqueItems();
      this.convertToDataUrls();

      console.log("OwnerAll details: ", this.ownerAllDetails);


      this.initialRentalLength = (this.report.length) / 2;
      for (let index = 0; index < this.report.length; index++) {
        if (!(index % 2 == 0)) {
          if (this.report[index]) {
            for (let index1 = 0; index1 < this.report[index].length; index1++) {
              const element = this.report[index][index1];
              element.firstNight = moment.unix(element.firstNight).format('YYYY-MM-DD');
              element.formulaList = this.getFormulaObject(element.formulaList);

              element.lastNight = moment.unix(element.lastNight).add(1, 'day').format('YYYY-MM-DD');
              if (moment(element.lastNight, 'YYYY-MM-DD').isAfter(moment(this.endDate, 'YYYY-MM-DD'))) {
                element.lastNight = this.endDate;

              }
            }
          }
        }
      }

      this.generateReport(this.report);

    })
  }



  getUniqueItems() {
    const uniqueids = new Set();
    this.invoiceList = []

    let uniqueMaintenanceJobList = [];
    for (const job of this.maintenanceJobList) {
      if (!uniqueids.has(job.id)) {
        uniqueMaintenanceJobList.push(job);
        uniqueids.add(job.id);
        if (job.invoiceAddresses) {
          for (let invoice of job.invoiceAddresses) {

            let pathArray = invoice.split('\\');
            let RowfileName = pathArray[pathArray.length - 1];
            let RowFileName = RowfileName.split('/');
            let fileName = RowFileName[RowFileName.length - 1];

            this.assetService.getExpenseImages(job.id, invoice).subscribe(response => {
              let reader = new FileReader();
              let currentImage: any;
              reader.addEventListener("load", () => {
                currentImage = reader.result;
                currentImage = this.sanitizer.bypassSecurityTrustUrl(currentImage);
                let jobWithInvoice = job;
                let isError = false
                if(response.type != "text/xml"){
                  isError = true;
                }
                jobWithInvoice['path'] = currentImage;
                jobWithInvoice['type'] = this.getFileType(invoice);
                jobWithInvoice['name'] =fileName;
                jobWithInvoice['isError'] =isError;
                

                this.invoiceList.push(jobWithInvoice);
              }, false);
              if (response) {
                reader.readAsDataURL(response);
              }

            });
          }
        }
      }
      this.maintenanceJobList = uniqueMaintenanceJobList;
    }
  }

  generateReport(report) {
    this.bookings = Array();

    this.totalsOfTotalBookingAmount = 0;
    this.totalsOfTotalBaseBookingAmount = 0;
    this.totalsOfTotalPlatformCommission = 0;
    this.totalsOfTotalPropertyMngmtComm = 0;
    this.totalsOfTotalCleaningFee = 0;
    this.totalsOfTotalTax = 0;
    this.totalsOfTotalPaymentProcessingFee = 0;
    this.totalsOfTotalNetProfit = 0;
    this.totalsOfTotalExpenses = 0;

    for (let index = 0; index < report.length; index++) {
      var tableJson = [];
      this.totalBookingAmount = 0;
      this.totalPlatformCommission = 0;
      this.totalPropertyMngmtComm = 0;
      this.totalCleaningFee = 0;
      this.totalTax = 0;
      this.totalPaymentProcessingFee = 0;
      this.totalNetProfit = 0;
      this.totalExpenses = 0;
      if (!(index % 2 == 0)) {
        if (report[index]) {
          for (let index1 = 0; index1 < report[index].length; index1++) {
            const element = report[index][index1];
            this.totalBookingAmount += element.bookingPrice;
            this.totalPlatformCommission += element.commission;
            this.totalCleaningFee += element.cleaningFees;
            this.totalTax += element.tax;
            this.totalPaymentProcessingFee += element.paymentProcessingFee;
            this.totalPropertyMngmtComm += element.propertyManagementCommission;
            this.totalNetProfit += element.netProfit;
            this.totalsOfTotalBookingAmount += element.bookingPrice;
            this.totalsOfTotalBaseBookingAmount += element.bookingBasePrice;
            this.totalsOfTotalPlatformCommission += element.commission;
            this.totalsOfTotalPropertyMngmtComm += element.propertyManagementCommission;
            this.totalsOfTotalCleaningFee += element.cleaningFees;
            this.totalsOfTotalTax += element.tax;
            this.totalsOfTotalPaymentProcessingFee += element.paymentProcessingFee;
            this.totalsOfTotalNetProfit += element.netProfit;
          }
        }
        var temp = new Array();
        temp = report[index - 1].split("?maintanaceJob=", 2);
        this.rentalName = temp[0];
        this.totalExpenses = parseFloat(temp[1]);
        this.totalsOfTotalExpenses += parseFloat(temp[1]);
        this.totalNetProfit = this.totalNetProfit - this.totalExpenses;
        tableJson['totalBookingAmount'] = this.totalBookingAmount.toFixed(2);
        tableJson['totalPlatformCommission'] = this.totalPlatformCommission.toFixed(2);
        tableJson['totalCleaningFee'] = this.totalCleaningFee.toFixed(2);
        tableJson['totalTax'] = this.totalTax.toFixed(2);
        tableJson['totalPaymentProcessingFee'] = this.totalPaymentProcessingFee.toFixed(2);
        tableJson['totalPropertyMngmtComm'] = this.totalPropertyMngmtComm.toFixed(2);
        tableJson['totalNetProfit'] = this.totalNetProfit.toFixed(2);
        tableJson['roomName'] = (this.rentalName);
        tableJson['totalExpenses'] = (this.totalExpenses);
        tableJson['bookings'] = new MatTableDataSource(report[index]);
        this.bookings.push(tableJson);
      }
    }
    this.totalsOfTotalNetProfit = this.totalsOfTotalNetProfit - this.totalsOfTotalExpenses;
    this.dataSource = new MatTableDataSource(this.bookings);
    this.dataSource.sort = this.sort;
    //this.displayColumns = ['Booking Id', 'Date', 'Platform', 'Booking Amount', 'Platform Commission',   'Cleaning Fee', 'Tax', 'Payment Processing Fee', 'Property Management Commission', 'Net Profit'];
  }


  roomSelected() {
    if (this.revenueForm.value.property !== null) {
      var rentals = new Array();
      // this.revenueForm.value.property.forEach(property => {
      let flag = false;
      for (let index = 0; index < this.revenueForm.value.property.length; index++) {
        const property = this.revenueForm.value.property[index];
        for (let index = 0; index < this.rentalsWithId.length; index++) {
          const rental = this.rentalsWithId[index];
          if (property === 'all') {
            flag = true;

            break;
          } else {
            // console.log("else rental: ", rental);
            if (rental.name === property.name) {
              rentals.push(rental);
            }
          }
        }
      }

    }
  }



  getInitials() {
    this.userService.getUser().subscribe(user => {

      this.user = user.payload;
      this.setCurrency(user.payload);

      if ((user.payload.role) == ("ADMINISTRATOR")) {
        this.isOwner = false;
        this.enableDownloadInvoiceFlag = false;
        this.userService.getPropertiesByAdmin().subscribe(properties => {

          if (properties && properties.payload) {
            this.propertyResponse = properties.payload;
            this.populateRentalData(properties.payload);
          }
          else {
            //console.error("Property data not found for user.payload.id", user.payload.id);
          }
        });

      } else if ((user.payload.role) == ("OWNER")) {

        let ownerId = localStorage.getItem('userId');
        if (ownerId) {
          this.ownerService.getOwnerDetails(ownerId).subscribe((res: any) => {
            if (res.code == '200') {
              this.enableDownloadInvoiceFlag = res.payload.enableDownloadInvoice;
            }
          })
          this.isOwner = true;
        }

        this.userService.getOwnerRoomUnits(user.payload.id).subscribe(ownerRoomUnitsResponse => {
          console.log("ownerRoomUnitsResponse: ", ownerRoomUnitsResponse);
          this.populateRentalData(ownerRoomUnitsResponse.payload);
        });


      }
    });
    this.getDisplayColumns();
  }

  private populateRentalData(properties) {
    this.rentals = new Array();
    this.rentalsWithId = new Array();
    this.rentalsWithId1 = new Array();

    properties.forEach(property => {
      if (property.name) {
        this.properties.push(property);
        if (property.unitItems) {
          if (property.unitItems.length == 0) {
            this.rentals.push(property.name + " - " + property.propertyName);
            this.rentalsWithId1.push({ id: property.id, name: property.name + " - " + property.propertyName });
            this.rentalsWithId.push({ id: property.id, name: property.name + " - " + property.propertyName });
          } else if (property.unitItems.length > 0) {
            property.unitItems.forEach(unitItem => {
              this.rentals.push(unitItem.unitName + " - " + property.name + " - " + property.propertyName);
              this.rentalsWithId1.push({
                id: property.id,
                name: unitItem.unitName + " - " + property.name + " - " + property.propertyName,
                unitId: unitItem.unitId
              });
              this.rentalsWithId.push({
                id: property.id,
                name: unitItem.unitName + " - " + property.name + " - " + property.propertyName,
                unitId: unitItem.unitId
              });
            });
          }
        } else if (!property.unitItems) {
          this.rentals.push(property.name + " - " + property.propertyName);
          this.rentalsWithId1.push({ id: property.id, name: property.name + " - " + property.propertyName });
          this.rentalsWithId.push({ id: property.id, name: property.name + " - " + property.propertyName });
        } else {
          console.log("property.unitItem - in here");
          this.rentals.push(property.name + " - " + property.propertyName);
          this.rentalsWithId1.push({ id: property.id, name: property.name + " - " + property.propertyName });
          this.rentalsWithId.push({ id: property.id, name: property.name + " - " + property.propertyName });
        }
      } else {
        console.log("property.units 2 - in here");
        this.rentals.push(property.name + " - " + property.propertyName);
        this.rentalsWithId1.push({ id: property.id, name: property.name + " - " + property.propertyName });
        this.rentalsWithId.push({ id: property.id, name: property.name + " - " + property.propertyName });
      }
    });

  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  searchRental(filterValue) {
    if (filterValue && filterValue.length != 0) {
      let tempProperties = new Array();
      for (let rental of this.rentalsWithId1) {
        if (rental.name.trim().toLowerCase().includes(filterValue.trim().toLowerCase())) {
          tempProperties.push(rental);
        }
      }
      this.rentalsWithId = tempProperties;
    } else {
      filterValue = null;
      this.rentalsWithId = this.rentalsWithId1;
    }
  }

  searchOwner(filterValue) {
    if ((filterValue) && (filterValue !== null)) {
      let tempOwners = new Array();
      for (let user of this.owners1) {
        if (user.role == "OWNER") {
          let currentOwner = user.firstName + " " + user.lastName;
          if (currentOwner.trim().toLowerCase().includes(filterValue.trim().toLowerCase())) {
            tempOwners.push(user);
          }
        }
      }
      this.owners = tempOwners;
    } else {
      filterValue = null;
      this.owners = this.owners1;
    }
  }

  convert(string) {
    var date = new Date(string),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }

  setMinDate(value: any): Date {
    return new Date(value);
  }

  lastDate(): Date {
    return new Date(this.revenueForm.get('toDate').value);
  }

  getFileType(path) {

    // Extract the file extension
    const fileExtension = path.split('.').pop().toLowerCase();

    // Check if the file type is an image
    const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp']; // Add more extensions as needed

    if (imageExtensions.includes(fileExtension)) {
      return "image";
    } else if (fileExtension === 'pdf') {
      return "pdf";
    } else if (fileExtension === 'doc' || fileExtension === 'docx') {
      return "doc";
    } else {
      return "no";
    }
  }

  convertToDataUrls(): void {
    if (this.statement.invoiceAddresses == null) {
      return;
    }

    this.invoices = []
    for (let index = 0; index < this.statement.invoiceAddresses.length; index++) {
      const invoiceAddress = this.invoiceAddresses[index];

      this.statementService.getAttachments(this.statement.id, invoiceAddress).subscribe(response => {
        let reader = new FileReader();
        let currentImage: any;
        reader.addEventListener("load", () => {
          currentImage = reader.result;
          let type = this.getFileType(invoiceAddress);
          currentImage = this.sanitizer.bypassSecurityTrustUrl(currentImage);

          let pathArray = invoiceAddress.split('\\');
          let RowfileName = pathArray[pathArray.length - 1];
          let RowFileName = RowfileName.split('/');


          // The last element of the array will be the file name
          let fileName = RowFileName[RowFileName.length - 1];
          //check if any error to read file
          let isError = false;
          if(response.type != "text/xml"){
            isError = true
          }
          let fileData = { name: fileName, path: currentImage, type: type,isError: isError };
          this.invoices.push(fileData);
        }, false);
        if (response) {
          reader.readAsDataURL(response);
        }

      });
    }

  }




  getAdditionalDetails() {
    this.additionalDetail.forEach((element) => {
      return `${element.fieldName} ${element.value}`
    })

  }

  downloadInvoice() {
    if (this.revenueForm.value.owner) {
      this.ownerInfo = this.revenueForm.value.owner
    }

    if (this.user.role == ("ADMINISTRATOR")) {
      this.ownerService.getOwnerDetails(this.ownerId).subscribe((res: any) => {
        if (res.code == '200') {
          if (this.revenueForm.value.owner) {
            this.ownerInfo = this.revenueForm.value.owner
            this.ownerFullName = this.ownerInfo.firstName + " " + this.ownerInfo.lastName
          }
          this.additionalDetail = [];
          if (res.payload.additionalDetails != null) {
            this.additionalDetail = res.payload.additionalDetails;
          }

          const doc = new jsPDF();
          doc.setFontSize(20);
          doc.text("Financial Statement", 10, 15);
          doc.setFontSize(15);
          doc.text(this.ownerFullName, 15, 25);
          if (this.additionalDetail.length > 0) {
            this.additionalDetail.forEach((element, idx) => {
              let additionalData = element.fieldName + ": " + element.value
              doc.setFontSize(10);
              doc.text(additionalData, 140, (idx + 2.5) * 10, 'left')
              if (idx === this.additionalDetail.length - 1) {
                let marginTop = ((idx + 2.5) * 10) + 15;
                setTimeout(() => {
                  doc.autoTable({
                    margin: { top: marginTop },
                    body: [['Total commission', this.cp.transform(this.totalsOfTotalPlatformCommission, this.currency, 'symbol-narrow')],
                    ['Total Tax', this.cp.transform(this.totalsOfTotalTax, this.currency, 'symbol-narrow')],
                    ['Total Payment Processing Fee', this.cp.transform(this.totalPaymentProcessingFee, this.currency, 'symbol-narrow')],
                    ['Total Platform Fees', this.cp.transform(this.totalsOfTotalPropertyMngmtComm, this.currency, 'symbol-narrow')],
                    ['Total Maintenance job expense', this.cp.transform(this.totalsOfTotalExpenses, this.currency, 'symbol-narrow')],
                    ['Grand Total (sum of all above fees)', this.cp.transform(this.totalsOfTotalExpenses + this.totalsOfTotalPropertyMngmtComm + this.totalPaymentProcessingFee + this.totalsOfTotalTax + this.totalsOfTotalPlatformCommission, this.currency, 'symbol-narrow')]],
                    didParseCell: function (data) {
                      var rows = data.table.body;
                      if (data.row.index === rows.length - 1) {
                        data.cell.styles.fillColor = [239, 154, 154];
                      }
                    }
                  });
                  doc.save(`financial-statement.pdf`);
                }, 200)
              }
            });
          } else {
            setTimeout(() => {
              doc.autoTable({
                margin: { top: 35 },
                body: [['Total commission', this.cp.transform(this.totalsOfTotalPlatformCommission, this.currency, 'symbol-narrow')],
                ['Total Tax', this.cp.transform(this.totalsOfTotalTax, this.currency, 'symbol-narrow')],
                ['Total Payment Processing Fee', this.cp.transform(this.totalPaymentProcessingFee, this.currency, 'symbol-narrow')],
                ['Total Platform Fees', this.cp.transform(this.totalsOfTotalPropertyMngmtComm, this.currency, 'symbol-narrow')],
                ['Total Maintenance job expense', this.cp.transform(this.totalsOfTotalExpenses, this.currency, 'symbol-narrow')],
                ['Grand Total (sum of all above fees)', this.cp.transform(this.totalsOfTotalExpenses + this.totalsOfTotalPropertyMngmtComm + this.totalPaymentProcessingFee + this.totalsOfTotalTax + this.totalsOfTotalPlatformCommission, this.currency, 'symbol-narrow')]],
              });
              doc.save(`financial-statement.pdf`);
            }, 200)
          }
        }
      }


      );
    } else {
      let ownerId = localStorage.getItem('userId');
      this.ownerService.getOwnerDetails(ownerId).subscribe((res: any) => {
        if (res.code == '200') {

          this.ownerFullName = this.user.firstName + " " + this.user.lastName

          this.additionalDetail = [];
          if (res.payload.additionalDetails != null) {
            this.additionalDetail = res.payload.additionalDetails;
          }

          const doc = new jsPDF();
          doc.setFontSize(20);
          doc.text("Financial Statement", 10, 15);
          doc.setFontSize(15);
          doc.text(this.ownerFullName, 15, 25);
          if (this.additionalDetail.length > 0) {
            this.additionalDetail.forEach((element, idx) => {
              let additionalData = element.fieldName + ": " + element.value
              doc.setFontSize(10);
              doc.text(additionalData, 140, (idx + 2.5) * 10, 'left')
              if (idx === this.additionalDetail.length - 1) {
                let marginTop = ((idx + 2.5) * 10) + 15;
                setTimeout(() => {
                  doc.autoTable({
                    margin: { top: marginTop },
                    body: [['Total commission', this.cp.transform(this.totalsOfTotalPlatformCommission, this.currency, 'symbol-narrow')],
                    ['Total Tax', this.cp.transform(this.totalsOfTotalTax, this.currency, 'symbol-narrow')],
                    ['Total Payment Processing Fee', this.cp.transform(this.totalPaymentProcessingFee, this.currency, 'symbol-narrow')],
                    ['Total Platform Fees', this.cp.transform(this.totalsOfTotalPropertyMngmtComm, this.currency, 'symbol-narrow')],
                    ['Total Maintenance job expense', this.cp.transform(this.totalsOfTotalExpenses, this.currency, 'symbol-narrow')],
                    ['Grand Total (sum of all above fees)', this.cp.transform(this.totalsOfTotalExpenses + this.totalsOfTotalPropertyMngmtComm + this.totalPaymentProcessingFee + this.totalsOfTotalTax + this.totalsOfTotalPlatformCommission, this.currency, 'symbol-narrow')]],
                    didParseCell: function (data) {
                      var rows = data.table.body;
                      if (data.row.index === rows.length - 1) {
                        data.cell.styles.fillColor = [239, 154, 154];
                      }
                    }
                  });
                  doc.save(`financial-statement.pdf`);
                }, 200)
              }
            });
          } else {
            setTimeout(() => {
              doc.autoTable({
                margin: { top: 35 },
                body: [['Total commission', this.cp.transform(this.totalsOfTotalPlatformCommission, this.currency, 'symbol-narrow')],
                ['Total Tax', this.cp.transform(this.totalsOfTotalTax, this.currency, 'symbol-narrow')],
                ['Total Payment Processing Fee', this.cp.transform(this.totalPaymentProcessingFee, this.currency, 'symbol-narrow')],
                ['Total Platform Fees', this.cp.transform(this.totalsOfTotalPropertyMngmtComm, this.currency, 'symbol-narrow')],
                ['Total Maintenance job expense', this.cp.transform(this.totalsOfTotalExpenses, this.currency, 'symbol-narrow')],
                ['Grand Total (sum of all above fees)', this.cp.transform(this.totalsOfTotalExpenses + this.totalsOfTotalPropertyMngmtComm + this.totalPaymentProcessingFee + this.totalsOfTotalTax + this.totalsOfTotalPlatformCommission, this.currency, 'symbol-narrow')]],
              });
              doc.save(`financial-statement.pdf`);
            }, 200)
          }
        }
      }


      )
    }


  }


  getPropertyBookingReportData(propertyBookings, propertyName): any[] {
    const bookingTableData = [];
    for (let index = 0; index < propertyBookings.length; index++) {
      const propertyBooking = propertyBookings[index];
      if (propertyBooking.bookingPrice === 0) {
        continue;
      }
      let date = "";
      if (propertyBooking.firstNight != "" && propertyBooking.lastNight != "") {
        date = propertyBooking.firstNight + " to " + propertyBooking.lastNight;
      } else {
        date = "";
      }
      let referer = propertyBooking.referer;
      let newrefere = '';
      for (let i = 1; i <= referer.length; i++) {
        if (i % 14 == 0) {
          newrefere = newrefere + '\n'
        }
        newrefere = newrefere + referer[i - 1]
      }

      bookingTableData.push([
        propertyBooking.bookId,
        date,
        newrefere,
        this.cp.transform(propertyBooking.bookingPrice, this.currency, 'symbol-narrow'),
        this.cp.transform(propertyBooking.commission, this.currency, 'symbol-narrow'),
        this.cp.transform(propertyBooking.cleaningFees, this.currency, 'symbol-narrow'),
        this.cp.transform(propertyBooking.tax, this.currency, 'symbol-narrow'),
        this.cp.transform(propertyBooking.paymentProcessingFee, this.currency, 'symbol-narrow'),
        this.cp.transform(propertyBooking.propertyManagementCommission, this.currency, 'symbol-narrow'),
        this.cp.transform(propertyBooking.netProfit, this.currency, 'symbol-narrow')
      ]);
    }
    return [
      [
        { text: 'ID', style: 'tableHeaderStyle' },
        { text: 'Dates', style: 'tableHeaderStyle' },
        { text: 'Platform', style: 'tableHeaderStyle' },
        { text: 'Revenue', style: 'tableHeaderStyle' },
        { text: 'Platform Commission', style: 'tableHeaderStyle' },
        { text: 'Cleaning', style: 'tableHeaderStyle' },
        { text: 'Tax', style: 'tableHeaderStyle' },
        { text: 'Payment Processing Fee', style: 'tableHeaderStyle' },
        { text: 'Management Commission', style: 'tableHeaderStyle' },
        { text: 'Net Profit', style: 'tableHeaderStyle' }
      ],
      [{ text: propertyName, colSpan: 10, fillColor: "#8181e5", fontSize: 15, margin: 5 }],
      ...bookingTableData
    ];
  }

  getBookingReportData(report): any[] {
    //console.log("getBookingReportData: {}", report);
    const bookingReportData = [];
    for (let index = 0; index < report.length; index++) {
      const propertyBookings = report[index];
      if (!(index % 2 == 0)) {
        if (propertyBookings) {
          const propertyName = report[index - 1].split("?maintanaceJob=", 2)[0];
          bookingReportData.push({
            style: 'tableStyle',
            table: {
              headerRows: 1,
              // dontBreakRows: true,
              keepWithHeaderRows: 1,
              body: this.getPropertyBookingReportData(propertyBookings, propertyName)
            }
          });


        }
      }
    }

    return bookingReportData;
  }




  toDate(dateStr) {
    const d = new Date(Date.parse(dateStr));
    return d;
  }

  filterExpensesByRoomList() {

    const selectedProperties = (this.revenueForm.value.property)
      ? this.revenueForm.value.property
      : this.rentalsWithId;
    const roomIds = selectedProperties.map(room => room.id);
    return this.rawExpenseData.filter(expense =>
      roomIds.includes(expense.roomObjectId) && (
        this.revenueForm.value.fromDate && this.revenueForm.value.toDate &&
        this.toDate(expense.jobDate) >= this.toDate(this.startDate) &&
        this.toDate(expense.jobDate) <= this.toDate(this.end_Date)));
  }

  getPropertyExpenses() {
    const roomExpenses = this.filterExpensesByRoomList();


    const roomExpensesTableData = [];
    this.maintenanceJobList.forEach((item, index) => {
      roomExpensesTableData.push([
        index + 1,
        item.jobTitle,
        item.jobDetails,
        item.unitName,
        this.cp.transform(item.taxInPercent, this.currency, 'symbol-narrow'),
        this.cp.transform(item.totalPayable, this.currency, 'symbol-narrow'),
      ]);

    })

    return [
      [
        { text: 'S.No', style: 'tableHeaderStyle' },
        { text: 'Name', style: 'tableHeaderStyle' },
        { text: 'Detail', style: 'tableHeaderStyle' },
        { text: 'Unit Name', style: 'tableHeaderStyle' },
        { text: 'Tax (%)', style: 'tableHeaderStyle' },
        { text: 'Total Payable', style: 'tableHeaderStyle' },
      ],
      ...roomExpensesTableData
    ]

  }

  calculateFinancialSummary(report) {
    this.totalBookingAmount = 0;
    this.totalPlatformCommission = 0;
    this.totalPropertyMngmtComm = 0;
    this.totalCleaningFee = 0;
    this.totalTax = 0;
    this.totalPaymentProcessingFee = 0;
    this.totalNetProfit = 0;
    this.totalExpenses = 0;
    for (let index = 0; index < report.length; index++) {
      const element = report[index];
      if (!(index % 2 == 0)) {
        if (report[index]) {
          for (let index = 0; index < element.length - 1; index++) {
            const element1 = element[index];
            this.totalBookingAmount += element1.bookingPrice;
            this.totalPlatformCommission += element1.commission;
            this.totalCleaningFee += element1.cleaningFees;
            this.totalTax += element1.tax;
            this.totalPaymentProcessingFee += element1.paymentProcessingFee;
            this.totalPropertyMngmtComm += element1.propertyManagementCommission;
            this.totalNetProfit += element1.netProfit;
          }
          // console.log("this.totalBookingAmount: ", this.totalBookingAmount);
          var temp = new Array();
          temp = report[index - 1].split("?maintanaceJob=", 2);
          this.rentalName = temp[0];
          this.totalExpenses = temp[1];
          this.totalNetProfit = this.totalNetProfit - this.totalExpenses;

        }
      }
    }
  }

  hasBookingAmount(): boolean {
    return this.hasPermission('Booking amount') || this.hasPermission('Booking Base Price')
  }

  getBookingAmount(): number {
    if (this.hasPermission('Booking amount'))
      return this.totalsOfTotalBookingAmount;

    return this.totalsOfTotalBaseBookingAmount;
  }

  getDisplayColumns() {
    this.displayColumns = [];
    this.totalsColumnsToDisplay = [];
    this.userService.getUser().subscribe(user => {

      if ((user.payload.role) == ("OWNER")) {
        this.ownerService.getOwnerPermissions(user.payload.id).subscribe(response => {
          console.log("getOwnerPermissions response: ", response);
          this.columnPermissions = response.payload.columnPermissions;
          this.ownerPermissions = response.payload.ownerPermissions;

          this.totalsColumnsToDisplay.push('rentalName');

          this.displayColumns.push('Booking Id');
          this.displayColumns.push('Date');
          this.displayColumns.push('Platform');

          if (this.columnPermissions.includes('Booking amount')) {
            this.displayColumns.push('Booking Amount');
            this.totalsColumnsToDisplay.push('totalBookingAmount');
          }
          if (this.columnPermissions.includes('Booking Base Price')) {
            this.displayColumns.push('Booking Base Price');
            // this.totalsColumnsToDisplay.push('totalBookingBasePrice');
          }
          if (this.columnPermissions.includes('Platform Commission')) {
            this.displayColumns.push('Platform Commission')
            this.totalsColumnsToDisplay.push('totalPlatformCommission');
          }
          if (this.columnPermissions.includes('Cleaning Fee')) {
            this.displayColumns.push('Cleaning Fee')
            this.totalsColumnsToDisplay.push('totalCleaningFees');
          }
          if (this.columnPermissions.includes('Tax')) {
            this.displayColumns.push('Tax');
            this.totalsColumnsToDisplay.push('totalTax');
          }
          if (this.columnPermissions.includes('Payment Processing')) {
            this.displayColumns.push('Payment Processing Fee');
            this.totalsColumnsToDisplay.push('totalPaymentProcessingFee');
          }
          if (this.columnPermissions.includes('Expenses')) {
            this.totalsColumnsToDisplay.push('totalExpenses')
          }
          if (this.columnPermissions.includes('Management Commission')) {
            this.displayColumns.push('Property Management Commission');
            this.totalsColumnsToDisplay.push('totalPropertyMngmtComm');
          }


          if (this.columnPermissions.includes('Net Profit')) {
            this.displayColumns.push('Net Profit');
            this.totalsColumnsToDisplay.push('totalNetProfit');
          }
        });
      } else if (user.payload.role == "ADMINISTRATOR") {
        this.displayColumns = ['Booking Id', 'Date', 'Platform', 'Booking Amount', 'Booking Base Price', 'Platform Commission', 'Cleaning Fee', 'Tax', 'Payment Processing Fee', 'Property Management Commission', 'Net Profit'];
        this.columnPermissions = ['Booking amount', 'Booking Base Price', 'Platform Commission', 'Cleaning Fee', 'Tax', 'Payment Processing', 'Management Commission', 'Expenses', 'Net Profit']

        this.totalsColumnsToDisplay = ['rentalName', 'totalBookingAmount', 'totalPlatformCommission', 'totalCleaningFees', 'totalTax', 'totalPaymentProcessingFee',
          'totalPropertyMngmtComm', 'totalExpenses', 'totalNetProfit'];

      }
    });
  }

  public hasPermission(permission: string) {
    if (this.tokenService.getCurrentUser() == 'OWNER') {
      return this.ownerPermissions != null && this.ownerPermissions.includes(permission);
    }
    return true;
  }

  expenseInvoices(element) {
    let rentalId;

    this.rentalsWithId.forEach(rental => {
      if (rental.name === element.roomName) {
        rentalId = rental.id;
        console.log(this.revenueForm.getRawValue());

        this.propertyService.getAllMaintenanceJobsByRoomId(rentalId, this.startDate, this.endDate).subscribe(response => {
          console.log("expens", response.payload);
          this.dialog.open(RevenueExpenseComponent, {
            data: { maintenanceJobs: response.payload, roomId: rentalId }
          });
        });
      }
    });
  }

  private setCurrency(payload: User): void {
    this.currency = payload.currency ? payload.currency : 'USD';
  }

  getFormulaObject(formulaList) {
    let data = {}
    for (const key in formulaList) {
      if (formulaList.hasOwnProperty(key)) {
        const value = formulaList[key];
        if (typeof value === 'string') {
          const resultObject = {};
          let formulaString = value.replace(/{/g, "").replace(/}/g, "");
          const pairs = formulaString.split(', ');
          pairs.forEach(pair => {
            const [key, value] = pair.split(': ');
            resultObject[key.trim()] = value;
          });

          data[key] = resultObject;

        } else {
          data[key] = { namedFormula: "", formula: "" }
        }

      }
    }
    return data;
  }

  isTooltipDisplay(value, formula) {

    if (value == 0) {
      return false;
    }
    if (this.isOwner) {
      let allPermission = ['Base Price', 'Platform Commission', 'Tax', 'Payment Processing Fee', 'Management Commission'];
      for (let i = 0; i < allPermission.length; i++) {
        let permission = allPermission[i];
        console.log(permission);
        if (formula.includes(permission)) {
          if (permission == "Payment Processing Fee") {
            permission = "Payment Processing"
          }
          if (permission == "Base Price") {
            permission = "Booking Base Price"
          }
          console.log(permission, " has this formula ", formula);
          if (!this.hasPermission(permission)) {
            return false;
          }
        }
      }

    }
    return true;
  }

  addMedia(invoices: any) {
    if (invoices) {
      this.assetService.uploadStatementInvoices(this.statementId, invoices).subscribe(response => {
        this.invoices = [];
        this.toastrService.success("Images Uploaded Successfully", success, {
          timeOut: 3000,
          onActivateTick: true
        });
        this.getReport();
      });

    }
  }

  //----------------------PDF EXPORT START-------

  generatePDF() {

    if ((this.searchReport !== null) && (this.searchReport != undefined) && (this.searchReport)) {
      this.getPDF(this.searchReport);
    } else {
      this.getPDF(this.report)
    }
  }

  getPDF(_report) {
    // deep clone object
    const report = JSON.parse(JSON.stringify(_report))
    if (report.length != 0) {
      report.forEach((element, index) => {
        if (Array.isArray(element) && element.length != 0) {
          let totalBookingPrice = 0;
          let commission = 0;
          let cleaningFees = 0;
          let tax = 0;
          let paymentProcessingFee = 0;
          let propertyManagementCommission = 0;
          let netProfit = 0;
          let guestPropertyName = '';
          let guestRoomName = '';
          let guestUnitName = ''
          element.forEach((item, idx) => {
            totalBookingPrice = totalBookingPrice + item.bookingPrice;
            commission = commission + item.commission;
            cleaningFees = cleaningFees + item.cleaningFees;
            tax = tax + item.tax;
            paymentProcessingFee = paymentProcessingFee + item.paymentProcessingFee;
            propertyManagementCommission = propertyManagementCommission + item.propertyManagementCommission;
            netProfit = netProfit + item.netProfit;
            guestPropertyName = item.guestPropertyName;
            guestRoomName = item.guestRoomName;
            guestUnitName = item.guestUnitName;
            if (element.length - 1 == idx) {
              element.push({
                "guestPropertyName": guestPropertyName,
                "guestRoomName": guestRoomName,
                "guestUnitName": guestUnitName,
                "netProfit": netProfit,
                "cleaningFees": cleaningFees,
                "propertyManagementCommission": propertyManagementCommission,
                "paymentProcessingFee": paymentProcessingFee,
                "bookId": "Total",
                "firstNight": "",
                "lastNight": "",
                "bookingPrice": totalBookingPrice,
                "tax": tax,
                "commission": commission,
                "referer": "",
              },
              );
            }
          });

        }

      })
    }


    this.calculateFinancialSummary(report);

    const images = this.getinvoceFileInPDF();
    this.ownerService.findOwnerByUserId(this.ownerId).subscribe(ownerDataResponse => {
      //  console.log("Found owner: ", ownerDataResponse);
      this.owner = ownerDataResponse.payload;
      const dd = {
        styles: {
          tableStyle: {
            margin: [0, 5, 0, 15],
            fontSize: 8,
          },
          tableHeaderStyle: {
            bold: true,
            fontSize: 10,
            color: 'black'
          },
          header: {
            fontSize: 18,
            bold: true
          },
          bigger: {
            fontSize: 15,
            italics: true
          },
          summaryTableStyle: {
            fillColor: "#eeeeff",
            alignment: "center"
          }
        },
        defaultStyle: {
          columnGap: 20
        },
        content: [
          // {
          //     alignment: 'justify',
          //     columns: [
          //       {
          //         alignment: 'left',
          //         text: [
          //           `PMI Example Toronto \n`,
          //           `300 young st w \n`,
          //           `Suite 330 \n`,
          //           `Toronto, Ontario, Canada`,
          //         ]
          //       },
          //       {
          //         image: 'logo',
          //         width: 200,
          //       }
          //     ]
          //   },
          '\n\n',
          {
            text: 'Statement',
            style: 'header',
            fontSize: 35,
            alignment: 'center',
          },
          '\n\n',

          {
            alignment: 'justify',
            columns: [
              {
                alignment: 'left',
                text: [
                  { text: ` Prepared for: `, bold: true },
                  { text: `${this.ownerFullName} \n`, bold: true, color: '#02849b' },
                  { text: ` Mobile: `, bold: true },
                  { text: `${this.ownerDetail.mobile} \n`, bold: true, color: '#02849b' },
                  { text: ` Email: `, bold: true },
                  { text: `${this.ownerDetail.email} \n`, bold: true, color: '#02849b' },
                  ...this.ownerAllDetails.additionalDetails.flatMap(item => [
                    { text: `${item.fieldName}: `, bold: true },
                    { text: `${item.value} \n`, bold: true, color: '#02849b' },
                  ]),
                ]
              },
              {
                alignment: 'left',
                margin: [50, 0, 0, 0],
                text: [
                  { text: ` Report Type: `, bold: true },
                  { text: `${this.statement.reportType} \n`, bold: true, color: '#02849b' },
                  { text: ` Date: `, bold: true },
                  { text: `${this.startDate} - ${this.endDate} \n`, bold: true, color: '#02849b' },
                  { text: `Name: `, bold: true },
                  { text: `${this.statement.name} `, bold: true, color: '#02849b' }
                  ,


                ]
              }
            ]
          },
          '\n\n',

          {
            text: 'Financial Summary',
            style: 'header'
          },
          '\n\n',

          {
            style: 'summaryTableStyle',
            table: {
              body: [
                [
                  {
                    text: [
                      'Total booking amount: \n',
                      this.cp.transform(this.totalBookingAmount, this.currency, 'symbol-narrow')
                    ],
                  },
                  {
                    text: [
                      'Total Platform Commission: \n',
                      this.cp.transform(this.totalPlatformCommission, this.currency, 'symbol-narrow')
                    ],
                  },
                  {
                    text: [
                      'Total Cleaning Fee: \n',
                      this.cp.transform(this.totalCleaningFee, this.currency, 'symbol-narrow')
                    ],
                  },
                  {
                    text: [
                      'Total Tax: \n',
                      this.cp.transform(this.totalTax, this.currency, 'symbol-narrow')
                    ],
                  }
                ],
                [
                  {
                    text: [
                      'Total Rental Processing Fee: \n',
                      this.cp.transform(this.totalsOfTotalPaymentProcessingFee, this.currency, 'symbol-narrow')
                    ]
                  },
                  {
                    text: [
                      'Total Management Commission: \n',
                      this.cp.transform(this.totalPropertyMngmtComm, this.currency, 'symbol-narrow')
                    ]
                  },
                  {
                    text: [
                      'Total Expenses: \n',
                      this.cp.transform(this.totalsOfTotalExpenses, this.currency, 'symbol-narrow')
                    ]
                  },
                  {
                    text: [
                      'Total Net Profit: \n',
                      this.cp.transform(this.totalsOfTotalNetProfit, this.currency, 'symbol-narrow')
                    ]
                  },
                ]
              ]
            }
          },
          '\n\n',

          {
            text: 'Booking Details',
            style: 'header'
          },

          ...this.getBookingReportData(report),
          '\n\n',

          {
            text: 'Expense Details',
            style: 'header'
          },
          {
            style: 'tableStyle',
            table: {
              headerRows: 1,
              // dontBreakRows: true,
              keepWithHeaderRows: 1,
              body: this.getPropertyExpenses()
            }
          },
          '\n\ ',
          {
            text: `${this.invoiceLeval}`,
            style: 'header'
          },

          ...images,
        ],

      }

      // tableNode
      pdfMake.createPdf(dd).download(`Statement-from-${this.startDate}-to-${this.endDate}.pdf`);
    });


  }

  getinvoceFileInPDF() {
    this.pdfInvoiceFile = [...this.invoices, ...this.invoiceList];
    this.pdfInvoiceFile.sort((a, b) => {
      if (a.type === 'image' && b.type !== 'image') {
        return -1; // 'image' comes before other types
      } else if (a.type !== 'image' && b.type === 'image') {
        return 1; // other types come after 'image'
      } else {
        return 0; // order remains unchanged
      }
    });
   

    const images = this.pdfInvoiceFile.map((item, index) => {
      let i = index + 1
      if(item.isError){
        return null;
      }
      if (item.type === 'image') {
       
        return {
          stack: [
            {
              text: '\n File: ' + item.name, // replace with the actual label you want
              alignment: 'left',
            },
            {
              image: `${item.path.changingThisBreaksApplicationSecurity}`,
              width: 450, // adjust the width as needed
              alignment: 'center',
            },

          ],
        };

      } else {

        let url = environment.API + "/public/download/" + item.name;
        return {
          text: [
            {text: '\n Click here to download  ', style: 'pdfLinkStyle'},
            {text: "File " + item.name, style: {bold: true, color: '#02849b'}}
          ],
          link: `${url}`,
        }

      }
    }).filter(image => image !== null); // Remove any null values

    if (images.length > 0) {
      this.invoiceLeval = "File Uploads"
      return images;

    } else {
      this.invoiceLeval = "End"
      return;

    }
  }

  deleteAttachment(index) {
    if (index !== -1) {
      this.invoices.splice(index, 1);
      this.statement.invoiceAddresses.splice(index, 1);
    }

    this.statementService.updateStatement(this.statement).subscribe(response => {

      this.invoices = [];
      this.toastrService.success("The uploaded file has been successfully deleted.", success, {
        timeOut: 3000,
        onActivateTick: true
      });
      this.getReport();
    });
  }



}
