import {Injectable} from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { Workbook } from 'exceljs';
// import * as fs from 'file-saver';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable()
export class ExcelService {

  constructor() {
  }


  public exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    
    XLSX.utils.sheet_add_aoa(worksheet, [["new_value"]], { origin: 0 });
    const workbook: XLSX.WorkBook = {Sheets: {'data': worksheet}, SheetNames: ['data']};
    const excelBuffer: any = XLSX.write(workbook, {bookType: 'xlsx', type: 'array'});
    // const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }

 
  /**
 * 
 * @param excelData The Excel data to be exported. An object where keys represent worksheet names and values are arrays of data.
 * @param excelFileName The filename for the exported Excel file.
 * @param owner Details of the owner associated with the data (optional).
 */
public exportAsExcel(excelData: any, excelFileName: string, owner: any) {
  // Initialize a new workbook
  const workbook = new Workbook();
  let i = 0;
  // Iterate over each worksheet in the excelData object
  for (const key in excelData) {
      if (excelData.hasOwnProperty(key)) {
          // Add a new worksheet to the workbook
          const worksheet = workbook.addWorksheet(key);
          // Get the data array for the current worksheet
          const json = excelData[key];
          const array = [];

          if(i == 0 && owner != null && owner != 'allOwner'){
            const ownerDetails=[['Name ','Id ','Email '],
              [owner.firstName+" "+owner.lastName,owner.id,owner.email]
            ]
            ownerDetails.forEach((d:any)=>{
              worksheet.addRow(d);
            });
            // Blank Row
            worksheet.addRow([]);
          }
          
          // Iterate over each element in the data array
          json.forEach((element, idx) => {
              // Convert values to strings and push them to the array
              const modifiedValues = Object.values(element).map(value => value + '');
              array.push(modifiedValues);
              // Add headers at the beginning of the array
              if (idx === 0) {
                  array.unshift(Object.keys(element));
              }
          });
          
          // Add rows to the worksheet
          array.forEach(row => worksheet.addRow(row));
          // Add a blank row for spacing
          worksheet.addRow([]);
          // Apply styling to the first row of the worksheet
          this.applyStylingToRow(worksheet, 1);
          if(i == 0 && owner != null && owner != 'allOwner'){
            this.applyStylingToRow(worksheet, 4);
          }
          i++;
          
          // Adjust column widths for better readability
          worksheet.columns.forEach(column => {
              const maxLength = column.values.map(str => str ? String(str).length : 0)
                  .reduce((max, length) => Math.max(max, length), 0);
              column.width = maxLength;
          });
      }
  }

  // Write the workbook data to a buffer
  workbook.xlsx.writeBuffer().then((data: any) => {
      // Create a Blob object with the workbook data
      const blob = new Blob([data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      // Save the Blob as a file with the specified filename
      FileSaver.saveAs(blob, excelFileName);
  });
}
 

 public applyStylingToRow(worksheet: XLSX.WorkSheet, rowNumber: number): void {
  worksheet.getRow(rowNumber).eachCell((cell) => {
    cell.font = { bold: true, color: { argb: 'FFFFFF' } }; // Font color (white)
    cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '007bff' } }; // Background color
    cell.border = {
      top: { style: 'thin', color: { argb: '000000' } }, // Top border
      left: { style: 'thin', color: { argb: '000000' } }, // Left border
      bottom: { style: 'thin', color: { argb: '000000' } }, // Bottom border
      right: { style: 'thin', color: { argb: '000000' } }, // Right border
    };
    cell.alignment = { horizontal: 'center', vertical: 'middle' }; // Text alignment
    cell.numFmt = '" "General" "';
  });
}

}
