import { Component,ViewChildren, QueryList, OnInit,ChangeDetectorRef, HostListener, ElementRef, Renderer2  } from '@angular/core';
import { ToastrService } from 'ngx-toastr'; 
import { info ,success, successUserDeleted, errorMessage } from '../../constants/notification-messages.constant';
import { HttpErrorResponse } from '@angular/common/http';
import {GenericDto} from '../../models/generic-dto';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { AuthGuard } from 'src/app/core/guards/auth.guard';
import { TokenService } from 'src/app/core/services/token.service';
import { UserService } from 'src/app/services/user.service';
import { GeneralRequestsService } from 'src/app/services/general-requests.service';
import { DataService } from 'src/app/shared/services/data.service';
import { SharedService } from 'src/app/services/shared.service';
import { ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { ConfirmationDialogComponent } from 'src/app/shared/components/confirmation-dialog/confirmation-dialog.component';
import { MatDialog } from '@angular/material';


@Component({
  selector: 'app-general-requests',
  templateUrl: './general-requests.component.html',
  styleUrls: ['./general-requests.component.scss']
})
export class GeneralRequestsComponent implements OnInit {
  @ViewChild('messageContent') chatContainer!: ElementRef;
  adminReply:any ="";
  selectedStatus: string = 'waiting for responce'; 
  showStatusDropdown = [];
  chatThread: any[] = [];
  requests: any[] = [];
  owners = []; 
  myForm: FormGroup;
  admin:any;
  owner:any;
  isOwner: boolean = false;
  ownerId:any  ;
  adminId:any ;  
  createdBy:any;
  defaultStatus ="waiting"
  filteredOwners = [];
  isNewRequest:boolean = false;
  activeRequest:any = null;
  isSwitchRequest:boolean = false;


  totalItems = 0;
  pageSize = 50;
  currentPage = 0;

  mainRequestTotalItems = 0;
  mainRequestPageSize = 50;
  mainRequestCurrentPage = 0;

  isCreateNewRequest:boolean = false;
  @ViewChild(MatPaginator) paginator: MatPaginator;
   
  

  constructor(public dialog: MatDialog,private renderer: Renderer2, public router: Router, dataService: DataService,private sharedService: SharedService, private cdr: ChangeDetectorRef,protected userService: UserService, public tokenService:TokenService, public authGuard: AuthGuard, private fb: FormBuilder, private toastrService: ToastrService,private generalRequestsService:GeneralRequestsService) {}

  ngAfterViewInit() { 
    

    this.scrollToBottomAfterDelay();
    
    setTimeout(() => {
      this.sharedService.shareAndCommunicate.subscribe(
        (data) => {
          this.updateComponent(data)  
          this.scrollToBottom(); 
         
        }
      );
      let owner;     
           
      // console.log("preview",this.authGuard.isPreviewLogin());
      if (this.authGuard.isPreviewLogin()) {
        owner = this.tokenService.getPreviewOwner();
        // console.log("owner = ",owner);
          this.owner = owner;
          this.adminId = owner.userLinkId;  
          this.createdBy =  "OWNER"
          this.isOwner =  true;
          this.getRequests();
                
      } else{                  
         if(this.tokenService.getCurrentUser() == "ADMINISTRATOR"){
          this.createdBy = "ADMIN"
          this.isOwner =  false;
          this.adminId = localStorage.getItem('userId');
          this.getOwnersByAdminId();
          
         }else{
          
          this.createdBy = "OWNER"
          this.isOwner =  true;
          this.ownerId = localStorage.getItem('userId');
          this.adminId = localStorage.getItem('userLinkId');
          this.owner = {id:this.ownerId}
          // console.log(localStorage)
          this.getRequests();
          
         }         
           
      }    
       
      this.cdr.detectChanges();
    }, 2000); // Adjust the time as needed
       
  }

  

  ngOnInit() { 
    this.myForm = this.fb.group({
      topic: ['', Validators.required],
      message: ['', Validators.required]
    });  
     
  }
  onPageChange(event: PageEvent) {    
       console.log(event)
       this.currentPage = event.pageIndex;
       this.pageSize = event.pageSize;
       this.getConversation();
     }

  onMainRequestPageChange(event: PageEvent) {    
    console.log(event)
    this.mainRequestCurrentPage = event.pageIndex;
    this.mainRequestPageSize = event.pageSize;
    this.activeRequest = null;
    this.getRequests();
  }
    
  
  getRequests() {
    
     
    let ownerId = this.owner.id;
    
    
    if(this.owner == "all"){
      ownerId ="all"
    }
     
    var createdBy   = (this.isOwner)?"ADMIN":"OWNER"
    this.generalRequestsService.getRequests(ownerId, this.adminId, createdBy ,this.mainRequestCurrentPage,this.mainRequestPageSize).subscribe((response: GenericDto<any>) => {
      if (response.code === 200) {   
        this.mainRequestTotalItems = response.payload.totalElements; 
        if(this.mainRequestTotalItems < 1){
          this.requests = []
          this.chatThread = []
          this.activeRequest = null;
          return;
        }  
         
       this.requests = response.payload.content
               
       if(this.activeRequest == null || this.isNewRequest){
        this.activeRequest = this.requests[0];
        this.isNewRequest = false;
       }else{       
        this.activeRequest = this.requests.find(request => request.id === this.activeRequest.id); 

       }
       if(this.isSwitchRequest){
        this.isSwitchRequest =  false
       }else{
        this.requestActive(this.activeRequest);  
       }          
                         
      } else {        
        this.toastrService.error(response.desc, errorMessage, {
          timeOut: 3000,
          onActivateTick: true
        }); 
      }
  
    }, (error: HttpErrorResponse) => {
      // show error and redirect to login page
    });
    
  }   
 

  loadMoreContent(): void {    
    if(this.currentPage*this.pageSize<this.totalItems){
      this.currentPage += 1;
      this.getConversation();
    }
    
  }


  getConversation() {     
    let ownerId;
    
    if(this.owner == "all"){
      ownerId ="all"
    }else{
      ownerId  = this.owner.id;
    }
     
    
    var createdBy   = (this.isOwner)?"ADMIN":"OWNER"
    this.generalRequestsService.getConversation( this.activeRequest.id, createdBy ,this.currentPage,this.pageSize).subscribe((response: GenericDto<any>) => {
      if (response.code === 200) {    
        if(this.isSwitchRequest){
          this.getRequests();     
        }
        this.totalItems = response.payload.totalElements;  
        this.chatThread = response.payload.content.reverse();//[...response.payload.content, ...this.chatThread];            
        this.sharedService.setshareAndCommunicate({code:"GENERAL_REQUEST_MARK",data:null});           
        this.scrollToBottomAfterDelay();   
      } else {        
        this.toastrService.error(response.desc, errorMessage, {
          timeOut: 3000,
          onActivateTick: true
        }); 
      }
  
    }, (error: HttpErrorResponse) => {
      // show error and redirect to login page
    });
    
  }  
    
  requestActive(request){
      this.activeRequest =  request;  
      this.isNewRequest = false; 
      this.isSwitchRequest=true; 
      this.getConversation();       
      
  }


  
  createRequest(form: NgForm){ 

    let status;
    if(this.isOwner) {
      status = "WAITING_FOR_ADMIN";
    }else{
      status = "WAITING_FOR_OWNER";
    }

    this.generalRequestsService
    let request ={
      message : form.value.message,
      adminId : this.adminId,
      topic:form.value.topic,
      ownerId : this.owner.id,      
      createdBy: this.createdBy,
      status: status ,
      read:false,
    }   
    this.isNewRequest = true;
    
    form.reset();
    this.send(request);
  }
   

  toggleStatusDropdown(formIndex: number) {      
    this.showStatusDropdown[formIndex] = !this.showStatusDropdown[formIndex];
  }   

  ownerSelected(){         
    this.currentPage = 0; 
    this.mainRequestCurrentPage = 0;
    this.activeRequest = null;
    this.getRequests();

  }

  getOwnersByAdminId(){
    this.userService.getOwnersByAdminId(localStorage.getItem('userId')).subscribe((response) => {
      response.payload.forEach((owner) => {
        if(owner.ownerDetails != null && owner.ownerDetails.enableRequestInbox != null && owner.ownerDetails){
          this.owners.push(owner);
        }
                 
      });
       
      this.owners = this.owners.filter(owner => owner.ownerDetails.enableRequestInbox === true);

      this.owner =   'all';  
      this.filteredOwners =  this.owners;
      this.ownerSelected(); 
       
    }); 
  }

  resolveStatus() {    
    this.activeRequest.status = "RESOLVED" ;
    this.send(this.activeRequest);
  }
  deleteRequest(){
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '500px',
      height: '150px',
      data: "Do you confirm the deletion of this request?"
    });
    
    dialogRef.afterClosed().subscribe(result => {  
         
      if( result=="close" || (typeof result != 'boolean' || !result )){
        return false
      }else{
        this.generalRequestsService.deleteRequest( this.activeRequest.id).subscribe((response: GenericDto<any>) => {
          if (response.code === 200) {
            this.activeRequest = null;
            this.getRequests();       
            this.toastrService.success(response.desc, success, {
              timeOut: 3000,
              onActivateTick: true
            });  
    
          } else {        
            this.toastrService.error(response.desc, errorMessage, {
              timeOut: 3000,
              onActivateTick: true
            }); 
          } 
        })   
        
      }             
    });

   


   
    
  }

  sendReply(myForm){
    let request ={
      message : myForm.value.message,
      adminId : this.activeRequest.adminId,    
      ownerId : this.activeRequest.ownerId,      
      createdBy: this.createdBy,  
      generalRequestsId:this.activeRequest.id,    
      read:false,
      
    }     
     

    this.generalRequestsService.sendReply(request).subscribe((response: GenericDto<any>) => {
      if (response.code === 201) {
        myForm.reset();       
        this.getRequests();    
        this.toastrService.success(response.desc, success, {
          timeOut: 3000,
          onActivateTick: true
        });  

      } else {        
        this.toastrService.error(response.desc, errorMessage, {
          timeOut: 3000,
          onActivateTick: true
        }); 
      }
  
    }, (error: HttpErrorResponse) => {
      // show error and redirect to login page
    });
    

  }

  send(request){
    this.generalRequestsService.sendRequest(request).subscribe((response: GenericDto<any>) => {
      if (response.code === 201) {
        this.getRequests();       
        this.toastrService.success(response.desc, success, {
          timeOut: 3000,
          onActivateTick: true
        });  

      } else {        
        this.toastrService.error(response.desc, errorMessage, {
          timeOut: 3000,
          onActivateTick: true
        }); 
      }
  
    }, (error: HttpErrorResponse) => {
      // show error and redirect to login page
    });
  }  
 
  

  searchOwner(inputValue:any){
    this.filteredOwners = this.owners.filter(owner =>
      (owner.firstName.toLowerCase() + ' ' + owner.lastName.toLowerCase())
        .includes(inputValue.toLowerCase())
    );

  }

  //--------------------------
  updateComponent(obj) {
      
    // clean all the message and hide the chat-boat
    if(obj.code == "GENERAL_REQUEST" &&  this.router.url == "/dashboard/request/inbox"){    

      this.getRequests();
    }  
     
    
  }
  showStatus(request){
    if(request.status == 'WAITING_FOR_OWNER'){
      return "Waiting for "+request.ownerName;
    }else if(request.status == 'WAITING_FOR_ADMIN'){
      return 'Waiting for '+request.adminName;
    }else if(request.status == 'RESOLVED'){
      return 'Resolved';
    }

  }  
  getProfileBackgroundColor(chat: any): string {    
    let userId = ""
    if((!this.isOwner  && chat.createdBy =="ADMIN" && chat.adminId == this.adminId)||(this.isOwner  && chat.createdBy =="OWNER" && chat.ownerId == this.owner.id)){
      return "#94C2ED";
    }else{
       
      if(  chat.createdBy =="OWNER"){
        userId = chat.ownerId
      }else{
        userId =  chat.adminId
      }
    }
    const profileColors = ['#007bff', '#28a745', '#ffc107', '#dc3545', '#17a2b8'];

    // Get the index based on the user ID
    const index = userId.split('').reduce((acc, char) => char.charCodeAt(0) + acc, 0) % profileColors.length;
    return profileColors[index];
  }
  

  scrollToBottom(): void {
    try {
      if(this.chatContainer == null || this.chatContainer.nativeElement == null){
        return;
      }
      const container = this.chatContainer.nativeElement;
  
      // Check if the container is scrollable
      if (container.scrollHeight > container.clientHeight) {
        console.log("Container Height:", container.scrollHeight);
        container.scrollTop = container.scrollHeight;
  
        // Trigger change detection to ensure the view is updated
        this.cdr.detectChanges();
      } else {
        console.log("Container is not scrollable.");
      }
    } catch (err) {
      console.log("Error-", err);
    }
  }
  
  scrollToBottomAfterDelay() {
    setTimeout(() => {
      this.scrollToBottom();
    }, 100);
  }
  
goToOwnerProfile(chat){
  if(this.isOwner || chat.createdBy == 'ADMIN'){
    return;
  }
  this.router.navigate([
    "/dashboard/users/",chat.ownerId,
            
  ]);
}


}
