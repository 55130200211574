import { AfterViewInit, Component, Input, OnInit, Output } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { PropertyService } from "../../../services/property.service";
import { HttpErrorResponse } from "@angular/common/http";
import { GenericDto } from "../../../models/generic-dto";
import { Property } from "../../../models/property";
import { DataService } from "../../services/data.service";
import { Subscription } from "rxjs/index";
import { UserService } from "../../../services/user.service";
import { TourService } from "src/app/services/tour.service";
import { SharedService } from "src/app/services/shared.service";
import { Room, Rooms } from "socket.io";
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators'
@Component({
  selector: "app-property-list",
  templateUrl: "./property-list.component.html",
  styleUrls: ["./property-list.component.scss"],
})
export class PropertyListComponent implements OnInit,AfterViewInit {
  searchSubject = new Subject<string>();
  searchSubscription: Subscription;
  properties: Array<Property>;
  propertyDropDown = [];
  searchText: string;
  watcher: Subscription;
  isLoading: boolean;
  selectedProperty: string;
  propertyId: any;
  roomTypeId: any;
  subscription: Subscription;
  unit: any;
  unitId:any;
  isUnitsDefined: any;
  isPlugingsAdded: boolean;
  @Input() tabSelected;
  propertiesList: any;
  selectedPropertyId: string | number | null;
  activeUnit: any;
  activeRoom: any;

  constructor(
    private router: Router,
    private propertyService: PropertyService,
    private dataService: DataService,
    private route: ActivatedRoute,
    private userService: UserService,
    private tourService: TourService,
    private sharedService :SharedService,
  ) {}
  ngAfterViewInit(): void {
    this.sharedService.shareAndCommunicate.subscribe(
      (data) => {
        this.updateComponent(data)   
      }
    );
  }
  
  search(keyword: string): void {
    this.searchSubject.next(keyword);
  }

  ngOnInit() {
    this.searchSubscription = this.searchSubject
    .pipe(
      debounceTime(300), // Adjust the debounce time as needed (e.g., 300 milliseconds)
      distinctUntilChanged()
    )
    .subscribe((keyword: string) => {
      this.searchProperty(keyword);
    });

    this.getProperties();
    this.plugingsCheck();
    

    this.dataService.customEvent.subscribe(() => {
      // this.getProperties();
    });
  
    let currentUrl = this.router.url;
    if (currentUrl.includes("dashboard/property")) {
      currentUrl = currentUrl.substring(currentUrl.indexOf("property") + 9);
      if (currentUrl.includes("/")) {
        currentUrl = currentUrl.substring(0, currentUrl.indexOf("/"));
        this.selectedPropertyId = currentUrl;
      } else {
        this.selectedPropertyId = currentUrl;
      }
    } 
    
  }

  onPropertyClick(id,index) {   
    this.isLoading = true;        
    if(this.propertyDropDown[index].roomTypes ==null || this.propertyDropDown[index].roomTypes.length == 0){
      this.getRooms(id,index);
    }else{        
      this.isLoading = false;
    }
    if (this.selectedPropertyId == id) {
      this.selectedPropertyId = null;
    } else {
      this.selectedPropertyId = id;
    }
    this.router.navigate([
      "/dashboard/property/info",id,
              
    ]);
       
  }

  getProperties(): void {
     
    this.isLoading = true;
    this.watcher = this.propertyService.getAllName().subscribe(
      (data: GenericDto<Array<Property>>) => {
         
        this.propertiesList = Array();
        if (data.code === 200) {
           
          var newProperty = [];
          for (let index = 0; index < data.payload.length; index++) {
            data.payload[index]['roomTypes']=[];
            const property = data.payload[index];
             
            var newRoomTypes = [];
            for (let index = 0; index < property.roomTypes.length; index++) {
              const roomType = property.roomTypes[index];
               
              var newUnitList = [];
              for (let index = 0; index < roomType.unitItems.length; index++) {
                const unitItem = roomType.unitItems[index];
                if (unitItem.unitName != "") {
                  newUnitList.push(unitItem);
                }
              }
              roomType.units = newUnitList;
              newRoomTypes.push(roomType);
            }
            //console.log(newRoomTypes)
           // this.propertiesList.roomTypes = newRoomTypes;
            
            this.propertiesList.push(property);
          }

          this.properties = this.propertiesList || [];
           
          this.searchProperty("");
        } else {
          // show error message //
        }
        this.isLoading = false;
      
      },
      (error: HttpErrorResponse) => {
        // show error message
       
        this.isLoading = false;
      }
    );
   

  }

  navigate(property, roomType, unit) {    
    this.propertyId = property.id;
    this.roomTypeId = roomType.id;
    this.unitId = unit.unitId;
    this.unit = unit.unitName;    
    this.tabSelected = 0;
    this.dataService.property = property;
    this.selectedPropertyId = this.propertyId;
    if (unit == undefined) {
      this.router.navigate([
        "/dashboard/property",
        this.propertyId,
        this.roomTypeId,
      ]);
      this.activeRoom = this.roomTypeId;
    } else if (unit != undefined) {
      this.activeUnit = roomType.id;
      this.roomTypeId = this.roomTypeId;
      this.router.navigate([
        "/dashboard/property",
        this.propertyId,
        this.roomTypeId,
        this.unit,
        unit.unitId        
      ]);
    }
  }
  searchProperty(keyword): void {    
    this.propertyDropDown = [];
    if (keyword && keyword.trim().length < 1){
      this.propertyDropDown = this.properties;
      return ;
    }

    this.propertyService.searchProperty(keyword).subscribe((response: GenericDto<any>) => {
      if (response.code === 200) {        
       let properties = response.payload || []               
       this.propertyDropDown = properties; 
      }
      this.isLoading = false;
    }, error => {
      this.isLoading = false;
    });   
    
  }  

  onSelect(propId: string) {
    this.selectedPropertyId = propId;
    this.selectedProperty = propId;
  }

  plugingsCheck() {
    this.userService
      .redirectUser()
      .subscribe((response: GenericDto<boolean>) => {
        if (response.payload === false) {
          this.isPlugingsAdded = false;
          if (this.router.url === "/dashboard/property") {
            this.isPlugingsAdded = true;
          }
        } else {
          this.isPlugingsAdded = true;
        }
      });
  }
  activeClass(unit,roomId){     
    if(this.roomTypeId+this.unitId == roomId+unit.unitId){
      return true;
    }
    return false;   
  }
  getRooms(id,index): void {    
    this.propertyService.getRooms(id).subscribe((response: GenericDto<any>) => {
      if (response.code === 200) {        
         this.propertyDropDown[index].roomTypes = response.payload;               
      }      
      this.isLoading = false;
    }, error => {
      this.isLoading = false;
    });
  }


  updateComponent(obj){
    if(obj.code == "updatePropertyList"){
      this.getProperties();
    }
    
  }
  

}
