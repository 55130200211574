import {Component, OnInit} from '@angular/core';
import {FormGroup, FormControl, FormBuilder, Validators, FormArray} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {PropertyService} from '../../../services/property.service';
import {HttpErrorResponse} from '@angular/common/http';
import {GenericDto} from '../../../models/generic-dto';
import {
  success, successPropertyCreated,
  successPropertyUpdated,
  successRoomTypeUpdated
} from '../../../constants/notification-messages.constant';
import {ActivatedRoute, Router} from '@angular/router';
import {Property} from '../../../models/property';
import {RoomType} from '../../../models/RoomType';
import {DataService} from '../../../shared/services/data.service';
import {MatDialog, MatTableDataSource} from '@angular/material';
import {PaymentProcessingFeeComponent} from '../payment-processing-fee/payment-processing-fee.component';
import {CopyPropertyChargesComponent} from './copy-property-charges/copy-property-charges.component';
import mexp from "math-expression-evaluator";
import { BookingConfirmationComponent } from './booking-confirmation/booking-confirmation.component';
 

function evaluate(exp: string) {
  return mexp.eval(exp);
}

function notEmptyValueOr(value: string, defaultValue: string) {
  return (!value || value === "" || value === "null" || value === "undefined") ? defaultValue : value;
}

@Component({
  selector: 'app-property-form',
  templateUrl: './property-form.component.html',
  styleUrls: ['./property-form.component.scss']
})

export class PropertyFormComponent implements OnInit {
  propertyForm: FormGroup;
  property: Property;
  counter: Array<number>;
  isLoading = false;
  editForm = false;
  emptyState = true;
  includeCleaningFee: string;
  defaultCleaningFee = new FormControl();
  propertyManagementCommission = new FormControl();
  paymentProcessingFee = new FormControl();
  paymentProcessingFeeEdit:any;
  platformProcessingFeeEdit:any;
  updatedPyament:any;
  updatedPlatformValue:any;
  
  
  defaultTax = new FormControl();
  roomName: string;
  roomTypeId: string;
  unit: string;
  unitId:string;
  isCalculationUpdate:boolean = true;
  maxAdult: string;
  maxChildren: number;
  maxPeople: number;
  minPrice: number;
  qty: number;
  unitAllocationPerGuest: number;
  updatedFees: any[];
  dataSource: any;
  paymentProcessingFeesDS: any;
  
  displayedColumns = ['platform', 'fees'];
  isUpdated: boolean;

  managementCommissionformula: string[];
  defaultTaxFormula: string[];
  cleaningFeeFormula: string[];
  paymentProcessingFeeFormula: string[];
  platformCommissionFormula: string[];
  netProfitFormula: string[];

  showManagementCommissionFormula: boolean = false;
  showDefalutTaxFormula: boolean = false;
  showCleaningFeeFormula: boolean = false;
  showPaymentProcessingFeeFormula: boolean = false;
  showPlatformCommissionFormula: boolean = false;
  showNetProfitFormula: boolean = false;

  errors: {} = {};


  constructor(public dialog: MatDialog, private fb: FormBuilder, private router: Router,
              private toastrService: ToastrService, private propertyService: PropertyService,
              private dataService: DataService, private route: ActivatedRoute) {

  }

  ngOnInit() {
    if (this.router.url.includes('/edit/')) {
      // create filled form
      this.editForm = true;
      this.isLoading = true;
      this.route.params.subscribe((data: { id: string, id2: string, id3: string, id4: string }) => {
        this.roomTypeId = data.id2;
        this.unit = data.id3;
        this.unitId =data.id4;
        this.propertyService.get(data.id)
          .subscribe((response: GenericDto<Property>) => {
            if (response.code === 200) {
              this.includeCleaningFee = response.payload.includeCleaningFee;
              /*    this.propertyManagementCommission = response.payload.propertyManagementCommission;*/
              this.createPropertyForm();
              this.property = response.payload as Property;
              this.updatedFees = this.property.platformProcessingFees;
              //this.dataSource = new MatTableDataSource(this.updatedFees);  
                      
              //this.paymentProcessingFeesDS = new MatTableDataSource(this.property.paymentProcessingFees);
              // create filled Form
              this.fillForm();
              this.emptyState = false;
            } else {
              // show error message
            }
            this.isLoading = false;
          }, (error: HttpErrorResponse) => {
            // show error message
            this.isLoading = false;
          });
      });
    } else {
      this.emptyState = false;
      this.createPropertyForm();
    }
    // initlize variables here

  }
  
  goBack() {
    if (this.editForm) {
      const urlBuilder = ['/dashboard/property', this.property.id, this.roomTypeId];
      if(this.unit) {
        urlBuilder.push(this.unit);
      }
      const url = urlBuilder.join("/");
 
      this.router.navigate([url]);
    } else {
      this.router.navigate(['/dashboard/property']);
    }
  }

  private createPropertyForm() {
    this.propertyForm = this.fb.group({
      name: [null, [Validators.required]],
      // unitsName: [null, [Validators.required]],
      address: [],
      city: [],
      state: [],
      defaultCleaningFee: new FormControl(),
      paymentProcessingFee: new FormControl(),
      propertyManagementCommission: new FormControl(),
      defaultTax: new FormControl(),
      latitude: [0, [Validators.required]],
      longitude: [0, [Validators.required]],
      assetList: this.fb.array([]),
      includeCleaningFee: [],
      accessInstructions: [],
      garbageInstructions: [],
      parkInstructions: [],
      otherInformations: [],
      cleaningChecklist: [],
      roomTypes: this.fb.array([
        this.prepareRooms('Bedroom'), this.prepareRooms('Bed'), this.prepareRooms('Bathroom')]),
      roomName: [],
      newUnit: [],
      oldUnit: [],
      maxAdult: new FormControl(),
      maxChildren: new FormControl(),
      maxPeople: new FormControl(),
      minPrice: new FormControl(),
      qty: new FormControl(),
      unitAllocationPerGuest: new FormControl()
    });
  }

  fillForm(): void {
    Object.keys(this.propertyForm.controls).forEach((key: string) => {     
      if (key === 'roomTypes') {
        var room;
        for (let index = 0; index < this.property[key].length; index++) {
          room = this.property[key][index];
          room.unit = this.unit
          
        }      
        this.propertyForm.removeControl('roomTypes');
        this.propertyForm.addControl('roomTypes', this.fb.array(this.iterateRooms()));

      } else if (key === 'assetList') {
        this.propertyForm.removeControl('assetList');
        /* this.propertyForm.addControl('assetList', this.fb.array(this.loadAssets()));*/
      } else if (key === 'defaultCleaningFee' || key === 'propertyManagementCommission' || key === 'includeCleaningFee' || key === 'defaultTax') {

        for (let index = 0; index < this.property.roomTypes.length; index++) {
          let value = this.property.roomTypes[index][key];
          if (value) {
            for (var i = 0; i < value.length; i++) {
              if (value[i].unit === this.unit) {
                this.propertyForm.get(key).setValue(value[i].value);
              }
            }

          }
        }


      } else if (key === '') {
        //TODO :
      } else {
        if (!!this.propertyForm.get(key)) {
          this.propertyForm.get(key).setValue(this.property[key]);

        }
      }

    });

    this.property['roomTypes'].forEach(element => {
      if (element.id == this.roomTypeId) {
      
        /*this.paymentProcessingFeeFormula = this.getUnitFormula(element.paymentProcessingFeesFormula, this.unit);
        this.platformCommissionFormula = this.getUnitFormula(element.platformProcessingFeesFormula, this.unit);*/
        if(element.paymentProcessingFee){        
          this.paymentProcessingFeeEdit =  this.getUnitProcessingFee(element.paymentProcessingFee); 
          this.updatedPyament = element.paymentProcessingFee;      
          this.paymentProcessingFeesDS = new MatTableDataSource(this.paymentProcessingFeeEdit);
        }
        if(element.platformProcessingFees){        
          this.platformProcessingFeeEdit =  this.getUnitProcessingFee(element.platformProcessingFees);  
          this.updatedPlatformValue = element.platformProcessingFees;              
          this.dataSource = new MatTableDataSource(this.platformProcessingFeeEdit);
        }
        
        this.defaultTaxFormula = this.getUnitFormula(element.defaultTaxFormula, this.unitId);
        if(!this.defaultTaxFormula){
          this.defaultTaxFormula = ["0"];
        }
               
        this.cleaningFeeFormula = this.getUnitFormula(element.cleaningFeeFormula, this.unitId);
        if(!this.cleaningFeeFormula){
          this.cleaningFeeFormula = ["0"];
        }
        this.managementCommissionformula = this.getUnitFormula(element.managementCommissionFormula, this.unitId);
        if(!this.managementCommissionformula){
          this.managementCommissionformula = ["0"];
        }

        this.netProfitFormula = this.getUnitFormula(element.netProfitFormula, this.unitId);
        if(!this.netProfitFormula){
          this.netProfitFormula = ["Gross Revenue", "-","Cleaning Fee", "-", "Tax", "-" , "Platform Commission", "-", "Payment Processing Fee", "-", "Management Commission"];
        }

        this.propertyForm.get('newUnit').setValue(element.unit)
        this.propertyForm.get('oldUnit').setValue(element.unit);
        this.propertyForm.get('roomName').setValue(element.name);
        this.propertyForm.get('maxAdult').setValue(element.maxAdult);
        this.propertyForm.get('maxChildren').setValue(element.maxChildren);
        this.propertyForm.get('maxPeople').setValue(element.maxPeople);
        this.propertyForm.get('minPrice').setValue(element.minPrice);
        this.propertyForm.get('qty').setValue(element.qty);
        this.propertyForm.get('unitAllocationPerGuest').setValue(element.unitAllocationPerGuest);       
        

      }
    });

  }

  private getUnitProcessingFee(processingFeesList){
    if(!processingFeesList){
      return null;
    }
    for (var i = 0; i < processingFeesList.length; i++) {
      var unitValue = processingFeesList[i];
      if (unitValue.unit == this.unitId) {
        return unitValue.value;
      } 
    }
   
  }

  getUnitFormula(formulaList, unit) {
     
    if (!formulaList) {
      return undefined;
    }
    try {
      for (var i = 0; i < formulaList.length; i++) {
        if (formulaList[i].unit == this.unitId) {
          return formulaList[i].formula;
        }              
      }
      for (var i = 0; i < formulaList.length; i++) {
        if (formulaList[i].unit == this.unit) {
          return formulaList[i].formula;
        }              
      }


    } catch (e) {
      console.error(e);
    }
    return undefined;
  }

  /* loadAssets(): FormControl[] {
     const controls: FormControl[] = new Array<FormControl>();
     const assets: Array<string> = this.property.assetList;
     assets.forEach((asset: string) => {
       const formControl: FormControl = new FormControl(asset, []);
       controls.push(formControl);
     });
     return controls;
   }*/

  iterateRooms(): FormGroup[] {
    const rooms: RoomType[] = this.property.roomTypes;
    const formArray: FormGroup[] = [];
    let roomForm: FormGroup = null;

    // iterate rooms
    rooms.forEach((room: RoomType) => {
      // get keys
      roomForm = new FormGroup({});
      Object.keys(room).forEach((key: string) => {
        roomForm.addControl(key, new FormControl(room[key], []));
      });
      formArray.push(roomForm);

    });
    return formArray;
  }

  prepareRooms(name: string): FormGroup {
    return this.fb.group({
      maxAdult: [0],
      maxChildren: [0],
      maxPeople: [0],
      minPrice: [0],
      name: [name],
      qty: [0],
      roomId: [null],
      unitAllocationPerGuest: [null],
      unitNames: [null]
    });
  }

  getRoomQty(index: number): number {

    return Number((<RoomType>((<FormControl>(<FormArray>this.propertyForm.get('roomTypes')).at(index)).value)).qty);
  }

  /*decrease(index: number): void {
      const roomGroup: FormGroup = (<FormArray>this.propertyForm.get('roomTypes')).at(index) as FormGroup;
      const qty = Number(roomGroup.get('qty').value);
      if (qty > 0) {
        roomGroup.patchValue({'qty': qty - 1});
      }
    }

    increase(index: number): void {
      const roomGroup: FormGroup = (<FormArray>this.propertyForm.get('roomTypes')).at(index) as FormGroup;
      const qty = Number(roomGroup.get('qty').value);

      roomGroup.patchValue({'qty': qty + 1});
    }
  */

  useFormulaWithValues(formula: string, testValues: {}): string {
    let formulaWithValues = formula;
    for (const name in testValues) {
      formulaWithValues = formulaWithValues.replace(name, testValues[name])
    }
    return formulaWithValues;
  }

  useFormulaWithTestValues(formula: string): string {
    const testValues = {
      "Gross Revenue": 100.0,
      "Platform Commission": 1.0,
      "Cleaning Fee": 10.0,
      "Tax": 2.0,
      "Payment Processing Fee": 3.0,
      "Management Commission": 4.0
    }
    return this.useFormulaWithValues(formula, testValues);
  }

  validateFormula(formulaArr: string[], formulaFieldName: string): void {
    if(!formulaArr || formulaArr.length === 0){
      return
    }
    const testFormula = this.useFormulaWithTestValues(formulaArr.join(""));
    try {
     // console.log("evaluate test: ", {f: formulaArr, tf: testFormula, e: evaluate(testFormula)});
    }
    catch(e) {
    //  console.log("Error while eval formula", e)
      this.errors[formulaFieldName] = `Invalid formula: ${e.message}. Example formula: ${testFormula}`;
      throw new Error("Error validating");
    }
  }

  createProperty(): void {     
    this.errors = {};
    if (this.propertyForm.valid) {
      // call property creation API here
      this.isLoading = true;
      if (!this.editForm) {
        this.propertyService.createProperty(this.propertyForm.getRawValue()).subscribe((data: GenericDto<Property>) => {

            if (data.code === 200) {
              this.toastrService.success(successPropertyCreated, success, {
                timeOut: 3000,
                onActivateTick: true
              });
              this.dataService.customEvent.next();
              this.router.navigate(['/dashboard/property/' + data.payload.id]);
            }
            this.isLoading = false;
          },
          (error: HttpErrorResponse) => {
            console.log(error);
            this.isLoading = false;
          });
      } else {
         
        this.validateFormula(this.managementCommissionformula, "managementCommissionformula");

        this.validateFormula(this.platformCommissionFormula, "platformCommissionFormula");
        this.validateFormula(this.paymentProcessingFeeFormula, ", paymentProcessingFeeFormula");
        this.validateFormula(this.netProfitFormula, ", netProfitFormula");
        this.validateFormula(this.cleaningFeeFormula, ", cleaningFeeFormula");
        this.validateFormula(this.defaultTaxFormula, ", defaultTaxFormula");
         

        // update property
        this.property.name = this.propertyForm.get('name').value;
        this.property.address = this.propertyForm.get('address').value;
        this.property.city = this.propertyForm.get('city').value;
        this.property.state = this.propertyForm.get('state').value;
       
        this.property.roomTypes = this.propertyForm.get('roomTypes').value;         
        //this.property.defaultCleaningFee = this.propertyForm.get('defaultCleaningFee').value;
        //this.property.propertyManagementCommission = this.propertyForm.get('propertyManagementCommission').value;
        //this.property.paymentProcessingFee = this.propertyForm.get('paymentProcessingFee').value;
        //this.property.defaultTax = this.propertyForm.get('defaultTax').value;
        //this.property.includeCleaningFee = this.propertyForm.get('includeCleaningFee').value;

        this.property.accessInstructions = this.propertyForm.get('accessInstructions').value;
        this.property.garbageInstructions = this.propertyForm.get('garbageInstructions').value;
        this.property.parkInstructions = this.propertyForm.get('parkInstructions').value;
        this.property.otherInformations = this.propertyForm.get('otherInformations').value;
        this.property.cleaningChecklist = this.propertyForm.get('cleaningChecklist').value;
        let indexOfRoom;
        
        for (let index = 0; index < this.property.roomTypes.length; index++) {
          const room = this.property.roomTypes[index];
          if (this.roomTypeId == room.id) {
            indexOfRoom = index;
            this.property.roomTypes[index].platformProcessingFees = this.updatedPlatformValue
             
            this.property.roomTypes[index].paymentProcessingFee = this.updatedPyament   

            this.property.roomTypes[index].oldUnit = notEmptyValueOr(this.propertyForm.get('oldUnit').value, "");
            this.property.roomTypes[index].newUnit = notEmptyValueOr(this.propertyForm.get('newUnit').value, "");
            this.property.roomTypes[index].name = this.propertyForm.get('roomName').value;
            this.property.roomTypes[index].maxAdult = this.propertyForm.get('maxAdult').value;
            this.property.roomTypes[index].maxChildren = this.propertyForm.get('maxChildren').value;
            this.property.roomTypes[index].maxPeople = this.propertyForm.get('maxPeople').value;
            this.property.roomTypes[index].minPrice = this.propertyForm.get('minPrice').value;
            this.property.roomTypes[index].qty = this.propertyForm.get('qty').value;
            this.property.roomTypes[index].unitAllocationPerGuest = this.propertyForm.get('unitAllocationPerGuest').value;

            this.property.roomTypes[index].defaultTax = this.setUnitConfiguration(this.property.roomTypes[index].defaultTax, this.unit, this.propertyForm.get("defaultTax").value);
            this.property.roomTypes[index].includeCleaningFee = this.setUnitConfiguration(this.property.roomTypes[index].includeCleaningFee, this.unit, this.propertyForm.get("includeCleaningFee").value);
            this.property.roomTypes[index].propertyManagementCommission = this.setUnitConfiguration(this.property.roomTypes[index].propertyManagementCommission, this.unit, this.propertyForm.get("propertyManagementCommission").value);
            this.property.roomTypes[index].defaultCleaningFee = this.setUnitConfiguration(this.property.roomTypes[index].defaultCleaningFee, this.unit, this.propertyForm.get("defaultCleaningFee").value);

            this.property.roomTypes[index].defaultTaxFormula = this.setFormulaConfiguration(this.property.roomTypes[index].defaultTaxFormula, this.unitId, this.defaultTaxFormula);
            this.property.roomTypes[index].cleaningFeeFormula = this.setFormulaConfiguration(this.property.roomTypes[index].cleaningFeeFormula, this.unitId, this.cleaningFeeFormula);
            this.property.roomTypes[index].managementCommissionFormula = this.setFormulaConfiguration(this.property.roomTypes[index].managementCommissionFormula, this.unitId, this.managementCommissionformula);
            this.property.roomTypes[index].platformProcessingFeesFormula = this.setFormulaConfiguration(this.property.roomTypes[index].platformProcessingFeesFormula, this.unitId, this.platformCommissionFormula);
            this.property.roomTypes[index].paymentProcessingFeesFormula = this.setFormulaConfiguration(this.property.roomTypes[index].paymentProcessingFeesFormula, this.unitId, this.paymentProcessingFeeFormula);
            this.property.roomTypes[index].netProfitFormula = this.setFormulaConfiguration(this.property.roomTypes[index].netProfitFormula, this.unitId, this.netProfitFormula);
             
                         
          }
        }        
         
        this.propertyService.updateRoomType(this.property.roomTypes[indexOfRoom],this.unitId,this.isCalculationUpdate).subscribe((response: GenericDto<any>) => {
          
          if (response.code === 200) {
            this.toastrService.success(successRoomTypeUpdated, success, {
              timeOut: 3000,
              onActivateTick: true
            });
            //this.dataService.customEvent.next();
            //this.router.navigate(['/dashboard/property' + response.payload.id]);
          } else {
            // show errors here
          }
          this.isLoading = false;
        }, (error: HttpErrorResponse) => {
          this.isLoading = false;
          // show errors here
        });


        this.propertyService.updateProperty(this.property).subscribe((response: GenericDto<any>) => {
          if (response.code === 200) {
            this.toastrService.success(successPropertyUpdated, success, {
              timeOut: 3000,
              onActivateTick: true
            });
            this.dataService.customEvent.next();
            const urlBuilder = ['/dashboard/property', response.payload.id, this.roomTypeId];
            if(this.unit) {
              urlBuilder.push(this.unit);
            }
            urlBuilder.push(this.unitId);
            const url = urlBuilder.join("/");
 
            this.router.navigate([url]);

          } else {
            // show errors here
          }
          this.isLoading = false;
        }, (error: HttpErrorResponse) => {
          this.isLoading = false;
          // show errors here
        });
      }


    } else {
      // throw warning
      Object.keys(this.propertyForm.controls).forEach(field => {
        const control = this.propertyForm.get(field);
        control.markAsTouched({onlySelf: true});
      });
      if (this.propertyForm.invalid) {
        this.toastrService.error('Please enter required fields', '', {
          timeOut: 3000,
          onActivateTick: true
        });
      }
    }
  }

  setFormulaConfiguration(configurationList, unit, value) {
    if (!value) {
      return configurationList;
    }

    if (!configurationList) {
      configurationList = [{unit: unit, formula: value}];
      return configurationList;
    }

    for (var i = 0; i < configurationList.length; i++) {
      if (configurationList[i].unit === unit) {
        configurationList[i].formula = value;
        return configurationList;
      }
    }

    configurationList.push({unit: unit, formula: value})
    return configurationList;
  }

  setUnitConfiguration(configurationList, unit, value) {
    if (!configurationList) {
      configurationList = [{unit: unit, value: value}];
      return configurationList;
    }

    for (var i = 0; i < configurationList.length; i++) {
      if (configurationList[i].unit === unit) {
        configurationList[i].value = value;
        return configurationList;
      }
    }
    configurationList.push({unit: unit, value: value})
    return configurationList;
  }

  get address(): FormGroup {
    return this.propertyForm.get('address') as FormGroup;
  }


  openProcessingFees(type: any) {
    var title = "Platform Commission";
    let obj = this.platformProcessingFeeEdit;
    if (type == "paymentProcessingFees") {
      title = "Payment Processing Fee by Platform";
      obj = this.paymentProcessingFeeEdit;
    }
    let dialogRef = this.dialog.open(PaymentProcessingFeeComponent, {
      width: '53%',
      // height : '100%',
      data: {propertyId: this.property.id, type: type, title: title,paymentProcessingFeesDS:obj}
    })
    dialogRef.afterClosed().subscribe(updatedprocessedFees => {
      
      if (type == "platformProcessingFees" && updatedprocessedFees !== 'Cancel' && this.editForm && updatedprocessedFees) {
        this.isUpdated = updatedprocessedFees.isUpdated;
        this.updatedFees = updatedprocessedFees.channels        
        let updateFeeOfUnit = {
          unit:this.unitId,
          value:this.updatedFees
        } ; 
         
        
        this.property.roomTypes.forEach((item,index) => {         
           if(item.id == this.roomTypeId ){             
            if(this.property.roomTypes[index].platformProcessingFees && this.property.roomTypes[index].platformProcessingFees !=null ) {
              let flag = 0;      
              this.property.roomTypes[index].platformProcessingFees.forEach((element,i) => {
                if(element.unit ==  this.unitId){
                  flag =1 ;
                  this.property.roomTypes[index].platformProcessingFees[i] = updateFeeOfUnit;
                }
                if(flag == 0){
                  this.property.roomTypes[index].platformProcessingFees.push(updateFeeOfUnit);   
                }
              })
            } else{
              this.property.roomTypes[index].platformProcessingFees =[]
              this.property.roomTypes[index].platformProcessingFees.push(updateFeeOfUnit); 
            }   
            this.updatedPlatformValue = this.property.roomTypes[index].platformProcessingFees
           }
        });      
        
       // this.property.roomTypes[index].platformProcessingFees = platformProcessingFees  
       // this.property.platformProcessingFees = updatedprocessedFees.channels;
        this.dataSource = new MatTableDataSource(this.updatedFees);
      }
      if (type == "paymentProcessingFees" && updatedprocessedFees !== 'Cancel') {
        let updateFeeOfUnit = {
          unit:this.unitId,
          value:updatedprocessedFees.channels
        };
         
         
        this.property.roomTypes.forEach((item,index) => {
                   
          if(item.id == this.roomTypeId ){ 
                         
           if(this.property.roomTypes[index].paymentProcessingFee && this.property.roomTypes[index].paymentProcessingFee !=null ) {
             let flag = 0;      
             this.property.roomTypes[index].paymentProcessingFee.forEach((element,i) => {
               if(element.unit ==  this.unitId){
                 flag =1 ;
                 this.property.roomTypes[index].paymentProcessingFee[i] = updateFeeOfUnit;
               }
               if(flag == 0){
                 this.property.roomTypes[index].paymentProcessingFee.push(updateFeeOfUnit);   
               }
             })
           } else{
             this.property.roomTypes[index].paymentProcessingFee =[]
             this.property.roomTypes[index].paymentProcessingFee.push(updateFeeOfUnit); 
           }   
           this.updatedPyament = this.property.roomTypes[index].paymentProcessingFee
          }
       }); 








       this.paymentProcessingFeesDS = new MatTableDataSource(updatedprocessedFees.channels);
       // this.paymentProcessingFeesDS = new MatTableDataSource(updatedprocessedFees.channels)
      //  this.property.paymentProcessingFees = updatedprocessedFees.channels;
      }
      this.openProcessingFees = this.openProcessingFees;
    })
  }

  copy(copyingFor: string) {

    const importDialogRef = this.dialog.open(CopyPropertyChargesComponent, {
      width: '50%',
      height: '400px',
      data: {
        copyingFor,
        property: this.property,
        unit: this.unit,
        roomId: this.roomTypeId
      }
    });
    
  }

  openConfirmationDialog(): void {
    const dialogRef = this.dialog.open(BookingConfirmationComponent);
  
    dialogRef.afterClosed().subscribe((result: any) => {
      if(result == "close"){
        return;
      }
      this.isCalculationUpdate = result;
      this.createProperty();  
    });
  }

  setManagementCommissionFormula(value: string[]) {
    this.managementCommissionformula = value;

    console.log("Formula -", this.managementCommissionformula);
  }

  setDefalutTaxFormula(value: string[]) {
    this.defaultTaxFormula = value;
  }

  setCleanFeeFormula(value: string[]) {
    this.cleaningFeeFormula = value;
  }

  setPaymentProcessingFeeFormula(value: string[]) {
    this.paymentProcessingFeeFormula = value;
  }

  setPlatformCommissionFormula(value: string[]) {
    this.platformCommissionFormula = value;
  }

  setNetProfitFormula(value: string[]) {
    this.netProfitFormula = value;
  }


  setShowManagementCommissionFormula() {  
    this.showManagementCommissionFormula = !this.showManagementCommissionFormula;
  }

  setShowCleaningFeeFormula() {
    this.showCleaningFeeFormula = !this.showCleaningFeeFormula;
  }

  setShowDefaultTaxFormula() {
    this.showDefalutTaxFormula = !this.showDefalutTaxFormula;
  }

  setShowPaymentProcessingFeeFormula() {
    this.showPaymentProcessingFeeFormula = !this.showPaymentProcessingFeeFormula;
  }

  setShowPlatformComissionFormula() {
    this.showPlatformCommissionFormula = !this.showPlatformCommissionFormula;
  }

  setShowNetProfitFormula() {
    this.showNetProfitFormula = !this.showNetProfitFormula;
  }

  
  
}
