import {Injectable} from '@angular/core';
import {HttpService} from '../core/services/http.service';
import {Observable,throwError} from 'rxjs/index';
import {environment} from '../../environments/environment';
import {HttpErrorResponse} from '@angular/common/http';
import {catchError, tap} from 'rxjs/internal/operators';
 
import {
  createMaintenanceJob,
  createNewProperty,
  deleteBooking, deleteMaintenanceJob,
  deleteMultipleJobs,
  deleteProperty,
  deleteRoomUnit,
  sendMessage,
  getAllByPropertyIdMaintenanceJobs, 
  getAllByRoomObjectIdAndUnitId,
  getAllProperties,
  getBookingsByPropertyId,
  getProperties,
  getPropertiesName,
  getProperty,
  searchProperty,
  getRooms,
  importProperty,
  updateBookingProperty,
  updateProperty,
  updateRoomType,
  updateNotifyUrl,
  removeNotifyUrl,
  checkNotificationReceiving,
  importBookingForMonth,
  saveProcessingFees,
  openProcessingFees,
  cloneExpense,
  setPropertyPricings,
  getAllMaintenanceJobsByRoomId,
  updateMaintenanceJob
} from '../constants/property-api.constants';
import {GenericDto} from '../models/generic-dto';
import {Property} from '../models/property';
import {RoomType} from '../models/RoomType';
import {JobAssign} from '../models/job-assign';
import {
  createJob, deleteJob,
  getAllJobsAssignDetails,
  getAllJobsAssignDetailsByBookingId,
  getAllJobsAssignDetailsByPropertyId, getAssignedJobsById,
  getJobById, getPlannedJobsForCleaner,
  updateJobAssign, updateJobDetail, uploadImage
} from '../constants/job-api.constants';
import {Notification} from '../models/notification';
import {getAllPendingNotification} from '../constants/notification-api.constants';
import {getTopFiveRecentlyAddedBookings, updateBookingStatus} from '../constants/booking-api.constants';
import {Booking} from '../models/booking';
import {MaintenanceJob} from '../models/maintenanceJob';
import { LoaderService } from '../shared/services/loader.service';

@Injectable()
export class PropertyService {
  constructor(private httpService: HttpService, private loaderService: LoaderService) {

  }

  createProperty(data: Property): Observable<GenericDto<Property>> {
    return this.httpService.postAuth<GenericDto<Property>>(environment.API + createNewProperty, data);
  }


  createJob(data: JobAssign): Observable<GenericDto<JobAssign>> {
    return this.httpService.postAuth<GenericDto<JobAssign>>(environment.API + createJob, data);
  }

  getJobAssignDetails(): Observable<GenericDto<JobAssign[]>> {
    return this.httpService.getAuth<GenericDto<JobAssign[]>>(environment.API + getAllJobsAssignDetails);
  }

  getPlannedJobsForCleaner(): Observable<GenericDto<JobAssign[]>> {
    return this.httpService.getAuth<GenericDto<JobAssign[]>>(environment.API + getPlannedJobsForCleaner);
  }

  getJobAssignDetailsByBookingId(id: string): Observable<GenericDto<JobAssign[]>> {
    return this.httpService.getAuth<GenericDto<JobAssign[]>>(environment.API + getAllJobsAssignDetailsByBookingId + '?bookingId=' + id);
  }


  getJobAssignDetailsByPropertyId(id: string): Observable<GenericDto<JobAssign[]>> {
    return this.httpService.getAuth<GenericDto<JobAssign[]>>(environment.API + getAllJobsAssignDetailsByPropertyId + '?propertyId=' + id);
  }


  getJobById(id: string): Observable<GenericDto<JobAssign>> {
    return this.httpService.getAuth<GenericDto<JobAssign>>(environment.API + getJobById + id);
  }

  getPendingNotifications(): Observable<GenericDto<Notification[]>> {
    return this.httpService.getAuth<GenericDto<Notification[]>>(environment.API + getAllPendingNotification);
  }

  updateJob(id: string, cleanerId: string, jobRequest: string): Observable<GenericDto<JobAssign>> {
    return this.httpService.putAuth<GenericDto<JobAssign>>(environment.API + updateJobAssign + '?id=' + id + '&cleanerId = ' + cleanerId + ' &jobRequest=' + jobRequest, jobRequest);
  }


  updateJobDetail(jobAssign: JobAssign): Observable<GenericDto<JobAssign>> {
    return this.httpService.putAuth<GenericDto<JobAssign>>(environment.API + updateJobDetail, jobAssign);
  }
  getAll(): Observable<GenericDto<Property[]>> {
    return this.httpService.getAuth<GenericDto<Property[]>>(environment.API + getPropertiesName);
   // return this.httpService.getAuth<GenericDto<Property[]>>(environment.API + getProperties);
  }


  getAllPropertiesWithRoomType(): Observable<GenericDto<Property[]>> {
    return this.httpService.getAuth<GenericDto<Property[]>>(environment.API + getProperties);
  }

  getAllName(): Observable<GenericDto<Property[]>> {
    return this.httpService.getAuth<GenericDto<Property[]>>(environment.API + getPropertiesName);
  }

  getAllProperties(status: string, ownerId: string): Observable<GenericDto<Property[]>> {
    return this.httpService.getAuth<GenericDto<Property[]>>(environment.API + getAllProperties + '?status=' + status + '&ownerId=' + ownerId);
  }

  get(id: string): Observable<GenericDto<Property>> {
    return this.httpService.getAuth<GenericDto<Property>>(environment.API + getProperty + id);
  }

  searchProperty(keyword: string) {
    return this.httpService.postAuth<GenericDto<Property[]>>(environment.API + searchProperty+'?keyword='+keyword,null);
  }  

  /**
   * Get Rooms by propertyId
   */
  getRooms(id: string): Observable<GenericDto<RoomType[]>> {
    this.loaderService.startLoader();
    return this.httpService.getAuth<GenericDto<RoomType[]>>(environment.API + getRooms + '?propertyId=' + id)
    .pipe(tap(() => {
      this.loaderService.stopLoader(); // Stop loader when response received
    }), catchError((error: HttpErrorResponse) => {
      this.loaderService.stopLoader(); // stop loader if any error occurred
      return throwError(error);
    }));
  }

  createMaintenanceJob(maintenanceJob: any) {
    return this.httpService.postAuth<GenericDto<any>>(environment.API + createMaintenanceJob , maintenanceJob);
  }

  deleteMaintenanceJob(id: string , jobType : string): Observable<GenericDto<any>> {
    return this.httpService.deleteAuth<GenericDto<any>>(environment.API + deleteMaintenanceJob + jobType +'/'+ id);
  }

  getAllMaintenanceJobs(propId: string): Observable<GenericDto<MaintenanceJob[]>> {
    return this.httpService.getAuth<GenericDto<MaintenanceJob[]>>(environment.API + getAllByPropertyIdMaintenanceJobs + '?propertyId=' + propId);
  }

  getAllMaintenanceJobsByRoomIdUnitId(roomId: string ,  unitId:string ): Observable<GenericDto<MaintenanceJob[]>> {
    return this.httpService.getAuth<GenericDto<MaintenanceJob[]>>(environment.API + getAllByRoomObjectIdAndUnitId + roomId +'/'+ unitId );
  }

  /**
   * delete property
   */
  deleteProperty(id: string): Observable<GenericDto<any>> {
    this.loaderService.startLoader();
    return this.httpService.deleteAuth<GenericDto<any>>(environment.API + deleteProperty + id)
    .pipe(tap(() => {
      this.loaderService.stopLoader(); // Stop loader when response received
    }), catchError((error: HttpErrorResponse) => {
      this.loaderService.stopLoader(); // stop loader if any error occurred
      return throwError(error);
    }));
  }

  deleteRoomUnit(roomId:string,id: string,isBookingDeleted:boolean): Observable<GenericDto<any>> {
    return this.httpService.deleteAuth<GenericDto<any>>(environment.API + deleteRoomUnit + roomId+"/"+id+"/"+isBookingDeleted);
  }

  /**
   * Delete unit-item, room-type or property with bookings
   */
  deletePropertyWithBooking(payload): Observable<GenericDto<Property>> {
    return this.httpService.postAuth(environment.API + deleteRoomUnit ,payload);
  }

  deleteJob(id: string): Observable<GenericDto<any>> {
    return this.httpService.deleteAuth<GenericDto<any>>(environment.API + deleteJob + id);
  }

  deleteMultipleJobs(id: any): Observable<GenericDto<any>> {
    return this.httpService.deleteAuth<GenericDto<any>>(environment.API + deleteMultipleJobs + id);
  }


  deleteBooking(id: string): Observable<GenericDto<any>> {
    return this.httpService.deleteAuth<GenericDto<any>>(environment.API + deleteBooking + id);
  }

  /**
   * update property
   */
  updateProperty(property: Property): Observable<GenericDto<any>> {
    return this.httpService.putAuth(environment.API + updateProperty, property);
  }

  /**
     * update RoomType
     */
  updateRoomType(roomType: RoomType,unitId,isCalculationUpdate): Observable<GenericDto<any>> {
    return this.httpService.putAuth(environment.API + updateRoomType+'?unitId='+unitId+"&isCalculationUpdate="+isCalculationUpdate, roomType);
  }

  /**
   * update Booking
   */
  updateBooking(bookingId: string, jobId: string): Observable<GenericDto<any>> {
    return this.httpService.putAuth(environment.API + updateBookingProperty + '?bookingId=' + bookingId + '&jobId=' + jobId, jobId);
  }


  /**
   * update property Asset
   */
  updateAsset(propertyId: string, assetId: string): Observable<GenericDto<any>> {
    return this.httpService.putAuth(environment.API + updateProperty + '?propertyId=' + propertyId + '&assetId=' + assetId, assetId);
  }


  updateBookingStatus(bookingId: any, status: any): Observable<GenericDto<any>> {
    return this.httpService.putAuth(environment.API + updateBookingStatus + '?id=' + bookingId + '&status=' + status, bookingId);
  }


  /**
   * import property
   */
  importProperty(pluginId: string, properties: Property): Observable<GenericDto<Property>> {
    return this.httpService.postAuth(environment.API + importProperty + pluginId, properties);
  }

  /**
   * @author : Suraj Pratap Singh
   * @desc : Get All Bookings by property id to create a new Job;
   * @param Propertyid
   */
  getBookingsByPropertyId(id: string): Observable<GenericDto<Booking[]>> {
    return this.httpService.getAuth<GenericDto<Booking[]>>(environment.API + getBookingsByPropertyId + '?propertyId=' + id);
  }

  getFiveRecentlyAddedBookings(): Observable<GenericDto<Booking[]>> {
    return this.httpService.getAuth<GenericDto<Booking[]>>(environment.API + getTopFiveRecentlyAddedBookings );
  }

  getAssignedJobsByCleanerId(id: string): Observable<GenericDto<JobAssign[]>> {
    return this.httpService.getAuth<GenericDto<JobAssign[]>>(environment.API + getAssignedJobsById + '?cleanerId=' + id);
  }

  updateNotifyUrl(id: String): Observable<GenericDto<String>> {
    return this.httpService.putAuth<GenericDto<String>>(environment.API + updateNotifyUrl +'?propertyId='+id, '');
  }

  removeNotifyUrl(id: String): Observable<GenericDto<String>> {
    return this.httpService.deleteAuth<GenericDto<String>>(environment.API + removeNotifyUrl +'?propertyId='+id);
  }

  checkNotificationReceiving(id: String): Observable<GenericDto<boolean>> {
    return this.httpService.getAuth<GenericDto<boolean>>(environment.API + checkNotificationReceiving + '?propertyId=' + id);
  }

  reImportBookings(id: string, fromMonth: number): Observable<GenericDto<any>>{
    if(id) {
      return this.httpService.postAuth<GenericDto<boolean>>(environment.API + importBookingForMonth + '?propertyId=' + id + '&fromMonth=' + fromMonth, '');
    } else {
      return this.httpService.postAuth<GenericDto<boolean>>(environment.API + importBookingForMonth + '?fromMonth=' + fromMonth, '');
    }
  }

  uploadImage(image: any, id: String) {
    return this.httpService.postAuth<GenericDto<JobAssign>>(environment.API + uploadImage + '/' + id, image);

  }

  saveProcessingFees(id:String, type:any, channels: any){
    return this.httpService.postAuth<GenericDto<any>>(environment.API + saveProcessingFees + '/'+ type +'/' +  id, channels);
  }
  getPlatformFees (propertyId:String, type:any) {
    return this.httpService.getAuth<GenericDto<any>>(environment.API +  openProcessingFees +'/'+ type +'/' + propertyId);
  }

  setPropertyPricings (oldPropertyId: string, changesFor: any, propertyIds: any, unit: any, roomId: any, unitId: any, isCalculationUpdate): Observable<GenericDto<any>> {
    return this.httpService.postAuth<GenericDto<any>>(environment.API + setPropertyPricings + '?oldPropertyId=' + oldPropertyId + '&changesFor=' + changesFor + '&unit=' + unit + '&roomId=' + roomId +'&unitId=' + unitId+' &isCalculationUpdate='+ isCalculationUpdate, propertyIds);
  }

  cloneExpense (oldExpenseId: string, jobType:string,  propertyIds: any): Observable<GenericDto<any>> {
    return this.httpService.postAuth<GenericDto<any>>(environment.API + cloneExpense + '?maintenanceJobId=' + oldExpenseId+'&jobType='+jobType , propertyIds);
  }

  getAllMaintenanceJobsByRoomId(roomId: string, fromDate: any, toDate: any) {
    return this.httpService.getAuth<GenericDto<MaintenanceJob[]>>(environment.API + getAllMaintenanceJobsByRoomId + '?roomId=' + roomId + '&fromDate=' + fromDate + '&toDate=' + toDate);
  }

  getAllMaintenanceJobsByRoomIds(roomId: string[]) {
    return this.httpService.postAuth<GenericDto<MaintenanceJob[]>>(environment.API + '/maintenanceJob/get-by-roomId-list', roomId);
  }

  updateMaintenanceJob(maintenanceJob: any): Observable<GenericDto<any>> {
    return this.httpService.postAuth<GenericDto<any>>(environment.API + updateMaintenanceJob, maintenanceJob );
  }

  getPlatformList(): Observable<any>{
    return this.httpService.getAuth(environment.API + "/booking/getPlatforms");
  }
}
