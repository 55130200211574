import {Component, OnInit} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {HttpErrorResponse} from '@angular/common/http';
import {GenericDto} from '../../../models/generic-dto';
import {inviteMessage, success, successUserUpdated} from '../../../constants/notification-messages.constant';
import {ActivatedRoute, Router} from '@angular/router';
import {User} from '../../../models/user';
import {DataService} from '../../../shared/services/data.service';
import {UserService} from '../../../services/user.service';
import {emailValidator} from '../../../validators/email.validator';
import {FormGroup, FormControl, FormBuilder, Validators, FormArray} from '@angular/forms';
import {TokenService} from '../../../core/services/token.service';
import {contactNumbersValidator} from '../../../validators/number.validator';
import {AuthService} from '../../../services/auth.service';
import {StorageService} from '../../../core/services/storage.service';


export interface Currency {
  name: string;
  code: string;
}


@Component ({
  selector: 'app-update-profile',
  templateUrl: './update-profile.component.html',
  styleUrls: ['./update-profile.component.scss']
})

export class UpdateProfileComponent implements OnInit {
  updateUserProfile: FormGroup;
  user: User;
  isLoading = false;
  editForm = false;
  emptyState = true;
  currentCurrency : Currency;
  currencies: Currency[] = [
    { name: 'US Dollar', code: 'USD' },
    { name: 'EURO', code: 'EUR' },
    { name: 'Canadian Dollar', code: 'CAD' },
    { name: 'British Pound', code: 'GBP' },
    { name: 'Australian Dollar', code: 'AUD' }
  ];

  phoneNumber = new FormControl('', [Validators.required, Validators.compose([contactNumbersValidator])]);
  constructor(private fb: FormBuilder, private router: Router,
              private toastrService: ToastrService, private userService: UserService,
              private storageService: StorageService,
              private dataService: DataService, private route: ActivatedRoute , private tokenService: TokenService) {

  }

  ngOnInit() {
    if (this.router.url.includes('/edit')) {
      // create filled form
      this.editForm = true;
      this.isLoading = true;
      this.route.params.subscribe(() => {
        this.userService.getUser()
         .subscribe((response: GenericDto<User>) => {
            if (response.code === 200) {

              this.user = response.payload as User;
              this.updateUserForm();
              // create filled Form
             // this.userFillForm();
              this.emptyState = false;
            } else {
              // show error message
            }
            this.isLoading = false;
          }, (error: HttpErrorResponse) => {
            // show error message
            this.isLoading = false;
          });
      });
    } else {
      this.emptyState = false;
      this.updateUserForm();
    }

    // initlize variables here

    // console.log(this.router.url);
    // create filled form
    // this.isLoading = true;
    // this.createCleanerForm();
  }

  private updateUserForm() {
    let formCurrency: Currency;
    for(let currency of this.currencies) {
      if(currency.code === this.user.currency){
       formCurrency = currency;
       this.currentCurrency = currency;
       // console.log('hre')
       //this.currentCurrency = currency.
      }
    }
    if(!formCurrency) {

      formCurrency = { name: 'US Dollar', code: 'USD' };
    }
    //console.log(formCurrency)
    this.updateUserProfile = this.fb.group({
      firstName: [this.user.firstName, [Validators.required]],
      lastName: [this.user.lastName, [Validators.required]],
      companyName: [this.user.companyName,  ],
      phoneNumber: new FormControl(this.user.phoneNumber, [Validators.compose([contactNumbersValidator])]),
      email: new FormControl(this.user.email, [Validators.required, Validators.compose([emailValidator])]),
      currency: [formCurrency.code, [Validators.required]],
    });

  }


  goBack() {
      this.router.navigate(['/dashboard/profile']);
  }

  updateUser(): void {

    if (this.updateUserProfile.valid) {
      // call cleaner creation API here
      this.isLoading = true;
      let isEmailChanged = false;
      {
        // update property
        this.user.firstName = this.updateUserProfile.get('firstName').value;
        this.user.lastName = this.updateUserProfile.get('lastName').value;
        this.user.phoneNumber = this.updateUserProfile.get('phoneNumber').value;
        this.user.currency = this.updateUserProfile.get('currency').value;
        this.user.companyName = this.updateUserProfile.get('companyName').value;

        
        if (this.updateUserProfile.get('email').value !==  this.user.email) {
          this.user.email = this.updateUserProfile.get('email').value;
          isEmailChanged = true;
        }
        console.log(this.user.currency);
        this.userService.updateUser(this.user).subscribe((response: GenericDto<any>) => {
          if (response.code === 200) {
         //   this.tokenService.saveToken(response.payload)
            this.toastrService.success(successUserUpdated, success, {
              timeOut: 3000,
              onActivateTick: true
            });
            if (isEmailChanged) {
              this.storageService.clearAll();
              this.router.navigate(['/login']);
            } else {
              this.dataService.customEvent.next();
              this.router.navigate(['/dashboard/profile']);
            }
          } else {
            // show errors here
          }
          this.isLoading = false;
        }, (error: HttpErrorResponse) => {
          this.isLoading = false;
          // show errors here
        });

      }
    } else {
      // throw warning

    }
  }

  userFillForm(): void {
    Object.keys(this.updateUserProfile.controls).forEach((key: string) => {
      if (!!this.updateUserProfile.get(key)) {
        this.updateUserProfile.get(key).setValue(this.user[key]);
      }
    });
  }
}
