import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatTableDataSource, MatPaginator, MatSort, MatDialog } from '@angular/material';
import { MaintenanceJob } from 'src/app/models/maintenanceJob';
import { PropertyService } from 'src/app/services/property.service';
import { success } from 'src/app/constants/notification-messages.constant';
import { ToastrService } from 'ngx-toastr';
import { MaintenanceJobDetailsComponent } from '../../property/property-details/maintenance-job-details/maintenance-job-details.component';
import { UserService } from 'src/app/services/user.service';
import { User } from 'src/app/models/user';

@Component({
  selector: 'app-revenue-expense',
  templateUrl: './revenue-expense.component.html',
  styleUrls: ['./revenue-expense.component.scss']
})
export class RevenueExpenseComponent implements OnInit {
  dataSource;
  hasMaintenanceJobs: boolean = false;
  maintenanceJobs: any;
  displayColumns = ['unitName', 'jobTitle', 'Job Date', 'amount', 'taxInPercent', 'totalPayable', 'Details','action'];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private dialogRef: MatDialogRef<RevenueExpenseComponent>,
              @Inject(MAT_DIALOG_DATA)
              private data: any,
              private propertyService: PropertyService, private toastrService: ToastrService, private dialog: MatDialog,
              private userService: UserService) {
    console.log(this.data);

    if(this.data.maintenanceJobs && this.data.maintenanceJobs !== undefined && this.data.maintenanceJobs !== null) {
      if(this.data.maintenanceJobs.length > 0) {
        this.hasMaintenanceJobs = true;
        this.maintenanceJobs = this.data.maintenanceJobs;
        this.dataSource = new MatTableDataSource(this.data.maintenanceJobs);
      } else {
        this.hasMaintenanceJobs = false;
      }
    }
  }

  ngOnInit() {

  }
  deleteJob(id: any , jobType : string) {
    this.propertyService.deleteMaintenanceJob(id , jobType).subscribe(response => {
      if (response.code === 200) {
        this.toastrService.success(' Job Successfully Deleted!', success, {
          timeOut: 3000,
          onActivateTick: true
        });
      }
    });
  }

  showDetails(id: any) {
    let room = new Array();
    console.log('here')
    let currentJob: any;
    for(let job of this.maintenanceJobs) {
      if(job.id === id) {
        currentJob = job;
      }
    }
   if(currentJob) {
     console.log(currentJob);
    this.userService.getUser().subscribe(user => {
      if(user.payload.role === 'ADMINISTRATOR') {
        this.userService.getPropertiesById(user.payload.id).subscribe (properties => {
            for (let index = 0; index < properties.payload.length; index++) {
              const property = properties.payload[index];
              if(property.id === currentJob.roomObjectId) { room.push(property); break;}
            }
            this.showMaintenanceJob(currentJob, room);
        });
      } else if(user.payload.role === 'OWNER') {
        this.userService.getPropertiesByOwnerId(user.payload.id).subscribe (properties => {
          for (let index = 0; index < properties.payload.length; index++) {
            const property = properties.payload[index];
            if(property.id === currentJob.roomObjectId) { room.push(property);  break;}
          }
          this.showMaintenanceJob(currentJob, room);
        });
      }
    });
    }
  }

  showMaintenanceJob(currentJob, room) {
    if(room && room.length > 0 && room !== undefined && room !== null) {
      const dialogRef = this.dialog.open(MaintenanceJobDetailsComponent , {
        width: '70%',
        height: '90%',
        data: { selectedJob: currentJob, room: room }
      });
      dialogRef.afterClosed().subscribe(() => {
      });
     }

  }
}

