import {NgModule} from '@angular/core';
import {PropertyDashboardComponent} from './property.component';
import {PropertyFormModule} from './property-form/property-form.module';
import {PropertyDetailsModule} from './property-details/property-details.module';
import {SharedModule} from '../../shared/shared.module';
import {RouterModule} from '@angular/router';
import {GalleryModule} from './gallery/gallery.module';
import {PluginsModule} from './plugins/plugins.module';
import {NgSlimScrollModule, SLIMSCROLL_DEFAULTS} from 'ngx-slimscroll';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ExpenseComponent } from './expense/expense.component';
import { BrowserModule } from '@angular/platform-browser';
import { EditExpenseComponent } from './edit-expense/edit-expense.component';
import { NumberInputComponent } from './number-input/number-input.component';
import { PropertyInformationComponent } from './property-information/property-information.component';

@NgModule({
  imports: [
    PropertyFormModule,
    PropertyDetailsModule,
    PluginsModule,
    GalleryModule,
    SharedModule,
    RouterModule,
    NgSlimScrollModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserModule
  ],
  declarations: [PropertyDashboardComponent, ExpenseComponent, EditExpenseComponent, NumberInputComponent, PropertyInformationComponent],
  exports: [PropertyDashboardComponent],
  providers: [{provide: SLIMSCROLL_DEFAULTS, useValue: {
    alwaysVisible: false,
    gridOpacity: '0.2', barOpacity: '0.5',
    gridBackground: '#e7e7e7',
    gridWidth: '6',
    gridMargin: '2px 2px',
    barBackground: '#acb1b5',
    barWidth: '6',
    barMargin: '2px 2px'
  }}]
})
export class PropertyDashboardModule {

}
