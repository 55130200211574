
import { FormControl } from '@angular/forms';

/**
 * Regular Expression for Email
 * @type {RegExp}
 */
const emailRegexp: RegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,2}\.[0-9]{1,2}\.[0-9]{1,2}\.[0-9]{1,2}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

/**
 *
 * @param {FormControl} control
 * @returns {{[p: string]: any}}
 */
export function emailValidator(control: FormControl): { [key: string]: any } {

  if (control.value && !emailRegexp.test(control.value)) {
    return {invalidEmail: true};
  }
}


