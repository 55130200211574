/**
 * Define user api endpoints here
 */
export const getUser = '/user/getUserInfo';

export const createUser = '/user/createProfile';

export const  updateUser =  '/user/update';
export const  updateUserProfile =  '/user/updateUserProfile';

export const  changePassword =  '/user/updatePassword';

export const getAllUsers = '/user/getAllUsers';

export const getUserDetails = '/user/';

export const inviteUser = '/user/invite';

export const previewOwnerPortal = '/public/generate-preview-token';

export const switchUsersUrl = '/public/switch-users';

export const getUserByRole = '/user/getUsersByRole';

export const getOwnerProperties = '/owner/getOwnerProperties';

export const getPropertiesByOwnerId = '/owner/getPropertiesByOwnerId';

export const getOwnerRoomIdUnitIdList = '/owner/getOwnerRoomIdUnitIdList';

export const getOwnerDetails = '/owner/getOwnerDetails';

export const getOwnerRoomUnits = '/owner/getOwnerRoomUnits';

export const getOwnerPersonalBooking = '/owner/getOwnerPersonalBooking';

export const getAllOwnerPersonalBookings = '/owner/getAllPersonalBookingOfOwners';

export const getMonthlyRevenueNotification = '/owner/getMonthlyRevenueNotification';

export const updateOwnerRevenueNotification = '/owner/updateMonthlyRevenueNotification';

export const deleteUser = '/user/';

export const resendConfirmation = '/user/resendInvite';

export const isNewAdmin = '/user/isNewAdmin';

export const getOwnersByAdminId = '/user/ownersByAdmin';

export const getPropertiesByAdmin = '/user/propertiesByAdmin';

export const getAdminPropertiesByAdmin = '/user/adminProperties';

export const totalBookings = '/booking/getTotalBookings';

export const superAdminDashboard = '/user/superAdminDashboard';

export const userBlocking = '/user/block';
export const updateFreeAccountStatus = '/account/updateFreeAccountStatus';

export const sendInviteByEmail = '/user/email';

export const loginToAccount = '/user/loginToAccount';

export const getRevenueOfAllUsers = '/user/allUsersRevenue';

export const getAllAccountUser = '/account/get-all-user-by-account'

export const userAccount = '/account/'


