import {Router} from '@angular/router';
import {Component} from '@angular/core';

@Component ({
  selector: 'app-dashboard-job',
  templateUrl: './job.component.html',
  styleUrls: ['./job.component.scss']
})

export class JobComponent {
  constructor (public router: Router) {}
}
