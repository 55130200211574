/**
 * define all plugins endpoints here
 */
export const getPluginsList = '/plugin/get-all';

export const savePlugin = '/propertyapikey/save';

export const getSavedPluginsList = '/propertyapikey/get-all-api-key';

export const deletePlugin  = '/propertyapikey';

export const importDataApi = '/property/importProperty';


export const importAllDataApi = '/property/importBooking';
export const startPropertySync = '/property/startPropertySync?propertyApiKeyId=';
export const startBookingSync = '/property/startBookingSync?propertyApiKeyId=';
export const singlePropertySync = '/property/singlePropertySync';

export const cancelSync = '/account/cancel-sync-process?accountId=';






export const updatePlugin = '/propertyapikey/update';

export const syncProcess = '/sync-process/find-by-account';
