import {NgModule} from '@angular/core';
import {PropertyDetailsComponent} from './property-details.component';
import {PropertyInfoComponent} from './property-info/property-info.component';
import {SharedModule} from '../../../shared/shared.module';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {NgSlimScrollModule} from 'ngx-slimscroll';
import {RoomInfoComponent} from './room-info/room-info.component';
import {MaintenanceJobComponent} from './maintenance-job/maintenance-job.component';
import { FormsModule } from '@angular/forms';
import {ReactiveFormsModule} from '@angular/forms';
import { ImportBookingComponent } from './import-booking/import-booking.component';
import { MaintenanceJobDetailsComponent } from './maintenance-job-details/maintenance-job-details.component';
import { CopyPropertyChargesComponent } from '../property-form/copy-property-charges/copy-property-charges.component';
import { MaintenanceJobMediaComponent } from './maintenance-job-media/maintenance-job-media.component';
import { DragDropDirective } from './drag-drop.directive';
import { EditExpenseComponent } from '../edit-expense/edit-expense.component';
import { FormulaComponent } from './formula/formula.component';
import { DeletePropertyPopupComponent } from './delete-property-popup/delete-property-popup.component';

@NgModule ({
  declarations: [PropertyDetailsComponent, PropertyInfoComponent, RoomInfoComponent, MaintenanceJobComponent, ImportBookingComponent, MaintenanceJobDetailsComponent, MaintenanceJobMediaComponent, DragDropDirective, FormulaComponent, DeletePropertyPopupComponent],
  imports: [ReactiveFormsModule, FormsModule, CommonModule, SharedModule, RouterModule, NgSlimScrollModule],
  providers: [],
  entryComponents: [ImportBookingComponent, MaintenanceJobDetailsComponent, CopyPropertyChargesComponent, MaintenanceJobMediaComponent, DeletePropertyPopupComponent, EditExpenseComponent],
    exports: [PropertyDetailsComponent, MaintenanceJobComponent, PropertyInfoComponent, MaintenanceJobMediaComponent,DeletePropertyPopupComponent, FormulaComponent]
})

export class PropertyDetailsModule {
}
