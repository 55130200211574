import {OwnerDashboardComponent} from './ownerDashboard.component';
import {RouterModule} from '@angular/router';
import {CommonModule} from '@angular/common';
import {SharedModule} from '../shared/shared.module';
import {AssetsService} from '../services/assets.service';
import { NgModule } from '@angular/core';
import {OwnerDashboardRoutingModule} from './ownerDashboard-routing.module';


@NgModule({
  declarations : [OwnerDashboardComponent],
  imports : [RouterModule, SharedModule, CommonModule, OwnerDashboardRoutingModule],
  providers : [AssetsService],
  exports : [OwnerDashboardComponent],
  entryComponents: [OwnerDashboardComponent]
})
export class OwnerDashboardModule {
}
