import { Injectable } from '@angular/core';
import { BehaviorSubject,Subject  } from 'rxjs';



@Injectable({ providedIn: 'root' })
export class SharedService {

    private sidePanelEndNext = new BehaviorSubject({});
sPanelEndNext = this.sidePanelEndNext.asObservable();

    sidePanelEndAndStartNext(data: any): void {
        console.log("QWERTY",data);
        this.sidePanelEndNext.next(data);
    }
    checkPermission(permissions:any[], resource:string, access: string){
        if(permissions == null){
          //TODO CHANGE
          return true;
        }
        let havePermission = false;
        permissions.forEach(permission =>{
          if(permission.name == resource){
            havePermission = permission[access]
          }
        })
        return havePermission;
    }


    //------------
    public message = new Subject<string>();
    setMessage(value: string) {
    this.message.next(value); 
  }

  // The setshareAndCommunicate method is a generic function used for facilitating communication and data sharing between two or more components.
  // It allows components to exchange information in a decoupled and efficient manner.
  // An example of its usage can be found in the sidebar component, where events are registered to share data, and in the owner-dashboard-home component,
  // where the shared data is consumed and utilized for updating the component's state or behavior.
  public shareAndCommunicate = new Subject<any>();
    setshareAndCommunicate(value: any) {
    this.shareAndCommunicate.next(value); 
  }

  //-------------change first character in capital letter ----------------------
  capitalizeFirstLetterEachWord(str) {
    return str.replace(/\b\w/g, match => match.toUpperCase());
}

 

calculateRemainingDays(expirationDate: number): number {
  // Get current UNIX timestamp
  const currentTimestamp = Math.floor(Date.now() / 1000);
  // Calculate remaining seconds until expiration
  const remainingSeconds = expirationDate - currentTimestamp;
  // Convert remaining seconds to days
  return Math.ceil(remainingSeconds / (24 * 60 * 60));
}

}