import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TokenService } from 'src/app/core/services/token.service';
import { OwnerService } from 'src/app/services/owner.service';
import { UserService } from 'src/app/services/user.service';
import { DataService } from 'src/app/shared/services/data.service';
import { emailValidator } from 'src/app/validators/email.validator';
import { contactNumbersValidator, numbersOnlyValidator } from 'src/app/validators/number.validator';
import * as _ from 'lodash'
import { ToastrService } from 'ngx-toastr';
import { MatCheckboxChange } from '@angular/material';
@Component({
  selector: 'app-staff-details',
  templateUrl: './staff-details.component.html',
  styleUrls: ['./staff-details.component.scss']
})
export class StaffDetailsComponent implements OnInit {
  editForm:boolean=false;
  addStaffForm: any;
  emptyState = true;
  inviteUserEdit = true;
  isRoleSelected=true;
  isLoad=true;
  isLoading=false;
  listofUser:any[]= []
  payload:any={};
  user:string='STAFF';
  configurationList: any = ['PROPERTY', 'BOOKING', 'PMS', 'OWNER', 'BILLING']
  typeUser=[{key:'STAFF',value:'STAFF'},{key:'ADMINISTRATOR',value:'ADMINISTRATOR'} ]
  Permissions: any= {
    "PROPERTY":{
   "view":"" ,
   "edit":"" ,
   "delete":"" ,
},
   "BOOKING":{
    "view":"" ,
  "edit":"" ,
  "delete":"",
  },
  "PMS_Page_Settings":{
    "view":"" ,
    "edit":"" ,
    "delete":"",
  },
  "OWNER":{
    "view":"",
    "edit":"" ,
    "delete":"" ,
  },
  "BILLING":{
  "manage":"" ,
}
  }
  permissionsKey: any;
  data:any;
  userId: string;
  constructor(private toastrService: ToastrService, private fb: FormBuilder,private tokenService: TokenService , private ownerService:OwnerService,private router: Router, private userService: UserService, private dataService:DataService,private _route: ActivatedRoute) {
    _route.params.subscribe(val => {
      // put the code from `ngOnInit` here
      this.staffDetails(val.id);
    });
   }

ngOnInit() {
    
}
configPermission(response) {
  const approvalArray = [];
  _.each(this.configurationList, (configuration) => {
    const formGroup = new FormGroup({});
    if(configuration !='BILLING'){
    formGroup.addControl('name', new FormControl(configuration, Validators.required));
    formGroup.addControl('view', new FormControl(false, Validators.required));
    formGroup.addControl('edit', new FormControl(false, Validators.required));
    formGroup.addControl('delete', new FormControl(false, Validators.required));
    approvalArray.push(formGroup);
    }else{
    formGroup.addControl('name', new FormControl(configuration, Validators.required));
    formGroup.addControl('manage', new FormControl(false, Validators.required));
    approvalArray.push(formGroup);
    }

  })
  return approvalArray;
}

createStaff(){
  this.userId = this._route.snapshot.paramMap.get('id');
  this.payload = {
    "firstName":this.addStaffForm.controls['firstName'].value ,
    "lastName":this.addStaffForm.controls['lastName'].value ,
    "email":this.addStaffForm.controls['email'].value ,
    "phoneNumber":this.addStaffForm.controls['phoneNumber'].value ,
    "userType": this.addStaffForm.controls['userType'].value,
    "permissions":this.addStaffForm.value.configPermission
  }
  //console.log(this.userId);
  if(this.userId!=null){
    this.payload.id=this.userId;
    this.userService.updateUserAccount(this.payload).subscribe((res)=>{
      if(res.code == 200){
        this.toastrService.success("User updated Succesfully");
      }else if(res.code == 404){
        this.toastrService.error("User Not found");
      }
      this.dataService.customEvent.next();
      this.router.navigate(['/dashboard/staff']);
    })
  }else{
    this.userService.InviteNewAccount(this.payload).subscribe((res)=>{
      if(res.code == 200){
        this.toastrService.success("User Created Succesfully");
      }else if(res.code == 409){
        this.toastrService.info(res.desc);
      }
      this.dataService.customEvent.next();
      this.router.navigate(['/dashboard/staff']);
    })
  }
}

staffDetails(id: string){
  this.userId= id;
  // this.editForm=false;
  this.addStaffForm = this.fb.group({
    firstName: ['', [Validators.required]],
    lastName: ['', [Validators.required]],
    phoneNumber: new FormControl(null),
    email: new FormControl('', [Validators.required, Validators.compose([emailValidator])]),
    configPermission: this.fb.array(this.configPermission([])),
    userType:new FormControl(''),
  });
  if (this.router.url.includes('/edit/')) {
    this.userService.getUserDetails(this.userId).subscribe((res)=>{
      //console.log("Get User")
    })
    this.userService.getUserDetails(this.userId).subscribe((res)=>{
      //console.log("Get User", res.payload);
      this.editForm = true;
      this.addStaffForm.patchValue({
       firstName: res.payload.firstName,
       lastName: res.payload.lastName,
       phoneNumber: res.payload.phoneNumber,
       email: res.payload.email,
       configPermission: res.payload.permissions,
       userType: res.payload.userType,
      });
    })
    const myControl = this.addStaffForm.get('email');
    myControl.disable();
  }
  this.isLoad = false;
  this.isLoading = false;
}


showDatePicker(ev){

}
goBack(): void {
  this.router.navigate(['/dashboard/staff']);
}

changePermission(event: MatCheckboxChange, item: FormGroup, tab: string) {
  
  if (tab === 'edit') {
    if (event.checked) {
      // If edit is checked, automatically check view and disable it
      item.controls.view.setValue(true);
      item.controls.view.disable();
      item.value.view = true;
    } else {
      // If edit is unchecked, enable the view checkbox
      item.controls.view.enable();
    }
  }
 
}




onUserTypeChange(ev){

}
}

