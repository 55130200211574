import { NgModule } from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {RegistrationComponent} from './registration/registration.component';
import {ResetPasswordComponent} from './login/reset-password/reset-password.component';
import {LoginComponent} from './login/login.component';
import {AuthGuard} from './core/guards/auth.guard';
import {ResetGuard} from './core/guards/reset.guard';
import {VerifyAccountComponent} from './verify-account/verify-account.component';
import {ActiveAccountGuard} from './core/guards/active.guard';
import {ActivateAccountComponent} from './account/activate-account.component';
import {AccessDeniedComponent} from './403/access-denied.component';

const routes: Routes = [
  {path: '', component: LoginComponent, canActivate: [AuthGuard]},
  {path: 'login', component: LoginComponent, canActivate: [AuthGuard]},
  {path: 'registration', component: RegistrationComponent},
  {path: 'resetPassword', component: ResetPasswordComponent, canActivate: [ResetGuard]},
  {path: 'accountPassword', component: ResetPasswordComponent, canActivate: [ResetGuard]},
  {path: 'verifyAccount', component: VerifyAccountComponent, canActivate: [ActiveAccountGuard]},
  {path: 'activateAccount', component: ActivateAccountComponent},
  {path: 'forbidden', component: AccessDeniedComponent}

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
