import {NgModule} from '@angular/core';
import {AddCleanerComponent} from './add-cleaner.component';
import {ReactiveFormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {SharedModule} from '../../../shared/shared.module';
import {UserService} from '../../../services/user.service';

@NgModule ({
  declarations: [AddCleanerComponent],
  imports: [ReactiveFormsModule, CommonModule, SharedModule],
  providers: [UserService],
  exports: [AddCleanerComponent]
})

export class AddCleanerModule {
}
