import { Component, EventEmitter, Inject, Input, Output, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog, MatTableDataSource, MatPaginator } from '@angular/material';
import { DataService } from '../../services/data.service';
import { PropertyService } from '../../../services/property.service';
import { JobManageComponent } from '../../../dashboard/job/job-manage/job-manage.component';
import { GenericDto } from '../../../models/generic-dto';
import { JobAssign } from '../../../models/job-assign';
import { HttpErrorResponse } from '@angular/common/http';
import { ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { SelectionModel } from '@angular/cdk/collections';
import { info, success } from '../../../constants/notification-messages.constant';
import { TokenService } from '../../../core/services/token.service';
import { Property } from '../../../models/property';
import * as moment from 'moment';
import {AuthGuard} from "../../../core/guards/auth.guard";
import {OwnerService} from "../../../services/owner.service";

@Component({
  selector: 'app-job-details-modal',
  templateUrl: './job-details-modal.component.html',
  styleUrls: ['./job-details-modal.component.scss'],

})

export class JobDetailsModalComponent implements OnInit {
  isLoading = false;
  @Input() event: any;
  @Input() jobAssigns: JobAssign[];
  jobIds = [];
  dataSource;
  selection = new SelectionModel(true, []);
  @ViewChild(MatPaginator) paginator: MatPaginator;
  displayedColumns: any[];
  emptyState = false;
  jobLength: boolean;
  showAdmin = true;
  showbookingAdmin = true;
  showBookingAmount = true;
  updateDisable: boolean;
  disablePersonalBookings: boolean;
  enableBookings: boolean;
  property: Property;
  endDate: Date;
  showCreateNewJob = false;
  ownerPermission=[];

  @Output() reloadevents = new EventEmitter<any>();
  constructor(public dialogRef: MatDialogRef<JobDetailsModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { event: any }, public dialog: MatDialog, private dataService: DataService,
              private propertService: PropertyService, private toastrService: ToastrService, public tokenService: TokenService,
              public authGuard: AuthGuard, public ownerService: OwnerService) {
    this.endDate = data.event.lastNight;
    data.event.start = moment.unix(data.event.firstNight).set({hour: 12, minute: 0}).utc().format('YYYY-MM-DD');
//    data.event.endDate = moment.unix(data.event.lastNight).add('days', 1).format('YYYY-MM-DD');
    data.event.endDate = moment.unix(data.event.lastNight).set({hour: 12, minute: 0}).utc().add(1, 'day').format('YYYY-MM-DD');

    this.event = data.event;

    if (this.event.status === '0'){
      this.event.status = 'Cancelled'
    } else if (this.event.status === '1'){
      this.event.status = 'Confirmed'
    } else if (this.event.status === '2'){
      this.event.status = 'New'
    } else if (this.event.status === '3'){
      this.event.status = 'Request'
    } else if (this.event.status === '4'){
      this.event.status = 'Black'
    } else {
      this.event.status = 'Unknown'
    }
  }
   

  ngOnInit() {
    this.showCreateNewJob = false;
    this.propertService.get(this.event.guestPropertyId)
      .subscribe((response: GenericDto<any>) => {
        if (response.code === 200) {
          this.property = response.payload;
        }
      },
        (error: HttpErrorResponse) => {
          // show errors here
        });


    if (this.tokenService.getCurrentUser() === 'OWNER' || this.tokenService.getCurrentUser() === 'CLEANER') {

      // this.ownerService.getOwnerDetailPermissions().subscribe(permission => {
      //     this.ownerPermission = permission.payload['columnPermissions'];
      // });       

      if(this.authGuard.isPreviewLogin()){
        this.ownerPermission = this.tokenService.getPreviewOwner().permission;         
      }else{
        this.ownerService.getOwnerDetailPermissions().subscribe(permission => {        
          this.ownerPermission = permission.payload['columnPermissions'];         
      });
      }


      this.showAdmin = false;
      this.displayedColumns = ['endingDate', 'jobTime', 'cleanerName', 'status'];
      this.updateDisable = false;
      if (this.event.ownerId == null || this.event.ownerId === '') {
        this.disablePersonalBookings = true;
        this.showbookingAdmin = true;
      } else {
        this.enableBookings = true;
        this.showbookingAdmin = false;
      }
    } else {
      this.displayedColumns = ['select', 'endingDate', 'jobTime', 'cleanerName', 'status', 'edit'];
      this.updateDisable = true;
      this.enableBookings = false;
      this.showBookingAmount = false;
      if (this.event.ownerId == null || this.event.ownerId === '') {
        this.disablePersonalBookings = true;
        this.showbookingAdmin = true;
      } else {
        this.enableBookings = true;
        this.showbookingAdmin = false;
      }

      this.showCreateNewJob = (moment(new Date()).isBefore(new Date(this.event.endDate)));
    }

    this.loadJobdetails();
  }


  hide() {
    this.dialogRef.close();
  }



  createNewJobDialog(status: any, row: any) {
   console.log(this.event.endDate)
   if(moment(new Date()).isBefore(new Date(this.event.endDate))) {
    const dialogRef = this.dialog.open(JobManageComponent, {
      width: '200%',
      height: '65%',
      data: {
        minDate: moment().format('YYYY-MM-DD'),
        event: this.event,
        status: status,
        row: row.id

      }
    });
    dialogRef.afterClosed().subscribe(() => {
      this.loadJobdetails();
      this.jobIds = [];
    });
   } else {
     this.toastrService.info("Cannot create Job For dates in past", info, {
      timeOut: 3000,
      onActivateTick: true
      })
     return;
   }

  }



  deleteBooking(): void {
    if (confirm("Are you sure you want to delete this Booking ?")) {

      this.propertService.deleteBooking(this.event.id)
        .subscribe((response: GenericDto<any>) => {
          if (response.code === 200) {
            this.hide();
            this.toastrService.success('Delete Booking Successfully', success, {
              timeOut: 3000,
              onActivateTick: true
            });
          }
        },
          (error: HttpErrorResponse) => {
            // show errors here
          });
    }
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }


  isBigEnough(element, index, array) {
    return (element >= 10);
  }

  toggle(selected: any, selectedRow: boolean, row: any, hasValue: boolean) {

    if (hasValue) {
      this.jobIds.push(row.id);
    } else {
      this.jobIds = [];
    }
    if (selectedRow === false) {
      for (let i = 0; i < this.jobIds.length; i++) {
        if (row.id === this.jobIds[i]) {
          this.jobIds.splice(i, 1);
        }
      }
    }
  }

  deleteJobs(): void {

    if (this.jobIds === null || this.jobIds.length === 0) {
      this.toastrService.info('Please select Atleast one Job', info, {
        timeOut: 3000,
        onActivateTick: true
      });
    } else {
      this.propertService.deleteMultipleJobs(this.jobIds)
        .subscribe((response: GenericDto<JobAssign[]>) => {
          if (response.code === 200) {
            this.toastrService.success('Delete Jobs Successfully', success, {
              timeOut: 3000,
              onActivateTick: true
            });
            this.loadJobdetails();
            this.jobIds = [];
            this.selection.clear();
          }
        },
          (error: HttpErrorResponse) => {
            // show errors here
          });
    }
  }

  masterToggle() {
    this.jobIds = new Array();
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => {
        this.jobIds.push(row.id);
        this.selection.select(row);
      });
  }

  loadJobdetails(): void {
    this.propertService.getJobAssignDetailsByBookingId(this.event.id)
      .subscribe((response: GenericDto<JobAssign[]>) => {
        if (response.code === 404) {
          this.jobAssigns = response.payload;
          this.dataSource = new MatTableDataSource(this.jobAssigns);
          this.jobLength = false;
        }
        if (response.code === 200) {
          if (response.payload.length > 0) {
            this.jobAssigns = response.payload;
            /*  moment.unix(data.event.lastNight ).add('days', 1 ).format('YYYY-MM-DD');*/
            for (let job of this.jobAssigns) {
              job.checkOutDate = moment.unix(job.checkOutDate).add('days', 1).format('YYYY-MM-DD');


            }
            this.dataSource = new MatTableDataSource(this.jobAssigns);
            this.dataSource.paginator = this.paginator;
            this.jobLength = true;
            this.jobAssigns.forEach((jobAssignable: JobAssign) => {
              if (this.tokenService.getCurrentUser() === 'CLEANER') {
                if (jobAssignable.jobRequest === 'PLANNED') {
                  jobAssignable.statusCheck = false;
                  jobAssignable.statuscheck1 = true;
                  jobAssignable.statuscheck2 = false;
                } else if (jobAssignable.jobRequest === 'COMPLETED') {
                  jobAssignable.statusCheck = true;
                  jobAssignable.statuscheck1 = false;
                  jobAssignable.statuscheck2 = false;
                } else if (jobAssignable.jobRequest === 'REJECTED') {
                  jobAssignable.statusCheck = true;
                  jobAssignable.statuscheck1 = false;
                  jobAssignable.statuscheck2 = false;
                } else {
                  jobAssignable.statusCheck = false;
                  jobAssignable.statuscheck1 = false;
                  jobAssignable.statuscheck2 = true;
                }

              } else {
                jobAssignable.statusCheck = true;
              }
            });

          } else {
            this.jobLength = false;
            this.emptyState = true;
          }
        } else {
          // show errors here
        }
      },
        (error: HttpErrorResponse) => {
          // show errors here
        });
  }

}
