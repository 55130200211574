import {NgModule} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import {RouterModule} from '@angular/router';
import {SharedModule} from '../../shared/shared.module';
import { StaffDetailsComponent } from './staff-details/staff-details.component';
import { StaffListComponent } from './staff-list/staff-list.component';
import { StaffComponent } from './staff.component';


@NgModule ({
  imports: [RouterModule, SharedModule,BrowserModule, FormsModule,
    ReactiveFormsModule],
  declarations: [StaffComponent,StaffListComponent,StaffDetailsComponent],
  exports: [StaffComponent]
})

export class StaffModule {}