import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {LoginComponent} from './login.component';
import {CommonModule} from '@angular/common';
import {SharedModule } from '../shared/shared.module';
import {AuthService} from '../services/auth.service';
import {ForgotPasswordModule} from './forgot-password/forgot-password.module';
import {ResetPasswordModule} from './reset-password/reset-password.module';

@NgModule({
  imports: [ForgotPasswordModule, ResetPasswordModule, ReactiveFormsModule, CommonModule, SharedModule, FormsModule],
  declarations: [LoginComponent],
  providers: [AuthService],
  exports: [LoginComponent],
  entryComponents: []
})
export class LoginModule {
}
