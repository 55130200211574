import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BillingService } from '../billing.service';

@Component({
  selector: 'app-billing-checkout-success',
  templateUrl: './billing-checkout-success.component.html',
  styleUrls: ['./billing-checkout-success.component.scss']
})
export class BillingCheckoutSuccessComponent implements OnInit {
  session_id: any;

  constructor(private route: ActivatedRoute,private billingService:BillingService) {
    this.route.queryParamMap.subscribe((param:any)=>{
      this.session_id=param.params.session_id;
      this.billingService.billingPortal(this.session_id).subscribe(res=>{
        console.log("billingPortal",res)
      })
    })
   }

  ngOnInit() {
  }

}
