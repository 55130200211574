import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {ReactiveFormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {OwnerService} from '../../../../services/owner.service';
import {SharedModule} from '../../../../shared/shared.module';
import {OwnerPropertiesComponent} from './owner-properties.component';

@NgModule ({
  imports: [ReactiveFormsModule, RouterModule, SharedModule, CommonModule ],
  declarations: [OwnerPropertiesComponent],
  providers: [OwnerService],
  exports: [OwnerPropertiesComponent]
})

export class OwnerPropertiesModule {}
