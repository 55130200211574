export const createJob = '/job/save';
export const getAllJobsAssignDetails = '/job/getJobsByUsers';
export const getAllJobsAssignDetailsByPropertyId = '/job/getJobsByPropertyId/';
export const getAllJobsAssignDetailsByBookingId = '/job/getJobsByBookingId/';
export const getJobById = '/job/';
export const updateJobAssign = '/job/updateJob';
export const deleteJob = '/job/';
export const updateJobDetail = '/job/updateJobDetail';
export const getAssignedJobsById = '/job/getAssignedJobsByCleanerId';
export const getPlannedJobsForCleaner = '/job/getPlannedJobsForCleaner';
export const getJobsByJobRequest = '/job/status/';
export const updateJob = '/job/updateJob';
export const uploadImage = '/job/image';




