/**
 * To allow numbers only in input field call this method on `keypress` event
 */
export function restrictText(event: any) {
  const pattern = /[0-9]/;
  const inputChar = String.fromCharCode(event.charCode);
  if (!pattern.test(inputChar)) {
    // invalid character , prevent input
    event.preventDefault();
  }
}
