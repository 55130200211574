import {
  Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, Renderer2,
  ViewChild
} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {emailValidator} from '../../validators/email.validator';
import {GenericDto} from '../../models/generic-dto';
import {HttpErrorResponse} from '@angular/common/http';
import {AuthService} from '../../services/auth.service';
import {errorMessage, success} from '../../constants/notification-messages.constant';
import {ToastrService} from 'ngx-toastr';
import {Router} from '@angular/router';
import {OtpDto} from '../../models/otp-dto';
import {ModalDirective} from 'angular-bootstrap-md';
import {LoaderService} from '../../shared/services/loader.service';
import {restrictText } from '../../utils/restrictText';
import {Timer} from '../../utils/timer';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {
  verifyEmailForm: FormGroup;
  verifyOtpForm: FormGroup;
  @Output() hide = new EventEmitter<OtpDto>();
  userEmailVerify: boolean;
  email: string;
  isLoading: boolean;
  isLoadingResend: boolean;
  timer: Timer;

  @ViewChild('verifyUserEmailForm') modalForgot: ModalDirective;
  @ViewChild('emailSpy') emailInput: ElementRef;

  constructor(private fb: FormBuilder, private authService: AuthService,
              private toastrService: ToastrService, private router: Router,
              private loaderService: LoaderService, private renderer: Renderer2) {
    this.createVerifyEmailForm();
    this.createVerifyOtpForm();
  }

  ngOnInit() {
    this.timer = new Timer();
    this.timer.resetTimer();

  }

  showModal() {
    this.modalForgot.show();
  }

  private createVerifyEmailForm() {
    this.verifyEmailForm = this.fb.group({
      email: new FormControl('', [Validators.required, Validators.compose([emailValidator])])
    });
  }

  private createVerifyOtpForm() {
    this.verifyOtpForm = this.fb.group({
      otp: new FormControl(null, [Validators.required, Validators.maxLength(4)])
    });
  }

  verifyEmail(): void {
    if (this.verifyEmailForm.valid) {
      // TODO: call auth service
      this.isLoading = true;

      this.authService.verifyEmail(this.verifyEmailForm.getRawValue())
        .subscribe((response: GenericDto<string>) => {

          // response code == 200 then process for OTP generation
          if (response.code === 200) {

            this.email = this.verifyEmailForm.get('email').value;
            // if email is valid then generate OTP
            this.authService.generateOTP(this.verifyEmailForm.getRawValue())
              .subscribe((otpResponse: GenericDto<string>) => {

                // if response code is 200 then show OPT entry view
                if (otpResponse.code === 200) {

                  // show opt modal
                  this.userEmailVerify = true;

                  // show success toastr
                  this.toastrService.success(otpResponse.payload, success, {
                    timeOut: 3000,
                    onActivateTick: true
                  });

                  // start timer for resend
                  this.timer.startTimer();
                } else {
                  // show error for OTP generation error
                  // show error toastr
                  this.toastrService.error(otpResponse.payload, errorMessage, {
                    timeOut: 4000,
                    onActivateTick: true
                  });
                }
                // disable loading variable
                this.isLoading = false;
              }, (error: HttpErrorResponse) => {
                // show error for OTP errors
                // disable loading variable
                this.isLoading = false;
              });
          } else {

            // show error toastr
            this.toastrService.error(response.payload, errorMessage, {
              timeOut: 4000,
              onActivateTick: true
            });

            // show error here
            this.isLoading = false;
          }

        }, (error: HttpErrorResponse) => {
          // show error here

          this.isLoading = false;
        });
    }
  }

  verifyOtp(): void {
    if (this.verifyOtpForm.valid) {
      this.isLoading = true;
      var payload = {otp:this.verifyOtpForm.get('otp').value, email:this.email }
      this.authService.verifyOTP(payload)
        .subscribe((otpResponse: GenericDto<any>) => {
          if (otpResponse.code === 200) {
            // 1. show confirm password component
            this.loaderService.startLoader();
            this.modalForgot.hide();
          } else {
            // show error
            this.verifyOtpForm.reset(true);
          }
          this.isLoading = false;
        }, (error: HttpErrorResponse) => {
          // disable isLoading
          // show error
          this.verifyOtpForm.reset(true);
          this.isLoading = false;
        });
    }
  }

  resendOtp(): void {

    // enable loading
    this.isLoadingResend = true;
    // reset otp form
    this.verifyOtpForm.reset(true);

    // reset timer interval
    this.timer.resetTimer();

    this.authService.generateOTP(this.verifyEmailForm.getRawValue())
      .subscribe((response: GenericDto<string>) => {
          if (response.code === 200) {
            // show success notification
            this.toastrService.success(response.payload, success, {
              timeOut: 3000,
              onActivateTick: true
            });

            // start timer
            this.timer.startTimer();
          } else {

            // show error message
            this.toastrService.error(response.payload, errorMessage, {
              timeOut: 4000,
              onActivateTick: true
            });
          }

          // disable loading
          this.isLoadingResend = false;
        },
        (error: HttpErrorResponse) => {
          this.isLoadingResend = false;
        }
      );
  }

  // to hide modal call this method
  hideModal() {
    if (!!this.isLoading || !!this.isLoadingResend) {
      return;
    } else {
      this.modalForgot.hide();
    }
  }

  // after MDB onHide event trigger it will be called
  onHideModal() {

    // remove mdb counter classes on email input
    if (!this.userEmailVerify) {
      this.renderer.removeClass(this.emailInput.nativeElement, 'counter-danger');
      this.renderer.removeClass(this.emailInput.nativeElement, 'counter-success');
    }

    // reset timer
    this.timer.resetTimer();
    this.userEmailVerify = false;
    if (!!this.verifyOtpForm.get('otp').value) {
      this.hide.emit(this.verifyOtpForm.getRawValue());
    } else {
      this.verifyEmailForm.reset(true);
      this.verifyOtpForm.reset(true);
      this.hide.emit();
    }
  }

  restrictText(event: any) {
    restrictText(event);
  }

  goBack(): void {
    this.timer.resetTimer();
    this.userEmailVerify = false;

  }

  ngOnDestroy() {
    this.timer.resetTimer();
  }
}
