import {Component, OnInit, Input , ViewChild} from '@angular/core';
import {PropertyService} from '../../../../services/property.service';
import {EventService} from '../../../../shared/services/event.service';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatPaginator} from '@angular/material';
import {Router} from '@angular/router';
import {UserService} from '../../../../services/user.service';
import {ToastrService} from 'ngx-toastr';
import {DataService} from '../../../../shared/services/data.service';
import {Inject} from '@angular/core';
import {GenericDto} from '../../../../models/generic-dto';
import {HttpErrorResponse} from '@angular/common/http';
import {OwnerProperty} from '../../../../models/owner-property';
import {MatTableDataSource} from '@angular/material';
import * as moment from 'moment';
import {success, errorMessage} from '../../../../constants/notification-messages.constant';
import {FormBuilder, FormGroup, Validators, FormControl} from '@angular/forms';
import {OwnerService} from '../../../../services/owner.service';
import { Permissions } from './permission.model';






@Component({
  selector: 'app-owner-properties',
  templateUrl: './owner-properties.component.html',
  styleUrls: ['./owner-properties.component.scss']
})

export class OwnerPropertiesComponent implements OnInit {
  displayedColumns: any[] ;
  @Input() event: any;
  ownerProperty: OwnerProperty;
  ownerPropertiesList: Array<OwnerProperty>;
  dataSource;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  selectedBooking: any;
  personalBookingList: any;
  editableBooking = false;
  editableProperty = false;
  showPermissions = false;
  ownerForm: FormGroup;
  ownerPermissions = ['View Revenue' , 'View Properties' , 'Reservation Request'];
  containsViewRevenue = false;
  ownerPermission: any;
  columnPermissions = ['Booking amount', 'Platform Commission', 'Cleaning Fee', 'Tax',
        'Payment Processing', 'Management Commission', 'Expenses', 'Net Profit'];








  constructor( private dialog1: MatDialogRef<OwnerPropertiesComponent> , @Inject (MAT_DIALOG_DATA) public data: {row: any , bookingSelection: any} , private fb: FormBuilder, protected eventService: EventService, public dialog: MatDialog, private router: Router,
               private propertyService: PropertyService, private userService: UserService , private toastrService: ToastrService , private dataService: DataService , private ownerService: OwnerService) {
    this.event = data.row;
    this.selectedBooking = data.bookingSelection;

  }

  ngOnInit() {
    if (this.selectedBooking === 'booking') {
      this.editableBooking = true ;
      this.displayedColumns = ['firstNight' , 'lastNight' , 'guestPropertyName' , 'ownerBookingStatus'];
      this.showPersonalBooking();
    } else if (this.selectedBooking === 'ownerPermissions') {
      this.showPermissions = true;
      this.editPermissions(this.ownerPermissions, this.columnPermissions);
      this.showOwnerPermissions();
    } else {
      this.editableProperty = true;
      this.displayedColumns = ['serialNo' , 'propertyName'] ;
      this.showProperties();
    }


  }

  showOwnerPermissions(): void {
    this.editPermissions(this.ownerPermissions, this.columnPermissions);
    this.ownerService.getOwnerPermissions(this.event)
      .subscribe((response: GenericDto<Permissions>) => {
          if (response.code === 200 ) {
            if (response.payload !== null) {
              const ownerPermissions = response.payload['ownerPermissions'];
              const columnPermissions = response.payload['columnPermissions'];
              this.editPermissions(ownerPermissions, columnPermissions);

            }

          }
        },
        (error: HttpErrorResponse) => {
          // show errors here
        });
  }

  addPermissions(): void {
    if(this.containsViewRevenue) {
      const ownerPermissions = {
        ownerPermissions: this.ownerForm.get('ownerData').value,
        columnPermissions: this.ownerForm.get('columnPermissions').value
      }
      this.ownerService.saveOwnerPermissionWithColumnPermissions(this.event , ownerPermissions)
        .subscribe((response: GenericDto<any>) => {
            if (response.code ===  200) {
              this.toastrService.success( ' Add Permission successfully ', success, {
                timeOut: 3000,
                onActivateTick: true
              });
              this.hide();
            }
          },
          (error: HttpErrorResponse) => {
            // show errors here
            this.toastrService.error( 'Unable to save permissions!', errorMessage, {
              timeOut: 3000,
              onActivateTick: true
            });
            this.hide();
          });
    } else {
      let ownerPermissions = {
        ownerPermissions: this.ownerForm.get('ownerData').value,
        columnPermissions: []
      }
      this.ownerService.saveOwnerPermissionWithColumnPermissions(this.event , ownerPermissions)
        .subscribe((response: GenericDto<any>) => {
            if (response.code ===  200) {
              this.toastrService.success( ' Add Permission successfully ', success, {
                timeOut: 3000,
                onActivateTick: true
              });
              this.hide();
            }
          },
          (error: HttpErrorResponse) => {
            // show errors here
            this.toastrService.error( 'Unable to save permissions!', errorMessage, {
              timeOut: 3000,
              onActivateTick: true
            });
          });
    }

  }

  acceptRequest(status: any , row: any): void {
    this.propertyService.updateBookingStatus(row.id , status)
      .subscribe((response: GenericDto<any>) => {
          if (response.code ===  200) {
            this.showPersonalBooking();
            this.toastrService.success(status + ' Request successfully ', success, {
              timeOut: 3000,
              onActivateTick: true
            });
          }
        },
        (error: HttpErrorResponse) => {
          // show errors here
        });
  }

  showProperties(): void {
    this.userService.getOwnerPropertiesById(this.event)
      .subscribe((response: GenericDto<Array<string>>) => {
          if (response.code ===  200) {
            let count = 1;
            this.ownerPropertiesList = new Array<OwnerProperty>();
            response.payload.forEach((propertyName: string) => {
              this.ownerProperty = {} as OwnerProperty;
              this.ownerProperty.serialNo = count;
              this.ownerProperty.propertyName = propertyName;
              count++;
              this.ownerPropertiesList.push(this.ownerProperty);
            });
            this.dataSource = new MatTableDataSource(this.ownerPropertiesList);
            this.dataSource.paginator = this.paginator;
          }
        },
        (error: HttpErrorResponse) => {
          // show errors here
        });
  }



  editPermissions(getOwnerPermissions: any, getColumnPermissions: any) {
    if(getOwnerPermissions.includes('View Revenue')){
      this.containsViewRevenue = true;
    } else {
      this.containsViewRevenue = false;
    }
    this.ownerForm = this.fb.group({
      ownerData: [getOwnerPermissions, [Validators.required]],
      columnPermissions: [getColumnPermissions],
    });

  }





  showPersonalBooking(): void {
    this.userService.getOwnerPersonalBooking(this.event)
      .subscribe((response: GenericDto<any>) => {
          if (response.code ===  200) {
            this.personalBookingList = [];
            response.payload.forEach((row: any) => {
              row.firstNight = moment.unix(row.firstNight).format('YYYY-MM-DD');
              row.lastNight = moment.unix(row.lastNight).format('YYYY-MM-DD');

              if (row.ownerBookingStatus === 'PENDING') {
                row.ownerBookingStatusPending = true ;
              } else {
                row.ownerBookingStatusActive = true ;
              }
              this.personalBookingList.push(row);
            });
            this.dataSource = new MatTableDataSource(this.personalBookingList);
            this.dataSource.paginator = this.paginator;
          }
        },
        (error: HttpErrorResponse) => {
          // show errors here
        });


  }


  hide() {
    this.dialog1.close(OwnerPropertiesComponent);
  }

  onPermissionChange(event: any){
    let permissionSelected =  event.value;
    console.log(permissionSelected);
    if(permissionSelected.includes('View Revenue')){
      this.containsViewRevenue = true;
    } else {
      this.containsViewRevenue = false;
    }
  }

}
