import { Component, OnInit, ViewChild } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { MatTableDataSource, MatDialog, MatSort, MatPaginator } from '@angular/material';
import { UserActionComponent } from './user-action/user-action.component';
import { GenericDto } from 'src/app/models/generic-dto';
import { success } from 'src/app/constants/notification-messages.constant';
import { AuthService } from 'src/app/services/auth.service';
import { StorageService } from 'src/app/core/services/storage.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { ConfirmDialougeComponent } from 'src/app/dashboard/confirm-dialouge/confirm-dialouge.component';

@Component({
  selector: 'app-admin-dashboard-home',
  templateUrl: './admin-dashboard-home.component.html',
  styleUrls: ['./admin-dashboard-home.component.scss']
})
export class AdminDashboardHomeComponent implements OnInit {
  displayColumns = ['id', 'userName', 'email', 'totalOwners', 'totalRentals', 'totalRevenue', 'pmsStatus', 'lastLogin', 'login','accountType','modify'];
  dataSource;
  action;
  selectedUser;
  block;
  accountType;
  pmsList;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(private authService: AuthService, public userService: UserService, public dialog: MatDialog,
              private storageService: StorageService, private toastr: ToastrService, private router: Router) {
                this.router.routeReuseStrategy.shouldReuseRoute = function() {
                  return false;
                };
              }

  ngOnInit() {
    this.initialize();
  }

  initialize() {
    this.userService.getSuperAdminDashboard().subscribe( response => {
      let table = response.payload.superAdminTable;
      let index = 8000;
      response.payload.superAdminTable.forEach(element => {
        element.index = index;
        index++;
      });
      this.pmsList = response.payload.pmss;
      this.dataSource = new MatTableDataSource(response.payload.superAdminTable);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      /*this.userService.getRevenueOfAllUsers().subscribe(response => {
        for (let index = 0; index < table.length; index++) {
          for (let index1 = 0; index1 < response.payload.length; index1++) {
            if(table[index].id === response.payload[index1].id) {
              table[index].totalRevenue = response.payload[index1].revenue;
            }
          }
        }
      });*/
    });
  }

  blockUser(user, block){
    this.action = block;
    const actionDialog =  this.dialog.open(UserActionComponent, {
      width: '40%',
      height: '200px',
      data: {
      Action: this.action,
      User: user
      }
    });
    actionDialog.afterClosed().subscribe(res => {
      window.location.reload();
    });
  }

  deleteUser(user){
    this.action = 'Delete';
    const actionDialog =  this.dialog.open(UserActionComponent, {
      width: '40%',
      height: '200px',
      data: {
      Action: this.action,
      User: user
      }
    });
    actionDialog.afterClosed().subscribe(res => {
      window.location.reload();
    });
  }


  invite(){
    this.action = 'Invite'
    const actionDialog =  this.dialog.open(UserActionComponent, {
      width: '40%',
      height: '280px',
      data: {
      Action: this.action
      }
    });
  }

  loginToAccount(user){
    this.action = 'Login'
    const actionDialog =  this.dialog.open(UserActionComponent, {
      width: '40%',
      height: '280px',
      data: {
      Action: this.action,
      User: user
      }
    });
  }

  logout(): void {
    this.authService.logout()
      .subscribe((response: GenericDto<string>) => {
        this.clearCurrentUserSession(response);
      }, (e) => {
        console.error(e);
        this.clearCurrentUserSession(e);
      });
  }

  clearCurrentUserSession(response: GenericDto<string>): void {
    this.storageService.clearAll();
    this.toastr.success(response.desc, success, {
      timeOut: 3000,
      onActivateTick: true
    });
    this.router.navigate(['/login']);
  }

  pms(user) {
    this.action = 'PMS'
    const actionDialog =  this.dialog.open(UserActionComponent, {
      width: '40%',
      height: '280px',
      data: {
      Action: this.action,
      User: user
      }
    });
  }

  changeAccountType(element, event: any){    
    const isChecked: boolean = event.target ? event.target.checked : event.checked;
    element.freeAccount = isChecked;    
      
    this.action = 'freeAccount'
    const actionDialog =  this.dialog.open(UserActionComponent, {
      width: '40%',
      height: '280px',
      data: {
      Action: this.action,
      User: element
      }
    });
    
  }

   
}
