import {ViewChild, ElementRef,  Component, OnInit ,AfterViewChecked } from '@angular/core';
import { PropertyService } from '../services/property.service';
import { SharedService } from '../services/shared.service';
import { HttpErrorResponse } from '@angular/common/http';
import { GenericDto } from '../models/generic-dto';  
import { ChatBotService } from '../services/chat-bot.service'; 
import { environment } from 'src/environments/environment';
 

@Component({
  selector: 'app-chat-bot',
  templateUrl: './chat-bot.component.html',
  styleUrls: ['./chat-bot.component.scss']
})
export class ChatBotComponent implements OnInit, AfterViewChecked {
  @ViewChild('chatContainer') chatContainer!: ElementRef;

  messages: any[] = [];
  userInput:any;
  isChatVisible: boolean = false; // Set it to true to initially show the chat section
  isChatBotAllow: boolean = environment.isChatBotAllow;
  from:any = 1;
  chatId: string =null;

  constructor(private chatBotService: ChatBotService, private propertyService: PropertyService,private sharedService: SharedService) {
   
   }

  ngOnInit() {
     
  }

  ngAfterViewInit(): void {
    this.sharedService.shareAndCommunicate.subscribe(
      (data) => {
        this.updateComponent(data)   
      }
    );
  }
  
  sendMessage(){ 

    let user = {
      from: 'user',
      text: this.userInput,
      img: 'https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava3-bg.webp',
      timestamp: '00:06'
    }
    this.messages.push(user);

     
    let payload = {       
      chatId:this.chatId,
      userId:localStorage.userId,
      message:this.userInput
    }
    

    this.chatBotService.sendMessage(payload).subscribe((response: GenericDto<any>) => {
      if (response.code === 200) {       
        console.log(response)
        
        
      }
    }, (error: HttpErrorResponse) => {
      // console.log(error);
    });  
    
    this.userInput = ''; // Clear the input field after sending the message

    
  }

  ngAfterViewChecked() {
    this.scrollToBottom(); 
}

scrollToBottom(): void {
  try {
    // Scroll the chat container to the bottom to show the latest message
    this.chatContainer.nativeElement.scrollTop = this.chatContainer.nativeElement.scrollHeight;
  } catch (err) { }
}

// updateComponent(obj){       
//   if(obj.code == "CHAT"){
//     let chatbot = {
//       from: 'chatbot',
//       text:  obj.data.message,
//       img: 'https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava4-bg.webp',
//       timestamp: '00:06'
//     }
//     console.log("chat=",obj.data)
//     this.messages.push(chatbot);
      
//   }  
// }
  
getUniqueChatId(){
  const originalString = "fasdfasdertqwer";
  const startIndex = Math.floor(Math.random() * (originalString.length - length + 1));
  const endIndex = Math.floor(Math.random() * (originalString.length - length + 1)); 
  const randomSubstring = originalString.substring(startIndex, endIndex);
  let chatId = randomSubstring+new Date().getTime()+randomSubstring;
  this.chatId =  chatId;
  localStorage.setItem('chatId',this.chatId);  
}

toggleChatVisibility() {
  if(!this.isChatVisible && this.chatId == null){
    this.getUniqueChatId();
    let name = " ";  
    if(localStorage.userName){
      name =  " "+localStorage.userName+" ";
    }
    
    let chatbot = {
      from: 'chatbot',
      text:  "Hi!"+name+"Welcome in BedPlus. How can i help you",
      img: 'https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava4-bg.webp',
      timestamp: '00:06'
    }
    this.messages.push(chatbot);

  }
  this.isChatVisible = !this.isChatVisible;
}

// --------------------------------------------------
updateComponent(obj) {
  // clean all the message and hide the chat-boat
  if(obj.code == "event-login-logout-occured"){
    this.isChatVisible = false;
    this.messages = [];
  }else if (obj.code === "CHAT") {
    
    // Simulate typing effect with a typing indicator
    this.simulateTyping(obj.data.message);
  }
}

simulateTyping(text) {
  const typingDelay = 120; // Delay between each character in milliseconds
  let currentText = '';

  this.addChatMessage('system', 'Typing...'); // Show typing indicator

  const typeNextCharacter = (index) => {
    if (index < text.length) {
      currentText += text.charAt(index);
      this.updateMessageText(this.messages.length - 1, currentText); // Update typing indicator text
      index++;

      setTimeout(() => typeNextCharacter(index), typingDelay);
    } else {
      let index = this.messages.length - 1;
      this.updateMessageText(this.messages.length - 1, text); // Update typing indicator text to full response
      const elementToRemove = document.getElementById('pencil-'+index);
      const parentElement = elementToRemove.parentElement;
      parentElement.removeChild(elementToRemove);
    }
  };

  typeNextCharacter(0);
}

addChatMessage(from, text) {
  let message = {
    from: from,
    text: text,
    typing:true,
    img: 'https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava4-bg.webp',
    timestamp: this.getCurrentTimestamp()
  };

  this.messages.push(message);
}

updateMessageText(index, newText) {
  this.messages[index].text = newText;
}

getCurrentTimestamp() {
  const now = new Date();
  const hours = String(now.getHours()).padStart(2, '0');
  const minutes = String(now.getMinutes()).padStart(2, '0');
  return `${hours}:${minutes}`;
}




 
}
