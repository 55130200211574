import {BehaviorSubject, Observable, throwError} from 'rxjs/index';
import {Injectable, OnInit} from '@angular/core';
import {HttpService} from '../core/services/http.service';
import {environment} from '../../environments/environment';

import {
  changePassword,updateUserProfile,
  deleteUser, getAllUsers, getOwnerProperties, getOwnerRoomIdUnitIdList, getOwnerDetails, getOwnerRoomUnits, getOwnerPersonalBooking, getUser,
  getUserByRole, getUserDetails, inviteUser, updateUser, getPropertiesByOwnerId, resendConfirmation,
  isNewAdmin, getOwnersByAdminId, getPropertiesByAdmin, getAdminPropertiesByAdmin, superAdminDashboard,
  userBlocking, sendInviteByEmail, loginToAccount, getRevenueOfAllUsers, previewOwnerPortal, switchUsersUrl, getAllAccountUser, userAccount,updateFreeAccountStatus
} from '../constants/user-api.constatnts';
import {GenericDto} from '../models/generic-dto';
import {User} from '../models/user';
import {Property} from '../models/property';
import { LoaderService } from '../shared/services/loader.service';
import { HttpErrorResponse } from '@angular/common/http';
import { tap, catchError } from 'rxjs/internal/operators';
import {RoomType} from "../models/RoomType";
import { OwnerDto } from '../models/owner-dto';

@Injectable()
export class UserService implements OnInit {

  constructor(private httpService: HttpService, private loaderService: LoaderService) {
  }

  ngOnInit() {

  }
  /**
   * Get user info
   */
  private userData = new BehaviorSubject({});
  editUserdata = this.userData.asObservable();

  editUserData(data: any): void {
    this.userData.next(data);
  }

  getUser(): Observable<GenericDto<any>> {
    return this.httpService.getAuth<GenericDto<any>>(environment.API + getUser);
  }

  /**
   * get user by role
   */
  getUserByRole(role: any): Observable<GenericDto<User[]>> {
    return this.httpService.getAuth<GenericDto<User[]>>(environment.API + getUserByRole + '?role=' + role);
  }

  getOwnerPropertiesById(ownerId: any): Observable<GenericDto<Array<string>>> {
    return this.httpService.getAuth<GenericDto<Array<string>>>(environment.API + getOwnerProperties + '?ownerId=' + ownerId);
  }

  getPropertiesByOwnerId(ownerId: any): Observable<GenericDto<Array<any>>> {
    return this.httpService.getAuth<GenericDto<Array<any>>>(environment.API + getPropertiesByOwnerId + '?ownerId=' + ownerId);
  }

  getOwnerRoomIdUnitIdList(ownerId: any): Observable<GenericDto<Array<string>>> {
    return this.httpService.getAuth<GenericDto<Array<string>>>(environment.API + getOwnerRoomIdUnitIdList + '?ownerId=' + ownerId);
  }

  getOwnerDetails(ownerId: any): Observable<GenericDto<OwnerDto>>{
    return this.httpService.getAuth<GenericDto<OwnerDto>>(environment.API + getOwnerDetails + '?ownerId=' + ownerId);
  }

  getOwnerRoomUnits(ownerId: any): Observable<GenericDto<Array<RoomType>>> {
    return this.httpService.getAuth<GenericDto<Array<RoomType>>>(environment.API + getOwnerRoomUnits + '?ownerId=' + ownerId);
  }

  getPropertiesByAdmin():Observable<GenericDto<Array<any>>>{
    return this.httpService.getAuth<GenericDto<Array<string>>>(environment.API + "/owner/getPropertiesForAdmin");
  }

  /**
   * @desc to fetch list of ownerBookings
   * @param role
   * @returns {Observable<GenericDto<User[]>>}
   */
  getOwnerPersonalBooking(ownerId: any): Observable<GenericDto<any>> {
    return this.httpService.getAuth<GenericDto<any>>(environment.API + getOwnerPersonalBooking + '?ownerId=' + ownerId);
  }

  /**
   * invite user
   */
  inviteUser(users: User): Observable<GenericDto<any>> {
      return this.httpService.postAuth(environment.API + inviteUser, users);
  }
  // stripe Checkout for billing
  checkoutPage(priceId:any): Observable<any> {
    return this.httpService.postAuth(environment.API+ `/payment/create-checkout-session?priceId=${priceId}`,{}
  )}

  previewOwnerPortal(userId: any, users: User): Observable<GenericDto<any>>{
    return this.httpService.postAuth(environment.API + previewOwnerPortal +"/"+userId, users);
  }


  switchUser():Observable<GenericDto<any>>{
    return this.httpService.getAuth(environment.API + switchUsersUrl);
  }

  /**
  * get all user
  */
  getUserDetails(id: string): Observable<GenericDto<User>> {
    return this.httpService.getAuth<GenericDto<User>>(environment.API + getUserDetails + id);
  }


  /**
   * get all user
   */
  getAllUsers(): Observable<GenericDto<User[]>> {
    return this.httpService.getAuth<GenericDto<User[]>>(environment.API + getAllUsers);
  }

  /**
   * update user
   */
  updateUser(user: User): Observable<GenericDto<any>> {
    return this.httpService.putAuth(environment.API + updateUserProfile, user);
  }
  

  /**
   * change Password
   */
  changePassword(user: User): Observable<GenericDto<any>> {
    return this.httpService.putAuth(environment.API + changePassword, user);
  }
/**
   * delete user
   */
  deleteUser(id: string): Observable<GenericDto<any>> {
    return this.httpService.deleteAuth<GenericDto<any>>(environment.API + deleteUser + id);
  }

  putCurrency(userId: string, currency: string ) {
    return this.httpService.putAuth<GenericDto<any>>(environment.API + deleteUser + `currency/${currency}/user/${userId}`, '');
  }

  resendInvite(id: string): Observable<GenericDto<any>>{
    return this.httpService.getAuth(environment.API + resendConfirmation + '/' + id);
  }

  redirectUser(): any {
    return this.httpService.getAuth(environment.API + isNewAdmin);
  }

  getOwnersByAdminId (id: string) : Observable<GenericDto<any>>{
    return this.httpService.getAuth<GenericDto<any>>(environment.API + getOwnersByAdminId + '/'+ id)
  }

  getPropertiesById (id: string) : Observable<GenericDto<any>>{
    return this.httpService.getAuth<GenericDto<any>>(environment.API + getPropertiesByAdmin + '/'+ id)
  }

  getAdminPropertiesById (id: string) : Observable<GenericDto<any>>{
    return this.httpService.getAuth<GenericDto<any>>(environment.API + getAdminPropertiesByAdmin + '/'+ id)
  }

  getSuperAdminDashboard():Observable<GenericDto<any>> {
    this.loaderService.startLoader();
    return this.httpService.getAuth<GenericDto<any>>(environment.API + superAdminDashboard)
    .pipe(tap(() => {
      this.loaderService.stopLoader(); // Stop loader when response received
    }), catchError((error: HttpErrorResponse) => {
      this.loaderService.stopLoader(); // stop loader if any error occurred
      return throwError(error);
    }));
  }

  userBlocking(id: string, blocking: String): Observable<GenericDto<any>> {
    return this.httpService.getAuth<GenericDto<any>>(environment.API + userBlocking+ '/' +  blocking + '/' + id )
  }

  updateFreeAccountStatus(accountId,status): Observable<GenericDto<any>> {
    return this.httpService.getAuth<GenericDto<any>>(environment.API + updateFreeAccountStatus+'/'+accountId+"/"+status );
  }
  

  sendInviteByEmail(email: String): Observable<GenericDto<any>> {
    return this.httpService.getAuth<GenericDto<any>>(environment.API + sendInviteByEmail + '?email=' + email );
  }

  loginToAccount(id: String, pass: String): Observable<GenericDto<any>> {
    return this.httpService.getAuth<GenericDto<any>>(environment.API + loginToAccount + '?id=' + id + '&pass=' + pass);
  }

  getRevenueOfAllUsers(): Observable<GenericDto<any>> {
    return this.httpService.getAuth<GenericDto<any>>(environment.API + getRevenueOfAllUsers);
  }

// //////// New user acc /////////////////////////////

getallUserAccount(): Observable<any> {
  return this.httpService.getAuth<any>(environment.API + getAllAccountUser);
}

deleteUserAccount(id: String,): Observable<any> {
  return this.httpService.deleteAuth<any>(environment.API + userAccount + id );
}

InviteNewAccount(users):Observable<any>{
  return this.httpService.postAuth<any>(environment.API + userAccount , users);
}


updateUserAccount(user): Observable<GenericDto<any>> {
  return this.httpService.putAuth(environment.API + userAccount ,user);
}

getAccount(accountid): Observable<any> {
    return this.httpService.getAuth<any>(environment.API + userAccount+accountid);
}

}
