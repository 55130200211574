import {Component, Inject, OnDestroy} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';

@Component({
  selector: 'app-image-viewer',
  templateUrl: './image-viewer.component.html',
  styleUrls: ['./image-viewer.component.scss']
})
export class ImageViewerComponent implements OnDestroy{
  images: string[];
  visibleIndex: number;
  disablePrev = true;
  disableNext: boolean;

  constructor(public dialogRef: MatDialogRef<ImageViewerComponent>,
              @Inject(MAT_DIALOG_DATA) public data: string[]) {
    this.images = data;
    this.visibleIndex = 0;
  }


  showNext() {
    this.visibleIndex++;

    if (this.visibleIndex === this.images.length - 1) {
      this.disableNext = true;
    }

    if (this.visibleIndex > 0) {
      this.disablePrev = false;
    }
  }

  showPrev() {
    this.visibleIndex--;

    if (this.visibleIndex === 0) {
      this.disablePrev = true;
    }

    if (this.visibleIndex < this.images.length - 1) {
      this.disableNext = false;
    }
  }

  ngOnDestroy() {
    this.visibleIndex = 0;
    this.disablePrev = true;
  }

}
