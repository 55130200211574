/*
* Define endpoint here for booking APIs
*/

export const getBooking = '/booking/get-all';

export const getBookingAllBy = '/booking/get-all-by';

export const getPropertyRevenueReport = '/booking/getPropertyRevenueReport';

export const saveBooking = '/booking/save';

export const updateBooking = '/booking/update';

export const updateBookingStatus = '/booking/updateBookingStatus';

export const totalBookings = '/booking/getTotalBookings';

export const getTopFiveRecentlyAddedBookings = '/booking/getTopFiveRecentBookings'

export const deleteBookings = '/booking/deleteBookings';

export const getNewPropertyRevenueReport = '/booking/getNewPropertyRevenueReport';
export const getRevenueReport = '/revenue/getNewPropertyRevenueReport';



export const getCalendarBookings= '/booking/get-calender-bookings';
export const syncBookingToPms = '/booking/send-booking-to-pms';

export const reCalculateBooking = '/booking/reCalculateBooking';