import {Injectable} from '@angular/core';
import {OtpDto} from '../../models/otp-dto';
import {Property} from '../../models/property';
import {Subject, Subscription} from 'rxjs/index';
import {User} from '../../models/user';

@Injectable()
export class DataService {
  /**
   *  otp object will be shared for reset password component
   *  and will be removed after navigation.
   */
  public otp: OtpDto;

  /**
   *  User info will be stored in `user` object and it will available in whole application
   *
   */
  public user: any;
  /**
   *  Property object will be shared to PropertyDetailsComponent from propertyListComponent
   *  Note : flush object at `onDestroy` lifecycle of PropertyDetailsComponent.
   */
  public property: Property;

  /**
   * Cleaner object will be shared to CleanerDetailsComponent from cleanerListComponent
   */
  public cleaner: User;

  /** observable User Object */
  public userAsync: Subject<any>;
  /**
   * Event Trigger to communicate between components
   */
  public customEvent: Subject<string>;

  constructor() {
    this.customEvent = new Subject<string>();
    this.userAsync = new Subject<any>();
  }
}
