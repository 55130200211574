import { Component, OnInit, Inject, SecurityContext } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { MaintenanceJob } from 'src/app/models/maintenanceJob';
//import { PropertyDetails }  from 'src/app/dashboard/property/property-details';
import { DomSanitizer } from '@angular/platform-browser';
import { AssetsService } from 'src/app/services/assets.service';

@Component({
  selector: 'app-maintenance-job-details',
  templateUrl: './maintenance-job-details.component.html',
  styleUrls: ['./maintenance-job-details.component.scss']
})
export class MaintenanceJobDetailsComponent implements OnInit {

  job: MaintenanceJob;
  roomName: String;
  invoices;
  isImageLoading: boolean = false;
  enlargeImage = false;
  selectedImage: any;
  isdownload = false; 
  currentImageIndex;
  constructor(private dialog: MatDialogRef<MaintenanceJobDetailsComponent>,
    @Inject (MAT_DIALOG_DATA) public data: any, private assetService:AssetsService, private sanitizer : DomSanitizer) { 
      if(data.selectedJob.invoiceAddresses && data.selectedJob.invoiceAddresses !== null && data.selectedJob.invoiceAddresses !== undefined && data.selectedJob.invoiceAddresses.length !== 0) {
        this.isImageLoading = true;
        this.invoices = new Array();
        this.getJobImages(data.selectedJob);
      }
      this.job = data.selectedJob as MaintenanceJob;
      this.roomName = data.room[0].name;
    }

  ngOnInit() {

    this.showMaintenanceJobDetails();
  }

  showMaintenanceJobDetails() {
  }
  
  hide(){
    this.dialog.close();
  }

  getJobImages(maintenanceJob) {
    for (let index = 0; index < maintenanceJob.invoiceAddresses.length; index++) {
      const invoiceAddress = maintenanceJob.invoiceAddresses[index];
      this.assetService.getExpenseImages(maintenanceJob.id, invoiceAddress).subscribe(response=> {
        let reader = new FileReader();
        let currentImage: any;
        reader.addEventListener("load", () => {
          currentImage = reader.result;
          currentImage = this.sanitizer.bypassSecurityTrustUrl(currentImage);
          this.invoices.push(currentImage);
        }, false);
        if (response) {
          reader.readAsDataURL(response);
        } 
        console.log(index); 
        if(maintenanceJob.invoiceAddresses.length - 1 === index) {
          this.isImageLoading = false
        }
      }); 
    }
  }
 showImage(image: any, i){
    this.currentImageIndex = i;
    this.selectedImage = image;
    this.enlargeImage = true;
 }

  downloadImage(image) {
    // console.log(this.currentImageIndex);
  }

}
