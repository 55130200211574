import {Component, Inject, Input, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {PropertyService} from '../../../../services/property.service';
import {MaintenanceJob} from 'src/app/models/maintenanceJob';
import {maintenanceJobCreate, success} from '../../../../constants/notification-messages.constant';
import {ToastrService} from 'ngx-toastr';
import {GenericDto} from '../../../../models/generic-dto';
import {RoomType} from '../../../../models/RoomType';
import {UnitNames} from '../../../../models/unitNames';
import {Property} from '../../../../models/property';
import {numbersOnlyValidator, percentageValidator} from '../../../../validators/number.validator';
import {MaintenanceJobMediaComponent} from '../maintenance-job-media/maintenance-job-media.component';
import {AssetsService} from 'src/app/services/assets.service';
import { ConfirmationDialogComponent } from 'src/app/shared/components/confirmation-dialog/confirmation-dialog.component';


declare var $: any;
@Component({
  selector: 'app-maintenance-job',
  templateUrl: './maintenance-job.component.html',
  styleUrls: ['./maintenance-job.component.scss'],
})

export class MaintenanceJobComponent implements OnInit {
  @Input() event: any;
  minDate = new Date()
  maintenanceJobForm: FormGroup;
  maintenanceJob: MaintenanceJob;
  selectedProperty: Property;
  currentPropertyId: string;
  currentRoomTypeId: string;
  currentRoomId: string;
  currentRoomName: string;
  unitId:any;
  roomList: any;
  selectedUnit: UnitNames;
  roomTypes: Array<RoomType>;
  hasUnits: boolean;
  isButtonDisable : boolean ;
  isScheduleExpense : false;
  @ViewChild(MaintenanceJobMediaComponent ) media: MaintenanceJobMediaComponent ;
  jobTitle = new FormControl('', [Validators.required, Validators.max(30)]);
  jobDetails = new FormControl('', [Validators.required, Validators.max(120)]);
  amount = new FormControl('', [Validators.required, Validators.compose([numbersOnlyValidator])]);
  taxInPercent = new FormControl('', [Validators.required, Validators.compose([percentageValidator])]);
  roomId = new FormControl('');
  // roomId = new FormControl('', [Validators.required]);
  includeTax= new FormControl(false, [Validators.required]);
  totalPayable: number;
  expenseFrequency: string;
  isCurrentDateExpense:boolean = false;
  constructor(private dialog: MatDialogRef<MaintenanceJobComponent>, @Inject(MAT_DIALOG_DATA) data: any,
  public confirmDialog: MatDialog, private propertyService: PropertyService, private toastrService: ToastrService,
              private formBuilder: FormBuilder, private expenseMediaDialog: MatDialog, private assetService: AssetsService) {
    this.currentPropertyId = data.currentPropertyId;
    this.currentRoomTypeId = data.currentRoomTypeId;
    this.currentRoomId = data.currentRoomId;
    this.currentRoomName = data.currentRoomName;
    this.unitId = data.unitId;
   
    
  }

  ngOnInit() {
    this.maintenanceJobForm = this.formBuilder.group({
      jobTitle: ['', Validators.required],
      jobDetails: ['', Validators.required],
      amount:  new FormControl('', [Validators.required, Validators.compose([numbersOnlyValidator])]),
      taxInPercent: new FormControl('', [Validators.required, Validators.compose([percentageValidator])]),
      // roomId: [`${this.currentRoomTypeId}-roomId-${this.currentRoomId}-unit-${this.currentRoomName}`],
      // roomId: ['', Validators.required],
      roomId: [this.currentRoomId],
      roomObjectId:[this.currentRoomTypeId],
      jobDate: [new Date(), Validators.required],
      includeTax: [false, Validators.required],
      recurringSchedule: [null],
    });
    this.fetchRoom(this.currentPropertyId);
    this.isButtonDisable = false ;
  }
  getErrorMessage() {
    return this.jobTitle.hasError('jobTitle') ? 'Enter a valid maintenance job title' :
      this.jobDetails.hasError('jobDetails') ? 'Enter a valid the Maintenance Job Details' :
        this.amount.hasError('amount') ? 'Enter a valid Maintenance Job Amount' :
        this.roomId.hasError('roomId') ? 'Room or Unit not selected.' :
        '';
  }



  hide() {
    if(this.isScheduleExpense){
      this.dialog.close(2);
    }else{
      this.dialog.close(1);
    }
  }

  confirmDialogOpen() {
    const dialogRef = this.confirmDialog.open(ConfirmationDialogComponent, {
      width: '430px',
      height: '200px',
      data: "This scheduled expense will create a future "+this.expenseFrequency+" expense for this room, would you like to also create one for today?"
    });
    dialogRef.afterClosed().subscribe(result => {       
      if(result=="close" || (typeof result != 'boolean')){
        return false
      }else{
        this.isCurrentDateExpense = result; 
        this.createMaintenanceJob();
      }
      
               
    });
   }

   createJob(){
    const selectedDate = new Date(this.maintenanceJobForm.value.jobDate); 
    const currentDate = new Date();
    // Check if the selected date is today's date or in the past
    if (selectedDate <= currentDate && this.isScheduleExpense) {
      this.confirmDialogOpen();      
    }else{
      this.createMaintenanceJob();
    } 

   }

  createMaintenanceJob(): void {         
    if (this.maintenanceJobForm.invalid) {
      return;
    } 
    
  
  
    const dateTime = new Date( this.maintenanceJobForm.value.jobDate);
    const localDate = new Date(dateTime.getTime() - (dateTime.getTimezoneOffset() * 60000));
    let date = localDate.toISOString().split('T')[0];
      
    this.maintenanceJobForm.get('jobDate').patchValue(date);
    this.maintenanceJob = this.maintenanceJobForm.value;
    this.maintenanceJob.totalPayable = this.totalPayable;
    this.maintenanceJob.propertyId = this.currentPropertyId;   
    this.maintenanceJob.isCurrentDateExpense = this.isCurrentDateExpense;
    this.maintenanceJob.unitName= this.currentRoomName;
    if(this.isScheduleExpense){
      this.maintenanceJob.jobType = 'RECURRING' ;
      this.maintenanceJob.recurringStartDate = this.maintenanceJob.jobDate ;
      this.maintenanceJob.unitId = this.unitId ;
      this.maintenanceJob.expenseFrequency = this.expenseFrequency;
      this.maintenanceJobForm.get('isCurrentDateExpense').patchValue(this.isCurrentDateExpense);
    }else{
      this.maintenanceJob.jobType = 'REGULAR'
      this.maintenanceJob.unitId = this.unitId ;
      this.isCurrentDateExpense =  false;
    }
    console.log('this.media =' , this.media)
    
    if (this.media) {
    console.log('this.media if call ')
  }
  this.media.onSubmit();

  }
  

  onScheduleExpenseChange(value){
    this.isScheduleExpense = value ;
    if(value){
      this.maintenanceJobForm = this.formBuilder.group({
        jobTitle: [this.maintenanceJobForm.value.jobTitle , Validators.required],
        jobDetails: [this.maintenanceJobForm.value.jobDetails  , Validators.required],
        amount:  new FormControl(this.maintenanceJobForm.value.amount  , [Validators.required, Validators.compose([numbersOnlyValidator])]),
        taxInPercent: new FormControl(this.maintenanceJobForm.value.taxInPercent  , [Validators.required, Validators.compose([percentageValidator])]),
        // roomId: [`${this.currentRoomTypeId}-roomId-${this.currentRoomId}-unit-${this.currentRoomName}`],
        roomId: [this.currentRoomId],
        // roomId: ['', Validators.required],
        roomObjectId:[this.currentRoomTypeId],
        jobDate: [this.maintenanceJobForm.value.jobDate , Validators.required],
        includeTax: [this.maintenanceJobForm.value.includeTax  , Validators.required],
        recurringSchedule: [this.maintenanceJobForm.value.recurringSchedule  ,  Validators.required ],
        isCurrentDateExpense: [this.maintenanceJobForm.value.isCurrentDateExpense  ,  ],
        unitId: [this.unitId  ,  ],
      });
    }else{
      this.maintenanceJobForm = this.formBuilder.group({
        jobTitle: [this.maintenanceJobForm.value.jobTitle , Validators.required],
        jobDetails: [this.maintenanceJobForm.value.jobDetails  , Validators.required],
        amount:  new FormControl(this.maintenanceJobForm.value.amount  , [Validators.required, Validators.compose([numbersOnlyValidator])]),
        taxInPercent: new FormControl(this.maintenanceJobForm.value.taxInPercent  , [Validators.required, Validators.compose([percentageValidator])]),
        roomId: [`${this.currentRoomTypeId}-roomId-${this.currentRoomId}-unit-${this.currentRoomName}`],
        // roomId: ['', Validators.required],
        jobDate: [this.maintenanceJobForm.value.jobDate , Validators.required],
        includeTax: [this.maintenanceJobForm.value.includeTax  , Validators.required],
        recurringSchedule: [null  ],
        unitId: [this.unitId  ,  ],
      });
    }
  }

  onExpenseFrequencyChange(value){
    this.expenseFrequency = value;
  }

  fetchRoom(propId: string): void {
    this.propertyService.get(propId).subscribe((data2: GenericDto<Property>) => {
      this.selectedProperty = data2.payload as Property;
      this.hasUnits = true;
      this.selectedProperty.roomTypes.forEach((property: RoomType) => {
        if (property.unitItems.length === 0) {
          this.hasUnits = false;
          this.fetchRooms(true);
        }
      });
    });
  }

  fetchRooms(event: boolean): void {
    if (event) {
      this.selectedProperty.roomTypes.forEach((property: RoomType) => {
        property.unitArray = new Array();
        for (let i = 0; i < property.units.length; i++) {
          this.selectedUnit = new Object() as UnitNames;
          this.selectedUnit.roomId = property.units[i] + '-' + property.id;
          this.selectedUnit.unitName = property.units[i];
          property.unitArray.push(this.selectedUnit);
        }
      });
    }
  }

  addMedia(invoices: any) {
    if(invoices) {
      this.isButtonDisable = true ;
      this.propertyService.createMaintenanceJob(this.maintenanceJob).subscribe((response: GenericDto<any>) => {
        console.log("response.code =", response.code)
        if (response.code === 200 || response.code === 201) {
          this.isButtonDisable = false ;
          this.assetService.uploadExpenseInvoices(response.payload.id, invoices).subscribe();
          this.toastrService.success(response.desc, success, {
            timeOut: 3000,
            onActivateTick: true
          });
          this.hide();
        }
      }, error => {
      });
    }
  }

  public calculateTotalPayable() {
    const amount = this.maintenanceJobForm.get('amount').value;
    const taxInPercent = this.maintenanceJobForm.get('taxInPercent').value;
    const isTaxInclusive =  this.maintenanceJobForm.get('includeTax').value;
    this.totalPayable = isTaxInclusive ? (amount + (amount * taxInPercent / 100)).toFixed(2): amount;
  }
}
