import { createHostListener } from '@angular/compiler/src/core';
import { Directive,  ElementRef,  HostListener,Input,Renderer2,TemplateRef, ViewContainerRef } from '@angular/core';
import { HasElementRef } from '@angular/material/core/typings/common-behaviors/color';

@Directive({
  selector: '[appTolltipTemplate]'
})
export class TolltipTemplateDirective {
  @Input('myTemplate') myTemplate:TemplateRef<any>;
  @Input() data: string;
  @Input() display: boolean;
  @Input()  cellNum:number  =0;
  @Input() rowNum:number = 1;
  tooltip = null;


  constructor(public eleRef: ElementRef, public renderer: Renderer2, private viewRefContainer: ViewContainerRef) { }
  createToolTip() {
    const tooltip = this.renderer.createElement('div');

    const namedFormula: string = this.data && this.data.hasOwnProperty('namedFormula') ? this.data['namedFormula'] : "";
    const formula: string = this.data && this.data.hasOwnProperty('formula') ? this.data['formula'] : "";

    if (tooltip) {
      try {
        this.renderer.addClass(tooltip, 'toolTipMy');
        let content = `
        <div class='mytooltip'>
          <div class="row">
            <div class="col-12"><b><u>Formula</u></b> : ${namedFormula}</div>
          </div>
          <div class="row">
            <div class="col-12"><b><u>Calculation</u></b> : ${formula}</div>
          </div>
        </div>
      `;
      this.renderer.addClass(tooltip, 'toolTipMy');
      this.renderer.setStyle(tooltip, 'position', 'absolute');

      let contentLength: number;

      try {
        contentLength = (namedFormula.length > formula.length) ? namedFormula.length : formula.length;
      } catch (error) {
        contentLength = 0;
        console.error(error);
      }

       if(this.rowNum ==0){
        if(contentLength >130){
          this.renderer.setStyle(tooltip, 'top', '45px');
         }else{
          this.renderer.setStyle(tooltip, 'top', '50px');
         }
       }

       if(contentLength >50){
       this.renderer.setStyle(tooltip, 'right', '45px');
       }else{
        if(this.cellNum == 3 && contentLength > 50){
          this.renderer.setStyle(tooltip, 'right', '80px');
        }else if(this.cellNum == 4 && contentLength > 40){
          this.renderer.setStyle(tooltip, 'right', '50px');
        }else if(this.cellNum == 5 && contentLength > 7){
          this.renderer.setStyle(tooltip, 'right', '45px');
        }
        else if(this.cellNum == 6 && contentLength > 7){
          this.renderer.setStyle(tooltip, 'right', '45px');
        }

       }



     this.renderer.setProperty(tooltip, 'innerHTML', content);

        const viewRef = this.viewRefContainer.createEmbeddedView(this.myTemplate);
        const tooltipContent = viewRef.rootNodes[0];
        this.renderer.appendChild(tooltip, tooltipContent);
        const hostElement = this.eleRef.nativeElement;
        if (hostElement) {
          const parentElement = hostElement.parentElement;
          this.tooltip= tooltip;
         // parentElement.insertBefore(tooltip, parentElement.firstChild);
          this.renderer.appendChild(hostElement, tooltip);
        }
      } catch (error) {
        console.error('Error adding class to tooltip:', error);
      }
    } else {
      console.error('Tooltip element is undefined.');
    }
  }


  @HostListener('mouseover')
  onMouseOver() {
    if(this.display) {
      this.createToolTip();
    }

  }

 @HostListener('mouseout')
 onMouseOut() {
   if(this.display)    {
     const tooltip = this.eleRef.nativeElement.querySelector('.toolTipMy');
     this.renderer.removeChild(this.eleRef.nativeElement,tooltip);
   }

  }
}
