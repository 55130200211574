import {NgModule} from '@angular/core';
import {ActivateAccountComponent} from './activate-account.component';
import {CommonModule} from '@angular/common';
import {ReactiveFormsModule} from '@angular/forms';
import {SharedModule} from '../shared/shared.module';

@NgModule ({
  imports: [ReactiveFormsModule, SharedModule, CommonModule],
  declarations: [ActivateAccountComponent],
  exports: [ActivateAccountComponent],
  providers: []
})

export class ActivateAccountModule {}
