import {Injectable} from '@angular/core';
import {HttpService} from '../core/services/http.service';
import {GenericDto} from '../models/generic-dto';
import {Observable, throwError} from 'rxjs/index';
import {catchError, tap} from 'rxjs/internal/operators';
import {environment} from '../../environments/environment';
import {getAssetById, getMaintenanceJobImages, uploadExpenseInvoices, uploadStatementInvoices} from '../constants/assets-api.constants';
import {HttpResponse} from '@angular/common/http';
import { uploadImage } from '../constants/job-api.constants';
import { LoaderService } from '../shared/services/loader.service';
import {HttpErrorResponse} from '@angular/common/http';

@Injectable()
export class AssetsService {

  constructor(private httpService: HttpService,  private loaderService: LoaderService) {

  }

  /**
   *  get assets by asset ID
   */
  public getAsset(id: string): Observable<Blob> {
    return this.httpService.getAsset(environment.API + getAssetById + '/' + id);
  }

  public  getJobImage(jobId: String, imageUrl: String): Observable<Blob> {
    return this.httpService.postAsset(environment.API + uploadImage + '?jobId=' + jobId, { imageUrl: imageUrl});
  }
  public getExpenseImages(maintenanceJobId: string, invoiceAddress: String): Observable<any> {
    return this.httpService.postAsset(environment.API + getMaintenanceJobImages+ '?maintenanceJobId=' + maintenanceJobId,  invoiceAddress);
  }
  public uploadExpenseInvoices(maintenanceJobId: string, invoices: any): Observable<any> {
    return this.httpService.postAsset(environment.API + uploadExpenseInvoices + '/' + maintenanceJobId,  invoices);
  }

  uploadStatementInvoices(statementId: string, invoices: any): Observable<any> {
    this.loaderService.startLoader();
    return this.httpService.postAsset(environment.API + uploadStatementInvoices + '/' + statementId,  invoices)
    .pipe(tap(() => {
      this.loaderService.stopLoader(); // Stop loader when response received
    }), catchError((error: HttpErrorResponse) => {
      this.loaderService.stopLoader(); // stop loader if any error occurred
      return throwError(error);
    }));
  }
  

}
