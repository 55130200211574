import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, NavigationEnd, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { TokenService } from '../services/token.service';
import { tokenKey, currentUserKey } from 'src/app/constants/storage.constants';
import { filter } from 'rxjs/internal/operators';
import { AuthService } from 'src/app/services/auth.service';

@Injectable()
export class AuthGuard implements CanActivate {
  editable = false;
  editCleaner = true;
  previousUrl: string;
  constructor(private router: Router, private tokenService: TokenService,
    private authService: AuthService) {
    router.events
  .pipe(filter(event => event instanceof NavigationEnd))
  .subscribe((event: NavigationEnd) => {
     
    this.previousUrl = event.url;
  });
  }

  private performRouting(routeRoot: string, role: string): void {
    if (routeRoot === '/dashboard' && (role === 'ADMINISTRATOR' || role === 'OWNER')) {
      this.router.navigate(['/ownerDashboard']);
    } else if (routeRoot === '/dashboard' && (role === 'SUPER-ADMINISTRATOR')) {
      this.router.navigate(['/ownerDashboard']);
    }
    if (routeRoot === '/' || routeRoot === '/login') {
      if (role === 'ADMINISTRATOR' || role === 'OWNER') {
        this.router.navigate(['/ownerDashboard']);
      } else if (role === 'SUPER-ADMINISTRATOR') {
        this.router.navigate(['/adminDashboard']);
      }
    }
    if (routeRoot === 'admin') {
      this.router.navigate(['/adminDashboard']);
    }
  }

  public hasOwnerRole(role: string) {
    return role == 'OWNER'
  }

  public hasAdministratorRole(role: string) {
    return role === 'ADMINISTRATOR';
  }

  public isPreviewLogin() {
    return this.tokenService.getPreviewLogin();
  }


  public hasCheckPermission(route: string, role: string) {
    const permissions = [
      {
        path: '/dashboard', roles: ['ADMINISTRATOR', 'MANAGER', 'ACCOUNTANT', 'CLEANER',
          'HEADER_OF_CLEANER', 'GUEST_SUPPORT', 'CONCIERAGE']
      },
      {
        path: '/dashboard/property', roles: ['ADMINISTRATOR', 'MANAGER', 'ACCOUNTANT',
          'HEADER_OF_CLEANER', 'GUEST_SUPPORT', 'PPROPERTY_OWNER', 'CONCIERAGE']
      },
      {
        path: '/dashboard/staff', roles: ['ADMINISTRATOR', 'MANAGER', 'ACCOUNTANT',
          'HEADER_OF_CLEANER', 'GUEST_SUPPORT', 'PPROPERTY_OWNER', 'CONCIERAGE']
      },
      {
        path: '/dashboard/billing', roles: ['ADMINISTRATOR', 'MANAGER', 'ACCOUNTANT',
          'HEADER_OF_CLEANER', 'GUEST_SUPPORT', 'PPROPERTY_OWNER', 'CONCIERAGE']
      },
      {
        path: '/dashboard/success', roles: ['ADMINISTRATOR', 'MANAGER', 'ACCOUNTANT',
          'HEADER_OF_CLEANER', 'GUEST_SUPPORT', 'PPROPERTY_OWNER', 'CONCIERAGE']
      },
      {
        path: '/dashboard/failure', roles: ['ADMINISTRATOR', 'MANAGER', 'ACCOUNTANT',
          'HEADER_OF_CLEANER', 'GUEST_SUPPORT', 'PPROPERTY_OWNER', 'CONCIERAGE']
      },
      {
        path: '/dashboard/calendar', roles: ['ADMINISTRATOR', 'MANAGER', 'ACCOUNTANT',
          'HEADER_OF_CLEANER', 'GUEST_SUPPORT', 'PPROPERTY_OWNER', 'CONCIERAGE', 'OWNER']
      },
      {
        path: '/dashboard/bookings-table', roles: ['ADMINISTRATOR', 'MANAGER', 'ACCOUNTANT',
          'HEADER_OF_CLEANER', 'GUEST_SUPPORT', 'PPROPERTY_OWNER', 'CONCIERAGE', 'OWNER']
      },
      {
        path: '/dashboard/revenue-report', roles: ['ADMINISTRATOR', 'MANAGER', 'ACCOUNTANT',
          'HEADER_OF_CLEANER', 'GUEST_SUPPORT', 'PPROPERTY_OWNER', 'CONCIERAGE', 'OWNER']
      },
      {
        path: '/dashboard/statement', roles: ['ADMINISTRATOR', 'MANAGER', 'ACCOUNTANT',
          'HEADER_OF_CLEANER', 'GUEST_SUPPORT', 'PPROPERTY_OWNER', 'CONCIERAGE', 'OWNER']
      },
      {
        path: '/dashboard/request/inbox', roles: ['ADMINISTRATOR', 'MANAGER', 'ACCOUNTANT',
          'HEADER_OF_CLEANER', 'GUEST_SUPPORT', 'PPROPERTY_OWNER', 'CONCIERAGE', 'OWNER']
      },
      
      {
        path: '/dashboard/users', roles: ['ADMINISTRATOR', 'MANAGER', 'ACCOUNTANT',
          'HEADER_OF_CLEANER', 'GUEST_SUPPORT', 'PPROPERTY_OWNER', 'CONCIERAGE']
      },
      {
        path: '/profile/edit', roles: ['ADMINISTRATOR']
      },
      {
        path: '/profile', roles: ['ADMINISTRATOR', 'MANAGER', 'ACCOUNTANT', 'CLEANER',
          'HEADER_OF_CLEANER', 'GUEST_SUPPORT', 'PPROPERTY_OWNER', 'CONCIERAGE','OWNER']
      },
      { path: '/jobAssign', roles: ['ADMINISTRATOR', 'CLEANER'] },
      { path: '/ownerDashboard', roles: ['OWNER'] },
      { path: '/ownerDetails', roles: ['ADMINISTRATOR'] },
      // {path: '/ownerPayouts', roles: ['ADMINISTRATOR']},
      {
        path: '/ownerPayouts', roles: ['ADMINISTRATOR', 'MANAGER', 'ACCOUNTANT',
          'HEADER_OF_CLEANER', 'GUEST_SUPPORT', 'PPROPERTY_OWNER', 'CONCIERAGE', 'OWNER']
      },
      { path: '/plugins', roles: ['ADMINISTRATOR'] },
      { path: '/adminDashboard', roles: ['SUPER_ADMINISTRATOR'] },
      { path: '/login', roles: ['ADMINISTRATOR', 'OWNER'] },

    ];
    const check = permissions.filter(item => {
      return route !== '/' && route.includes(item.path);
    });

    if (route === '/') {
      check.push(permissions[0]);
      route = '/dashboard';
    }
    if (route === '/login' && (role === 'ADMINISTRATOR' || role === 'OWNER')) {
      check.push(permissions[0]);
      route = '/dashboard';
    }
    if (check.length > 1 && check[1].roles.indexOf(role) >= 0) {
      return true;
    }

    if (check[0].path === route) {
      return true;
    }




    return false;
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    var pathArray = state.url.split( '/' );
    var protocol = pathArray[0];
    var host = pathArray[2];
    var url = protocol + '//' + host;

    if (!this.authService.isLoggedIn && decodeURI(state.url) !='/login') {
      this.authService.isLoggedIn=true;
    this.authService.redirectUrl = decodeURI(state.url);
  }
    let token: string
    if (this.tokenService.getToken()) {
      token = this.tokenService.getToken();
    } else {
      if (localStorage.getItem(tokenKey)) {
        token = localStorage.getItem(tokenKey);
        this.tokenService.saveToken(localStorage.getItem(tokenKey));
      }
    }
    if ((token) && (token.length > 0)) {
      let role;
      if (this.tokenService.getCurrentUser()) {
        role = this.tokenService.getCurrentUser();
      } else {
        if (localStorage.getItem(currentUserKey)) {
          role = localStorage.getItem(currentUserKey);
          this.tokenService.saveCurrentUser(role);
        }
      }
      const hasPermissions = this.hasCheckPermission(state.url, role);
      if (hasPermissions) {
        this.performRouting(state.url, role);
        return hasPermissions;
      } else {
//        this.router.navigate(['/forbidden']);
        this.router.navigate(['/forbidden'], { queryParams: { attemptedUrl: state.url } });

      }




    } else {
      if (state.url !== '/login') {
        this.router.navigate(['/login']);
      } else {
        return true;
      }
    }
    return false;

  }

}
