import {Component, ElementRef,ChangeDetectorRef , Input, OnInit, ViewChild} from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {HttpErrorResponse} from '@angular/common/http';
import {GenericDto} from '../../../models/generic-dto';
import {info, inviteMessage, success, successUserUpdated} from '../../../constants/notification-messages.constant';
import {ActivatedRoute, Router} from '@angular/router';
import {User} from '../../../models/user';
import {DataService} from '../../../shared/services/data.service';
import {UserService} from '../../../services/user.service';
import {emailValidator} from '../../../validators/email.validator';
import {Property} from '../../../models/property';
import {PropertyService} from '../../../services/property.service';
import {Subscription} from 'rxjs';
import {MatDialog, MatRadioChange, MatSelectChange} from '@angular/material';
import {OwnerService} from '../../../services/owner.service';
import {Owner} from '../../../models/owner';
import {RoomType} from '../../../models/RoomType';
import {contactNumbersValidator, numbersOnlyValidator} from '../../../validators/number.validator';
import {EventService} from '../../../shared/services/event.service';
import {tokenKey} from "../../../constants/storage.constants";
import {TokenService} from "../../../core/services/token.service";
import { OwnerDto } from 'src/app/models/owner-dto';
import { AdditionaDetail } from 'src/app/models/additional-detail';
export interface Currency {
  name: string;
  code: string;
}

@Component({
  selector: 'app-add-cleaner',
  templateUrl: './add-cleaner.component.html',
  styleUrls: ['./add-cleaner.component.scss']
})

export class AddCleanerComponent implements OnInit {
  addCleanerForm: FormGroup;
  @Input() event: any;
  properties: Array<Property>;
  tempProperties: Array<Property>;
  user: User;
  loginUserCurrency:any ;
  watcher: Subscription;
  isLoading = false;
  editForm = false;
  emptyState = true;
  emailHide = false;
  roleHide = false;
  propertyId = 'all';
  // selectedProperty: Property;
  rentalSearchValue:any=new FormControl('');
  selectedRentals: string [] = [];
  additionalDetails: AdditionaDetail[] = [];
  editProperty = false;
  editPropetiesDetails = false;
  isRoleSelected = false;
  inviteUserEdit = true;
  roomList = [];
  owner: Owner;
  isLoad = false;
  isOwner: boolean;
  roomId: string;
  isAdmin: boolean;
  containsViewRevenue: boolean;
  ownerPermissions = ['View Revenue', 'View Properties', 'Reservation Request'];
  ownerPermission: any;
  columnPermissions = ['Booking amount', 'Booking Base Price', 'Platform Commission', 'Cleaning Fee', 'Tax',
    'Payment Processing', 'Management Commission', 'Expenses', 'Net Profit', 'Allow Owner Reservation'];
  ownerPriceMultiplierDescription = 'Owner will view Booking Amount as [Booking Amount] * [multiplier]';
  selectedValue:any=[];
  propertyDropDown = [];
  propertyRoomList = [];
  propertyRoomListFilter = [];
  dayOfMonths:any;
  ownerData = new FormControl('');
  dayOfMonth=[{key:"First Day of the Month",value:"FIRST_DAY"},
  {key:"LAST Day of the Month",value:"LAST_DAY"}]
  // propertyManagementCommission = new FormControl('', [Validators.required, Validators.compose([numbersOnlyValidator])]);
  // paymentProcessingFeeInPercent = new FormControl('', [Validators.required, Validators.compose([percentageValidator])]);
  // paymentProcessingFeeInAmount = new FormControl('', [Validators.required, Validators.compose([numbersOnlyValidator])]);
  phoneNumber = new FormControl('', [Validators.compose([contactNumbersValidator])]);
  ownerPriceMultiplier = new FormControl('', [Validators.required, Validators.compose([numbersOnlyValidator])]);
  enableDownloadInvoice: boolean;
  enableRequestInbox: boolean;
  enableBookingNotificationEmail: boolean;
  enableNotification:boolean;
  automaticStatementEnabled : boolean =false;
  notificationDelay : any =0;
  reportType:any = 'ProRata';
  reportGenerationDate : any;

  // hideDatepicker: boolean=false;
  notificationDayOfWeek: any;
  currencies: Currency[] = [
    { name: 'US Dollar', code: 'USD' },
    { name: 'EURO', code: 'EUR' },
    { name: 'Canadian Dollar', code: 'CAD' },
    { name: 'British Pound', code: 'GBP' },
    { name: 'Australian Dollar', code: 'AUD' }
  ];

  constructor(private cdr: ChangeDetectorRef, public dialog: MatDialog, private fb: FormBuilder, private router: Router,
              private toastrService: ToastrService, private userService: UserService, private ownerService: OwnerService,
              private dataService: DataService, private route: ActivatedRoute, private tokenService: TokenService, protected eventService: EventService, private propertyService: PropertyService) {

  }

  fetchAllPropertyBookings(event: MatSelectChange): void {
    if (event.value === 'all') {
      this.propertyId = 'all';
    }
  }


  ngOnInit() {
    if (this.router.url.includes('/edit/')) {
      this.editForm = true;
      this.isLoad = true;
      this.roleHide = true;
      this.isRoleSelected = true;
      this.route.params.subscribe((data: { id: string }) => {
        this.userService.getUserDetails(data.id)
          .subscribe((response: GenericDto<User>) => {
            if (response.code === 200) {
              this.user = response.payload as User;
              if (this.user.role === 'OWNER') {
                this.isOwner = true;
                this.editPropetiesDetails = true;
                this.watcher = this.propertyService.getAllProperties('updation', this.user.id)
                  .subscribe((data1: GenericDto<Array<Property>>) => {
                      if (data1.code === 200 || data1.code === 404) {
                        this.properties = data1.payload || [];
                        this.propertyDropDown = this.properties;
                        this.updatePropertyRoomList()
                        this.userService.getOwnerDetails(this.user.id)
                        .subscribe((responseData: GenericDto<OwnerDto> | any)=>{
                          if(responseData.code === 200){                             
                            this.user.ownerPriceMultiplier = responseData.payload.ownerPriceMultiplier;
                            this.enableDownloadInvoice=responseData.payload.enableDownloadInvoice
                            this.enableBookingNotificationEmail = responseData.payload.enableBookingNotificationEmail;
                            this.enableRequestInbox = responseData.payload.enableRequestInbox
                            this.enableNotification=responseData.payload.enableNotification;
                            this.notificationDayOfWeek=responseData.payload.notificationDayOfWeek;
                            this.selectedRentals = responseData.payload.roomIdUnitIdMap;
                            this.additionalDetails = responseData.payload.additionalDetails;
                            this.automaticStatementEnabled = responseData.payload.automaticStatementEnabled;
                            if(this.automaticStatementEnabled) {
                              this.notificationDelay =  responseData.payload.automaticStatementConfiguration.notificationDelay;
                              this.reportType =  responseData.payload.automaticStatementConfiguration.reportType;
                              this.reportGenerationDate =  responseData.payload.automaticStatementConfiguration.reportGenerationDate;

                            }

                          }
                          this.editCleanerForm(this.selectedRentals, this.user);
                        }, (error: HttpErrorResponse)=>{
                          console.log("Error");
                        })
                      }
                    },
                    (error: HttpErrorResponse) => {
                      // show error message
                      /* this.isLoading = false;*/
                      console.log("Error: ", error);
                    });

              } else {
                this.isOwner = false;
                this.getProperties('updation', this.user.id);
                this.editCleanerForm(null, this.user);
                //  this.userFillForm();
              }
              // create filled Form
              this.isLoading = false;
              this.emptyState = false;
              this.inviteUserEdit = false;

            } else {
              // show error message
            }
            // this.isLoading = false;
          }, (error: HttpErrorResponse) => {
            // show error message
            //  this.isLoading = false;
          });
      });
    } else {
      this.emailHide = true;
      this.emptyState = false;
      this.roleHide = false;
      this.editProperty = true;
      this.getProperties('creation', 'new');

      let event: any = {'value': 'OWNER'};
      this.radioChange(event);

      if (this.tokenService.getPreviewUser() && this.tokenService.getPreviewUser() != 'null') {
        let previewOwner = JSON.parse(this.tokenService.getPreviewUser());

        this.addCleanerForm = this.fb.group({
          firstName: [previewOwner.firstName, [Validators.required]],
          lastName: [previewOwner.lastName, [Validators.required]],
          currency:[previewOwner.currency, [Validators.required]],
          phoneNumber: new FormControl(previewOwner.phone, [Validators.compose([contactNumbersValidator])]),
          email: new FormControl(previewOwner.email, [Validators.required, Validators.compose([emailValidator])]),
          enableDownloadInvoice: new FormControl(previewOwner.checked, [Validators.required]),
          enableBookingNotificationEmail: new FormControl(previewOwner.checked, [Validators.required]),
          enableRequestInbox: new FormControl(previewOwner.checked, [Validators.required]),
          // enableNotification:new FormControl(previewOwner.checked, [Validators.required]),
          role: ['OWNER', [Validators.required]],
          roomIds: [previewOwner.roomIds],
          // propertyManagementCommission: new FormControl(20, [Validators.required, Validators.compose([numbersOnlyValidator])]),
          ownerData: [previewOwner.permissionList],
          // paymentProcessingFeeInPercent: new FormControl(0, [Validators.required, Validators.compose([percentageValidator])]),
          // paymentProcessingFeeInAmount: new FormControl(0, [Validators.required, Validators.compose([numbersOnlyValidator])]),
          ownerPriceMultiplier: new FormControl(previewOwner.ownerPriceMultiplier, [Validators.required, Validators.compose([numbersOnlyValidator])]),
          additionalDetails: new FormArray([
          ])
        });
        this.tokenService.setPreviewUser(null);
      } else {
        this.getLoginUserDetails();
      }
    }
  // this.userService.inviteUser().subscribe(res)

  }

  updatePropertyRoomList() {
    if (this.propertyDropDown) {
      this.propertyDropDown.forEach(property => {
        if (property.roomList) {
          property.roomList.forEach(room => {
            this.propertyRoomList.push({
              label: room.name + " | " + property.propertyName,
              propertyId: property.propertyId,
              propertyName: property.propertyName,
              roomId: room.id,
              roomName: room.name,
              unitItems: room.unitItems
                ? room.unitItems
                  .filter(unitItem => unitItem && unitItem.unitId > 0)
                  .map(unitItem => ({
                    ...unitItem,
                    unitName: (unitItem.unitName)
                      ? unitItem.unitName
                      : `Unit ${unitItem.unitId}`,
                    unitSelectKey: `${room.id},${unitItem.unitId}`
                  }))
              : [],
            });
          })
        }
      });
    }
    this.propertyRoomListFilter = this.propertyRoomList;
  }


  radioChange(event: MatRadioChange) {
    if (event.value === 'OWNER') {
      this.editProperty = true;
      this.isRoleSelected = true;
    } else if (event.value === 'ADMINISTRATOR') {
      this.isRoleSelected = true;
      this.isAdmin = false;
      this.editProperty = false;
    } else {
      this.isRoleSelected = true;
      this.editProperty = false;
    }
  }

  fetchRooms(propId: string): void {
    this.roomList = null;
    this.propertyService.getRooms(propId)
      .subscribe((data: GenericDto<RoomType[]>) => {
          if (data.code === 200) {
            this.roomList = data.payload;
          } else {
            // show error message
          }
          this.isLoading = false;
        },
        (error: HttpErrorResponse) => {
          // show error message
        });
  }

  getProperties(ownerStatus, ownerId): void {
    this.isLoading = true;
    this.watcher = this.propertyService.getAllProperties(ownerStatus, ownerId)
      .subscribe((data: GenericDto<Array<Property>>) => {
        if (data.code === 200) {
          this.properties = data.payload || [];
          this.propertyDropDown = this.properties;
          this.updatePropertyRoomList()
        } else {
          // show error message
        }
        this.isLoading = false;
      }, (error: HttpErrorResponse) => {
        // show error message
        this.isLoading = false;
      });
  }


  goBack(): void {
    this.router.navigate(['/dashboard/users']);
  }

  private createCleanerForm() {
    
    this.addCleanerForm = this.fb.group({
      firstName: [null, [Validators.required]],
      lastName: [null, [Validators.required]],
      currency:[this.loginUserCurrency, [Validators.required]],
      phoneNumber: new FormControl('', [Validators.compose([contactNumbersValidator])]),
      email: new FormControl('', [Validators.required, Validators.compose([emailValidator])]),
      enableDownloadInvoice: new FormControl(false, [Validators.required]),
      enableBookingNotificationEmail: new FormControl(false, [Validators.required]),
      enableRequestInbox: new FormControl(false, [Validators.required]),
      enableNotification:new FormControl(false, [Validators.required]),
      notificationDayOfWeek:new FormControl(new Date(), [Validators.required]),
      role: ['OWNER', [Validators.required]],
      automaticStatementEnabled:new FormControl(false, [Validators.required]),
      roomIds: [null],
      // propertyManagementCommission: new FormControl(20, [Validators.required, Validators.compose([numbersOnlyValidator])]),
      ownerData: new FormControl(''),
      // paymentProcessingFeeInPercent: new FormControl(0, [Validators.required, Validators.compose([percentageValidator])]),
      // paymentProcessingFeeInAmount: new FormControl(0, [Validators.required, Validators.compose([numbersOnlyValidator])]),
      ownerPriceMultiplier: new FormControl(100, [Validators.required, Validators.compose([numbersOnlyValidator])]),
      additionalDetails: new FormArray([
      ])
    });
  }

  private editCleanerForm(roomIdUnitIdList: any, user: User) {

    let formArray = new FormArray([]);
    if( this.additionalDetails != null && this.additionalDetails.length > 0){
      this.additionalDetails.forEach(detail => {
        formArray.push(
          new FormGroup({
            fieldName: new FormControl(detail.fieldName),
            value: new FormControl(detail.value),
            fieldType: new FormControl(detail.fieldType),
            isDisplay: new FormControl(detail.isDisplay),
          })
        )
      })
    }

    if (this.tokenService.getPreviewUser() && this.tokenService.getPreviewUser() != 'null') {
      let previewOwner = JSON.parse(this.tokenService.getPreviewUser());
      this.addCleanerForm = this.fb.group({
        firstName: [previewOwner.firstName, [Validators.required]],
        lastName: [previewOwner.lastName, [Validators.required]],
        phoneNumber: new FormControl(previewOwner.phone, [Validators.compose([contactNumbersValidator])]),
        email: new FormControl(previewOwner.email, [Validators.required, Validators.compose([emailValidator])]),
        enableDownloadInvoice: new FormControl(this.enableDownloadInvoice, [Validators.required]),
        enableBookingNotificationEmail: new FormControl(this.enableBookingNotificationEmail
          , [Validators.required]),

        enableRequestInbox: new FormControl(this.enableRequestInbox, [Validators.required]),

        enableNotification:new FormControl(this.enableNotification, [Validators.required]),
        automaticStatementEnabled:new FormControl(false),
        notificationDayOfWeek:new FormControl(this.notificationDayOfWeek, [Validators.required]),

        role: ['OWNER', [Validators.required]],
        roomIds: [previewOwner.roomIds],
        // propertyManagementCommission: new FormControl(20, [Validators.required, Validators.compose([numbersOnlyValidator])]),
        ownerData: [previewOwner.permissionList],
        // paymentProcessingFeeInPercent: new FormControl(0, [Validators.required, Validators.compose([percentageValidator])]),
        // paymentProcessingFeeInAmount: new FormControl(0, [Validators.required, Validators.compose([numbersOnlyValidator])]),
        ownerPriceMultiplier: new FormControl(previewOwner.ownerPriceMultiplier, [Validators.required, Validators.compose([numbersOnlyValidator])]),
        additionalDetails: formArray
      });
      this.tokenService.setPreviewUser(null);
    } else {
       
      console.log("user =", user)

      this.addCleanerForm = this.fb.group({
        firstName: [user.firstName, [Validators.required]],
        currency: [user.currency, [Validators.required]],
        lastName: [user.lastName, [Validators.required]],
        phoneNumber: new FormControl(user.phoneNumber, [Validators.compose([contactNumbersValidator])]),
        email: [user.email],
        enableDownloadInvoice:this.enableDownloadInvoice,
        enableBookingNotificationEmail : this.enableBookingNotificationEmail,
        enableRequestInbox:this.enableRequestInbox,
        enableNotification:this.enableNotification,
        automaticStatementEnabled:new FormControl(false),
        notificationDayOfWeek:this.notificationDayOfWeek,
        role: [user.role, [Validators.required]],
        roomIds: [roomIdUnitIdList],
        ownerData: new FormControl(user.permission),
        /*propertyManagementCommission: [this.propertyManagementCommission, [Validators.required]],*/
        // paymentProcessingFeeInPercent: new FormControl(user.paymentProcessingFeeInPercent,
        //   [Validators.required, Validators.compose([percentageValidator])]),
        // paymentProcessingFeeInAmount: new FormControl(user.paymentProcessingFeeInAmount,
        //   [Validators.required, Validators.compose([numbersOnlyValidator])]),
        ownerPriceMultiplier: new FormControl(user.ownerPriceMultiplier,
          [Validators.required, Validators.compose([numbersOnlyValidator])]),
        additionalDetails: formArray
      });
    }
    this.changeAutomaticStatement();

    if (user.permission.includes('View Revenue')) {
      this.containsViewRevenue = true;
    } else {
      this.containsViewRevenue = false;
    }
    this.isLoad = false;
  }

  searchProperty(searchValue): void {
    this.propertyRoomList = [];
    this.propertyDropDown = [];
    if (searchValue && searchValue.trim().length > 0) {
      if(this.selectedRentals.length>0 && searchValue.trim().length == 1){
        this.selectedRentals.forEach(element=>{
          this.selectedValue.push(element);
        })
      }
      for (let property of this.propertyRoomListFilter) {
        if (property.label.trim().toLowerCase().includes(searchValue.trim().toLowerCase())) {
          this.propertyRoomList.push(property);
        } else {
          for (let unitItem of property.unitItems) {
            if ((unitItem.unitName + '').trim().toLowerCase().includes(searchValue.trim().toLowerCase())) {
              this.propertyRoomList.push(property);
              break;
            }
          }
        }
      }
    } else {
      if(this.selectedRentals.length>0){
      this.selectedValue.forEach(element=>{
        if(!this.selectedRentals.includes(element)){
          this.selectedRentals.push(element)
        }
      })}
      this.propertyDropDown = this.properties;
      this.propertyRoomList = this.propertyRoomListFilter;
    }
  }

  triggerEvent(ev:any){
    this.rentalSearchValue.setValue('');
    this.searchProperty('');
  }

  previewOwner(): void {
    if (this.ownerPermission) {
      this.ownerPermission.push('View Properties');
      this.ownerPermission.push('View Revenue');
    }
    var editUserId = null;
    if (this.user && this.user.id != null) {
      editUserId = this.user.id;
    }
    this.userService.previewOwnerPortal(editUserId, {...this.addCleanerForm.getRawValue()})
      .subscribe(
        (response: GenericDto<any>) => {
          if (response.code === 200) {
            console.log("Preview portal url", response);
            //this.router.navigate(['/dashboard/users']);
            this.tokenService.saveToken(response.payload);
            this.tokenService.saveRole("OWNER");
            this.tokenService.setPreviewLogin(true);
            localStorage.setItem(tokenKey, response.payload);
            //this.router.navigate(['/ownerDashboard']);
            location.replace('ownerDashboard');
          } else if (response.code === 404) {
            this.toastrService.info('Failed to load preview portal ', info, {
              timeOut: 3000,
              onActivateTick: true
            });
          }
        },
        (error: HttpErrorResponse) => {
          console.log(error);
          this.isLoading = false;
        }
      )
  }

  createCleaner(): void {
    this.isLoading = true;
    if (this.ownerPermission) {
      //adding these by defaults to not break existing flow.
      this.ownerPermission.push('View Properties');
      this.ownerPermission.push('View Revenue');
    }

    if (this.addCleanerForm.get('role').value === 'ADMINISTRATOR') {
      console.log('here role ADMINISTRATOR')
      console.log(...this.addCleanerForm.getRawValue());
      this.toastrService.info("Creating Admin Will Take some time...", info, {
        timeOut: 3000,
        onActivateTick: true
      });

      this.userService.inviteUser({...this.addCleanerForm.getRawValue()})
        .subscribe(
          (response: GenericDto<User[]>) => {
            if (response.code === 200) {
              this.toastrService.success(inviteMessage, success, {
                timeOut: 3000,
                onActivateTick: true
              });
              this.dataService.customEvent.next();
              this.router.navigate(['/dashboard/users']);
            } else if (response.code === 404) {
              this.toastrService.info('Invitation Already Send ', info, {
                timeOut: 3000,
                onActivateTick: true
              });
            }
            this.isLoading = false;
          },
          (error: HttpErrorResponse) => {
            console.log(error);
            this.isLoading = false;
          }
        );
    } else if (!this.editForm) {
      if (this.addCleanerForm.get('role').value === 'OWNER') {
         if (this.addCleanerForm.get('roomIds').value === null || this.addCleanerForm.get('roomIds').value.toString().length === 0) {
          this.toastrService.info('Please Select Properties For Owner', info, {
            timeOut: 3000,
            onActivateTick: true
          });


        }
        if ((this.addCleanerForm.get('ownerData').value === null) || (this.addCleanerForm.get('ownerData').value.toString().length === 0) && (this.addCleanerForm.get('role').value !== 'CLEANER')) {
          this.toastrService.info('Please Select Permissions', info, {
            timeOut: 3000,
            onActivateTick: true
          });
        } else if (this.ownerPermission) {

          let automaticStatementConfiguration = null;

          if(this.automaticStatementEnabled){
            automaticStatementConfiguration = {
              reportType:this.addCleanerForm.get('reportType').value,
              reportGenerationDate:this.addCleanerForm.get('reportGenerationDate').value,
              notificationDelay:this.addCleanerForm.get('notificationDelay').value,
            }
          }

          this.userService.inviteUser({
            ...this.addCleanerForm.getRawValue(),
            permission: this.addCleanerForm.get('ownerData').value,
            automaticStatementConfiguration:automaticStatementConfiguration

          }).subscribe((data: GenericDto<User[]>) => {
              if (data.code === 200) {
                
                this.toastrService.success("Owner created successfully.", success, {
                  timeOut: 3000,
                  onActivateTick: true
                });
                this.dataService.customEvent.next();
                this.router.navigate(['/dashboard/users']);
              } else if (data.code === 404) {
                this.toastrService.info('Invitation Already Send ', info, {
                  timeOut: 3000,
                  onActivateTick: true
                });
              }
              this.isLoading = false;
            },
            (error: HttpErrorResponse) => {
              console.log(error);
              this.isLoading = false;
            });
        } else if (!this.ownerPermission) {
          this.userService.inviteUser({
            ...this.addCleanerForm.getRawValue(),
            permission: ['View Revenue', 'View Properties', 'Reservation Request']
          }).subscribe((data: GenericDto<User[]>) => {
              if (data.code === 200) {
                this.toastrService.success(inviteMessage, success, {
                  timeOut: 3000,
                  onActivateTick: true
                });
                this.dataService.customEvent.next();
                this.router.navigate(['/dashboard/users']);
              } else if (data.code === 404) {
                this.toastrService.info('Invitation Already Send ', info, {
                  timeOut: 3000,
                  onActivateTick: true
                });
              }
              this.isLoading = false;
            },
            (error: HttpErrorResponse) => {
              console.log(error);
              this.isLoading = false;
            });
        }
        this.isLoading = false;
      } else {
        this.userService.inviteUser({
          ...this.addCleanerForm.getRawValue(),
          permission: []
        }).subscribe((data: GenericDto<User[]>) => {
            if (data.code === 200) {
              this.toastrService.success(inviteMessage, success, {
                timeOut: 3000,
                onActivateTick: true
              });
              this.dataService.customEvent.next();
              this.router.navigate(['/dashboard/users']);
            } else if (data.code === 404) {
              this.toastrService.info('Invitation Already Send ', info, {
                timeOut: 3000,
                onActivateTick: true
              });
            }
            this.isLoading = false;
          },
          (error: HttpErrorResponse) => {
            console.log(error);
            this.isLoading = false;
          });
      }
    }


    else if (
      (this.addCleanerForm.get('role').value !== 'CLEANER') &&
      (this.editForm) &&
      (!this.ownerPermission) &&
      (this.addCleanerForm.get('role').value == 'OWNER') &&
      (
        (this.addCleanerForm.get('ownerData').value === null) ||
        (this.addCleanerForm.get('ownerData').value.toString().length === 0)
      )) {
      this.isLoading = false;
      this.toastrService.info('Please Select Permissions', info, {
        timeOut: 3000,
        onActivateTick: true
      });
    } else {
      // update property
      this.user.firstName = this.addCleanerForm.get('firstName').value;
      this.user.lastName = this.addCleanerForm.get('lastName').value;
      this.user.phoneNumber = this.addCleanerForm.get('phoneNumber').value;
      this.user.role = this.addCleanerForm.get('role').value;
      this.user.email = this.addCleanerForm.get('email').value;
      this.user.enableDownloadInvoice =this.addCleanerForm.get('enableDownloadInvoice').value;
      this.user.enableBookingNotificationEmail =this.addCleanerForm.get('enableBookingNotificationEmail').value;
      this.user.enableRequestInbox =this.addCleanerForm.get('enableRequestInbox').value;


      this.user.enableNotification =this.addCleanerForm.get('enableNotification').value;
      this.user.notificationDayOfWeek=this.addCleanerForm.get('enableNotification').value==true?this.addCleanerForm.get('notificationDayOfWeek').value:null;
      this.user.currency =  this.addCleanerForm.get('currency').value;
      this.additionalDetails = this.addCleanerForm.get('additionalDetails').value;
      // this.user.paymentProcessingFeeInPercent = this.addCleanerForm.get('paymentProcessingFeeInPercent').value;
      // this.user.paymentProcessingFeeInAmount = this.addCleanerForm.get('paymentProcessingFeeInAmount').value;
      this.user.ownerPriceMultiplier = this.addCleanerForm.get('ownerPriceMultiplier').value;
      this.user.permission = this.addCleanerForm.get('ownerData').value;
      this.userService.updateUser(this.user).subscribe((response: GenericDto<any>) => {
        if (response.code === 200) {
          if (this.user.role === 'OWNER') {
            console.log("this.addCleanerForm: ", this.addCleanerForm);
            console.log("this.addCleanerForm.get('roomIds'): ", this.addCleanerForm.get('roomIds'));
            console.log("this.addCleanerForm.get('roomIds').value: ", this.addCleanerForm.get('roomIds').value);

            // create list of valid rental unit selections to remove any units that no longer exist but might be invisibly selected
            const allUniqueUnitKeys = [];
            this.propertyRoomList.forEach(rental => {
              if(rental.unitItems && rental.unitItems.length > 0) {
                rental.unitItems.forEach(unitItem => {
                  allUniqueUnitKeys.push(rental.roomId + ":" + unitItem.unitId)
                })
              }
            });
            console.log("allUniqueUnitKeys: ", allUniqueUnitKeys);
            console.log("index of: ", allUniqueUnitKeys.indexOf("635c998dba2f5d1917a6475f,1"));

            let arr = this.addCleanerForm.get('roomIds').value
            arr.sort()
            let roomUnitIds = ''
            for (let i = 0; i < arr.length; i++) {
              let substr = arr[i].split(',')
              let newSelection = substr[0] + ':' + substr[1];
              if(allUniqueUnitKeys.indexOf(newSelection) < 0) {
                console.log("does not exist anymore, should skip this", newSelection);
                continue;
              }
              if (i == (arr.length - 1)) {
                roomUnitIds = roomUnitIds + newSelection
              } else {
                roomUnitIds = roomUnitIds + newSelection + ','
              }
            }
            console.log("enableNotification", this.addCleanerForm.get('enableNotification').value);
            console.log("notificationDayOfWeek", this.addCleanerForm.get('notificationDayOfWeek').value);
            console.log("roomUnitIds", roomUnitIds);

            let automaticStatementConfiguration = null;

            if(this.automaticStatementEnabled) {
              automaticStatementConfiguration = {
                reportType:this.addCleanerForm.get('reportType').value,
                reportGenerationDate:this.addCleanerForm.get('reportGenerationDate').value,
                notificationDelay:this.addCleanerForm.get('notificationDelay').value,
              }
            }

            let payload : any = {
              automaticStatementEnabled:this.automaticStatementEnabled,
              automaticStatementConfiguration:automaticStatementConfiguration,
              enableDownloadInvoice: this.addCleanerForm.get('enableDownloadInvoice').value,
              enableBookingNotificationEmail: this.addCleanerForm.get('enableBookingNotificationEmail').value,
              enableRequestInbox: this.addCleanerForm.get('enableRequestInbox').value,

              additionalDetails:this.additionalDetails,
              enableNotification: this.addCleanerForm.get('enableNotification').value,
              notificationDayOfWeek:this.addCleanerForm.get('enableNotification').value === true
                ? this.addCleanerForm.get('notificationDayOfWeek').value
                : '',


            };
            this.ownerService.updateOwnerProperties(this.user.id, roomUnitIds, payload).subscribe((responseData: GenericDto<any>) => {
              if (responseData.code === 200) {
                this.toastrService.success(successUserUpdated, success, {
                  timeOut: 3000,
                  onActivateTick: true
                });
                this.dataService.customEvent.next();
                this.router.navigate(['/dashboard/users/' + response.payload.id]);
              } else {
                // show errors here
              }
            }, (error: HttpErrorResponse) => {
              this.isLoading = false;
              // show errors here
            });
          } else {
            this.toastrService.success(successUserUpdated, success, {
              timeOut: 3000,
              onActivateTick: true
            });
            this.dataService.customEvent.next();
            this.router.navigate(['/dashboard/users/' + response.payload.id]);
          }
        } else {
          // show errors here
        }
        this.isLoading = false;
      }, (error: HttpErrorResponse) => {
        this.isLoading = false;
        // show errors here
      });

    }
  }



  showDatePicker(event){

    this.enableNotification=event.checked==true?true:false;
  }

  validateReportGenerationDate(control: FormControl) {
    const value = control.value;
    if (value !== null && (value < 1 || value > 31)) {
      return { invalidDate: true };
    }
    return null;
  }
  validateNotificationDelay(control: FormControl) {
    const value = control.value;
    if (value !== null && (value < 0 || value > 24)) {
      return { invalidDate: true };
    }
    return null;
  }



  changeAutomaticStatement(){
    if(this.automaticStatementEnabled){
     this.addCleanerForm.addControl('notificationDelay', this.fb.control(null, [this.validateNotificationDelay]));
     this.addCleanerForm.addControl('reportGenerationDate', this.fb.control(null, [Validators.required,this.validateReportGenerationDate]));
     this.addCleanerForm.addControl('reportType', this.fb.control('ProRata', [Validators.required])); // Add 'reportType' control

    }else{
      this.addCleanerForm.removeControl('notificationDelay');
      this.addCleanerForm.removeControl('reportGenerationDate');
      this.addCleanerForm.removeControl('reportType'); // Remove 'reportType' control

    }

    this.cdr.detectChanges();

  }


  userFillForm(): void {
    Object.keys(this.addCleanerForm.controls).forEach((key: string) => {
      if (!!this.addCleanerForm.get(key)) {
        this.addCleanerForm.get(key).setValue(this.user[key]);
      }
    });
  }

  onPermissionChange(event: any) {
    let permissionSelected = event.value;
    if (permissionSelected.includes('View Revenue')) {
      this.containsViewRevenue = true;
    } else {
      this.containsViewRevenue = false;
    }
  }
  
  onFieldTypeChange(newType: string, index: number) {
    const additionalDetailsArray = this.addCleanerForm.get('additionalDetails') as FormArray;
    const fieldControl = additionalDetailsArray.at(index).get('value');
   
    // Reset previous validation
    fieldControl.clearValidators();
    fieldControl.updateValueAndValidity();

    // Apply URL validation if Web-URL is selected
    if (newType === 'Web-URL') {      
      fieldControl.setValidators([
        Validators.required,
      Validators.pattern("((http|https)://)?(www.)?[a-zA-Z0-9@:%._\\+~#?&//=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%._\\+~#?&//=]*)")
      ]);
      this.cdr.detectChanges();      
    }
  }  

  addMoreDetails(){
    console.log(this.addCleanerForm)
    const control = <FormArray> this.addCleanerForm.controls['additionalDetails'];
    control.push(
      new FormGroup({
        fieldName: new FormControl(),
        value: new FormControl(""),
        fieldType: new FormControl("Singe-line-text"),
        isDisplay: new FormControl(false, [ ]),
      })
    )
  }


  removeDetailForm(index: any){
    const control = <FormArray> this.addCleanerForm.controls['additionalDetails'];
    control.removeAt(index);
  }

  onDayChange(ev){

  }

  getLoginUserDetails(){
    this.userService.getUserDetails(localStorage.userId)
    .subscribe((response: GenericDto<User>) => {
      if (response.code === 200 && response.payload.currency) {
        this.loginUserCurrency = response.payload.currency;
      }else{
        this.loginUserCurrency = "USD";
      }
      this.createCleanerForm();
    })
  }

}
