import { Pipe, PipeTransform } from '@angular/core';
import {Property} from '../../models/property';
@Pipe({
  name: 'filterProperty'
})
export class FilterPipe implements PipeTransform {
  transform(items: Property[], searchText: string): Property[] {
    if (!items) {
      return [];
    }
    if (!searchText) {
      return items;
    }
    searchText = searchText.toLowerCase();
    return items.filter(it => {
      return it.name.toLowerCase().includes(searchText) || it.address.toLowerCase().includes(searchText)
      || it.city.toLowerCase().includes(searchText) || it.state.toLowerCase().includes(searchText);
    });
  }
}
