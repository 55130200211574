import { Component, OnInit,Renderer2,ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PropertyService } from 'src/app/services/property.service';
import { GenericDto } from '../../../models/generic-dto';
import { Property } from 'src/app/models/property';
import { RoomType } from 'src/app/models/RoomType';
import { HttpErrorResponse } from '@angular/common/http';
import { ConfirmDialougeComponent } from '../../confirm-dialouge/confirm-dialouge.component';
import { MatDialog } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { success, successPropertyDeleted, errorMessage } from '../../../constants/notification-messages.constant';
import { SharedService } from 'src/app/services/shared.service';


// Declare the global function
declare global {
  interface Window {
    initMap: () => void;
    onGoogleMapsLoaded: () => void;
  }
}
@Component({
  selector: 'app-property-information',
  templateUrl: './property-information.component.html',
  styleUrls: ['./property-information.component.scss']
})
export class PropertyInformationComponent implements OnInit {
  propertyId:any;
  property:any;
  isLoading = false;
  unitItems = [];
  placeName:any; 
  src:any;
  
  roomList:any = [];
  

  constructor(private renderer: Renderer2, private el: ElementRef,  private sharedService :SharedService, public router: Router,private route: ActivatedRoute,private toastrService: ToastrService, public dialog: MatDialog, private propertyService: PropertyService,) {
    let subscription = this.route.params.subscribe((data: { id: string}) => {
      this.propertyId = data.id;
      this.getProperty();
      this.fetchRooms();
      
    });
   }

  ngOnInit() {    
   
  }
  
  ngAfterViewInit() {
    
  }

  getProperty()  {    
    this.propertyService.get(this.propertyId).subscribe((response: GenericDto<Property>) => {
      if (response.code === 200) {
        this.property = response.payload; 
       // this.placeName = this.property.name.replace(new RegExp(' ', 'g'),'+'); 
        this.placeName =','+this.property.address.replace(new RegExp(' ', 'g'),'+'); 
        // this.placeName +=','+this.property.city.replace(new RegExp(' ', 'g'),'+');
        // this.placeName +=','+this.property.state.replace(new RegExp(' ', 'g'),'+');   
        this.createIframe(); 
        
      }
      this.isLoading = false;
    }, error => {
      this.isLoading = false;
    });
  }

  fetchRooms(): void {
    
    this.propertyService.getRooms(this.propertyId)
      .subscribe((data: GenericDto<RoomType[]>) => {        
          if (data.code === 200) {
            this.roomList = data.payload;
            const flatUnitArray = this.roomList.flatMap(room => room.unitItems);
            this.unitItems =  flatUnitArray;
                        
          } else {
            this.roomList = []
            this.unitItems = []
            // show error message
          }
          this.isLoading = false;
        },
        (error: HttpErrorResponse) => {
          // show error message
        });       
  }

  deleteProperty(){
    const dialogRef = this.dialog.open(ConfirmDialougeComponent, {
      width: '460px' , 
      height:'170px',
      panelClass: 'confirm_dialouge',
      data: { title: 'Confirm', message: 'Are you sure you want to perform this action?' }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if(result === true){
          this.propertyService.deleteProperty(this.propertyId).subscribe((response: GenericDto<Property>) => {
            if (response.code === 200) {
              this.toastrService.success(successPropertyDeleted, success, {
                timeOut: 2000,
                onActivateTick: true
              });
              this.sharedService.setshareAndCommunicate({code:"updatePropertyList",data:null});
              this.router.navigate(['/dashboard/property']);     
            }
            this.isLoading = false;
          }, error => {
            this.isLoading = false;
          });         
        }    
      }
    });

    
  }
 
     
  
  
  createIframe() {
    
    this.src = 'https://www.google.com/maps/embed/v1/place?key=AIzaSyCiQ7BlVbHkkGhtGN3tie0W7bAtGv3EoFk&q='+this.placeName;
 
    const iframe = this.renderer.createElement('iframe');
    this.renderer.setAttribute(iframe, 'width', '100%');
    this.renderer.setAttribute(iframe, 'height', '100%');
    // this.renderer.setAttribute(iframe, 'style', 'border:0');
    this.renderer.setAttribute(iframe, 'loading', 'lazy');
    this.renderer.setAttribute(iframe, 'allowfullscreen', '');
    this.renderer.setAttribute(iframe, 'referrerpolicy', 'no-referrer-when-downgrade');
    this.renderer.setAttribute(iframe, 'src', this.src);

    // Append the iframe to the head of the document
    const mapDiv = this.el.nativeElement.querySelector('#map-box');
 // Get a reference to the div with id "map"
 

 // Replace the content of the "map" div with the iframe
 mapDiv.innerHTML = '';
 this.renderer.appendChild(mapDiv, iframe);

  }

}
