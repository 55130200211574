import {NgModule} from '@angular/core';
import {VerifyAccountComponent} from './verify-account.component';
import {SharedModule} from '../shared/shared.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {ForgotPasswordModule} from '../login/forgot-password/forgot-password.module';

@NgModule({
declarations : [VerifyAccountComponent],
imports : [FormsModule, SharedModule, ReactiveFormsModule, CommonModule],
exports : [VerifyAccountComponent],
providers : []
})
export class VerifyAccountModule {

}
