import {Component, OnDestroy, Renderer2} from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component ({
  selector: 'app-access-denied',
  templateUrl: './access-denied.component.html',
  styleUrls: ['./access-denied.component.scss']
})

export class AccessDeniedComponent implements OnDestroy {
  attemptedUrl: string | null = null;
  constructor (private renderer: Renderer2,private route: ActivatedRoute) {
    this.renderer.addClass(document.body, 'login-bg');
  }

  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'login-bg'); // login-bg class remove onDestroy
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.attemptedUrl = params['attemptedUrl'] || null;
    });
  }

  formatAttemptedUrl(url: string | null): string | null {
    if (url) {
      // Remove the first '/' if it exists
      let attemtedUrl=  url.replace(/^\//, '');
      return attemtedUrl;      
    }
    return null;
  }
}
