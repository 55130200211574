import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material";
import {FormGroup} from "@angular/forms";

@Component({
  selector: 'app-number-input',
  templateUrl: './number-input.component.html',
  styleUrls: ['./number-input.component.scss']
})
export class NumberInputComponent implements OnInit {

  enteredNumber = 0;
  myGroup: FormGroup;
  constructor(public dialogRef: MatDialogRef<NumberInputComponent>,
              @Inject(MAT_DIALOG_DATA) public data: { numberInput: string }) {

  }

  ngOnInit() {
  }

  getInput(event){
    this.enteredNumber = event.target.value;
  }

  cancel() {
    this.dialogRef.close('Cancel');
  }

  continue() {
    this.dialogRef.close({numberInput: this.enteredNumber});
  }
}
