import { NgModule, Optional, SkipSelf } from '@angular/core';
import { HttpService } from './services/http.service';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {StorageService} from './services/storage.service';
import {AuthGuard} from './guards/auth.guard';
import {AuthInterceptor} from './interceptors/auth.interceptor';
import {TokenService} from './services/token.service';
import {ToastrModule} from 'ngx-toastr';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ResetGuard} from './guards/reset.guard';
import {ActiveAccountGuard} from './guards/active.guard';
import {ExcelService} from './services/excel.service';

@NgModule({
  imports: [HttpClientModule, ToastrModule, BrowserAnimationsModule],
  declarations: [],
  providers: [HttpService,
    StorageService,
    TokenService,
    ExcelService,
    AuthGuard,
    ResetGuard,
    ActiveAccountGuard,
    {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthInterceptor,
    multi: true
  }],
  exports: []
})
export class CoreModule {
  /* make sure CoreModule is imported only by one NgModule the AppModule */
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import only in AppModule');
    }
  }
}
