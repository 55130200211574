import { AfterViewChecked, AfterViewInit, Component, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Property } from '../../../models/property';
import { ActivatedRoute, Router } from '@angular/router';
import { PropertyService } from '../../../services/property.service';
import { Subscription } from 'rxjs/index';
import { GenericDto } from '../../../models/generic-dto';
import { MatDialog, MatPaginator, MatSort, MatTableDataSource, PageEvent } from '@angular/material';
import { ImageViewerComponent } from '../../../shared/components/image-viewer/image-viewer.component';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { success, successPropertyDeleted, errorMessage } from '../../../constants/notification-messages.constant';
import { DataService } from '../../../shared/services/data.service';
import { AssetsService } from '../../../services/assets.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { getFeatures } from '../../../utils/featureUtils';
import { RoomType } from '../../../models/RoomType';
import { JobManageComponent } from '../../job/job-manage/job-manage.component';
import { JobAssign } from '../../../models/job-assign';
import { TokenService } from '../../../core/services/token.service';
import { Booking } from '../../../models/booking';
import { JobInstructionComponent } from '../../job/job-instruction/job-instruction.component';
import { MaintenanceJobComponent } from './maintenance-job/maintenance-job.component';
import { DeletePropertyPopupComponent } from './delete-property-popup/delete-property-popup.component';

import { MaintenanceJob } from '../../../models/maintenanceJob';
import { MaintenanceJobDetailsComponent } from './maintenance-job-details/maintenance-job-details.component';
import { EditExpenseComponent } from '../edit-expense/edit-expense.component';
import {UserService} from '../../../services/user.service';
import { TourService } from 'src/app/services/tour.service';
import { OwnerService } from 'src/app/services/owner.service';
import { SharedService } from 'src/app/services/shared.service';
import { CopyPropertyChargesComponent } from '../property-form/copy-property-charges/copy-property-charges.component';


@Component({
  selector: 'app-property-details',
  templateUrl: './property-details.component.html',
  styleUrls: ['./property-details.component.scss'],
  // animations: [trigger(
  //   'enterAnimation', [
  //     transition(':enter', [
  //       style({transform: 'scale(0)', opacity: 0}),
  //       animate(50, style({transform: 'scale(1)', opacity: 1}))
  //     ]),
  //     transition(':leave', [
  //       style({transform: 'scale(1)', opacity: 1}),
  //       animate(50, style({transform: 'scale(0)', opacity: 0}))
  //     ])
  //   ]
  // )]
})

export class PropertyDetailsComponent implements OnInit, OnDestroy,AfterViewInit {
  property: Property = null;
  isLoading = true;
  isLoadingImage: boolean;
  selectedTab: any;
  @Input() jobAssigns: JobAssign[];
  @Output() tabSelected;
  jobAssign: JobAssign;
  @ViewChild(MatPaginator) paginatorMaintenanceJob: MatPaginator;
  @ViewChild(MatSort) sortMaintenanceJob: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  @ViewChild(MatPaginator) paginatorAssignJob: MatPaginator;
  @ViewChild(MatSort) sortAssignJob: MatSort;
  dataSource;
  maintenanceJobDataSource: any;
  maintenanceJobDataSourceRegular: any;
  maintenanceJobDataSourceSchedule: any;
  maintenanceJobs;
  currentPropertyId: string;
  displayColumns;
  displayColumns2;
  emptyState = false;
  jobLength: boolean;
  subscription: Subscription;
  images: Array<any>;
  placeholderImage: SafeUrl;
  jobDetails: string;
  jobTitle: string;
  amount: number;
  taxInPercent: number;
  hasMaintenanceJobs = false;
  isRegularSelected = true;
  propId: string;
  roomTypeId: string;
  unit: string;
  unitId: string;
  activeUnitId:string;
  notificationUrlUpdated = false;
  notificationResponse = false;
  currencyCode = 'USD';
  roomName: string;
  displayedColumns: any[] = ['propertyId', 'roomId', 'unitName', 'endingDate', 'cleanerId', 'status'];
  Home_FLAG_Rentel: any=true;
  permissions: any[] = [];
  constructor(private route: ActivatedRoute, private propertyService: PropertyService,
    public dialog: MatDialog,public deletePropertyPopupComponent:MatDialog, public maintenanceJobDialog: MatDialog, private router: Router,
    private toastrService: ToastrService, private dataService: DataService,
    private userService: UserService,
    private assetsService: AssetsService, private sanitizer: DomSanitizer, public tokenService: TokenService,
    private tourService: TourService,
    protected ownerService: OwnerService,
    private sharedService: SharedService) {

  }
  ngAfterViewInit(): void {

  }
  startTOur(){
    this.tourService.startTour();
  }

  ngOnInit() {
    this.selectedTab = 0;
    this.placeholderImage = this.sanitizer.bypassSecurityTrustResourceUrl('assets/images/no_images.png');
    this.subscription = this.route.params.subscribe((data: { id: string, id2: string, id3: string,id4: string}) => {
      // load Property
      this.notificationResponse = false;

      if (!!this.property && this.property.id !== data.id) {
        this.property = null;
        this.isLoading = true;
      }
      this.userService.getUser().subscribe(user => {
        this.permissions = user.payload.permissions;
        //console.log('PERM', this.permissions);
        this.getProperty(data, user.payload.currency);
      });
      this.loadJobdetails(data);
      this.currentPropertyId = data.id;
      this.propId = data.id;
      this.roomTypeId = data.id2;
      this.unit = data.id3;
      this.activeUnitId = data.id4;
    //  console.log("UNIT ",this.unit)
      this.loadMaintenanceJobs(this.propId);
      //  this.createNewJobDialog(data);
    });

    let steps= [
      {
        element: "#rentalInfo",
          title: "Property Info",
          content: "With this you get to see the detailed info of the property you have selected",
          placement: 'right',
          backdrop: true,
      },
      {
        element: "#rentalAddExpense",
          title: "Add Expense",
          content: "With this you can Create New Expense For Rental",
          placement: 'bottom',
          backdrop: true,
      },
      {
        element: "#rentalEdit",
        title: "Edit Button",
        content: "With this you can edit and and add formal uisng formula calculator",
        placement: 'bottom',
        backdrop: true,
      },
      {
        element: "#rentalDelete",
                  title: "Delete",
                  content: "Delete the Expense",
                  placement: 'bottom',
                  backdrop: true,

      },
      {
        element: "#rentalRoomHelp",
        title: "Help Guide",
        content: "Helps you to Setup Room Revenue Settings",
        placement: 'bottom',
        backdrop: true,
      },

    {
        element: "#mat-tab-label-0-0",
        title: "Rental Details",
        content: "Gives you the Rental Details",
        backdrop: true,
        placement: 'right',
      },
      {
        element: "#mat-tab-label-0-1",
        title: "Expenses",
        content: "With this you can view the Expenses",
        backdrop: true,
        placement: 'right',

      },{
        element: "#mat-tab-label-0-2",
        title: "Schedule Expenses",
        content: "With this you can Schedule your Expenses. ",
        backdrop: true,
        placement: 'right',
      },
    ]

    this.tourService.createTour(steps);



  }


  public get image() {
    return !!this.images && this.images.length > 0 ? this.images[0] : this.placeholderImage;
  }

  regularTab(){
    console.log("1")
    this.selectedTab = 3 ;
    this.isRegularSelected = true ;
  }
  scheduleTab(){
    console.log("2")
    this.selectedTab = 3 ;
    this.isRegularSelected = false ;
  }

  createNewJobDialog(id: string) {
    const dialogRef = this.dialog.open(JobManageComponent, {
      width: '200%',
      height: '50%'
    });
    dialogRef.afterClosed().subscribe(() => {
      // this.loadJobdetails(data);
    });
  }

  showImages(): void {
    if (!!this.images && !!this.images.length) {
      this.dialog.open(ImageViewerComponent, {
        data: this.images
      });
    }
  }

  loadJobdetails(data: { id: string }): void {
    this.propertyService.getJobAssignDetailsByPropertyId(data.id)
      .subscribe((response: GenericDto<JobAssign[]>) => {
        if (response.code === 200) {
          if (response.payload.length > 0) {
            this.jobLength = true;
            this.jobAssigns = response.payload;

            this.jobAssigns.forEach((jobAssignable: JobAssign) => {
              if (this.tokenService.getCurrentUser() === 'CLEANER') {
                if (jobAssignable.jobRequest === 'PENDING') {
                  jobAssignable.statusCheck = false;
                  jobAssignable.statuscheck1 = true;
                  jobAssignable.statuscheck2 = false;
                } else if (jobAssignable.jobRequest === 'COMPLETED') {
                  jobAssignable.statusCheck = true;
                  jobAssignable.statuscheck1 = false;
                  jobAssignable.statuscheck2 = false;
                } else if (jobAssignable.jobRequest === 'REJECTED') {
                  jobAssignable.statusCheck = true;
                  jobAssignable.statuscheck1 = false;
                  jobAssignable.statuscheck2 = false;
                } else {
                  jobAssignable.statusCheck = false;
                  jobAssignable.statuscheck1 = false;
                  jobAssignable.statuscheck2 = true;
                }

              } else {
                jobAssignable.statusCheck = true;
              }
            });
            var newJobAssign = [];
            if (this.property.roomTypes !== null && this.property.roomTypes.length > 0) {
              for (var j = 0; j < this.property.roomTypes.length; j++) {
                for (var i = 0; i < this.jobAssigns.length; i++) {
                  console.log('jobAssign--' + this.jobAssigns[i].roomId);
                  console.log('propertyRoom--' + this.property.roomTypes[j].name);
                  if (this.jobAssigns[i].roomId == this.property.roomTypes[j].name) {
                    console.log('Adding');
                    newJobAssign.push(this.jobAssigns[i]);
                  }
                }
              }
              this.jobAssigns = newJobAssign;
              console.log('length--' + this.jobAssigns.length);
              if (this.jobAssigns.length <= 0) {
                this.jobLength = false;
                this.emptyState = true;
              } else {
                this.dataSource = new MatTableDataSource(this.jobAssigns);
                this.dataSource.paginator = this.paginatorAssignJob;
              }
            }

          } else {
            this.jobLength = false;
            this.emptyState = true;
          }
        } else {
          this.dataSource = new MatTableDataSource();
          // show errors here
        }
      },
        (error: HttpErrorResponse) => {
          // show errors here
        });
  }

  getUniqueItems(list) {
    const uniqueids = new Set();

    let uniqueMaintenanceJobList =[];
    for (const job of list) {
      if (!uniqueids.has(job.id)) {
        uniqueMaintenanceJobList.push(job);
        uniqueids.add(job.id);
      }

    }
    return uniqueMaintenanceJobList;

  }

  loadMaintenanceJobs(propId: string) {
   // console.log("loadMaintenanceJobs =")
    this.hasMaintenanceJobs = false;
    this.maintenanceJobDataSource = [];

    this.displayColumns = ['unitName', 'jobTitle', 'Job Date', 'amount', 'taxInPercent', 'totalPayable', 'Details'];
    this.displayColumns2 = ['unitName', 'jobTitle', 'Job Date', 'amount', 'taxInPercent', 'totalPayable',"frequency" , 'nextExpenseDate', 'Details'];

    this.propertyService.getAllMaintenanceJobsByRoomIdUnitId(this.roomTypeId, this.activeUnitId ).subscribe((data: GenericDto<MaintenanceJob[]>) => {

    // this.propertyService.getAllMaintenanceJobs(propId).subscribe((data: GenericDto<MaintenanceJob[]>) => {
      if (data.code === 200) {
        if (data.payload.length > 0) {
          this.hasMaintenanceJobs = true;
          this.maintenanceJobs = data.payload;
          this.currencyCode = data.currency;

          let regular = []
          let schedule = []
          for( let i = 0 ; i < this.maintenanceJobs.length ; i++){
            if(this.maintenanceJobs[i].jobType == 'REGULAR'){
              regular.push(this.maintenanceJobs[i])
            }else{
              schedule.push(this.maintenanceJobs[i])
            }
          }

          this.maintenanceJobDataSourceRegular =  this.getUniqueItems(regular)  ;
          this.maintenanceJobDataSourceSchedule = this.getUniqueItems(schedule) ;
         ;

          this.maintenanceJobDataSource = new MatTableDataSource(this.maintenanceJobs);
          this.maintenanceJobDataSource.sort = this.sortMaintenanceJob;
          this.maintenanceJobDataSource.paginator = this.paginatorMaintenanceJob;

        }
      }
    });
  }

  editProperty(): void {
    const urlBuilder = ['/dashboard/property/edit', this.property.id, this.roomTypeId];
    if(this.unit) {
      urlBuilder.push(this.unit);
    }
    urlBuilder.push(this.activeUnitId);
    const url = urlBuilder.join("/");

    this.router.navigate([url]);
  }

  deleteProperty(): void {
    const dialogRef = this.deletePropertyPopupComponent.open(DeletePropertyPopupComponent, {
      width: '40%',
      height: 'auto',
      data: this.property
    });
    dialogRef.afterClosed().subscribe((result) => {
      console.log("result =" , result )
      if (result && result.isPropertyDeleted) {
        let payload = {
          roomTypeId:this.roomTypeId,
          unitId:this.activeUnitId,

          deletePropertyType:result.deletePropertyType,
          accountId:localStorage.accountId,
          propertyId:this.property.id
        }

        this.propertyService.deletePropertyWithBooking(payload).subscribe((response: GenericDto<any>) => {
          if (response.code === 200) {
            this.toastrService.success(successPropertyDeleted, success, {
              timeOut: 2000,
              onActivateTick: true
            });
            //this.dataService.customEvent.next();
            this.sharedService.setshareAndCommunicate({code:"updatePropertyList",data:null});
            this.router.navigate(['/dashboard/property']);
          }
        }, (error: HttpErrorResponse) => {
          // console.log(error);
        });
      }
    });



  }

  getProperty(data: { id: string }, userCurrency: string): void {
    this.selectedTab = this.tabSelected;
    this.propertyService.get(data.id).subscribe((response: GenericDto<Property>) => {
      if (response.code === 200) {
        this.property = response.payload;
        if (!!this.property && !!this.property.roomTypes && this.property.roomTypes.length > 0) {
          this.property.roomTypes.map((roomtype: RoomType) => {
            roomtype.unitItems.map((room: any) => {
              if(room.unitName == this.unit){
                this.unitId =  roomtype.roomId
              }
            });
          });
        }
        // regardless of anything, property should be displayed in user's currency. -> Thomas
        this.property.currency = userCurrency;
        this.isLoadingImage = true;
        this.images = [];
        //this.notificationUrlUpdated = response.payload.notifyUrlUpdated;
        // load images
        if (!!this.property && !!this.property.assetList && this.property.assetList.length > 0) {
          this.loadImages();
        } else {
          this.isLoadingImage = false;
        }

        if (!!this.property && !!this.property.featureCodes && this.property.featureCodes.length > 0) {
          this.property.features = getFeatures(this.property.featureCodes);
          // console.log('features : ', this.property.features);
        } else {
          this.property.features = [];
        }
        this.checkNotificationRecevied(data.id);


        if (!!this.property && !!this.property.roomTypes && this.property.roomTypes.length > 0) {

          this.property.roomTypes = this.property.roomTypes.map((room: RoomType) => {
            if (!!room.featureCodes && room.featureCodes.length > 0) {
              room.features = getFeatures(room.featureCodes);
            } else {
              room.features = [];
            }
            return room;
          });
          var newRoomTypes = [];
          for (var i = 0; i < this.property.roomTypes.length; i++) {
            // console.log('roomtype--' , this.property.roomTypes[i]);
            var room = this.property.roomTypes[i];
            // console.log(i, room)
            if (this.property.roomTypes[i].id === this.roomTypeId) {
              newRoomTypes.push(this.property.roomTypes[i]);
              var newUnits = [];
              newUnits.push(this.unit)
              for (let index = 0; index < room.unitItems.length; index++) {
                const unitItem = room.unitItems[index];
                if((unitItem.unitName != this.unit)){
                  newUnits.push(unitItem.unitName)
                }
              }
              this.property.roomTypes[i].units = newUnits;
            }
          }
          //console.log(newRoomTypes)
          this.property.roomTypes = newRoomTypes;
        }

      }
      this.isLoading = false;
    }, error => {
      this.isLoading = false;
    });
  }

  private loadImages(): void {
    const assetIds = this.property.assetList || new Array<string>();
    this.images = [];
    assetIds.forEach((id: string, index: number) => {
      this.assetsService.getAsset(id).subscribe((response: Blob) => {
        const imageUrl = window.URL.createObjectURL(response);
        const imageSafeUrl: SafeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(imageUrl);
        this.images.push(imageSafeUrl);
        if (this.images.length > 0) {
          this.isLoadingImage = false;
        }
      });
    });

  }

  findBookingsByPropertyId(id: string): void {
    this.propertyService.getBookingsByPropertyId(id).subscribe((response: GenericDto<Booking[]>) => {
      if (response.code === 200) {
      }
    }, error => {

    });
  }

  createMaintenanceJob(): void {
    const dialogRef = this.maintenanceJobDialog.open(MaintenanceJobComponent, {
      width: '40%',
      height: '75%',

      data: {
        jobTitle: this.jobTitle,
        jobDetails: this.jobDetails,
        amount: this.amount,
        taxInPercent: this.taxInPercent,
        currentPropertyId: this.currentPropertyId,
        currentRoomTypeId: this.roomTypeId ,
        currentRoomId: this.unitId ,
        currentRoomName: this.unit ,
        unitId:this.activeUnitId
      }
    });
    dialogRef.afterClosed().subscribe((result) => {
     // console.log("result =" , result )
      this.selectedTab = result;
      this.loadMaintenanceJobs(this.propId);
    });
  }



  updateNotifyUrl(){
    //console.log(this.currentPropertyId);
    this.propertyService.updateNotifyUrl(this.currentPropertyId)
      .subscribe(
        (response: GenericDto<String>) => {
          console.log(response);
          if (response.code === 201) {
            this.notificationUrlUpdated= true;
            this.toastrService.success('Notifications Receving Now!', success, {
              timeOut: 3000,
              onActivateTick: true
            });
          } else {
            console.log('error response: ' + response);
            this.toastrService.error(response.desc, errorMessage, {
              timeOut: 3000,
              onActivateTick: true
            });
          }
        }
      )
  }

  unsetNotifyUrl() {
   // console.log(this.currentPropertyId)
    this.propertyService.removeNotifyUrl(this.currentPropertyId)
      .subscribe(
        (response: GenericDto<String>) => {
         // console.log(response);
          if (response.code === 200) {
            // setting to false as notify Url is not set in BA anymore
            this.notificationUrlUpdated= false;
            this.toastrService.success('Notifications Unsubscribed Successfully!', success, {
              timeOut: 3000,
              onActivateTick: true
            });
          } else {
           // console.log('error response: ' + response);
            this.toastrService.error(response.desc, errorMessage, {
              timeOut: 3000,
              onActivateTick: true
            });
          }
        }
      )
  }

  checkNotificationRecevied(id: String) {
    this.propertyService.checkNotificationReceiving(id)
      .subscribe(
        (response: GenericDto<Boolean>) => {
          if(response.payload) {
            this.notificationUrlUpdated = true;
            this.notificationResponse = true;
            this.userService.getUser().subscribe((res:any) => {
              if (res.code === 200) {
                this.Home_FLAG_Rentel= res.payload.tourFlags.RENTALS_PAGE;
                if(this.Home_FLAG_Rentel == false){
                  this.tourService.startTour();
                  setTimeout(()=>{
                    let interval=   setInterval(()=>{
                      if(localStorage.getItem('tour_end')==='yes'){
                        localStorage.setItem('tour_end','No')
                        clearInterval(interval);
                        this.ownerService.changeTourFlag('RENTALS_PAGE').subscribe((res:any)=>{
                          this.Home_FLAG_Rentel=res.payload.tourFlags.REVENUE_PAGE;
                        })
                      }
                 //   console.log(localStorage.getItem('tour_end'))
                    },20);
                  },500)
                }
              }})
          } else {
            this.notificationUrlUpdated = false;
            this.notificationResponse = true;
            this.userService.getUser().subscribe((res:any) => {
              if (res.code === 200 && res.payload.tourFlags) {
                this.Home_FLAG_Rentel= res.payload.tourFlags.RENTALS_PAGE;
                if(this.Home_FLAG_Rentel == false){
                  this.tourService.startTour();
                  setTimeout(()=>{
                    let interval=   setInterval(()=>{
                      if(localStorage.getItem('tour_end')==='yes'){
                        localStorage.setItem('tour_end','No')
                        clearInterval(interval);
                        this.ownerService.changeTourFlag('RENTALS_PAGE').subscribe((res:any)=>{
                          this.Home_FLAG_Rentel=res.payload.tourFlags.REVENUE_PAGE;
                        })
                      }
                   // console.log(localStorage.getItem('tour_end'))
                    },20);
                  },500)
                }
              }})
          }
        }
      )
  }


  deleteJob(id: any , jobType : string) {
    let confirmation = confirm("Are you sure want to Delete Expense ? ");
    if(confirmation === true) {
      this.propertyService.deleteMaintenanceJob(id,jobType )
      .subscribe((response1: GenericDto<JobAssign>) => {
        if (response1.code === 200) {
          this.toastrService.success(response1.desc, success, {
            timeOut: 3000,
            onActivateTick: true
          });
          this.loadMaintenanceJobs(this.propId);
        }
      }, (error: HttpErrorResponse) => {
        this.isLoading = false;
        // show errors here
      });
    }
  }

  showDetails(id: any) {
   // console.log('here')
    let currentJob: any;
    for(let job of this.maintenanceJobs) {
      if(job.id === id) {
        currentJob = job;
      }
    }
   if(currentJob) {
    const dialogRef = this.dialog.open(MaintenanceJobDetailsComponent, {
      width: '70%',
      height: '90%',
      data: { selectedJob: currentJob, room: this.property.roomTypes }
    });
    dialogRef.afterClosed().subscribe(() => {
     // console.log('closed')
    });
   }
  }

  onTabChange(tagValues: any) {
    this.selectedTab = tagValues.index
   // console.log("tagValues.index =", tagValues.index)
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  editJob(element) {
    const dialogRef = this.dialog.open(EditExpenseComponent, {
      width: '70%',
      height: '90%',
      data: element
    });
    dialogRef.afterClosed().subscribe(() => {
      this.loadMaintenanceJobs(this.propId);
    });

  }

  copy(expense,jobType) {
    const importDialogRef = this.dialog.open(CopyPropertyChargesComponent, {
      width: '50%',
      height: '400px',
      data: {
        copyingFor: "expense",
        property: this.property,
        unit: this.unit,
        roomId: this.roomTypeId,
        unitId: this.activeUnitId,
        jobType:jobType,
        expense:expense
      }
    });
  }


}
