import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs/internal/Subscription';
import { UserService } from 'src/app/services/user.service';
import { DataService } from 'src/app/shared/services/data.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialougeComponent } from '../../confirm-dialouge/confirm-dialouge.component';


@Component({
  selector: 'app-staff-list',
  templateUrl: './staff-list.component.html',
  styleUrls: ['./staff-list.component.scss']
})
export class StaffListComponent implements OnInit {

 users:any[] 
  searchText: string;
  watcher: Subscription;
  isLoading: boolean;
  isOwner: boolean;
  userId: string;
  constructor (public router: Router, private route: ActivatedRoute, private dataService: DataService, private userService: UserService , private snackbar: MatSnackBar , public dialog: MatDialog) {}


  ngOnInit() {
    this.userId=localStorage.getItem('userId')
    this.getallUserAccount();
    this.dataService.customEvent.subscribe(() => {
      this.getallUserAccount();
    });
  }

  getallUserAccount(){
    this.userService.getallUserAccount().subscribe(res=>{
      this.users = res.payload
      //.log('usersssssssssssssssssssssssssss' , res)
    })

  }

  openDialog(userId): void {
    const dialogRef = this.dialog.open(ConfirmDialougeComponent, {
      width: '460px' , 
      height:'170px',
      panelClass: 'confirm_dialouge',
      data: { title: 'Confirm', message: 'Are you sure you want to perform this action?' }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if(result === true){
          this.userService.deleteUserAccount(userId).subscribe(res=>{
              this.getallUserAccount()
          })

         
        }else {
          //console.log('abcdddddd')
        }
       // console.log(result , "54545454")
  
      }
    });
  }

  navigate(user) {
    this.dataService.cleaner = user;
    if (user.role === 'OWNER') {
      this.isOwner = true;
      /*<button mat-stroked-button color="primary" style="margin-top: 2%" class="float-left" routerLink="/dashboard/ownerDetails">
        Show Owner Details <mat-icon>search</mat-icon>
      </button>*/
    //  this.router.navigate(['/dashboard/ownerDetails']);/*, user.id]);*/
      this.router.navigate(['/dashboard/users', user.id]);
    } else if (user.role === 'CLEANER') {
      this.isOwner = false;
      this.router.navigate(['/dashboard/users', user.id]);
    } else if(user.role === 'ADMINISTRATOR') {
      this.isOwner = false;
      this.router.navigate(['/dashboard/users', user.id]);
    }
  }

  editUser(user): void {
    this.router.navigate(['/dashboard/staff/edit/' + user.id]);
  }
  deleteUser(user):void{
   // console.log("DDDDDDDDDDDD?????????????????ddd",user);
    this.openDialog(user.id)
    
  }
}

