import {Component, Input, OnInit} from '@angular/core';
import {PropertyService} from '../../services/property.service';
import {GenericDto} from '../../models/generic-dto';
import {JobAssign} from '../../models/job-assign';
import {HttpErrorResponse} from '@angular/common/http';
import {MatDialog, MatTableDataSource} from '@angular/material';
import {TokenService} from '../../core/services/token.service';
import {JobInstructionComponent} from '../job/job-instruction/job-instruction.component';
import {EventService} from '../../shared/services/event.service';
import {success} from '../../constants/notification-messages.constant';
import {ToastrService} from 'ngx-toastr';
import { JobInstructions } from '../job/job-assign/job-instruction.model';
import { JobCommentsComponent } from '../job/job-comments/job-comments.component';
import { UploadImageFormComponent } from 'src/app/shared/components/upload-image-form/upload-image-form.component';


@Component({
  selector: 'app-dashboard-home',
  templateUrl: './dashboard-home.component.html',
  styleUrls: ['./dashboard-home.component.scss']
})

export class DashboardHomeComponent implements OnInit {
  acceptedJobs: any;
  plannedJobs: any;
  completedJobs: any;
  rejectedJobs: any;
  totalJobs: any;

  acceptedJobsPercent: any;
  plannedJobsPercent: any;
  completedJobsPercent: any;
  rejectedJobsPercent: any;
  totalJobsPercent: any;
  jobLength: boolean;
  emptyState = false;
  isCleaner: boolean;
  isLoading = false;
  hasAssignedJobs = false;
  noJobs = false;
  jobInstructions: JobInstructions;

  @Input() jobAssigns: JobAssign[];
  constructor( private propertyService: PropertyService, public tokenService: TokenService, public dialog: MatDialog, private toastrService: ToastrService ) {}

  ngOnInit() {
    this.getAllCleanerJobs();
    this.getPlannedJobs();
  }
  getAllCleanerJobs(): void {
    this.propertyService.getJobAssignDetails()
      .subscribe((response: GenericDto<JobAssign[]>) => {
          if (response.code === 200) {

            this.acceptedJobs = 0;
            this.completedJobs = 0;
            this.plannedJobs = 0;
            this.rejectedJobs = 0;
            this.totalJobs = response.payload.length;
            for ( const job of response.payload) {
              if (job.jobRequest === 'COMPLETED') {
                this.completedJobs += 1;
              } else if (job.jobRequest === 'REJECTED') {
                this.rejectedJobs += 1;
              } else if (job.jobRequest === 'PLANNED') {
                this.plannedJobs += 1;
              } else if (job.jobRequest === 'ACCEPTED') {
                this.acceptedJobs += 1;
              }

            }
            this.totalJobsPercent = ((this.totalJobs / this.totalJobs) * 100).toFixed(2);
            this.acceptedJobsPercent = ((this.acceptedJobs / this.totalJobs) * 100).toFixed(2);
            this.rejectedJobsPercent = ((this.rejectedJobs / this.totalJobs) * 100).toFixed(2);
            this.completedJobsPercent = ((this.completedJobs / this.totalJobs) * 100).toFixed(2);
            this.plannedJobsPercent = ((this.plannedJobs / this.totalJobs) * 100).toFixed(2);
          } else {

          }
        },
        (error: HttpErrorResponse) => {
          // show errors here
        });
  }
  getPlannedJobs(): void {
    this.propertyService.getPlannedJobsForCleaner()
      .subscribe((response: GenericDto<JobAssign[]>) => {
          if (response.desc === 'no jobs') {
            this.noJobs = true;
          }
          if (response.code === 200) {

            if ( response.payload.length  > 0 ) {
              this.hasAssignedJobs = true;
              this.jobAssigns = response.payload;
              this.jobLength = true;

              this.jobAssigns.forEach((jobAssignable: JobAssign) => {

                if (this.tokenService.getCurrentUser() === 'CLEANER') {
                  this.isCleaner = true;
                  if (jobAssignable.jobRequest === 'PLANNED') {
                    jobAssignable.statusCheck = false;
                    jobAssignable.statuscheck1 = true;
                    jobAssignable.statuscheck2 = false;
                  } else if (jobAssignable.jobRequest === 'COMPLETED') {
                    jobAssignable.statusCheck = true;
                    jobAssignable.statuscheck1 = false;
                    jobAssignable.statuscheck2 = false;
                  } else if (jobAssignable.jobRequest === 'REJECTED') {
                    jobAssignable.statusCheck = true;
                    jobAssignable.statuscheck1 = false;
                    jobAssignable.statuscheck2 = false;
                  } else {
                    jobAssignable.statusCheck = false;
                    jobAssignable.statuscheck1 = false;
                    jobAssignable.statuscheck2 = true;
                  }
                  jobAssignable.jobInstructions = this.setJobInstuctions(jobAssignable);

                } else {
                  jobAssignable.statusCheck = true;
                }
              });

            } else {

              this.hasAssignedJobs = false;
              this.jobLength = false;
              this.emptyState = true;
            }

          } else {

          }
        },
        (error: HttpErrorResponse) => {
          // show errors here
        });

  }

  getInstruction(instruction: JobInstructions): void {
    const dialogRef = this.dialog.open(JobInstructionComponent, {
      width: '70%',
      height: '70%',
      data: {message: instruction}
    });
    dialogRef.afterClosed().subscribe(() => {
    });
  }
  uploadImage(jobAssign: JobAssign) {
    //console.log('here');
    const dialogRef = this.dialog.open(UploadImageFormComponent, {
      width: '70%',
      height: '90%',
      data: { jobAssign: jobAssign, isCleaner: true }
    });
    dialogRef.afterClosed().subscribe(() => {
      console.log('closed')
      this.ngOnInit();
    });

  }

  commentJob(jobAssign: JobAssign){
    console.log(jobAssign);
    const dialogRef = this.dialog.open(JobCommentsComponent, {
      width: '50%',
      height: '50%',
      data: { jobAssign: jobAssign, isCleaner: true }
    });
    dialogRef.afterClosed().subscribe(() => {
      console.log('closed')
    });

  }


  acceptJob(jobAssign: JobAssign , status: any): void {
    this.getAllCleanerJobs();
    if (status === 'Accepted') {
      jobAssign.jobRequest = 'ACCEPTED';
    } else if (status === 'Rejected') {
      jobAssign.jobRequest = 'REJECTED';
    } else {
      jobAssign.jobRequest = 'COMPLETED';
    }

    this.propertyService.updateJob(jobAssign.id , jobAssign.cleanerId,  jobAssign.jobRequest)
      .subscribe((response1: GenericDto<JobAssign>) => {
        if (response1.code === 200) {
          this.ngOnInit();
          this.toastrService.success(' Job ' + status + ' Successfully ' , success, {
            timeOut: 3000,
            onActivateTick: true
          });
          this.getPlannedJobs();
        }
        
      }, (error: HttpErrorResponse) => {
        this.isLoading = false;
        this.ngOnInit();
        // show errors here
      });
  }

  setJobInstuctions(jobAssign: JobAssign): JobInstructions {
    return new JobInstructions(jobAssign.accessInstructions, jobAssign.garbageInstructions,
        jobAssign.parkInstructions, jobAssign.otherInformations, jobAssign.cleaningChecklist);
  }

}
