import {Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {UserService} from '../../../services/user.service';
import {HttpErrorResponse} from '@angular/common/http';
import {DataService} from '../../services/data.service';
import {GenericDto} from '../../../models/generic-dto';
import {User} from '../../../models/user';
import {Subscription} from 'rxjs/index';
import {OwnerPropertiesComponent} from '../../../dashboard/owner/owner/owner-properties/owner-properties.component';
import {MatDialog} from '@angular/material';

@Component ({
  selector: 'app-cleaner-list',
  templateUrl: './cleaner-list.component.html',
  styleUrls: ['./cleaner-list.component.scss']
})

export class CleanerListComponent implements OnInit {
  users: Array<User>;
  searchText: string;
  watcher: Subscription;
  isLoading: boolean;
  isOwner: boolean;

  constructor (public router: Router, private route: ActivatedRoute, private dataService: DataService, private userService: UserService) {}

  ngOnInit() {
    this.getCleaners();
    this.dataService.customEvent.subscribe(() => {
      this.getCleaners();
    });

  }

  getCleaners(): void {
    this.isLoading = true;
    this.userService.getAllUsers()
      .subscribe((data: GenericDto<User[]>) => {
          // console.log('get role', this.tokenService.getCurrentUser());
          if (data.code === 200) {
            this.users = data.payload;
          } else {
            // show error message
          }
          this.isLoading = false;
        },
        (error: HttpErrorResponse) => {
          // show error message
          this.isLoading = false;
        });
  }

  navigate(user) {
    this.dataService.cleaner = user;
    if (user.role === 'OWNER') {
      this.isOwner = true;
      /*<button mat-stroked-button color="primary" style="margin-top: 2%" class="float-left" routerLink="/dashboard/ownerDetails">
        Show Owner Details <mat-icon>search</mat-icon>
      </button>*/
    //  this.router.navigate(['/dashboard/ownerDetails']);/*, user.id]);*/
      this.router.navigate(['/dashboard/users', user.id]);
    } else if (user.role === 'CLEANER') {
      this.isOwner = false;
      this.router.navigate(['/dashboard/users', user.id]);
    } else if(user.role === 'ADMINISTRATOR') {
      this.isOwner = false;
      this.router.navigate(['/dashboard/users', user.id]);
    }
  }
}
