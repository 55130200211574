import {Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {emailValidator} from '../validators/email.validator';
import {AuthService} from '../services/auth.service';
import {TokenService} from '../core/services/token.service';
import {StorageService} from '../core/services/storage.service';
import {GenericDto} from '../models/generic-dto';
import {ToastrService} from 'ngx-toastr';
import {success, successLogin, warning} from '../constants/notification-messages.constant';
import {DataService} from '../shared/services/data.service';
import {OtpDto} from '../models/otp-dto';
import {HttpErrorResponse} from '@angular/common/http';
import {UserService} from '../services/user.service';
import { tokenKey, currentUserKey } from '../constants/storage.constants';
import { filter } from 'rxjs/internal/operators';
import { SharedService } from '../services/shared.service';
import { environment } from '../../environments/environment';
import { BillingService } from '../dashboard/billing/billing.service';


@Component({
  selector : 'app-login',
  templateUrl : './login.component.html',
  styleUrls : ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  loginForm: FormGroup;
  isEnabled: boolean;
  isLoading: boolean;
  isRememberMeChecked: boolean;
  userRole: string;
  @ViewChild('remember') remember: ElementRef;
  previousUrl: any;
  currentUrl: string;

  constructor(public sharedService: SharedService,private fb: FormBuilder, private renderer: Renderer2,
              private authService: AuthService, public router: Router,
              private tokenService: TokenService, private storageService: StorageService,
              private toastrService: ToastrService, private dataService: DataService,
              private userService: UserService,
              private billingService:BillingService,) {
    this.createLoginForm();
    this.renderer.addClass(document.body, 'login-bg');


  }



  ngOnInit() {
  }

  private createLoginForm() {
    this.loginForm = this.fb.group({
      email: new FormControl('', [Validators.required, Validators.compose([emailValidator])]),
      password: new FormControl('', [Validators.required, Validators.minLength(4)])
    });

  }

  forgotPassword(canShow: boolean): void {
    this.isEnabled = canShow;
  }

  resetPassword(event: OtpDto): void {
    this.isEnabled = false;
    if (!!event) {
      this.dataService.otp = event;
        this.router.navigate(['/resetPassword']);
    }
  }

  login(): void {
    if (this.loginForm.valid) {
      this.authService.login(this.loginForm.getRawValue()).subscribe((response: GenericDto<string>) => {
        if (response.code === 200) {
          this.storageService.configureStorage(this.isRememberMeChecked);
          this.tokenService.saveToken(response.payload);
          localStorage.setItem(tokenKey, response.payload);
          
          this.userService.getUser().subscribe((result: GenericDto<any>) => {
            if (result.code === 200) {
              localStorage.setItem('userId',result.payload.id);
              localStorage.setItem('userName',result.payload.firstName);
              localStorage.setItem('accountId',result.payload.accountId);
              localStorage.setItem(currentUserKey, result.payload.role);
              localStorage.setItem('userLinkId', result.payload.userLinkId);


              this.dataService.cleaner = result.payload;
              this.tokenService.saveCurrentUser(result.payload.role);
              this.tokenService.setPreviewLogin(false);
              this.userRole = this.tokenService.getCurrentUser();
              this.toastrService.success(successLogin, success, {
                timeOut: 3000,
                onActivateTick: true
              });

              window.analytics.identify(result.payload.id, {
                name: result.payload.firstName +' '+result.payload.lastName,
                email: result.payload.email,
                traits: {
                  "role": result.payload.role,
                  "accountId": result.payload.accountId,
                  "userLinkId": result.payload.userLinkId,
                },
              }) .then(() => console.log("Segment event sent"));

              if ( this.userRole === 'CLEANER' ) {
                if(this.authService.redirectUrl){
                  this.router.navigateByUrl(decodeURIComponent(this.authService.redirectUrl));
                  this.authService.redirectUrl = null;
                }else{
                  this.router.navigate(['/dashboard']);
                }
              } else if(this.userRole === 'OWNER') {
                if(this.authService.redirectUrl){
                  this.router.navigateByUrl(decodeURIComponent(this.authService.redirectUrl));
                  this.authService.redirectUrl = null;
                }else{
                  this.router.navigate(['/ownerDashboard']);
                }

              } else if(this.userRole === 'SUPER-ADMINISTRATOR') {
                if(this.authService.redirectUrl){
                  this.router.navigateByUrl(decodeURIComponent(this.authService.redirectUrl));
                  this.authService.redirectUrl = null;
                }else{
                this.router.navigate(['/adminDashboard']);
                }
              }
              else {
                if(this.userRole === 'ADMINISTRATOR') {
                  this.userService.redirectUser()
                    .subscribe(
                      (response: GenericDto<boolean>) => {
                        console.log(response.payload)
                        if(response.payload === false) {
                          if(this.authService.redirectUrl){
                            this.router.navigateByUrl(decodeURIComponent(this.authService.redirectUrl));
                            this.authService.redirectUrl = null;
                          }else{
                            this.router.navigate(['/dashboard/plugins/']);
                          }
                        }
                        else {
                          if(this.authService.redirectUrl){
                            this.router.navigateByUrl(decodeURI(this.authService.redirectUrl))

                            this.authService.redirectUrl = null;
                          }else{
                            this.router.navigate(['/ownerDashboard']);
                          }
                        }
                      },
                    );
                }
              }
               // close chat-boat and clear the public chat
               this.sharedService.setshareAndCommunicate({code:"event-login-logout-occured",data:null});
              //  this.router.navigate(['/dashboard']);
            } else {
              // show error and redirect to login page
            }
          });
        }
      }, (error: HttpErrorResponse) => {
        if (error.status === 403) {

          this.toastrService.warning((error.error as GenericDto<any>).desc, warning, {
            timeOut: 3000,
            onActivateTick: true
          });
          this.dataService.user = new Object();
          this.dataService.user['email'] = this.loginForm.get('email').value || null;
          this.dataService.user['isRememberMeChecked'] = this.isRememberMeChecked || false;
          this.router.navigate(['/verifyAccount']);
        }
      });
    }
  }

  ngOnDestroy() {

    this.renderer.removeClass(document.body, 'login-bg'); // login-bg class remove onDestroy
  }
   

}
