import {Component, ViewChild, OnInit, Renderer2, OnDestroy} from '@angular/core';

import {MatSidenav} from '@angular/material';
import {MediaChange, ObservableMedia} from '@angular/flex-layout';
import {Subscription} from 'rxjs/index';
import {Router} from '@angular/router';
import {UserService} from '../services/user.service';
import {GenericDto} from '../models/generic-dto';
import {HttpErrorResponse} from '@angular/common/http';
import {DataService} from '../shared/services/data.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})

export class DashboardComponent implements OnInit, OnDestroy {
  @ViewChild('sideNav') sidebar: MatSidenav;
  @ViewChild('propertiesDrawer') propertiesDrawer: MatSidenav;
  @ViewChild('usersDrawer') usersDrawer :MatSidenav
  navMode = 'side';
  navOpened: boolean;
  watcher: Subscription;
  activeMediaQuery = '';
  showPropertiesDrawer: boolean;
  showUsersDrawer:boolean;
  constructor(private renderer: Renderer2, private media: ObservableMedia,
              public router: Router, private userService: UserService,
              private dataService: DataService) {

  }

  ngOnInit() {
    this.navOpened = true;
    this.renderer.addClass(document.body, 'dashboard-bg');

    // get user object


    this.watcher = this.media.subscribe((change: MediaChange) => {
      this.activeMediaQuery = change ? `'${change.mqAlias}' = (${change.mediaQuery})` : '';
      if (change.mqAlias === 'sm' || change.mqAlias === 'xs') {
        this.closeNav();
        this.navMode = 'over';
      } else {
        if (!!this.propertiesDrawer) {
          this.propertiesDrawer.close();
        }
        this.navMode = 'side';
        this.openNav();
      }
    });

  }

  togglePropertiesDrawer(): any {
    this.propertiesDrawer.toggle();
  }

  toggleUsersDrawer():any{
    this.usersDrawer.toggle();
  }

  openNav() {
    this.sidebar.open();
  }

  closeNav() {
    this.sidebar.close();
  }

  onOpened() {

  }

  onClosed() {

  }

  ngOnDestroy() {
    this.watcher.unsubscribe();
  }
}
