import {Component, OnDestroy, Renderer2} from '@angular/core';
import {Router} from '@angular/router';
import { ActivatedRoute } from '@angular/router';

import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {emailValidator} from '../validators/email.validator';
import {AuthService} from '../services/auth.service';
import {GenericDto} from '../models/generic-dto';
import {HttpErrorResponse} from '@angular/common/http';
import {ToastrService} from 'ngx-toastr';
import {success} from '../constants/notification-messages.constant';
import {DataService} from '../shared/services/data.service';

@Component({
  selector : 'app-registration',
  templateUrl : './registration.component.html',
  styleUrls : ['./registration.component.scss']
})

export class RegistrationComponent {
  registrationForm: FormGroup;
  hide: boolean;
  logo:String ="bedPlus_logo.png";
  organizationId:any;
  constructor(private route:ActivatedRoute, private fb: FormBuilder, private renderer: Renderer2,
              public router: Router, private authService: AuthService,
              private dataService: DataService, private toastrService: ToastrService) {
    this.createRegistrationForm();
    this.renderer.addClass(document.body, 'login-bg');
  }

  ngOnInit() {
   
  }
  

  private createRegistrationForm() {
     
    this.route.queryParams.subscribe(params => {       
      this.organizationId = params['organizationId'];  
      if(this.organizationId != null && this.organizationId == "pmi-corp") {
        this.logo = this.organizationId+".png"
      }    
      });
      
    this.registrationForm = this.fb.group({
      firstName: new FormControl('', [Validators.required]),
      lastName: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.compose([emailValidator])]),
      password: new FormControl('', [Validators.required, Validators.minLength(6)]),
      organizationId: new FormControl(this.organizationId,[] )
      
    });
  }

  registration(): void {
    if (this.registrationForm.valid) {
      this.authService.register(this.registrationForm.getRawValue()).subscribe((response: GenericDto<string>) => {
        if (response.code === 200) {
          this.toastrService.success(response.payload, success, {
            timeOut: 3000,
            onActivateTick: true
          });
          this.dataService.user = new Object();
          this.dataService.user['email'] = this.registrationForm.get('email').value || null;
          this.router.navigate(['/verifyAccount']);

        }

      }, (error: HttpErrorResponse) => {
      });
    }
  }

}
