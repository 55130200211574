import { Component, ViewChild, AfterViewInit, NgZone } from '@angular/core';
import * as Highcharts from 'highcharts';
import { OnInit } from '@angular/core';
import {  MatDialog, MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { OwnerService } from '../../services/owner.service';
import { GenericDto } from '../../models/generic-dto';
import { HttpErrorResponse } from '@angular/common/http';
import { Month } from '../../models/month';
import { TokenService } from '../../core/services/token.service';
import { DataService } from '../../shared/services/data.service';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '../../services/user.service';
import * as moment from 'moment';
import { PropertyService } from '../../services/property.service';
import { JobService } from 'src/app/services/job.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { TourService } from 'src/app/services/tour.service';
import { SharedService } from 'src/app/services/shared.service';
import { Router } from '@angular/router';
import { ShowDetailsPopupComponent } from 'src/app/dashboard/show-details-popup/show-details-popup.component';
import { User } from 'src/app/models/user';
// declare var Tour: any;


@Component({
  selector: 'app-owner-dashboard',
  templateUrl: './owner-dashboard-home.component.html',
  styleUrls: ['./owner-dashboard-home.component.scss'],
  providers: [OwnerService],
})


export class OwnerDashboardHomeComponent implements OnInit, AfterViewInit {
  constructor(private fb: FormBuilder, public ownerService: OwnerService, private toastrService: ToastrService, private userService: UserService, public dataService: DataService, public dialog: MatDialog,
    private tokenService: TokenService,
    // private _ngZone: NgZone,
    private sharedService:SharedService,
    private tourService: TourService,
    public router: Router
    ) { }

  Highcharts = Highcharts;
  chartOptions: any;
  selectedMonth: any;
  selectedYear: any;
  daysArr = [];
  duration: any;
  totalRevenue: number;
  totalBaseRevenue: number;
  isMonth : Boolean;
  totalBookings: any;
  activeJobs: any;
  showLineChart = false;
  viewRevenuePermission = false;
  viewPropertiesPermission = false;
  displayColumns: any;
  displayColumns1: any;
  dataSource;
  dataSource1;
  isOwner: boolean;
  hasTableData: boolean;
  showRevenue = true;
  showBaseRevenue = false;
  currency = 'USD';
  years;
  currentYear;
  month;
  year;
  currentUser:User;
  isOccupancyRateChartShow :boolean = false;

  isCurrencyLoaded: boolean;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  completedJobs: number;
  dashboardGroup : FormGroup;
  months: any[] = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  durationArray : string[] =['Yearly', 'Monthly'];
  timesArray:  any[] =[moment().format('YYYY')];
  reportTypeArray:  any[] =['Revenue Report','Occupancy Rate' ];
  totalRentals;
  showFullText: { [key: string]: boolean } = {};
  showResourceText = '';
  difference;
  userEmail: string;
  ownerAdditionalDetails:any;
  showOwnerResources : boolean;
  showTrialAlert: boolean = false;
 
  
  remainingDaysString: any;// string =  localStorage.getItem("remainingDays") // Set the number of days until expiry 
  isShow = localStorage.getItem("alertShow");
// tour:any;
  ngAfterViewInit() {
    this.sharedService.shareAndCommunicate.subscribe(
      (data) => {
        this.updateComponent(data)   
      }
    );
    this.ownerService.getYears().subscribe( years => {
      this.years = years;
      this.years.forEach( year => {
        if(year != this.timesArray[0]) {
          this.timesArray.push(year)
        }
      });
      this.removeDuplicatesAndSortDesc();
    });

        
  }

  ngOnInit() {
    this.dashboardGroup = this.fb.group ({
      year: [this.timesArray[0],[Validators.required]],
      duration: [this.durationArray[0], [Validators.required]],
      month: [],
      reportType:["Revenue Report",[]]
    });
    if (this.tokenService.getCurrentUser() === 'OWNER') {

      console.log("current user is an owner");
      this.isOwner = true;
      this.userService.getUser().subscribe((response: GenericDto<any>) => {
        if (response.code === 200) {
          this.currentUser = response.payload;
          
          
          this.userEmail = response.payload.email;

          if(response.payload.ownerDetails && response.payload.ownerDetails.additionalDetails){
            this.ownerAdditionalDetails = response.payload.ownerDetails.additionalDetails;
          }
          if(this.ownerAdditionalDetails && this.ownerAdditionalDetails.length > 0) {
            this.ownerAdditionalDetails.forEach((detail) => {
              if(detail.display)
                this.showOwnerResources = true;
            });
          }
          if((response.payload.permission).includes('View Revenue')) { this.viewRevenuePermission = true; }
          else { this.viewRevenuePermission = false;  }
          if((response.payload.permission).includes('View Properties')) { this.viewPropertiesPermission = true; }
          else { this.viewPropertiesPermission = false;  }

          this.showRevenue = false
          this.showBaseRevenue = false
          if((response.payload.permission).includes('Booking amount')) {
            this.showRevenue = true;
          }
          else if((response.payload.permission).includes('Booking Base Price')) {
            this.showBaseRevenue = true;
          }
          this.onChange();
        } else {
          // show error and redirect to login page
        }

      }, (error: HttpErrorResponse) => {
        // show error and redirect to login page
      });
      this.viewPropertiesPermission = true;
    } else if (this.tokenService.getCurrentUser() === 'ADMINISTRATOR') {
      this.onChange()
    } else {
      this.viewRevenuePermission = true;
    }

    this.sharedService.sPanelEndNext.subscribe((data)=>{
      if(data==='ownerDashboardHome'){
      }
    })
  }

  handleResourceButtonClick(resourceDetail) {
    if(resourceDetail.fieldType === 'Web-URL') {
      console.log("resourceDetail: ", resourceDetail);
      window.open(resourceDetail.value);
    }
    else if(resourceDetail && this.showResourceText !== resourceDetail.fieldName) {
      this.showResourceText = resourceDetail.fieldName;
    }
    else {
      this.showResourceText = "";
    }
}




// test button for tour ////
startTour(){
    this.ownerService.refreshTourFlag().subscribe((res:any)=>{
      if(res){
        window.location.reload();
      }
    })
  }

  timeChange(event) {
    this.onChange();
  }

  durationChange (event) {
    if (event.value === 'Monthly') {
      this.timesArray = this.years;
      this.removeDuplicatesAndSortDesc();
      this.isMonth = true;
      let i = 0;
      for (let index = 0; index < this.years.length; index++) { if(this.years[index] === this.dashboardGroup.value.year) { i =index; } }
      this.dashboardGroup = this.fb.group ({
        year: [this.timesArray[i],[Validators.required]],
        month: [this.months[0],[Validators.required]],
        duration: [this.durationArray[1], [Validators.required]],
        reportType:["Revenue Report",[]]
      });
      this.onChange()
      } else if (event.value === 'Yearly') {
        let i = 0;
        for (let index = 0; index < this.years.length; index++) { if(this.years[index] === this.dashboardGroup.value.year) { i =index; } }
        this.timesArray = this.years;
        this.removeDuplicatesAndSortDesc();
        this.isMonth = false;
        this.dashboardGroup.value.month = null;
        this.dashboardGroup = this.fb.group ({
          year: [this.timesArray[i],[Validators.required]],
          month: [],
          duration: [this.durationArray[0], [Validators.required]],
          reportType:["Revenue Report",[]]
        });
        this.onChange()
      }
  }
  reportTypeChange($event){
    
    this.isOccupancyRateChartShow = true;
    if(this.dashboardGroup.value.reportType == "Occupancy Rate"){
      this.genrateOccupancyRateVerticalGraph()
    }
    else{
      this.isOccupancyRateChartShow = false;      
      this.onChange()
    }
   
  }

//---------------
 formatMonth(month: string): string {
  const [mm, yyyy] = month.split('-');
  const date = new Date(parseInt(yyyy), parseInt(mm) - 1);
  const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'short' };
  return new Intl.DateTimeFormat('en-US', options).format(date);
}





  genrateOccupancyRateVerticalGraph(){
    let id = localStorage.getItem("userId");
    
    if(this.isOwner){
      id =this.currentUser.id;
    }
    this.ownerService.getOccupancyRateInfo(id,this.dashboardGroup.value.year).subscribe(response => {
        let occupancyData = response.payload;
        occupancyData.sort((a, b) => b.date - a.date);       

        // Extract months and occupancy rates separately for the column chart
        const months = occupancyData.map(item => this.formatMonth(item.month));       
        const occupancyRates = occupancyData.map(item => item.occupancyRate);
        const formulas = occupancyData.map(item => item.formula);

        this.chartOptions = {
            credits: {
                enabled: false
            },
            chart: {
                type: 'column'
            },
            title: {
                text: 'Total Occupancy Rate'
            },
            xAxis: {
                categories: months,
                title: {
                    text: 'Month'
                }
            },
            yAxis: {
                title: {
                    text: 'Bookings'
                }
            },
            series: [
                {
                    name: 'Monthly',
                    data: occupancyRates.map((rate, index) => ({
                      y: rate,
                      color: rate > 100 ? 'lightcoral' : undefined // light red color for rates > 100
                  }))
                }
            ],
            tooltip: {
              formatter: function() {
                const month = months[this.point.index];
                const occupancyRate = this.point.y;
                const formula = formulas[this.point.index];
                const overbookedText = occupancyRate > 100 ? ' <span style="color:red; font-weight:bold;">(Over Booked)</span>' : '';
                return `<b>${month}</b><br/>Occupancy Rate: ${occupancyRate}%${overbookedText}<br/>Formula: ${formula}`;
            }
            }
        };

        this.showLineChart = true;
        this.daysArr = [];
    });
}   


  onChange() {     
    this.year = this.dashboardGroup.value.year;
    this.month = this.dashboardGroup.value.month;
    this.duration = this.dashboardGroup.value.duration;
    let time;
    if(this.month == null) {
      time = this.year
    } else {
      if(this.month === 'January') { time = this.year + '-01'} else if(this.month === 'February') { time = this.year + '-02'}
      else if(this.month === 'March') { time = this.year + '-03'} else if(this.month === 'April') { time = this.year + '-04'}
      else if(this.month === 'May') { time = this.year + '-05'} else if(this.month === 'June') { time = this.year + '-06'}
      else if(this.month === 'July') { time = this.year + '-07'} else if(this.month === 'August') { time = this.year + '-08'}
      else if(this.month === 'September') { time = this.year + '-09'} else if(this.month === 'October') { time = this.year + '-10'}
      else if(this.month === 'November') { time = this.year + '-11'} else if(this.month === 'December') { time = this.year + '-12'}
    }
    this.ownerService.getDashboardInfo(time, this.duration).subscribe(response => {

      if(response.code == 200)  {
        console.log("dashboard info ", response);
        this.isCurrencyLoaded = true;
        this.currency = response.currency;
        if(response.payload.topFiveBookings.length > 0) {
          for (const bookingsEntry of response.payload.topFiveBookings) {
            bookingsEntry.lastNight = moment.unix(bookingsEntry.lastNight).add('days', 1).format('YYYY-MM-DD');
            bookingsEntry.firstNight = moment.unix(bookingsEntry.firstNight).format('YYYY-MM-DD');
            // regardless of anything, property should be displayed in user's currency. -> Thomas
            bookingsEntry.currency = this.currency;
          }}
        this.displayColumns1 = ['bookId', 'guestFirstName', 'guestPropertyName', 'firstNight', 'lastNight'];
        if(this.showRevenue) {
          this.displayColumns1.push("bookingPrice")
        }
        if(this.showBaseRevenue) {
          this.displayColumns1.push("bookingBasePrice")
        }

        this.dataSource1 = new MatTableDataSource(response.payload.topFiveBookings);
        this.dataSource1.sort = this.sort;
        this.dataSource1.paginator = this.paginator;
        this.totalRevenue = response.payload.totalRevenue;
        this.totalBaseRevenue = response.payload.totalBaseRevenue;
        this.totalBookings = response.payload.totalBookings;
        this.totalRentals = response.payload.totalProperties;
        this.activeJobs = response.payload.activeJobs;
        this.difference = response.payload.difference;
        this.completedJobs = response.payload.completedJobs;
        if(response.payload.ownerPersonalBookings.length > 0) {
          this.hasTableData = true;
          this.displayColumns = ['count', 'propertyName', 'unitName', 'firstNight', 'lastNight', 'status'];
          let count = 1;
          for (const bookingsEntry of response.payload.ownerPersonalBookings) {
            bookingsEntry.lastNight = moment.unix(bookingsEntry.lastNight).add('days', 1).format('YYYY-MM-DD');
            bookingsEntry.firstNight = moment.unix(bookingsEntry.firstNight).format('YYYY-MM-DD');
            bookingsEntry.count = count++;
          }
          this.dataSource = new MatTableDataSource(response.payload.ownerPersonalBookings);
          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        } else {
          this.hasTableData = false;
        }

        if (this.dashboardGroup.value.duration == 'Yearly') {
          this.showChartYearly(response.payload.yearRevenueList);
        } else if (this.dashboardGroup.value.duration == 'Monthly') {
          this.showChart(response.payload.yearRevenueList)
        }
      } else {
        this.toastrService.error (response.desc, 'Error', {
          timeOut: 3000,
          onActivateTick: true
        })
      }
    });
  }
 

  showChart(event): void {    
    console.log("show chart event: ", event);
    this.showLineChart = true;
    var tempArray = new Array ();
        event.forEach((month: Month) => {
          tempArray.push(month.day);
        });
          for (let index = 1; index <= tempArray.length; index++) {
              this.daysArr.push (index);
          }
          const showPrice = this.showRevenue ? 'price' : 'basePrice';
          const order = ['month', showPrice];
          const output = event.map(function (item) {
            return order.reduce(function (a, c) {
              a.push(item[c]);
              return a;
            }, []);
          });
            this.chartOptions = {
              credits: {
                enabled: false
              },
              chart: {
                type: 'area'
              },
              title: {
                text: ' Revenue '
              },
              xAxis: {
                categories: this.daysArr,
                title: {
                  text: 'Date'
                }
              },
              yAxis: {
                title: {
                  text: 'Price'
                },
              },

              series: [
                {
                  name: this.month,
                  data: output
                }
              ]
            };
          this.daysArr = [];
  }

  showChartYearly(event): void {
    console.log("show chart yearly event: ", event);
    const showPrice = this.showRevenue ? 'price' : 'basePrice';
    const order = ['month', showPrice];
        const output = event.map(function (item) {
          return order.reduce(function (a, c) {
            a.push(item[c]);
            return a;
          }, []);
        });
          this.chartOptions = {
            credits: {
              enabled: false
            },
            chart: {
              type: 'area'
            },
            title: {
              text: ' Total Revenue '
            },
            xAxis: {
              categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
              title: {
                text: 'Month'
              }
            },
            yAxis: {
              title: {
                text: 'Revenue'
              },
            },

            series: [
              {
                name: 'Monthly',
                data: output
              }
            ]
          };
          this.showLineChart = true;
        this.daysArr = [];
      }


      toggleFullText(detailId: string) {
        this.showFullText[detailId] = !this.showFullText[detailId];
      }

      adjustUrl(detail) {
        // Check if the URL has a protocol
        if (!detail.startsWith('http://') && !detail.startsWith('https://')) {
          // If not, add 'http://' as the default protocol
          return 'http://' + detail;
        }
        return detail;
      }

      updateComponent(obj){ 
         
        if(obj.code == "show-trial" && obj.data.trial)  {         
          this.showTrialAlert = true;
          this.remainingDaysString =obj.data.remainingDaysString
        }else{
        this.showTrialAlert = false;
      }
       
    }

    removeDuplicatesAndSortDesc(){     
       this.timesArray = Array.from(new Set(this.timesArray)).sort((a, b) => b - a);
       return;
    }

  }
