import {Component, OnInit} from '@angular/core';
import {PluginsService} from '../../../services/plugins.service';
import {PMSPlugin} from '../../../models/pms-plugin';
import {GenericDto} from '../../../models/generic-dto';
import {HttpErrorResponse} from '@angular/common/http';
import {PluginDto} from '../../../models/plugin-dto';
import {MatDialog} from '@angular/material';
import {AddNewPluginComponent} from './add-new-plugin/add-new-plugin.component';
import {BedplusToastrService} from '../../../services/bedplus-toastr.service';
import { TourService } from 'src/app/services/tour.service';
import { OwnerService } from 'src/app/services/owner.service';
import { UserService } from 'src/app/services/user.service';
import { SharedService } from 'src/app/services/shared.service';

interface PluginDashboard {
  id: string;
  plugins: PluginDto[];
}

@Component({
  selector : 'app-plugins',
  templateUrl: './plugins.component.html',
  styleUrls: ['./plugins.component.scss']
})
export class PluginsComponent implements OnInit {

  // Tour guide///

  HOME_PMS_PAGE:any = true


  /**
   *  Plugins list for add new plugin component which will contain
   *  fresh list of all plugins.
   */
  pluginsList: PMSPlugin[];

  /**
   * Saved Plugin List to show on plugin dashboard
   */
  pluginDashboard: PluginDashboard[] = new Array<PluginDashboard>();

  /**
   * To Open Add-New-Componenet in edit mode it will require two parameters
   * 1. editMode : boolean
   * 2. plugin : PluginDto
   */
  plugin: PluginDto;
  editMode: boolean;

  /**
   * Empty State Flag
   *
   */
  isEmptyState = false;

  constructor(public sharedService: SharedService,public pluginsService: PluginsService, public dialog: MatDialog,
    protected userService: UserService,private toastr: BedplusToastrService,private tourService: TourService,protected ownerService: OwnerService) {

  }

  ngOnInit() {
    this.loadPlugins();
  }

  ngAfterViewInit(): void {
    this.sharedService.shareAndCommunicate.subscribe(
      (data) => {
        this.updateComponent(data)   
      }
    );
  }

  hide(): void {
    this.isEmptyState = this.pluginDashboard.length > 0 ? false : true;
  }

  createNew() {
    this.editMode = false;
    this.plugin = null;
    this.addNewPlugin();
  }



  addNewPlugin() {
    this.isEmptyState = false;
    this.pluginsService.getPluginsList()
      .subscribe((response: GenericDto<PMSPlugin[]>) => {
        console.log("in subscribe response: ", response);
        if (response.code === 200) {

          this.pluginsList = response.payload;
        const dialogRef =   this.dialog.open(AddNewPluginComponent, {
            width: '85%',
            data: {
              pluginList : this.pluginsList,
              plugin: this.plugin,
              editMode : this.editMode
            }
        });

          dialogRef.componentInstance.reloadPlugins.subscribe(() => {
            this.closeDialogAndShowSuccessMessage();
            this.loadPlugins();
          });

          dialogRef.afterClosed().subscribe(() => {
            this.hide();
          });
        } else {
          // show errors here
        }

      }, (error: HttpErrorResponse) => {
        // show errors here
      });
  }

  closeDialogAndShowSuccessMessage(): void {
    if(this.editMode) {
      this.dialog.closeAll();
      this.toastr.showSuccessMessage('Updated Successfully!', '', 3000);
    }
  }

  enableEditMode(plugin: PluginDto): void {
    this.editMode = true;
    this.plugin = plugin;
    this.addNewPlugin();
  }

  importPlugin(plugin: PluginDto): void {
    this.pluginsService.importData(plugin)
      .subscribe((response: GenericDto<any>) => {
          if (response.code === 200) {
            // successfully imported
            this.loadPlugins();
          } else {
            // show error here
            // show toster here
          }
        },
        (error: HttpErrorResponse) => {
          // show error here
        });
  }

  deletePlugin(plugin: PluginDto): void {
    if (confirm("Are you sure you want to delete this Plugin ?")) {

    this.pluginsService.deletePlugin(plugin)
      .subscribe(
        (response: GenericDto<any>) => {
          if (response.code === 200) {
            // successfully deleted
            this.loadPlugins();
          } else {
            // show error here
          }
        },
        (error: HttpErrorResponse) => {
          // show error here
        }
      );
  }
  }
  /**
   * Load saved plugins
   */
  loadPlugins(): void {
    this.pluginsService.getSavedPlugins()
      .subscribe((response: GenericDto<PluginDto[]>) => {
          if (response.code === 200) {
            this.pluginDashboard = this.preparePluginDashboard(response.payload);
            this.isEmptyState = this.pluginDashboard.length > 0 ? false : true;
            this.createSteps();
          } else {
            // show errors here
          }
        },
        (error: HttpErrorResponse) => {
          // show errors here
        });

  }
  

  createSteps(){
    let steps= [
      
      {
        element: "#SyncButton",
          title: "Re-Sync properties",
          content: "This will import all your property, room and unit data from your PMS to BedPlus.",
          placement: 'right',
          backdrop: true,
      },
      {
        element: "#bookingSyncButton",
          title: "Re-SyncBookings",
          content: "This will import all your bookings from your PMS to BedPlus. It will update any bookings that already exist and have changed.",
          placement: 'left',
          backdrop: true,
      },]

        if(this.isEmptyState==false){
          const index = steps.indexOf({
            element: "#Addbutton",
            title: "Add new PMS connection",
            content: "Click the add button to add your PMS.",
            placement: 'left',
            backdrop: true,
          });
if (index > -1) { // only splice array when item is found
  steps.splice(index, 1); // 2nd parameter means remove one item only
}
         
        }else{
          steps.unshift({
            element: "#Addbutton",
            title: "Add new PMS connection",
            content: "Click the add button to add your PMS.",
            placement: 'left',
            backdrop: true,
          },)

        }
      

      this.tourService.createTour(steps);

      this.userService.getUser().subscribe((res:any) => {
        if (res.code === 200 && res.payload.tourFlags) {
          this.HOME_PMS_PAGE= res.payload.tourFlags.PMS_PAGE;
          if(this.HOME_PMS_PAGE == false){
            this.tourService.startTour();
            setTimeout(()=>{
              let interval = setInterval(()=>{
                if(localStorage.getItem('tour_end')==='yes'){
                  localStorage.setItem('tour_end','No')
                  clearInterval(interval);
                  this.ownerService.changeTourFlag('PMS_PAGE').subscribe((res:any)=>{
                    this.HOME_PMS_PAGE=res.payload.tourFlags.PMS_PAGE;
                  })
                }
              console.log(localStorage.getItem('tour_end'))
              },50);
            },500)
          }
        }})
  }

  /**
   *
   */
  preparePluginDashboard(plugins: PluginDto[]): PluginDashboard[] {
    const dashboardPlugins: PluginDashboard[] = new Array<PluginDashboard>();
    plugins.forEach((plugin: PluginDto) => {

      const filteredPlugins: PluginDashboard[] = dashboardPlugins
        .filter(
          (dashboardPlugin: PluginDashboard) => plugin.pluginId === dashboardPlugin.id);
      if (filteredPlugins.length > 0) {
        filteredPlugins[0].plugins.push(plugin);
      } else {
        dashboardPlugins.push({
          id: plugin.pluginId,
          plugins: [plugin]
        });
      }
    });
    return dashboardPlugins;
  }
  
  updateComponent(obj){       
    if(obj.code == "updateLastSynTime"){
      this.loadPlugins();        
    }  
  }


}
