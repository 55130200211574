import {Component, Inject, OnInit} from '@angular/core';
import {PropertyService} from '../../../services/property.service';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import {FormGroup, FormBuilder, Validators} from '@angular/forms';
import {UserService} from '../../../services/user.service';
import {GenericDto} from '../../../models/generic-dto';
import {User} from '../../../models/user';
import {JobService} from '../../../services/job.service';
import {JobAssign} from '../../../models/job-assign';
import {success} from '../../../constants/notification-messages.constant';
import {ToastrService} from 'ngx-toastr';
@Component({
  selector: 'app-update-job',
  templateUrl: './update-job.component.html',
  styleUrls: ['./update-job.component.scss'],
  providers: [PropertyService, JobService]
})
export class UpdateJobComponent implements OnInit {
  updateJobForm: FormGroup;
  cleanerId: string;
  jobStatus: string;
  allCleanerUser: any;
  jobId: string;
  selectedCleaner: any;
  currentJobObject: any;
  currentJobStatus: any;
  constructor( public dialogRef: MatDialogRef<UpdateJobComponent>,
               @Inject(MAT_DIALOG_DATA) public data: any, private formBuilder: FormBuilder,
               private userService: UserService, private jobService: JobService, private toastrService: ToastrService) {
    this.jobId = data.jobId;
    this.currentJobObject = data.currentJobObject;
    this.selectedCleaner = this.currentJobObject[0].cleanerId;
    this.currentJobStatus = this.currentJobObject[0].jobRequest;

  }

  ngOnInit() {
    this.updateJobForm = this.formBuilder.group({
      cleanerId: [ this.selectedCleaner , Validators.required],
      jobStatus: [this.currentJobStatus , Validators.required]
    });

    this.userService.getUserByRole('CLEANER')
      .subscribe((response: GenericDto<User[]>) => {
        if (response.code === 200) {
          this.allCleanerUser = response.payload;
          
        }
      });

  }
  updateJobDetails() {

    this.cleanerId = this.updateJobForm.controls['cleanerId'].value;
    this.jobStatus = this.updateJobForm.controls['jobStatus'].value;
    this.jobService.updateCleanerJob(this.jobId, this.cleanerId, this.jobStatus)
      .subscribe((response: GenericDto<JobAssign>) => {
        if (response.code === 200) {
        this.hide();
          this.toastrService.success('Job Updated ', success, {
            timeOut: 2000,
            onActivateTick: true
          });
        }
      });
  }
  hide() {
    this.dialogRef.close();
  }
}
