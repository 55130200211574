import {GenericDto} from '../models/generic-dto';
import {environment} from '../../environments/environment';
import {HttpService} from '../core/services/http.service';
import {Observable} from 'rxjs/index';
import {Injectable} from '@angular/core';
import {updateJob, getJobsByJobRequest} from '../constants/job-api.constants';
import {JobAssign} from '../models/job-assign';

@Injectable()
export class JobService {

  constructor(private httpService: HttpService) {
  }


  updateCleanerJob(id: string, cleanerId: string, status: string): Observable<GenericDto<JobAssign>> {
    return this.httpService.putAuth<GenericDto<JobAssign>>(environment.API + updateJob +
      '?id=' + id + '&cleanerId=' + cleanerId + '&jobRequest=' + status, id);
  }

  getJobByJobStatus(status: string) {
    console.log(status)
    return this.httpService.getAuth<GenericDto<number>>(environment.API + getJobsByJobRequest + status);
  }
}
