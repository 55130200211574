import {OwnerService} from '../../../../services/owner.service';
import {UserService} from '../../../../services/user.service';
import {TokenService} from '../../../../core/services/token.service';
import {Component, OnInit, Input} from '@angular/core';
import {MatDialog} from '@angular/material';
import {ActivatedRoute, Router} from '@angular/router';
import {GenericDto} from '../../../../models/generic-dto';
import {User} from '../../../../models/user';
import {HttpErrorResponse} from '@angular/common/http';
import {FormBuilder} from '@angular/forms';
import {OwnerPropertiesComponent} from '../owner-properties/owner-properties.component';


@Component({
  selector: 'app-owner-details',
  templateUrl: './owner-details.component.html',
  styleUrls: ['./owner-details.component.scss'],
  providers: [OwnerService]
})
export class OwnerDetailsComponent implements OnInit  {

  @Input() ownerDetail: User;
  isLoading = false;
  id: any;

  constructor(private fb: FormBuilder, public dialog: MatDialog, private router: Router, private route: ActivatedRoute,
             private userService: UserService, public tokenService: TokenService) {
  }

  ngOnInit() {

    if (this.router.url.includes('/ownerDetails/')) {
        this.route.params.subscribe((data: { id: string }) => {
        this.userService.getUserDetails(data.id)
          .subscribe((response: GenericDto<User>) => {
            if (response.code === 200) {
              this.ownerDetail = response.payload;

            } else {
              // show error message
            }
            this.isLoading = false;
          }, (error: HttpErrorResponse) => {
            // show error message
            this.isLoading = false;
          });
      });
    }
    this.showPropertiesByOwnerId(this.ownerDetail , 'booking');
  }
  showPropertiesByOwnerId(user: User , bookingSelection: any): void {
    const dialogRef = this.dialog.open(OwnerPropertiesComponent, {
      width: '200%',
      data: {
        row: user.id,
        bookingSelection: bookingSelection
      }
    });
    dialogRef.afterClosed().subscribe(() => {
    });

    }


  }
