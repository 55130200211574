import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

declare var Tour: any;

@Injectable({
  providedIn: 'root'
})
export class TourService {

  private scroll = new BehaviorSubject({});
  scrollData = this.scroll.asObservable();

  scrollChange(csv: any): void {
    this.scroll.next(csv);
}

  tour: any;

  constructor() { }

  createTour(steps: any[]) {
     
    let step=[];
    step=steps;
    this.tour = new Tour({
      storage:window.localStorage,
        template:`<div class='popover tour'>
        <div class='arrow'></div>
        <h3 class='popover-title'></h3>
        <div class='popover-content'></div>
        <div class='popover-navigation'>
          <button class='btn btn-default' data-role='prev'>« Prev</button>
          <button class='btn btn-default' data-role='next'>Next »</button>
          <button class='btn btn-default' id="endTour" data-role='end'>End tour</button>
        </div>
      </div>`,
      steps: step
    });
  }
  startTour() {
    this.tour.end();
    this.tour.init();
    this.tour.restart()
      this.tour.start(true);

  }
}
