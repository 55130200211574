import {Component, OnDestroy, OnInit, ViewChild,  Renderer2, ElementRef } from '@angular/core';
import {GenericDto} from '../../models/generic-dto';
import {Booking} from '../../models/booking';
import * as moment from 'moment';


import {MatPaginator, MatSort, MatTableDataSource, MatDialog, DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS} from '@angular/material';
import {EventService} from '../../shared/services/event.service';
import * as jsPDF from 'jspdf';
import {ExcelService} from '../../core/services/excel.service';
import {Subscription} from 'rxjs';
import { OwnerService } from 'src/app/services/owner.service';
import { SelectionModel } from '@angular/cdk/collections';
import { ToastrService } from 'ngx-toastr';
import { info, success } from 'src/app/constants/notification-messages.constant';
import { ConfirmationDialogComponent } from 'src/app/shared/components/confirmation-dialog/confirmation-dialog.component';
import { TokenService } from 'src/app/core/services/token.service';
import { ImportBookingComponent } from '../property/property-details/import-booking/import-booking.component';
import { CurrencyPipe } from '@angular/common';
import { FormControl, FormGroup } from '@angular/forms';
import {MatDatepickerInputEvent,
  MatSelectChange,
  MatDatepicker} from '@angular/material';
import { UserService } from 'src/app/services/user.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Property } from 'src/app/models/property';
import { RoomType } from 'src/app/models/RoomType';

import * as _moment from 'moment';
// tslint:disable-next-line:no-duplicate-imports
import {default as _rollupMoment, Moment} from 'moment';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { MY_FORMATS } from 'src/app/shared/components/calendar/calendar.component';
import { TourService } from 'src/app/services/tour.service';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';

@Component({
  selector: 'app-bookings-table',
  templateUrl: './bookings-table.component.html',
  styleUrls: ['./bookings-table.component.scss'],
  providers: [ {
    provide: DateAdapter,
    useClass: MomentDateAdapter,
    deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
  },

  {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
]
})
export class BookingsTableComponent implements OnInit {

  @ViewChild('fromPicker') fromPicker: MatDatepicker<any>;
  displayColumns: any;
  startDate = 0;
  endDate = 0;
  currentRecord;
  roomId = 'all';
  propertyId = 'all';
  dataSource;
  x: number;
  y: number;
  selectedJobRequest: any = 'all';
  hasFilterSummery = false;
  totalBookingAmount: number;
  totalPlatformCommission: number;
  totalCleaningFee: number;
  totalTax: number;
  totalPaymentProcessingFee: number;
  totalPropertyMngmtComm: number;
  // totalExpenses: number;
  totalNetProfit: number;
  hasBookings = false;
  bookingDataList = new Array();
  isOwner = false;
  ownerPermissions: any;
  columnPermissions: any;
  selection = new SelectionModel(true, []);
  bookingIds = [];
  isDeleting = false;
  isAdmin = false;
  currency: 'USD';
  date = new FormControl(moment());
  Date: any=this.convert(new Date());
  bookingForm: FormGroup;
  booking: Booking;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  User: any;
  user: any;
  owners = [];
  owners1=[];
  rentals: any;
  rentalsWithId1: any=[];
  rentalsWithId=[];
  properties: Array<Property>;
  ownerId: string;
  rentalSearchValue:any=new FormControl('');
  propertiess=[];
  selectedRentals: string [] = [];
  selectedValue:any=[];
  propertyResponse: any[];

  displayedColumns: string[] = ['bookId', 'guestName', 'firstNight', 'lastNight', 'guestAddress', 'netProfit', "select"];
  allColumns: string[] = ['bookId', 'guestName', 'firstNight', 'lastNight', 'guestAddress', 'referer', 'status',
                          'bookingPrice', 'commission', 'cleaningFee', 'tax', 'paymentProcessingFee', 
                          'propertyManagementCommission', 'netProfit', 'select'];
  /// TOUR
  Home_FLAG_BOOKING: any=true;
  initialOwnersWithProperties: any;
  constructor(private renderer: Renderer2, private breakpointObserver: BreakpointObserver, protected eventService: EventService, private toastrService: ToastrService,
     protected excelService: ExcelService, public dialog: MatDialog,
      private ownerService: OwnerService, private tokenService: TokenService,
      private cp: CurrencyPipe,
      private userService: UserService,private tourService: TourService,
      ) {
  }
 

  ngOnInit() {
    this.breakpointObserver.observe(['(max-width: 782px)']).subscribe((state: BreakpointState) => {
      if (state.matches) {
        this.displayedColumns = ['bookId', 'guestName', 'firstNight', 'lastNight', 'guestAddress', 'netProfit', "select"]// Mobile view columns
      } else {
        this.displayedColumns = this.allColumns; // Desktop view columns
      }
    });
  

 
  


    const userId = (localStorage.getItem("userId"))
      ? localStorage.getItem("userId")
      : this.tokenService.getUserId();

    this.User = this.tokenService.getCurrentUser();
    if(this.User === 'ADMINISTRATOR') {
      this.isAdmin = true;
      this.userService.getPropertiesByAdmin().subscribe(properties => {
        //console.log("getPropertiesByAdmin: ", properties);
        if(properties && properties.payload) {
          this.propertyResponse=properties.payload;
          this.populateRentalData(properties.payload);
        }
        else {
          // console.error("Property data not found for user.payload.id", user.payload.id);
        }
      });
      this.userService.getOwnersByAdminId(userId).subscribe(response => {
        response.payload.forEach(owner => {
          this.owners.push(owner);
          this.owners1.push(owner);
          var tempProperties = new Array();

         });
      });
    }
    if(this.User=='Owner'){
      this.userService.getUser().subscribe((response: GenericDto<any>) => {
        if (response.code === 200) {
          this.user = response.payload.id;
          this.userService.getOwnerRoomUnits(response.payload.id).subscribe(ownerRoomUnitsResponse => {
          this.propertyResponse=ownerRoomUnitsResponse.payload;
          this.populateRentalData(ownerRoomUnitsResponse.payload,);
          });
        } else {
          // show error and redirect to login page
        }

      }, (error: HttpErrorResponse) => {
        // show error and redirect to login page
      });
    }


    // Bootstrap tour ////

    let steps= [
      {
        element: "#datepicker",
          title: "Date Picker",
          content: "With this you can Select From date.",
          placement: 'right',
          backdrop: true,
      },
      {
        element: "#ownerList",
          title: "Owner List",
          content: "With this you will able to see owner list to choose from",
          placement: 'right',
          backdrop: true,
      },{
        element: "#rentalList",
          title: "Rental List",
          content: "With this you will able to see Rentals list to choose from",
          placement: 'right',
          backdrop: true,
      },
      {
        element: "#ReSyncBookings",
          title: "Re-Sync Booking",
          content: "Re-Sync Booking button ",
          placement: 'right',
          backdrop: true,
      },
      {
        element: "#ExportAsExcel",
          title: "Export As Excel",
          content: "With this you can Export file as Excel.",
          placement: 'right',
          backdrop: true,
      },{
        element: "#ExportAsPDF",
          title: "Export As PDF",
          content: "With this you can Export file to PDF",
          placement: 'right',
          backdrop: true,
        },{
          element: "#DeleteBookings",
            title: "Delete Bookings",
            content: "With this you can DElete Bookings ",
            placement: 'left',
            backdrop: true,

        },{
          element: "#BookingtableList",
            title: "Booking Table ",
            content: "With this you can View the Bookings in a table form",
            placement: 'top',
            backdrop: true,

        }
        ]

      this.tourService.createTour(steps);

      this.userService.getUser().subscribe((res:any) => {
        if (res.code === 200 && res.payload.tourFlags) {
          this.Home_FLAG_BOOKING= res.payload.tourFlags.BOOKINGS_PAGE;
          if(this.Home_FLAG_BOOKING == false){
            this.tourService.startTour();
            setTimeout(()=>{

              let interval = setInterval(()=>{
                if(localStorage.getItem('tour_end')==='yes'){
                  localStorage.setItem('tour_end','No')
                  clearInterval(interval);
                  this.ownerService.changeTourFlag('BOOKINGS_PAGE').subscribe((res:any)=>{
                    this.Home_FLAG_BOOKING=res.payload.tourFlags.BOOKINGS_PAGE;
                  })
                }

              },50);
            },500)
          }
        }})
    // --- Bootstrap tour ends -- /////
    // if (this.tokenService.getCurrentUser() === 'CLEANER') {
    //   this.isCleaner = true;
    // }
    // this.userRole = this.tokenService.getCurrentUser();

    /*console.log('TimeZone =', Intl.DateTimeFormat().resolvedOptions().timeZone);*/

    if (this.tokenService.getCurrentUser() === 'OWNER') {
      // this.editJobStatus = false;
      // this.showRevenueCalculation = true;
      this.userService.getUser().subscribe((response: GenericDto<any>) => {
        if (response.code === 200) {
          this.user = response.payload.id;
          this.userService.getOwnerRoomUnits(response.payload.id).subscribe(ownerRoomUnitsResponse => {
            this.propertyResponse=ownerRoomUnitsResponse.payload;
           this.populateRentalData(ownerRoomUnitsResponse.payload,);
          });
          // this.showOwnerPermissions(response.payload.id);
        } else {
          // show error and redirect to login page
        }

      }, (error: HttpErrorResponse) => {
        // show error and redirect to login page
      });
    } else {
      // this.viewPropertiesPermissions = true;
      // this.showPermissionsTag = false;
    }

    this.bookingForm = new FormGroup({
      "date":new FormControl(null),
     // 'toDate': new FormControl(null),
     // 'fromDate': new FormControl(moment('mm/yyyy')),
     'owner':new FormControl(),
     'property': new FormControl(),
   });
   this.bookingsInTableView();
  }

  searchOwner(filterValue) {
    if ((filterValue) && (filterValue !== null)) {
      let tempOwners = new Array();
      for (let singleObject of this.initialOwnersWithProperties) {
        for (let currentOwnerObject of singleObject) {
          if (currentOwnerObject.owner) {
            let currentOwner = currentOwnerObject.owner.firstName + " " + currentOwnerObject.owner.lastName;
            if (currentOwner.trim().toLowerCase().includes(filterValue.trim().toLowerCase())) {
              tempOwners.push(currentOwnerObject.owner)
            }
          }
        }
      }
      this.owners = tempOwners;
    } else {
      filterValue = null;
      this.owners = this.owners1;
    }
  }

  bookingsInTableView() {

    this.ownerService.getOwnerDetailPermissions()
      .subscribe(
        (response: GenericDto<any>) => {
          // console.log(response);
          if(response.code === 404) {
            // do nothing
            if(this.isAdmin){
              this.displayColumns = ['bookId', 'guestName', 'firstNight', 'lastNight', 'guestAddress', 'referer', 'status', 'bookingPrice',
              'commission', 'cleaningFee', 'tax', 'paymentProcessingFee', 'propertyManagementCommission', 'netProfit', 'select'];
            } else {
              this.displayColumns = ['bookId', 'guestName', 'firstNight', 'lastNight', 'guestAddress', 'referer', 'status', 'bookingPrice',
              'commission', 'cleaningFee', 'tax', 'paymentProcessingFee', 'propertyManagementCommission', 'netProfit'];
            }

            // if (isNaN(this.startDate) === true && isNaN(this.endDate) === true) {
            //   this.startDate = 0;
            //   this.endDate = 0;
            // } else if (isNaN(this.startDate) === true) {
            //   this.startDate = 0;
            // } else if (isNaN(this.endDate) === true) {
            //   this.endDate = 0;
            // }
            if(this.bookingForm.value.property && this.bookingForm.value.property.length>0){
              var payload:any=this.bookingForm.value.property !== null && this.bookingForm.value.property[0]!='all' ? this.bookingForm.value.property:null;
            }else{
               payload = null;
            }
            this.eventService.getEvent(this.Date,this.ownerId,'all',payload).subscribe((data: GenericDto<Booking>) => {
              /*
                    let count = 1;
              */
              for (const bookingsEntry of data.payload.bookingList) {

                this.currentRecord = bookingsEntry;
                bookingsEntry.lastNight = moment.unix(bookingsEntry.lastNight).add('days', 1).format('YYYY-MM-DD');
                bookingsEntry.firstNight = moment.unix(bookingsEntry.firstNight).format('YYYY-MM-DD');
                if (bookingsEntry.guestName !== null) {
                  bookingsEntry.guestName = bookingsEntry.guestFirstName.concat(' ', bookingsEntry.guestName);
                } else {
                  bookingsEntry.guestName = bookingsEntry.guestFirstName;
                }
                if (bookingsEntry.status === '0'){
                  bookingsEntry.status = 'Cancelled'
                } else if (bookingsEntry.status === '1'){
                  bookingsEntry.status = "Confirmed"
                } else if (bookingsEntry.status === '2'){
                  bookingsEntry.status = 'New'
                } else if (bookingsEntry.status === '3'){
                  bookingsEntry.status = 'Request'
                } else if (bookingsEntry.status === '4'){
                  bookingsEntry.status = 'Black'
                } else {
                  bookingsEntry.status = 'Unknown'
                }
                //         bookingsEntry.count = count++;
                //
              }
              if (data.code === 200) {
                if (data.payload.bookingList.length > 0) {
                  this.hasBookings = true;

                  this.dataSource = new MatTableDataSource(data.payload.bookingList);

                  this.dataSource.sort = this.sort;
                  this.dataSource.paginator = this.paginator;
                  this.dataSource.filterPredicate = (data: any, filter: string) => {

                    let matchFound = false;
                    for (let column of this.displayColumns) {
                      //console.log(column +" = "+ data[column]);
                      if(column in data) {
                        var value = data[column];
                        if(column == "guestAddress"){
                          value = data["guestPropertyName"] +","+ data["guestRoomName"] + ","+ data["roomId"];
                        }
                        if(value) {
                          matchFound = (matchFound || value.toString().trim().toLowerCase().includes(filter.trim().toLowerCase()))
                        }
                      }
                    }
                    return matchFound;
                  };


                  this.bookingDataList = new Array();
                  this.currency = data.payload.bookingList[0].currency;
                  for (let i = 0; i < data.payload.bookingList.length; i++) {
                    let exportBookingData = {
                      RentalName: data.payload.bookingList[i].guestPropertyName + ',' + data.payload.bookingList[i].guestRoomName + ',' + data.payload.bookingList[i].roomId,
                      BookingAmount: data.payload.bookingList[i].bookingPrice,
                      PlatformCommission: data.payload.bookingList[i].commission,
                      CleaningFees: data.payload.bookingList[i].cleaningFees,
                      Tax: data.payload.bookingList[i].tax,
                      PaymentProcessingFee: data.payload.bookingList[i].paymentProcessingFee,
                      ManagementCommission: data.payload.bookingList[i].propertyManagementCommission,
                      // Expenses: '0',
                      NetProfit: data.payload.bookingList[i].netProfit
                    };
                    this.bookingDataList.push(exportBookingData);
                    if (i === 0) {
                      this.totalBookingAmount = data.payload.bookingList[i].bookingPrice;
                      this.totalPlatformCommission = data.payload.bookingList[i].commission;
                      this.totalCleaningFee = data.payload.bookingList[i].cleaningFees;
                      this.totalTax = data.payload.bookingList[i].tax;
                      this.totalPaymentProcessingFee = data.payload.bookingList[i].paymentProcessingFee;
                      this.totalPropertyMngmtComm = data.payload.bookingList[i].propertyManagementCommission;
                      // this.totalExpenses = 0;
                      this.totalNetProfit = data.payload.bookingList[i].netProfit;
                    } else {
                      this.totalBookingAmount += data.payload.bookingList[i].bookingPrice;
                      this.totalPlatformCommission += data.payload.bookingList[i].commission;
                      this.totalCleaningFee += data.payload.bookingList[i].cleaningFees;
                      this.totalTax += data.payload.bookingList[i].tax;
                      this.totalPaymentProcessingFee += data.payload.bookingList[i].paymentProcessingFee;
                      this.totalPropertyMngmtComm += data.payload.bookingList[i].propertyManagementCommission;
                      this.totalNetProfit += data.payload.bookingList[i].netProfit;
                    }
                  }


                }else{
                  this.dataSource = new MatTableDataSource([]);
                }
              }
            });

          } else {
            //console.log('is Owner');
            this.isOwner = true;
            this.columnPermissions = response.payload['columnPermissions'];
            this.ownerPermissions = response.payload['permissions'];
            //console.log(this.columnPermissions);
            this.getDisplayColumns();
            // this.displayColumns = ['bookId', 'guestName', 'firstNight', 'lastNight', 'guestAddress', 'referer', 'bookingPrice',
            //   'commission', 'cleaningFee', 'tax', 'paymentProcessingFee', 'propertyManagementCommission', 'netProfit'];
            if (isNaN(this.startDate) === true && isNaN(this.endDate) === true) {
              this.startDate = 0;
              this.endDate = 0;
            } else if (isNaN(this.startDate) === true) {
              this.startDate = 0;
            } else if (isNaN(this.endDate) === true) {
              this.endDate = 0;
            }
            // if(this.bookingForm.value.property && this.bookingForm.value.property.length>0){
            //   var payload:any=this.bookingForm.value.property !== null && this.bookingForm.value.property[0]!='all' ? this.bookingForm.value.property:null;
            // }else{
             var  payload = null;


            // }
            // this.eventService.getEvents(this.startDate, this.endDate, this.roomId, this.propertyId,
            //   this.selectedJobRequest).subscribe((data: GenericDto<Booking>) => {
              this.eventService.getEvent(this.Date,undefined,'all',payload).subscribe((data: GenericDto<Booking>) => {
              /*
                    let count = 1;
              */
              for (const bookingsEntry of data.payload.bookingList) {

                this.currentRecord = bookingsEntry;
                bookingsEntry.lastNight = moment.unix(bookingsEntry.lastNight).add('days', 1).format('YYYY-MM-DD');
                bookingsEntry.firstNight = moment.unix(bookingsEntry.firstNight).format('YYYY-MM-DD');
                if (bookingsEntry.guestName !== null) {
                  bookingsEntry.guestName = bookingsEntry.guestFirstName.concat(' ', bookingsEntry.guestName);
                } else {
                  bookingsEntry.guestName = bookingsEntry.guestFirstName;
                }
                /*
                        bookingsEntry.count = count++;
                */
              }
              if (data.code === 200) {
                if (data.payload.bookingList.length > 0) {
                  this.hasBookings = true;
                  this.dataSource = new MatTableDataSource(data.payload.bookingList);
                  this.dataSource.sort = this.sort;
                  this.dataSource.paginator = this.paginator;
                  this.bookingDataList = new Array();
                  this.currency = data.payload.bookingList[0].currency;
                  for (let i = 0; i < data.payload.bookingList.length; i++) {

                    let exportBookingData = {
                      RentalName: data.payload.bookingList[i].guestPropertyName + ',' + data.payload.bookingList[i].guestRoomName + ',' + data.payload.bookingList[i].roomId,
                      BookingAmount: data.payload.bookingList[i].bookingPrice,
                      PlatformCommission: data.payload.bookingList[i].commission,
                      CleaningFees: data.payload.bookingList[i].cleaningFees,
                      Tax: data.payload.bookingList[i].tax,
                      PaymentProcessingFee: data.payload.bookingList[i].paymentProcessingFee,
                      ManagementCommission: data.payload.bookingList[i].propertyManagementCommission,
                      // Expenses: '0',
                      NetProfit: data.payload.bookingList[i].netProfit
                    };
                    this.bookingDataList.push(exportBookingData);
                    if (i === 0) {
                      this.totalBookingAmount = data.payload.bookingList[i].bookingPrice;
                      this.totalPlatformCommission = data.payload.bookingList[i].commission;
                      this.totalCleaningFee = data.payload.bookingList[i].cleaningFees;
                      this.totalTax = data.payload.bookingList[i].tax;
                      this.totalPaymentProcessingFee = data.payload.bookingList[i].paymentProcessingFee;
                      this.totalPropertyMngmtComm = data.payload.bookingList[i].propertyManagementCommission;
                      // this.totalExpenses = 0;
                      this.totalNetProfit = data.payload.bookingList[i].netProfit;
                    } else {
                      this.totalBookingAmount += data.payload.bookingList[i].bookingPrice;
                      this.totalPlatformCommission += data.payload.bookingList[i].commission;
                      this.totalCleaningFee += data.payload.bookingList[i].cleaningFees;
                      this.totalTax += data.payload.bookingList[i].tax;
                      this.totalPaymentProcessingFee += data.payload.bookingList[i].paymentProcessingFee;
                      this.totalPropertyMngmtComm += data.payload.bookingList[i].propertyManagementCommission;
                      this.totalNetProfit += data.payload.bookingList[i].netProfit;
                    }
                  }


                }
              }
            });
          }
        }
      );
  }

  fromDateChanged(event: MatDatepickerInputEvent<Date>): void {
    // this.booking.bookingList = [];
    // const changedDate = new Date(event.value);
    // const toDateValue = new Date(this.bookingForm.get('toDate').value);
    // this.startDate = this.bookingForm.get('fromDate').value >= 0 ? moment(this.bookingForm.get('fromDate').value).unix() : 0;
    // this.endDate = this.bookingForm.get('toDate').value >= 0 ? moment(this.bookingForm.get('toDate').value).unix() : 0;
    this.Date = this.bookingForm.get('date').value >= 0 ? moment(this.bookingForm.get('date').value).unix() : 0;
    this.propertyId = (this.bookingForm.get('property').value as Property).id != null ? (this.bookingForm.get('property').value as Property).id : 'all';
    if (this.bookingForm.get('room').value === 'all') {
      this.roomId = 'all';
    } else {
      this.roomId = this.bookingForm.get('room').value != null ? (this.bookingForm.get('room').value as RoomType).roomId : 'all';
    }
    // this.booking.bookingList = [];
    this.bookingsInTableView();
    // this.containerEl.fullCalendar('destroy');
    // this.createCalendar('old');


    // this.resetProperty();
    // if (changedDate > toDateValue) {
    //   this.bookingForm.get('toDate').patchValue(null);
    // }
  }

  ownerSelected() {
    //console.log("this.bookingForm.value.owner",this.bookingForm.value.owner,"this.bookingForm.value.owner.id")
    if ((this.bookingForm.value.owner) && (this.bookingForm.value.owner.id !== null) && this.bookingForm.value.owner!='allOwner') {
      this.rentals = new Array();
      this.rentalsWithId1 = new Array();
      this.rentalsWithId = new Array()
      this.properties = [];
      this.ownerId = this.bookingForm.value.owner.id;
      this.userService.getPropertiesByOwnerId(this.bookingForm.value.owner.id).subscribe(properties => {
       // console.log("getPropertiesByOwnerId returns ", properties);
        if(properties && properties.payload) {
          this.populateRentalData(properties.payload);
        }
        else {
          console.error("Property data not found for this.revenueForm.value.owner.id", this.bookingForm.value.owner.id);
        }

      });
      this.bookingForm.patchValue({
        property : null
      });
      this.bookingsInTableView();

    } else if(this.bookingForm.value.owner=='allOwner'){
      console.log
    this.ownerId=undefined;
    this.owners = this.owners1;
    this.populateRentalData(this.propertyResponse);
    this.bookingsInTableView();
    }else {
      this.owners = this.owners1;
    }
  }

  chosenYearHandler(normalizedYear: Moment) {
    const ctrlValue = this.date.value;
    ctrlValue.year(normalizedYear.year());
    this.date.setValue(ctrlValue);
  }

   convert(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }

  chosenMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = this.date.value;
    ctrlValue.month(normalizedMonth.month());
    this.date.setValue(ctrlValue);
    this.Date=this.convert(this.date.value._d);
      this.fromPicker.close();
      this.selectedMonth();
  }

  selectedMonth(): void {
    // var calendar = $('#calendar').fullCalendar('getCalendar');
    // calendar.next()
    //  let calendarApi = calendar.getApi();
        // this.booking.bookingList = [];
        // this.containerEl.fullCalendar('destroy');
        this.bookingsInTableView();
      }

      roomSelected() {
        this.rentalSearchValue.setValue('');
        this.searchRental('');
        if (this.bookingForm.value.property !== null) {
          var rentals = new Array();
          // this.revenueForm.value.property.forEach(property => {
          let flag = false;
          for (let index = 0; index < this.bookingForm.value.property.length; index++) {
            const property = this.bookingForm.value.property[index];
            for (let index = 0; index < this.rentalsWithId.length; index++) {
              const rental = this.rentalsWithId[index];
              if (property === 'all') {
                flag = true;

                break;
              } else {
                console.log("else rental: ", rental);
                if (rental.name === property.name) {
                  rentals.push(rental);
                }
              }
            }
          }
        }

        this.bookingsInTableView();
      }

      searchRental(filterValue) {
        if (filterValue && filterValue.length != 0) {
          if(this.selectedRentals.length>0 && filterValue.trim().length == 1){
            this.selectedRentals.forEach(element=>{
              this.selectedValue.push(element);
            })
          }
          let tempProperties = new Array();
          for(let rental of this.rentalsWithId1){
            if (rental.name.trim().toLowerCase().includes(filterValue.trim().toLowerCase())) {
              tempProperties.push(rental);
            }
          }
          this.rentalsWithId = tempProperties;
        } else {
          if(this.selectedRentals.length>0){
            this.selectedValue.forEach(element=>{
              if(!this.selectedRentals.includes(element)){
                this.selectedRentals.push(element)
              }
            })}
          filterValue = null;
          this.rentalsWithId = this.rentalsWithId1;
        }
      }


  private populateRentalData(properties) {
    // this.rentals = new Array();
    this.rentalsWithId = new Array();
    this.rentalsWithId1 = new Array();
// if(flag===""){
//   properties.forEach(property => {
//     if (property.name) {
//       this.propertiess.push(property);
//       if (property.unitItems) {
//         if (property.unitItems.length == 0) {
//           // this.rentals.push(property.name + " - " + property.propertyName);
//           this.rentalsWithId1.push({id: property.id, name: property.name + " - " + property.propertyName});
//           this.rentalsWithId.push({id: property.id, name: property.name + " - " + property.propertyName});
//         } else if (property.unitItems.length > 0) {
//           property.unitItems.forEach(unitItem => {
//             // this.rentals.push(unitItem.unitName + " - " + property.name + " - " + property.propertyName);
//             this.rentalsWithId1.push({
//               id: property.id,
//               name: unitItem.unitName + " - " + property.name + " - " + property.propertyName,
//               unitId: unitItem.unitId
//             });
//             // this.rentalsWithId.push({
//             //   id: property.id,
//             //   name: unitItem.unitName + " - " + property.name + " - " + property.propertyName,
//             //   unitId: unitItem.unitId
//             // });
//           });
//         }
//       } else if (!property.unitItems) {
//         // this.rentals.push(property.name + " - " + property.propertyName);
//         this.rentalsWithId1.push({id: property.id, name: property.name + " - " + property.propertyName});
//         // this.rentalsWithId.push({id: property.id, name: property.name + " - " + property.propertyName});
//       } else {
//         console.log("property.unitItem - in here");
//         // this.rentals.push(property.name + " - " + property.propertyName);
//         this.rentalsWithId1.push({id: property.id, name: property.name + " - " + property.propertyName});
//         // this.rentalsWithId.push({id: property.id, name: property.name + " - " + property.propertyName});
//       }
//     } else {
//       console.log("property.units 2 - in here");
//       // this.rentals.push(property.name + " - " + property.propertyName);
//       this.rentalsWithId1.push({id: property.id, name: property.name + " - " + property.propertyName});
//       // this.rentalsWithId.push({id: property.id, name: property.name + " - " + property.propertyName});
//     }
//   });
// }else{
    properties.forEach(property => {
      if (property.name) {
        this.propertiess.push(property);
        if (property.unitItems) {
          if (property.unitItems.length == 0) {
            // this.rentals.push(property.name + " - " + property.propertyName);
            this.rentalsWithId1.push({id: property.id, name: property.name + " - " + property.propertyName});
            this.rentalsWithId.push({id: property.id, name: property.name + " - " + property.propertyName});
          } else if (property.unitItems.length > 0) {
            property.unitItems.forEach(unitItem => {
              // this.rentals.push(unitItem.unitName + " - " + property.name + " - " + property.propertyName);
              this.rentalsWithId1.push({
                id: property.id,
                name: unitItem.unitName + " - " + property.name + " - " + property.propertyName,
                unitId: unitItem.unitId
              });
              this.rentalsWithId.push({
                id: property.id,
                name: unitItem.unitName + " - " + property.name + " - " + property.propertyName,
                unitId: unitItem.unitId
              });
            });
          }
        } else if (!property.unitItems) {
          // this.rentals.push(property.name + " - " + property.propertyName);
          this.rentalsWithId1.push({id: property.id, name: property.name + " - " + property.propertyName});
          this.rentalsWithId.push({id: property.id, name: property.name + " - " + property.propertyName});
        } else {
          console.log("property.unitItem - in here");
          // this.rentals.push(property.name + " - " + property.propertyName);
          this.rentalsWithId1.push({id: property.id, name: property.name + " - " + property.propertyName});
          this.rentalsWithId.push({id: property.id, name: property.name + " - " + property.propertyName});
        }
      } else {
        console.log("property.units 2 - in here");
        // this.rentals.push(property.name + " - " + property.propertyName);
        this.rentalsWithId1.push({id: property.id, name: property.name + " - " + property.propertyName});
        this.rentalsWithId.push({id: property.id, name: property.name + " - " + property.propertyName});
      }
    });
  // }
    // this.fetchExpensesData()
  }


  getDisplayColumns(){
    this.displayColumns = ['bookId', 'guestName', 'firstNight', 'lastNight', 'guestAddress', 'referer'];
    if(this.columnPermissions.includes('Booking amount')){
      this.displayColumns.push('bookingPrice')
    }
    if(this.columnPermissions.includes('Platform Commission')){
      this.displayColumns.push('commission');
    }
    if(this.columnPermissions.includes('Cleaning Fee')){
      this.displayColumns.push('cleaningFee')
    }
    if(this.columnPermissions.includes('Tax')){
      this.displayColumns.push('tax');
    }
    if(this.columnPermissions.includes('Payment Processing')){
      this.displayColumns.push('paymentProcessingFee');
    }
    if(this.columnPermissions.includes('Management Commission')){
      this.displayColumns.push('propertyManagementCommission');
    }
    if(this.columnPermissions.includes('Net Profit')){
      this.displayColumns.push('netProfit')
    }
  }




  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    this.dataSource.filteredData
    this.bookingDataList = new Array();
    for (let i = 0; i < this.dataSource.filteredData.length; i++) {
      let exportBookingData = {
        RentalName: this.dataSource.filteredData[i].guestPropertyName + ',' + this.dataSource.filteredData[i].guestRoomName + ',' + this.dataSource.filteredData[i].roomId,
        BookingAmount: this.dataSource.filteredData[i].bookingPrice,
        PlatformCommission: this.dataSource.filteredData[i].commission,
        CleaningFees: this.dataSource.filteredData[i].cleaningFees,
        Tax: this.dataSource.filteredData[i].tax,
        PaymentProcessingFee: this.dataSource.filteredData[i].paymentProcessingFee,
        ManagementCommission: this.dataSource.filteredData[i].propertyManagementCommission,
        // Expenses: '0',
        NetProfit: this.dataSource.filteredData[i].netProfit
      };
      this.bookingDataList.push(exportBookingData);
    }
    this.hasFilterSummery = true;
    for (let i = 0; i < this.dataSource.filteredData.length; i++) {
      if (i === 0) {
        this.totalBookingAmount = this.dataSource.filteredData[i].bookingPrice;
        this.totalPlatformCommission = this.dataSource.filteredData[i].commission;
        this.totalCleaningFee = this.dataSource.filteredData[i].cleaningFees;
        this.totalTax = this.dataSource.filteredData[i].tax;
        this.totalPaymentProcessingFee = this.dataSource.filteredData[i].paymentProcessingFee;
        this.totalPropertyMngmtComm = this.dataSource.filteredData[i].propertyManagementCommission;
        // this.totalExpenses = 0;
        this.totalNetProfit = this.dataSource.filteredData[i].netProfit;
      } else {
        this.totalBookingAmount += this.dataSource.filteredData[i].bookingPrice;
        this.totalPlatformCommission += this.dataSource.filteredData[i].commission;
        this.totalCleaningFee += this.dataSource.filteredData[i].cleaningFees;
        this.totalTax += this.dataSource.filteredData[i].tax;
        this.totalPaymentProcessingFee += this.dataSource.filteredData[i].paymentProcessingFee;
        this.totalPropertyMngmtComm += this.dataSource.filteredData[i].propertyManagementCommission;
        this.totalNetProfit += this.dataSource.filteredData[i].netProfit;
      }
    }
  }


  getExcel() {
    let tempBookingDataList = Object.assign([], this.bookingDataList);
    let exportBookingData = {
      RentalName: 'Grand Total',
      BookingAmount: this.totalBookingAmount,
      PlatformCommission: this.totalPlatformCommission,
      CleaningFees: this.totalCleaningFee,
      Tax: this.totalTax,
      PaymentProcessingFee: this.totalPaymentProcessingFee,
      ManagementCommission: this.totalPropertyMngmtComm,
      // Expenses: '0',
      NetProfit: this.totalNetProfit
    };
    tempBookingDataList.push(exportBookingData);
    this.excelService.exportAsExcelFile(tempBookingDataList, 'FilteredRevenueReport');
  }

  getPDF() {
    const doc = new jsPDF();
    doc.text(60, 13, ' Bookings Revenue Report ');

    doc.setFontSize(10);
    this.y = 20;
    this.x = 20;
    // let str = ' dummy text of the printing and typesetting'//  industry Lorem Ipsum has been the industr standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries'
    for (let i = 0; i < this.bookingDataList.length; i++) {
      doc.text(this.x, this.y, '______________________________________________________________________________________');

      const heading =  'Rental Name : ' + this.bookingDataList[i].RentalName  ;
      let strArr = doc.splitTextToSize(heading , 160)
      doc.text(this.x + 3, this.y += 5, strArr);

      doc.text(this.x, this.y += 3, '______________________________________________________________________________________');

      if(this.isOwner){
        if(this.columnPermissions.includes('Booking amount')){
          doc.text(this.x, this.y += 5, 'Booking Price : ');
        doc.text(160, this.y, this.cp.transform(this.bookingDataList[i].BookingAmount, this.currency, 'symbol-narrow')  );
        }
        if(this.columnPermissions.includes('Platform Commission')){
          doc.text(this.x, this.y += 5, 'Platform Commission : ');
        doc.text(160, this.y, this.cp.transform(this.bookingDataList[i].PlatformCommission, this.currency, 'symbol-narrow'));
        }

        if(this.columnPermissions.includes('Cleaning Fee')){
          doc.text(this.x, this.y += 5, 'Cleaning Fees : ');
          doc.text(160, this.y, this.cp.transform(this.bookingDataList[i].CleaningFees, this.currency, 'symbol-narrow'));
        }
        if(this.columnPermissions.includes('Tax')){
          doc.text(this.x, this.y += 5, 'Tax : ');
          doc.text(160, this.y, this.cp.transform(this.bookingDataList[i].Tax, this.currency, 'symbol-narrow'));
        }

        if(this.columnPermissions.includes('Payment Processing')){
          doc.text(this.x, this.y += 5, 'Payment Processing Fee : ');
          doc.text(160, this.y, this.cp.transform(this.bookingDataList[i].PaymentProcessingFee, this.currency, 'symbol-narrow'));
        }
        if(this.columnPermissions.includes('Management Commission')){
          doc.text(this.x, this.y += 5, 'Management Commission : ');
          doc.text(160, this.y, this.cp.transform(this.bookingDataList[i].ManagementCommission, this.currency, 'symbol-narrow'));
        }
        // if(this.columnPermissions.includes('Expenses')){
        //   doc.text(this.x, this.y += 5, 'Expense Cost : ');
        //  doc.text(160, this.y, this.cp.transform(0.0, this.currency, 'symbol-narrow')/*this.bookingDataList[i].TotalMaintenanceCost*/);
        // }

        if(this.columnPermissions.includes('Net Profit')){
          doc.text(this.x, this.y += 5, 'Net Profit : ');
          doc.text(160, this.y, '$' + this.cp.transform(this.bookingDataList[i].NetProfit, this.currency, 'symbol-narrow'));
        }
        if (this.y === 235) {
          this.y = 20;
          doc.addPage();
      }
      }else{
        doc.text(this.x, this.y += 5, 'Booking Price : ');
        doc.text(160, this.y, this.cp.transform(this.bookingDataList[i].BookingAmount, this.currency, 'symbol-narrow'));
        doc.text(this.x, this.y += 5, 'Platform Commission : ');
        doc.text(160, this.y, this.cp.transform(this.bookingDataList[i].PlatformCommission, this.currency, 'symbol-narrow'));
        doc.text(this.x, this.y += 5, 'Cleaning Fees : ');
        doc.text(160, this.y, this.cp.transform(this.bookingDataList[i].CleaningFees, this.currency, 'symbol-narrow'));
        doc.text(this.x, this.y += 5, 'Tax : ');
        doc.text(160, this.y, this.cp.transform(this.bookingDataList[i].Tax, this.currency, 'symbol-narrow'));
        doc.text(this.x, this.y += 5, 'Payment Processing Fee : ');
        doc.text(160, this.y, this.cp.transform(this.bookingDataList[i].PaymentProcessingFee, this.currency, 'symbol-narrow'));
        doc.text(this.x, this.y += 5, 'Management Commission : ');
        doc.text(160, this.y, this.cp.transform(this.bookingDataList[i].ManagementCommission, this.currency, 'symbol-narrow'));
        // doc.text(this.x, this.y += 5, 'Expense Cost : ');
        // doc.text(160, this.y, this.cp.transform(0.0, this.currency, 'symbol-narrow')/*this.bookingDataList[i].TotalMaintenanceCost*/);
        doc.text(this.x, this.y += 5, 'Net Profit : ');
        doc.text(160, this.y, this.cp.transform(this.bookingDataList[i].NetProfit, this.currency, 'symbol-narrow'));
        if (this.y === 235) {
          this.y = 20;
          doc.addPage();
      }

      }
    }

    if(this.isOwner){
      this.y += 6;
      doc.text(this.x, this.y, '~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~GRAND TOTAL~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~');
      if(this.columnPermissions.includes('Booking amount')){

        doc.text(this.x, this.y += 5, 'Total Booking Price :');
        let totalBookingAmountPdf = this.totalBookingAmount != undefined ? this.totalBookingAmount : 0;
        doc.text(160, this.y, this.cp.transform(totalBookingAmountPdf.toFixed(2), this.currency, 'symbol-narrow'));
      }
      if(this.columnPermissions.includes('Platform Commission')){
        doc.text(this.x, this.y += 5, 'Total Platform Commission : ');
        let totalPlatformCommissionPdf = this.totalPlatformCommission != undefined ? this.totalPlatformCommission : 0;
        doc.text(160, this.y, this.cp.transform(totalPlatformCommissionPdf.toFixed(2), this.currency, 'symbol-narrow'));
      }
      if(this.columnPermissions.includes('Cleaning Fee')){
        doc.text(this.x, this.y += 5, 'Total Cleaning Fees : ');
        let totalCleaningFeePdf = this.totalCleaningFee != undefined ? this.totalCleaningFee : 0;
        doc.text(160, this.y, this.cp.transform(totalCleaningFeePdf.toFixed(2), this.currency, 'symbol-narrow'));
      }
      if(this.columnPermissions.includes('Tax')){
        doc.text(this.x, this.y += 5, 'Total Tax : ');
        let totalTaxPdf = this.totalTax != undefined ? this.totalTax : 0;
        doc.text(160, this.y, this.cp.transform(totalTaxPdf.toFixed(2), this.currency, 'symbol-narrow'));
      }
      if(this.columnPermissions.includes('Payment Processing')){
        doc.text(this.x, this.y += 5, 'Total Payment Processing Fee : ');
        let totalPaymentProcessingFeePdf = this.totalPaymentProcessingFee != undefined ? this.totalPaymentProcessingFee : 0;
        doc.text(160, this.y, this.cp.transform(totalPaymentProcessingFeePdf.toFixed(2), this.currency, 'symbol-narrow'));
      }
      if(this.columnPermissions.includes('Management Commission')){
        doc.text(this.x, this.y += 5, 'Total Management Commission : ');
        let totalPropertyMngmtCommPdf = this.totalPropertyMngmtComm != undefined ? this.totalPropertyMngmtComm : 0;
        doc.text(160, this.y, this.cp.transform(totalPropertyMngmtCommPdf.toFixed(2), this.currency, 'symbol-narrow'));
      }
      // if(this.columnPermissions.includes('Expense Costs')){

      // doc.text(this.x, this.y += 5, 'Total Expense Cost : ');
      // doc.text(160, this.y, this.cp.transform(0.0, this.currency, 'symbol-narrow'));/*this.revenueReportData[i].TotalMaintenanceCost*/
      // }
      if(this.columnPermissions.includes('Net Profit')){
        doc.text(this.x, this.y += 5, 'Total Net Profit : ');
        let totalNetProfitPdf = this.totalNetProfit != undefined ? this.totalNetProfit : 0;
        doc.text(160, this.y, this.cp.transform(totalNetProfitPdf.toFixed(2), this.currency, 'symbol-narrow'));
      }

      doc.text(this.x, this.y += 5, '~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~');
    // Save the PDF
    doc.save('BookingsRevenueReport.pdf');
    }else{
      this.y += 6;
    doc.text(this.x, this.y, '~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~GRAND TOTAL~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~');

    doc.text(this.x, this.y += 5, 'Total Booking Price :');
    let totalBookingAmountPdf = this.totalBookingAmount != undefined ? this.totalBookingAmount : 0;
    doc.text(160, this.y, this.cp.transform(totalBookingAmountPdf.toFixed(2), this.currency, 'symbol-narrow'));

    doc.text(this.x, this.y += 5, 'Total Platform Commission : ');
    let totalPlatformCommissionPdf = this.totalPlatformCommission != undefined ? this.totalPlatformCommission : 0;
    doc.text(160, this.y, this.cp.transform(totalPlatformCommissionPdf.toFixed(2), this.currency, 'symbol-narrow'));

    doc.text(this.x, this.y += 5, 'Total Cleaning Fees : ');
    let totalCleaningFeePdf = this.totalCleaningFee != undefined ? this.totalCleaningFee : 0;
    doc.text(160, this.y, this.cp.transform(totalCleaningFeePdf.toFixed(2), this.currency, 'symbol-narrow'));

    doc.text(this.x, this.y += 5, 'Total Tax : ');
    let totalTaxPdf = this.totalTax != undefined ? this.totalTax : 0;
    doc.text(160, this.y, this.cp.transform(totalTaxPdf.toFixed(2), this.currency, 'symbol-narrow'));

    doc.text(this.x, this.y += 5, 'Total Payment Processing Fee : ');
    let totalPaymentProcessingFeePdf = this.totalPaymentProcessingFee != undefined ? this.totalPaymentProcessingFee : 0;
    doc.text(160, this.y, this.cp.transform(totalPaymentProcessingFeePdf.toFixed(2), this.currency, 'symbol-narrow'));

    doc.text(this.x, this.y += 5, 'Total Management Commission : ');
    let totalPropertyMngmtCommPdf = this.totalPropertyMngmtComm != undefined ? this.totalPropertyMngmtComm : 0;
    doc.text(160, this.y, this.cp.transform(totalPropertyMngmtCommPdf.toFixed(2), this.currency, 'symbol-narrow'));

    // doc.text(this.x, this.y += 5, 'Total Expense Cost : ');
    // doc.text(160, this.y, this.cp.transform(0.0, this.currency, 'symbol-narrow'))/*this.revenueReportData[i].TotalMaintenanceCost*/

    doc.text(this.x, this.y += 5, 'Total Net Profit : ');
    let totalNetProfitPdf = this.totalNetProfit != undefined ? this.totalNetProfit : 0;
    doc.text(160, this.y, this.cp.transform(totalNetProfitPdf.toFixed(2), this.currency, 'symbol-narrow'));
    doc.text(this.x, this.y += 5, '~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~');
    // Save the PDF
    doc.save('BookingsRevenueReport.pdf');
    }
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  hiddenElements: { [key: string]: boolean } = {};

  showMoreToggle(event: Event,action:string) {
    const toggleButton = event.target as HTMLElement;
    let infoContainer = toggleButton.nextElementSibling as HTMLElement;
    if(action == "hide"){
       infoContainer = toggleButton.previousElementSibling as HTMLElement;
       this.renderer.addClass(infoContainer, 'hidden');
       this.renderer.removeClass(infoContainer.previousElementSibling, 'hidden'); 
              
    }else{
       infoContainer = toggleButton.nextElementSibling as HTMLElement;
       this.renderer.removeClass(infoContainer, 'hidden');  
       this.renderer.removeClass(infoContainer.nextElementSibling, 'hidden');     
    } 
    this.renderer.addClass(toggleButton, 'hidden'); 

    
}



  toggle(selected: any, selectedRow: boolean, row: any, hasValue: boolean) {

    if (hasValue) {
      this.bookingIds.push(row.id);
    } else {
      this.bookingIds = [];
    }
    if (selectedRow === false) {
      for (let i = 0; i < this.bookingIds.length; i++) {
        if (row.id === this.bookingIds[i]) {
          this.bookingIds.splice(i, 1);
        }
      }
    }
  }


  masterToggle() {
    this.bookingIds = new Array();
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => {
        this.bookingIds.push(row.id);
        this.selection.select(row);
      });
  }

  deleteBookings(): void {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '500px',
      height: '300px',
      data: "Do you confirm the deletion of this Booking(s)?"
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        console.log(this.bookingIds)
    if (this.bookingIds === null || this.bookingIds.length === 0) {
      this.toastrService.info('Please select Atleast one Job', info, {
        timeOut: 3000,
        onActivateTick: true
      });
    } else {
      this.isDeleting = true;
      this.eventService.deleteBookings(this.bookingIds)
        .subscribe(
          (response: any) => {
            console.log(response);
            if (response.code === 200) {
              this.toastrService.success('Deleted Bookings Successfully', success, {
                timeOut: 3000,
                onActivateTick: true
              });
              this.bookingsInTableView();
              this.bookingIds = [];
              this.selection.clear();
              this.isDeleting = false;
            }
          }
        )
    }
      }
    });
  }

  reImportBookings() {
    const dialogRef = this.dialog.open(ImportBookingComponent, {
      width: '35%',
      height: '35%',
      data: {
        currentPropertyId: null
      }
    });
    dialogRef.afterClosed().subscribe((result: string) => {
      console.log('closed');
      if(result === 'SUCCESS') {
        this.bookingsInTableView();
      }
      //this.booking
    });
  }

}
