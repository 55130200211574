import { Injectable } from '@angular/core';
import {GenericDto} from '../models/generic-dto';
import {BehaviorSubject, Observable, throwError} from 'rxjs/index';
import { HttpService } from '../core/services/http.service';
import { LoaderService } from '../shared/services/loader.service';
import {catchError, tap} from 'rxjs/internal/operators';
import {environment} from '../../environments/environment';
import {HttpErrorResponse} from '@angular/common/http';
import {
  getUser, getStatements,getStatementsDetails, sendStatementNotification,getAttachments, getAllStatements
} from '../constants/statement-api.constatnts';

@Injectable({
  providedIn: 'root'
})
export class StatementService {

  constructor(private httpService: HttpService, private loaderService: LoaderService) {
  }

  getUser(): Observable<GenericDto<any>> {
    return this.httpService.getAuth<GenericDto<any>>(environment.API + getUser);
  }

  getStatementsByOwnerId(ownerId): Observable<GenericDto<any>> {
    this.loaderService.startLoader();
    return this.httpService.getAuth<GenericDto<any>>(environment.API + getStatements+"/"+ownerId)
    .pipe(tap(() => {
      this.loaderService.stopLoader(); // Stop loader when response received
    }), catchError((error: HttpErrorResponse) => {
      this.loaderService.stopLoader(); // stop loader if any error occurred
      return throwError(error);
    }));
  }
  getAllStatements(ownerIds): Observable<GenericDto<any>> {
    this.loaderService.startLoader();
    return this.httpService.postAuth<GenericDto<any>>(environment.API + getAllStatements,ownerIds)
    .pipe(tap(() => {
      this.loaderService.stopLoader(); // Stop loader when response received
    }), catchError((error: HttpErrorResponse) => {
      this.loaderService.stopLoader(); // stop loader if any error occurred
      return throwError(error);
    }));
  }

  getStatementsDetails(statementId): Observable<GenericDto<any>> {
    this.loaderService.startLoader();
    return this.httpService.getAuth<GenericDto<any>>(environment.API + getStatementsDetails+"/"+statementId)
    .pipe(tap(() => {
      this.loaderService.stopLoader(); // Stop loader when response received
    }), catchError((error: HttpErrorResponse) => {
      this.loaderService.stopLoader(); // stop loader if any error occurred
      return throwError(error);
    }));
  }

  sendNotification(satementId): Observable<GenericDto<any>> {
    return this.httpService.getAuth<GenericDto<any>>(environment.API + sendStatementNotification+"/"+satementId);
  }
  
  public getAttachments(statementId: string, invoiceAddress: String): Observable<any> {
    return this.httpService.postAsset(environment.API + getAttachments+ '?statementId=' + statementId,  invoiceAddress);
  }

  public createStatement(statement: any): Observable<any> {
    return this.httpService.postAuth<GenericDto<any>>(environment.API + getStatements+"/",  statement);
  }

  public updateStatement(statement: any): Observable<any> {
    return this.httpService.putAuth<GenericDto<any>>(environment.API + getStatements+"/",  statement);
  }

   

  
}
