import { Component, OnInit, Inject } from '@angular/core';
import { JobInstructionComponent } from '../job-instruction/job-instruction.component';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup, FormControl } from '@angular/forms';
import { JobAssign } from 'src/app/models/job-assign';
import { PropertyService } from 'src/app/services/property.service';
import { GenericDto } from 'src/app/models/generic-dto';
import { HttpErrorResponse } from '@angular/common/http';


export interface JobCommentDialog {
  jobAssign: JobAssign, isCleaner: boolean
}

@Component({
  selector: 'app-job-comments',
  templateUrl: './job-comments.component.html',
  styleUrls: ['./job-comments.component.scss']
})
export class JobCommentsComponent implements OnInit {

  jobAssign: JobAssign;
  commentForm: FormGroup;
  isCleaner = false;
  jobComment: JobCommentDialog;
  hasComments = false;
  constructor(private dialog: MatDialogRef<JobCommentsComponent>,
     @Inject (MAT_DIALOG_DATA) public data: any,
     private propertyService: PropertyService ) {
    console.log(data)
    this.jobComment= data as JobCommentDialog;
    this.jobAssign = this.jobComment.jobAssign;
    this.isCleaner = this.jobComment.isCleaner;
   }

  ngOnInit() {
    if(this.isCleaner){
      this.createForm();
    } else {
      this.hasComments = this.jobAssign.comments ? true : false;      
    }
    
  }

  createForm() {
    if(this.jobAssign.comments){
      console.log('some value')
      this.hasComments = true;
      this.commentForm = new FormGroup({
        'comments': new FormControl(this.jobAssign.comments,)
      })
    } else {
      console.log('no value')
      this.hasComments = false
      this.commentForm = new FormGroup({
        'comments': new FormControl('',)
      })
    }
    
  }

  onSubmit() {
    console.log(this.commentForm.value);
    console.log(this.jobAssign.id);
    const userId =  this.jobAssign.userId;
    const propertyId =  this.jobAssign.propertyId;
    const roomId = this.jobAssign.roomId;
    this.jobAssign.comments = this.commentForm.value.comments;
    this.jobAssign.propertyId = this.jobAssign.propertyObjectId;
    this.jobAssign.userId = this.jobAssign.userObjectId
    this.jobAssign.roomId = this.jobAssign.roomObjectId;
    this.propertyService.updateJobDetail(this.jobAssign)
      .subscribe(
        (response: GenericDto<any>) => {
          console.log(response)
          this.jobAssign.propertyId = propertyId;
          this.jobAssign.userId = userId;
          this.jobAssign.roomId = roomId;
          this.dialog.close();
        },
        (error: HttpErrorResponse) => {
          this.jobAssign.propertyId = propertyId;
          this.jobAssign.userId = userId;
          this.jobAssign.roomId = roomId;
        }
      );
  }

}
