import {NgModule} from '@angular/core';
import {SharedModule} from '../../../shared/shared.module';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import { FormsModule } from '@angular/forms';

import {CleanerDetailsComponent} from './cleaner-details.component';
import { CreateStatementComponent } from '../create-statement/create-statement.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatNativeDateModule } from '@angular/material/core';
 

@NgModule ({
  imports: [SharedModule,FormsModule, CommonModule, RouterModule,MatDatepickerModule,MatInputModule,MatNativeDateModule],
  declarations: [CleanerDetailsComponent,CreateStatementComponent],
  exports: [CleanerDetailsComponent,CreateStatementComponent],
  entryComponents: [CreateStatementComponent]
})

export class CleanerDetailsModule {}
