import { NgModule } from '@angular/core';
import { DashboardHomeComponent } from './home/dashboard-home.component';
import { RouterModule } from '@angular/router';
import {PropertyDashboardComponent} from './property/property.component';
import {AuthGuard} from '../core/guards/auth.guard';
import {DashboardComponent} from './dashboard.component';
import {PropertyFormComponent} from './property/property-form/property-form.component';
import {PropertyDetailsComponent} from './property/property-details/property-details.component';
import {GalleryComponent} from './property/gallery/gallery.component';
import {PluginsComponent} from './property/plugins/plugins.component';
import {DashboardCalendarComponent} from './dashboard-calendar/dashboard-calendar.component';
import {CleanerComponent} from './owner/cleaner.component';
import {AddCleanerComponent} from './owner/add-owner-form/add-cleaner.component';
import {CleanerDetailsComponent} from './owner/owner-details/cleaner-details.component';
import {ProfileComponent} from './profile/profile.component';
import {ProfileDetailsComponent} from './profile/profile-details/profile-details.component';
import {UpdateProfileComponent} from './profile/update-profile/update-profile.component';
import {JobAssignComponent} from './job/job-assign/job-assign.component';
import {OwnerDetailsComponent} from "./owner/owner/owner-details/owner-details.component";
import {OwnerComponent} from "./owner/owner/owner.component";
import {BookingsTableComponent} from './bookings-table/bookings-table.component';
import {RevenueReportComponent} from './revenue-report/revenue-report.component';
import { PayoutReportComponent } from './payout-report/payout-report.component';
import { BillingCheckoutComponent } from './billing/billing-checkout/billing-checkout.component';

import { BillingCheckoutSuccessComponent } from './billing/billing-checkout-success/billing-checkout-success.component';
import { BillingCheckoutFailureComponent } from './billing/billing-checkout-failure/billing-checkout-failure.component';
import { StaffComponent } from './staff/staff.component';
import { StaffDetailsComponent } from './staff/staff-details/staff-details.component';
import { StatementComponent } from './statement/statement.component';
import { StatementDetailsComponent } from './statement-details/statement-details.component';
import { GeneralRequestsComponent } from './general-requests/general-requests.component';
import { PropertyInformationComponent } from './property/property-information/property-information.component';


@NgModule({
  imports: [
    RouterModule.forChild([

      {
        path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard],
        children: [
          {
            path: '', component: DashboardHomeComponent
          },
          {
            path: 'plugins', component: PluginsComponent
          },
          {
            path: 'statement', component: StatementComponent,
          },
          {
            path: 'request/inbox', component: GeneralRequestsComponent,
          },
          {
            path: 'statement/:id', component: StatementDetailsComponent,
          },
          {
            path: 'property', component: PropertyDashboardComponent,
            children: [
              {
                path: 'new', component: PropertyFormComponent
              },
              {
                path: 'edit/:id', component: PropertyFormComponent
              },
              {
                path: 'info/:id', component: PropertyInformationComponent
              },
              {
                path: 'edit/:id/:id2', component: PropertyFormComponent
              },{
                path: 'edit/:id/:id2/:id3/:id4', component: PropertyFormComponent
              },
              {
                path: 'gallery', component: GalleryComponent
              },
              // {
              //   path: 'plugins', component: PluginsComponent
              // },
              {
                path: ':id', component: PropertyDetailsComponent
              },{
                path: ':id/:id2', component: PropertyDetailsComponent
              },{
                path: ':id/:id2/:id3/:id4', component: PropertyDetailsComponent
              }
            ]
          },
          {
            path: 'calendar', component: DashboardCalendarComponent
          },
          {
            path: 'bookings-table', component: BookingsTableComponent
          },
          {
            path: 'billing', component: BillingCheckoutComponent
          },
          {
            path: 'staff', component: StaffComponent,
            children: [
              {
                path: 'invite', component: StaffDetailsComponent
              },
              {
                path: 'edit/:id', component: StaffDetailsComponent
              },
            ]
          },
          {
            path: 'success', component: BillingCheckoutSuccessComponent
          },
          {
            path:'success/:session_id', component: BillingCheckoutSuccessComponent
          },
          {
            path: 'failure', component: BillingCheckoutFailureComponent
          },
          {
            path: 'revenue-report', component: RevenueReportComponent,
          },
         
          {
            path: 'users', component: CleanerComponent,
            children: [
              {
                path: 'invite', component: AddCleanerComponent
              },
              {
                path: 'edit/:id', component: AddCleanerComponent
              },
              {
                path: ':id', component: CleanerDetailsComponent
              }
            ]
          },
          {
            path: 'profile', component: ProfileComponent,
            children: [
              {
                path: '', component: ProfileDetailsComponent
              },
              {
                path: 'edit', component: UpdateProfileComponent
              },
            ]
          },
          {
            path: 'jobAssign', component: JobAssignComponent,
          },
          {
            path: 'ownerDetails/:id', component: OwnerDetailsComponent ,
          },
          {
            path: 'ownerPayouts', component: PayoutReportComponent
          }

        ]
      }
    ])
  ],
  exports: [RouterModule]
})
export class DashboardRoutingModule {

}
