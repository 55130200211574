import {Component, OnInit, Renderer2} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../services/auth.service';
import {ToastrService} from 'ngx-toastr';
import {Router} from '@angular/router';
import {LoaderService} from '../shared/services/loader.service';
import {GenericDto} from '../models/generic-dto';
import {errorMessage, success, successActivated} from '../constants/notification-messages.constant';
import {DataService} from '../shared/services/data.service';
import {Timer} from '../utils/timer';
import {HttpErrorResponse} from '@angular/common/http';
import {OtpDto} from '../models/otp-dto';
import {restrictText} from '../utils/restrictText';
import {TokenService} from '../core/services/token.service';
import {StorageService} from '../core/services/storage.service';
import {UserService} from '../services/user.service';
import {currentUserKey} from "../constants/storage.constants";

@Component({
  selector : 'app-verify-account',
  templateUrl : './verify-account.component.html',
  styleUrls : ['./verify-account.component.scss']
})
export class VerifyAccountComponent implements OnInit {

  isLoading: boolean;
  isLoadingResend: boolean;
  email: string;
  verifyOtpForm: FormGroup;
  timer: Timer;

  constructor(private fb: FormBuilder, private authService: AuthService, private dataService: DataService,
              private toastrService: ToastrService, private router: Router, private renderer: Renderer2,
              private tokenService: TokenService, private storageService: StorageService, private loaderService: LoaderService,
              private userService: UserService) {
  }

  ngOnInit() {
    this.timer = new Timer();
    this.timer.startTimer();
    this.createVerifyOtpForm();
    this.renderer.addClass(document.body, 'login-bg');
  }

  private createVerifyOtpForm() {
    this.verifyOtpForm = this.fb.group({
      otp: new FormControl(null, [Validators.required, Validators.maxLength(4)])
    });
  }

  restrictText(event: any) {
    restrictText(event);
  }

  verifyOtp() {
    if (this.verifyOtpForm.valid) {
      this.isLoading = true;
      this.authService.activateAccount(this.verifyOtpForm.getRawValue() as OtpDto)
        .subscribe((response: GenericDto<string>) => {
          if (response.code === 200) {
            // 1. show confirm password component
            this.loaderService.startLoader();

            this.toastrService.success(successActivated, success, {
              timeOut: 3000,
               onActivateTick: true
            });
            const isRememberMeChecked = this.dataService.user['isRememberMeChecked'] || false;
            this.storageService.configureStorage(isRememberMeChecked);
            this.tokenService.saveToken(response.payload);
            this.userService.getUser().subscribe((result: GenericDto<any>) => {
              if (result.code === 200) {
                localStorage.setItem('accountId',result.payload.accountId);

                localStorage.setItem('userId',result.payload.id);
                localStorage.setItem('userName',result.payload.firstName);
                localStorage.setItem(currentUserKey, result.payload.role);
                localStorage.setItem('userLinkId', result.payload.userLinkId);

                this.dataService.cleaner = result.payload;
                this.tokenService.setPreviewLogin(false);
                this.tokenService.saveCurrentUser(result.payload.role);

                // this.dataService.user = null;
                this.loaderService.stopLoader();

                this.userService.redirectUser()
                .subscribe(
                  (response: GenericDto<boolean>) => {
                    if(response.payload === false) {
                      this.router.navigate(['/dashboard/plugins/']);
                    }else{
                      this.router.navigate(['/ownerDashboard']);/*/dashboard*/
                    }
                  }
                )
              }
            });

            // this.dataService.user = null;
            // this.loaderService.stopLoader();
            // this.router.navigate(['/dashboard']);

          } else {
            // show error
            this.verifyOtpForm.reset(true);
          }
          this.isLoading = false;
        }, (error: HttpErrorResponse) => {
          // disable isLoading
          // show error
          this.verifyOtpForm.reset(true);
          this.isLoading = false;
        });
    }
  }

  resendOtp(): void {

    // enable loading
    this.isLoadingResend = true;
    // reset otp form
    this.verifyOtpForm.reset(true);

    // reset timer interval
    this.timer.resetTimer();
    const email = this.dataService.user || null;
    this.authService.generateOTP(email)
      .subscribe((response: GenericDto<string>) => {
          if (response.code === 200) {
            // show success notification
            this.toastrService.success(response.payload, success, {
              timeOut: 3000,
              onActivateTick: true
            });

            // start timer
            this.timer.startTimer();
          } else {

            // show error message
            this.toastrService.error(response.payload, errorMessage, {
              timeOut: 4000,
              onActivateTick: true
            });
          }

          // disable loading
          this.isLoadingResend = false;
        },
        (error: HttpErrorResponse) => {
          this.isLoadingResend = false;
        }
      );
  }


}
