import {NgModule} from '@angular/core';
import {SharedModule} from '../../../shared/shared.module';
import {GalleryComponent} from './gallery.component';

@NgModule({
  declarations : [GalleryComponent],
  imports : [SharedModule],
  exports : [GalleryComponent],
  providers : []
})
export class GalleryModule {

}
