import {Injectable} from '@angular/core';
import {Observable, throwError} from 'rxjs/index';
import {HttpService} from '../../core/services/http.service';
import {environment} from '../../../environments/environment';
import {
  getBookingAllBy,
  getPropertyRevenueReport,
  totalBookings,
  deleteBookings,
  getRevenueReport,
  getCalendarBookings,
  reCalculateBooking
} from '../../constants/booking-api.constants';
import {LoaderService} from './loader.service';
import {catchError, tap} from 'rxjs/internal/operators';
import {HttpErrorResponse} from '@angular/common/http';
import {Booking} from "../../models/booking";
import {totalProperties} from '../../constants/property-api.constants';
@Injectable()
export class EventService {


  constructor (private httpService: HttpService, private loaderService: LoaderService) {
  }

  public getEvents(startDate: any, endDate: any, roomId: any, propertyId: any, selectedJobRequest: string): Observable<any> {
    this.loaderService.startLoader();
    return this.httpService.getAuth<Booking>(environment.API + getBookingAllBy + '?propertyId=' + propertyId + '&roomId=' + roomId + '&startDate=' + startDate + '&endDate=' + endDate + '&jobRequest=' + selectedJobRequest)
    .pipe(tap(() => {
      this.loaderService.stopLoader(); // Stop loader when response received
    }), catchError((error: HttpErrorResponse) => {
      this.loaderService.stopLoader(); // stop loader if any error occurred
      return throwError(error);
    }));
  }

  public getEvent(date:any, ownerId:any , selectedJobRequest: string, payload:any): Observable<any> {
    this.loaderService.startLoader();
    return this.httpService.postAuth<Booking>(environment.API + getCalendarBookings + '?startMonth=' + date +  '&ownerId='+ ownerId +'&jobRequest=' + selectedJobRequest,payload)
    .pipe(tap(() => {
      this.loaderService.stopLoader(); // Stop loader when response received
    }), catchError((error: HttpErrorResponse) => {
      this.loaderService.stopLoader(); // stop loader if any error occurred
      return throwError(error);
    }));
  }


  public getPropertyRevenueReport(startDate: any, endDate: any, roomId: any, propertyId: any, selectedJobRequest: string, month: any): Observable<any> {
    this.loaderService.startLoader();
    return this.httpService.getAuth<Booking>(environment.API + getPropertyRevenueReport + '?propertyId=' + propertyId + '&roomId=' + roomId + '&startDate=' + startDate + '&endDate=' + endDate + '&jobRequest=' + selectedJobRequest + '&month=' + month)
      .pipe(tap(() => {
        this.loaderService.stopLoader(); // Stop loader when response received
      }), catchError((error: HttpErrorResponse) => {
        this.loaderService.stopLoader(); // stop loader if any error occurred
        return throwError(error);
      }));
  }

  public getTotalBooking() {
    return this.httpService.getAuth(environment.API + totalBookings);
  }
  public getTotalProperties() {
    return this.httpService.getAuth(environment.API + totalProperties);
  }

  public deleteBookings(ids: String[]): Observable<any> {
    return this.httpService.postAuth(environment.API + deleteBookings, ids);
  }
  public getNewPropertyRevenueReport(startDate: any, endDate: any,  ownerId: string, rentalWithId: any, reportType: any): Observable<any> {
    this.loaderService.startLoader();
 //   return this.httpService.postAuth<Booking>(environment.API + getNewPropertyRevenueReport + '?startDate=' + startDate + '&endDate=' + endDate + '&ownerId=' + ownerId + '&reportType=' + reportType, rentalWithId)
    return this.httpService.postAuth<Booking>(environment.API + getRevenueReport + '?startDate=' + startDate + '&endDate=' + endDate + '&ownerId=' + ownerId + '&reportType=' + reportType, rentalWithId)

    .pipe(tap(() => {
      this.loaderService.stopLoader(); // Stop loader when response received
    }), catchError((error: HttpErrorResponse) => {
      this.loaderService.stopLoader(); // stop loader if any error occurred
      return throwError(error);
    }));
  }


  public reCalculateBooking(): Observable<any> {
    return this.httpService.getAuth(environment.API + reCalculateBooking);
  }

}
