/**
 * Define statement api endpoints here
 */
export const getUser = '/user/getUserInfo';

export const getStatements = '/statement';
export const getStatementsDetails = '/statement/getStatementDetails';
export const sendStatementNotification = "/statement/sendNotification"
export const getAttachments  = '/statement/getAttachments';
export const getAllStatements  = '/statement/findStatementsByOwnerIds';

/**
 * Define genral-request api endpoints here
 */
 
 
export const generalRequests = '/generalRequests/';
export const getRequests = generalRequests+"generalRequestsByOwnerIdAndAdminId/"
export const markAsRead = generalRequests+"markAsRead/"
export const hasNewMessage = generalRequests+"hasNewMessage/"
export const reply = '/generalRequests/reply';
export const getConversation = '/generalRequests/getConversation/';






 
 


