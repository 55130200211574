import {Injectable} from '@angular/core';
import {Observable, Subscriber} from 'rxjs/index';
import {PMSPlugin} from '../models/pms-plugin';
import {HttpService} from '../core/services/http.service';
import {environment} from '../../environments/environment';
import {
  deletePlugin, getPluginsList, getSavedPluginsList, importDataApi,importAllDataApi,
  savePlugin, updatePlugin, syncProcess, startPropertySync, startBookingSync, singlePropertySync, cancelSync
} from '../constants/plugins-api.constants';
import {GenericDto} from '../models/generic-dto';
import {PluginDto} from '../models/plugin-dto';
import {ToastrService} from 'ngx-toastr';
import {importMessage, info,cancelSyn} from '../constants/notification-messages.constant';

@Injectable()
export class PluginsService {
  mocPluginDto: PluginDto[];
  constructor(private httpService: HttpService, private toastrService: ToastrService) {

  }
  /**
   *  get plugins list
   */
  getPluginsList(): Observable<GenericDto<PMSPlugin[]>> {
    return this.httpService
      .getAuth<GenericDto<PMSPlugin[]>>(environment.API + getPluginsList);
  }

  /**
   *  save plugin data
   */
  save(plugin: PluginDto): Observable<GenericDto<any>> {
    return this.httpService.postAuth<GenericDto<PluginDto>>(environment.API + savePlugin, plugin);
  }

  /**
   *  Edit saved plugin data
   */
  edit(plugin: PluginDto): Observable<GenericDto<any>> {
    return this.httpService.putAuth<GenericDto<PluginDto>>( environment.API + updatePlugin, plugin);
  }

  /**
   * get all saved plugins
   */
  getSavedPlugins(): Observable<GenericDto<PluginDto[]>> {

    return this.httpService.getAuth<GenericDto<PluginDto[]>>(environment.API + getSavedPluginsList);
  }

  /**
   * 
   */
  startPropertySync(data: any): Observable<GenericDto<any>> {
    this.toastrService.info(importMessage , info , {
      timeOut: 3000,
      onActivateTick: true
    });    
    return this.httpService.postAuth<GenericDto<any>>(environment.API + startPropertySync + data.propertyApiKeyId,null);
    
  }

  startBookingSync(data: any): Observable<GenericDto<any>> {
    this.toastrService.info(importMessage , info , {
      timeOut: 3000,
      onActivateTick: true
    });    
    return this.httpService.postAuth<GenericDto<any>>(environment.API + startBookingSync+data.propertyApiKeyId ,null);
    
  }

  startSinglePropertySync(data: any): Observable<GenericDto<any>> {
    this.toastrService.info(importMessage , info , {
      timeOut: 3000,
      onActivateTick: true
    });    
    return this.httpService.postAuth<GenericDto<any>>(environment.API + singlePropertySync,data);
    
  }

  cancelSync(accountId: any): Observable<GenericDto<any>> {
    this.toastrService.info(cancelSyn , info , {
      timeOut: 3000,
      onActivateTick: true
    });    
    return this.httpService.postAuth<GenericDto<any>>(environment.API +cancelSync+accountId ,null);
    
  }

  /**
   * import data from plugin
   */
  importData(plugin: PluginDto): Observable<GenericDto<any>> {
    this.toastrService.info(importMessage , info , {
      timeOut: 3000,
      onActivateTick: true
    });
    return this.httpService.postAuth<GenericDto<any>>(environment.API + importDataApi + '/' + plugin.id, null);
  }

  importAllData(plugin: PluginDto): Observable<GenericDto<any>> {
    this.toastrService.info(importMessage , info , {
      timeOut: 3000,
      onActivateTick: true
    });
    return this.httpService.postAuth<GenericDto<any>>(environment.API + importAllDataApi + '/' + plugin.id, null);
  }

  /**
   * delete saved plugin
   */
  deletePlugin(plugin: PluginDto): Observable<GenericDto<any>> {
    return this.httpService.deleteAuth<GenericDto<any>>(environment.API + deletePlugin + '/' + plugin.id);
  }

  loadMocPlugin() {
    const mocPlugin: PluginDto = new Object() as PluginDto;
    mocPlugin.pluginId = 'w2342oasidfasdfadf';
    mocPlugin.propertyType = ['Properties', 'Bookings'];
    mocPlugin.apiKey = 'asdfasdfasdfad23423423';
    mocPlugin.status = 'Imported';

    this.mocPluginDto = new Array<PluginDto>(2).fill(mocPlugin);

  }

  getSynchronousTrackRecord(): Observable<GenericDto<PluginDto[]>> {    
    return this.httpService.getAuth<GenericDto<PluginDto[]>>(environment.API +syncProcess);  
}
}
