import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material';
import {PropertyService} from '../../../services/property.service';
import { JobInstructions } from '../job-assign/job-instruction.model';

@Component({
  selector: 'app-job-instruction',
  templateUrl: './job-instruction.component.html',
  styleUrls: ['./job-instruction.component.scss'],
})
export class JobInstructionComponent implements OnInit {
  instruction: JobInstructions;

  constructor(private dialog: MatDialogRef<JobInstructionComponent>, @Inject (MAT_DIALOG_DATA) public data: {message: any }) {
    this.instruction = data.message as JobInstructions;
  }

  ngOnInit() {
  }
  hide() {
    this.dialog.close(JobInstructionComponent);
  }
}
