import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {Property} from '../../../../models/property';
import {PluginDto} from '../../../../models/plugin-dto';
import {PropertyService} from '../../../../services/property.service';
import {HttpErrorResponse} from '@angular/common/http';
import {DataService} from '../../../../shared/services/data.service';

@Component({
  selector: 'app-import-properties',
  templateUrl: './import-properties.component.html',
  styleUrls: ['./import-properties.component.scss']
})
export class ImportPropertiesComponent implements OnInit {
  @Output() reloadPlugins = new EventEmitter<PluginDto>();
  isLoading: boolean;
  isImportSuccess: boolean;
  isImportFailed: boolean;
  failureDesc: any;
  propertyData: any;
  property: string;
  errorStatus: boolean;
  errorResponse: string;

  constructor(private dataService: DataService, private propertyService: PropertyService,
              public dialogRef: MatDialogRef<ImportPropertiesComponent>,
              @Inject(MAT_DIALOG_DATA) public data: {plugin: PluginDto, properties: Array<Property>, errorResponse: string }) {
              this.propertyData = data.properties;
              console.log("Property Data ==> ",data);
              console.log("PROPERTIES", this.property);
              console.log("ERROR RESPONSE", this.errorResponse)

              if (data.errorResponse === 'Usage limit exceeded in last 5 minutes' || data.errorResponse === 'API Key is not valid' || data.errorResponse === 'Api Key is Unauthorized' || data.errorResponse == 'Property key must be at least 16 characters' || data.errorResponse == 'Property key is Unauthorized' || data.errorResponse == 'No access to property with this propKey') {

                this.errorResponse = data.errorResponse;
                this.errorStatus = false;
              } else if(data.errorResponse === 'Property Imported'){
                this.errorStatus = true;
                this.errorResponse = data.errorResponse;
              }

  }


  ngOnInit() {

  }

  hide() {
    this.dialogRef.close();
  }

  proceed(properties: Property) {
    this.isLoading = true;
    this.isImportSuccess = false;
    this.isImportFailed = false;
    this.propertyService.importProperty(this.data.plugin.id, properties).subscribe( (response) => {
        if (response.code === 200) {
          this.isLoading = false;
          this.isImportSuccess = true;
        } else {
          this.isLoading = false;
          this.isImportFailed = true;
          this.failureDesc = response.desc;
          this.propertyData = response.payload;
        }
    }, (error: HttpErrorResponse) => {
      // show error here
    });
  }

}
