
import {Component, ViewChild, OnInit, Inject, Input, ElementRef, Renderer2,HostListener } from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {HttpErrorResponse} from '@angular/common/http';
import {
  MAT_DIALOG_DATA,
  MatTableDataSource,
  MatDatepickerInputEvent,
  MatSelectChange,
  MatDatepicker,
  MatDialog,
  MatDialogRef,
  MatPaginator,
  MatSort,
} from '@angular/material';
import {EventService} from '../../services/event.service';
import {JobDetailsModalComponent} from '../job-details-modal/job-details-modal.component';
import {Property} from '../../../models/property';
import {PropertyService} from '../../../services/property.service';
import {GenericDto} from '../../../models/generic-dto';
import {RoomType} from '../../../models/RoomType';
import {ToastrService} from 'ngx-toastr';
import * as $ from 'jquery';
import * as moment from 'moment';
import 'fullcalendar';
import '../../../fullcalendar-scheduler';
import {TokenService} from '../../../core/services/token.service';
import {Booking} from '../../../models/booking';
import {OwnerService} from '../../../services/owner.service';
import {DataService} from '../../services/data.service';
import {UserService} from '../../../services/user.service';
import {OwnerBookingDialogComponent} from '../../../owner-booking-dialog/owner-booking-dialog.component';
import {OwnerReservationComponent} from '../../../dashboard/owner/owner/owner-reservation/owner-reservation.component';
import {User} from '../../../models/user';
import { ExpenseComponent } from 'src/app/dashboard/property/expense/expense.component';
import {AuthGuard} from "../../../core/guards/auth.guard";
import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';

import * as _moment from 'moment';
// tslint:disable-next-line:no-duplicate-imports
import {default as _rollupMoment, Moment} from 'moment';
import { TourService } from 'src/app/services/tour.service';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';

export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

export const MY_FORMATS_SMALL_SCREEN = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

export function isMobileScreen(): boolean {
  return window.innerWidth <= 960;
}



@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
  providers: [PropertyService, {
    provide: DateAdapter,
    useClass: MomentDateAdapter,
    deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
  },

  {provide: MAT_DATE_FORMATS, useValue:isMobileScreen()?MY_FORMATS_SMALL_SCREEN: MY_FORMATS},
]
})
export class CalendarComponent implements OnInit {
  containerEl: JQuery;
  @ViewChild(CalendarComponent) ucCalendar: CalendarComponent;
  @ViewChild('fromPicker') fromPicker: MatDatepicker<any>;
  @HostListener('window:resize', ['$event'])
  onWindowResize(event) {
    this.containerEl.fullCalendar('destroy');
    this. createCalendar('new');     
  }
  displayEvent: any;
  dataSource;
  isMobileScreen = isMobileScreen();
  properties: Array<Property>;
  selectedProperty: Property;
  bookingForm: FormGroup;
  events: any;
  userRole: string;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  displayColumns: any;
  totalRevenue = 0;
  startDate = 0;
  endDate = 0;
  booking: Booking;
  editJobStatus = true;
  showRevenueCalculation = false;
  roomId = 'all';
  propertyId = 'all';
  viewPropertiesPermissions = false;
  showPermissionsTag = false;
  totalBookings: string;
  totalProperties: string;
  guestPropertyName: string;
  bookingPrice: any;
  totalBookingPrice: string;
  isCleaner: boolean;
  test: any;
  tooltip: any;
  user: User;
  columnPermissions: any;
  rentalsWithId=[];
  propertiess=[];
  User: any;
  rentalsWithId1: any=[];
  startedView:any='month'
  date = new FormControl(moment());
  Date: any=this.convert(new Date());
  selectedRentals: string [] = [];
  selectedValue:any=[];
  rentalSearchValue:any=new FormControl('');
  rentals: any;
  roomData: any;
  sendBooking:any;
//-- tour var --//

Home_FLAG_CALANDAR:any=true;
OWNER_RESERVATION_PERMISSION_ALLOW = false;
  owners = [];
  owners1=[];
  ownerId: string;
  initialOwnersWithProperties;
  defaultCalenderData: any=[];
  currentDate=moment(new Date());
  adminOwnerCalender: any=[];
  constructor(private breakpointObserver: BreakpointObserver,protected eventService: EventService, public dialog: MatDialog, private ownerService: OwnerService,
              private propertyService: PropertyService, private userService: UserService, private toastrService: ToastrService,
              public tokenService: TokenService, private dataService: DataService, public expenseDialog: MatDialog,
              private renderer: Renderer2, private tourService: TourService) {      
                this.isMobileScreen =  isMobileScreen();          
               }
 

  ngOnInit() {
    
    $(window).resize(function() {
      if (window.innerWidth < 960) {        
        this.constructor();
      }
     
    });
    const userId = localStorage.getItem("userId");
    this.User = this.tokenService.getCurrentUser();
    if (this.User === 'ADMINISTRATOR') {
      this.userService.getPropertiesByAdmin().subscribe(properties => {
        if(properties && properties.payload) {
          this.defaultCalenderData=properties.payload;
          this.adminOwnerCalender.push(this.defaultCalenderData)
          this.populateRentalData(properties.payload);
        }
        else {
          // console.error("Property data not found for user.payload.id", user.payload.id);
        }
      });
        this.userService.getOwnersByAdminId(userId).subscribe(response => {
          response.payload.forEach(owner => {
            this.owners.push(owner);
            this.owners1.push(owner);
            var tempProperties = new Array();
            // this.userService.getOwnerRoomUnits(owner.id).subscribe(ownerRoomUnitsResponse => {
            //   this.defaultCalenderData=ownerRoomUnitsResponse.payload;
            //   this.adminOwnerCalender.push(this.defaultCalenderData)
            //   this.populateRentalData(ownerRoomUnitsResponse.payload,"");
            // });

          });
        });

       
      }

//--- bootstrap tour -- ////
let steps= [
  {
    element: "#datepicker",
      title: "Date Picker",
      content: "With this you can Select From date.",
      placement: 'right',
      backdrop: true,
  },
  {
    element: "#ownerList",
      title: "Owner List",
      content: "With this you will able to see owner list to choose from",
      placement: 'right',
      backdrop: true,
  },{
    element: "#rentalList",
      title: "Rental List",
      content: "With this you will able to see Rentals list to choose from",
      placement: 'right',
      backdrop: true,
  },
  {
    element: "#addButton",
      title: "Add Expense Button",
      content: "With this you can add Expense it.",
      placement: 'right',
      backdrop: true,
  },
  {
    element: "#calendar",
      title: "Calender view",
      content: "With this you will able to see check the recent bookings.",
      placement: 'top',
      backdrop: true,
  },]

  this.tourService.createTour(steps);

      this.userService.getUser().subscribe((res:any) => {
          // check user has permition for owner reservation
          if (res.code === 200 && res.payload.permission.includes('Allow Owner Reservation')) {
            this.OWNER_RESERVATION_PERMISSION_ALLOW = true;
          } else {
            this.OWNER_RESERVATION_PERMISSION_ALLOW = false;
          }

        if (res.code === 200 && res.payload.tourFlags) {
          this.Home_FLAG_CALANDAR= res.payload.tourFlags.CALENDAR_PAGE;
          if(this.Home_FLAG_CALANDAR == false){
            this.tourService.startTour();
            setTimeout(()=>{

              let interval = setInterval(()=>{
                if(localStorage.getItem('tour_end')==='yes'){
                  localStorage.setItem('tour_end','No')
                  clearInterval(interval);
                  this.ownerService.changeTourFlag('CALENDAR_PAGE').subscribe((res:any)=>{
                    this.Home_FLAG_CALANDAR=res.payload.tourFlags.CALENDAR_PAGE;
                  })
                }

              },50);
            },500)
          }
        }})




    if (this.tokenService.getCurrentUser() === 'CLEANER') {
      this.isCleaner = true;
    }
    this.userRole = this.tokenService.getCurrentUser();

    /*console.log('TimeZone =', Intl.DateTimeFormat().resolvedOptions().timeZone);*/

    if (this.tokenService.getCurrentUser() === 'OWNER') {
      this.editJobStatus = false;
      this.showRevenueCalculation = true;
      this.userService.getUser().subscribe((response: GenericDto<any>) => {

         if (response.code === 200) {
          this.user = response.payload.id;
          this.showOwnerPermissions(response.payload.id);
          this.userService.getOwnerRoomUnits(response.payload.id).subscribe(ownerRoomUnitsResponse => {

            this.defaultCalenderData=ownerRoomUnitsResponse.payload;
           this.populateRentalData(ownerRoomUnitsResponse.payload,);
          });
        } else {
          // show error and redirect to login page
        }

      }, (error: HttpErrorResponse) => {
        // show error and redirect to login page
      });
    } else {
      this.viewPropertiesPermissions = true;
      this.showPermissionsTag = false;
    }
    this.bookingForm = new FormGroup({
       "date":new FormControl(null),
      // 'toDate': new FormControl(null),
      // 'fromDate': new FormControl(moment('mm/yyyy')),
      'owner':new FormControl(),
      'property': new FormControl(),
    });
    // this.resetProperty();
    this.booking = {} as Booking;
    this.booking.bookingList = [];
    this.createCalendar('new');
    this.selectedProperty = new Object() as Property;
    this.propertyService.getAll().subscribe((response: GenericDto<Property[]>) => {
      if (response.code === 200) {

        this.properties = response.payload || [];
      } else {
        // show errors
      }
    }, (error: HttpErrorResponse) => {
      // handle errors here
    });




  }

  ownerSelected() {
   // console.log("this.bookingForm.value.owner",this.bookingForm.value.owner,"this.bookingForm.value.owner.id")
    if ((this.bookingForm.value.owner) && (this.bookingForm.value.owner.id !== null) && this.bookingForm.value.owner!='allOwner') {
      this.rentals = new Array();
      this.rentalsWithId1 = new Array();
      this.rentalsWithId = new Array()
      this.properties = [];
      this.ownerId = this.bookingForm.value.owner.id;
      this.userService.getPropertiesByOwnerId(this.bookingForm.value.owner.id).subscribe(properties => {
       // console.log("getPropertiesByOwnerId returns ", properties);
        if(properties && properties.payload) {
          this.populateRentalData(properties.payload);
        }
        else {
          console.error("Property data not found for this.revenueForm.value.owner.id", this.bookingForm.value.owner.id);
        }

      });
      this.bookingForm.value.property = null;
      this.containerEl.fullCalendar('destroy');
        // this.currentDate=moment(this.Date);
        this.createCalendar('new');

    } else if(this.bookingForm.value.owner=='allOwner'){
       
    this.ownerId=undefined;
    this.owners = this.owners1;
    this.populateRentalData(this.defaultCalenderData);
    this.containerEl.fullCalendar('destroy');
    this.createCalendar('new');
    }else {
      this.owners = this.owners1;

    }
  }

  chosenYearHandler(normalizedYear: Moment) {
    const ctrlValue = this.date.value;
    ctrlValue.year(normalizedYear.year());
    this.date.setValue(ctrlValue);
  }

   convert(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }

  chosenDateHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) {       
     this.Date=this.convert(this.date.value._d);    
     this.selectedMonth();
  }

  chosenMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = this.date.value;
    ctrlValue.month(normalizedMonth.month());
    this.date.setValue(ctrlValue);
    this.Date=this.convert(this.date.value._d);
      this.fromPicker.close();
      this.selectedMonth();
  }

  selectedMonth(): void {
    var calendar = $('#calendar').fullCalendar('getCalendar');
    // calendar.next()
    //  let calendarApi = calendar.getApi();
        this.booking.bookingList = [];
        this.containerEl.fullCalendar('destroy');
        this.currentDate=moment(this.Date);
        this.createCalendar('new');
      }

  setMonthAndYear(normalizedMonthAndYear: Moment, datepicker: MatDatepicker<Moment>) {
    // // const ctrlValue = this.fromDate.value!;
    // ctrlValue.month(normalizedMonthAndYear.month());
    // ctrlValue.year(normalizedMonthAndYear.year());
    // this.date.setValue(ctrlValue);
    // datepicker.close();
  }
  private populateRentalData(properties) {
    // this.rentals = new Array();
    this.rentalsWithId = new Array();
    this.rentalsWithId1 = new Array();

    // if(flag===""){
    //   properties.forEach(property => {
    //     if (property.name) {
    //       this.propertiess.push(property);
    //       if (property.unitItems) {
    //         if (property.unitItems.length == 0) {
    //           // this.rentals.push(property.name + " - " + property.propertyName);
    //           this.rentalsWithId1.push({id: property.id, name: property.name + " - " + property.propertyName});
    //           this.rentalsWithId.push({id: property.id, name: property.name + " - " + property.propertyName});
    //         } else if (property.unitItems.length > 0) {
    //           property.unitItems.forEach(unitItem => {
    //             // this.rentals.push(unitItem.unitName + " - " + property.name + " - " + property.propertyName);
    //             this.rentalsWithId1.push({
    //               id: property.id,
    //               name: unitItem.unitName + " - " + property.name + " - " + property.propertyName,
    //               unitId: unitItem.unitId
    //             });
    //             // this.rentalsWithId.push({
    //             //   id: property.id,
    //             //   name: unitItem.unitName + " - " + property.name + " - " + property.propertyName,
    //             //   unitId: unitItem.unitId
    //             // });
    //           });
    //         }
    //       } else if (!property.unitItems) {
    //         // this.rentals.push(property.name + " - " + property.propertyName);
    //         this.rentalsWithId1.push({id: property.id, name: property.name + " - " + property.propertyName});
    //         // this.rentalsWithId.push({id: property.id, name: property.name + " - " + property.propertyName});
    //       } else {
    //         console.log("property.unitItem - in here");
    //         // this.rentals.push(property.name + " - " + property.propertyName);
    //         this.rentalsWithId1.push({id: property.id, name: property.name + " - " + property.propertyName});
    //         // this.rentalsWithId.push({id: property.id, name: property.name + " - " + property.propertyName});
    //       }
    //     } else {
    //       console.log("property.units 2 - in here");
    //       // this.rentals.push(property.name + " - " + property.propertyName);
    //       this.rentalsWithId1.push({id: property.id, name: property.name + " - " + property.propertyName});
    //       // this.rentalsWithId.push({id: property.id, name: property.name + " - " + property.propertyName});
    //     }
    //   });
    // }else{
      properties.forEach(property => {
       // console.log("prop========== = ",property)

        if (property.name) {
          this.propertiess.push(property);
          if (property.unitItems) {
            if (property.unitItems.length == 0) {

              // this.rentals.push(property.name + " - " + property.propertyName);
              this.rentalsWithId1.push({title: property.guestRoomName,occupancy: property.guestUnitName,roomId:property.id, propertyId: property.propertyId, name: property.name + " - " + property.propertyName});
              this.rentalsWithId.push({title: property.name,occupancy: property.guestUnitName,roomId:property.id, propertyId: property.propertyId, name: property.name + " - " + property.propertyName});
            } else if (property.unitItems.length > 0) {
              property.unitItems.forEach(unitItem => {
                //debugger
               // console.log(unitItem)
                // this.rentals.push(unitItem.unitName + " - " + property.name + " - " + property.propertyName);
                this.rentalsWithId1.push({
                  id: property.id,
                  propertyId: property.propertyId,
                  title: unitItem.unitName,
                  occupancy: property.name,
                  roomId:property.id,
                  name: unitItem.unitName + " - " + property.name + " - " + property.propertyName,
                  unitId: unitItem.unitId,
                });
                this.rentalsWithId.push({
                  propertyId: property.propertyId,
                  title: unitItem.unitName,
                  occupancy: property.name,
                  roomId:property.id,
                  name: unitItem.unitName + " - " + property.name + " - " + property.propertyName,
                  unitId: unitItem.unitId
                });
              });
            }
          } else if (!property.unitItems) {
            // this.rentals.push(property.name + " - " + property.propertyName);
            this.rentalsWithId1.push({id: property.id, name: property.name + " - " + property.propertyName});
            this.rentalsWithId.push({id: property.id, name: property.name + " - " + property.propertyName});
          } else {
            console.log("property.unitItem - in here");
            // this.rentals.push(property.name + " - " + property.propertyName);
            this.rentalsWithId1.push({id: property.id, name: property.name + " - " + property.propertyName});
            this.rentalsWithId.push({id: property.id, name: property.name + " - " + property.propertyName});
          }
        } else {
          console.log("property.units 2 - in here");
          // this.rentals.push(property.name + " - " + property.propertyName);
          this.rentalsWithId1.push({id: property.id, name: property.name + " - " + property.propertyName});
          this.rentalsWithId.push({id: property.id, name: property.name + " - " + property.propertyName});
        }
      });
    // }

    // this.fetchExpensesData()
  }
  // this.dataSource.sort = this.sort;
  showOwnerPermissions(user: any): void {
    this.ownerService.getOwnerPermissions(user)
      .subscribe((response: GenericDto<Array<string>>) => {
       // console.log("QWERTY",response);
          if (response.code === 200) {
            if (response.payload['ownerPermissions'].length > 0) {
              response.payload['ownerPermissions'].forEach((ownerPermission: String) => {
               // console.log('here')
                if (ownerPermission === 'View Properties') {
                  this.viewPropertiesPermissions = true;
                  this.showPermissionsTag = false;
                  this.columnPermissions = response.payload['columnPermission'];

                }

              });

            }

          }
        },
        (error: HttpErrorResponse) => {
          // show errors here
        });
  }

  createCalendar(code:any) {

    if (isNaN(this.startDate) === true && isNaN(this.endDate) === true) {
      this.startDate = 0;
      this.endDate = 0;
    } else if (isNaN(this.startDate) === true) {
      this.startDate = 0;
    } else if (isNaN(this.endDate) === true) {
      this.endDate = 0;
    }
    if(this.bookingForm.value.property && this.bookingForm.value.property.length>0){
      var payload:any=this.bookingForm.value.property !== null && this.bookingForm.value.property[0]!='all' ? this.bookingForm.value.property:null;
    }else{
       payload = null;
    }
    if(code==='new'){
      this.booking.bookingList = [];
      this.eventService.getEvent(this.Date, this.ownerId,'all', payload).subscribe((data: GenericDto<Booking>) => {
        this.createCalenders(data)
      })
    }
    if(code==='old'){
      this.eventService.getEvents(this.startDate, this.endDate, this.roomId, this.propertyId, 'all',).subscribe((data: GenericDto<Booking>) => {
        this.createCalenders(data);
       // console.log("this.eventService.getEvents",data)

      });
    }

  }

  createCalenders(data){    

    let view ;
    this.breakpointObserver.observe(['(max-width: 960px)']).subscribe((state: BreakpointState) => {           
      if (state.matches) {              
        //  Mobile view columns
        view = "mobile"        
      } else {
         // Desktop view columns
         view = "desktop"
      }
    });


    this.totalBookingPrice = data.payload.totalBookingPrice;
    this.totalBookings = data.payload.totalBookings;
    this.totalProperties = data.payload.totalProperties;

    if (this.booking.bookingList.length > 0) {
      this.events = this.loadEvents(this.booking.bookingList);
      this.booking.bookingList = [];
    } else {
      this.events = this.loadEvents(data.payload.bookingList);
    }


    this.containerEl = $('#calendar'); /*fullCalendar('option', 'timezone', this.value || false);*/
    this.containerEl.fullCalendar('addEventSource', this.events);

    this.totalRevenue = 0;
    let role = this.tokenService.getCurrentUser();
    this.containerEl.fullCalendar({
      schedulerLicenseKey: 'GPL-My-Project-Is-Open-Source',
      editable: false, // enable draggable events
      eventLimit: false,
      refetchResourcesOnNavigate: false,
      aspectRatio: 1.8,
      scrollTime: '00:00', // undo default 6am scrollTime
      

      header: {
        left: '',
        center: 'title',
       // right:''
        //right: 'timelineDay,timelineThreeDays,timelineWeek,timelineMonth,listWeek'  /*,month*/
        // right: 'agendaDay,agendaWeek,month,listMonth'
      },
      // defaultView: ( view == "mobile")? 'listWeek':  'timelineMonth',
      defaultView: ( view == "mobile")?'listDay':'month',
      
      
      defaultDate: this.currentDate,
      resourceColumns: [
        {
          group: true,
          labelText: 'Building',
          field: 'building'
        },
        {
          group: true,
          labelText: 'Room Type',
          field: 'title'
        },
        {
          labelText: 'Rooms',
          field: 'occupancy'
        }
      ],
      customButtons: {

        prev: {
          text: 'Prev',
          click: ()=> {
                      if(view == 'mobile'){                     
                        this.containerEl.fullCalendar('prev'); // Move back one day
                        return;                     

                      }
                      // so something befor 2022-12-25
                      if(this.Date != ''){
                        var month= +this.Date.slice(5).substring(0, 2).replace('-','');
                        let year = this.Date.substring(0, 4);
                        let date = new Date(this.Date).getDate();
                        if(month > 1){
                          const Month =month-1;
                          let da =Month >= 10 ? Month: '0'+Month;
                           let newDate =year+'-'+da+'-'+date;
                           this.Date= newDate;
                           this.currentDate=moment(this.Date);
                           this.containerEl.fullCalendar('destroy');
                           this.createCalendar('new');
                        }else{
                          let Month = 12;
                          let year =+this.Date.substring(0, 4)-1;
                          let date = new Date(this.Date).getDate();
                          let da = Month;
                          let newDate =year+'-'+da+'-'+date;
                          this.Date= newDate;
                          this.currentDate=moment(this.Date);
                          this.containerEl.fullCalendar('destroy');
                          this.createCalendar('new');
                        }
                      }
          }
        },
        next: {
          text: 'Next',
          click: ()=> {
            if(view == 'mobile'){                     
              this.containerEl.fullCalendar('next'); // Move back one day
              return;                     

            }
            if(this.Date != ''){
              let months=this.Date.slice(5).substring(0, 2).replace('-','');
              let year = this.Date.substring(0, 4);
              let date = new Date(this.Date).getDate();
              if( months < 12){
                let Month = +months+1;
                date = (Month == 2 && date > 28) ? 1 : date;
                let da = Month >= 10 ? Month: '0'+ Month;
                 let newDate =year+'-'+da+'-'+date;
                 this.Date= newDate;
                 this.currentDate=moment(this.Date);
                 this.containerEl.fullCalendar('destroy');
                 this.createCalendar('new');
              }else{
                let Month = '01';
                let year =+this.Date.substring(0, 4)+1;
                let date = new Date(this.Date).getDate();
                let da = Month;
                let newDate =year+'-'+da+'-'+date;
                this.Date= newDate;
                this.currentDate=moment(this.Date);
                this.containerEl.fullCalendar('destroy');
                this.createCalendar('new');
              }
            }
          }
        },
        today:{
          text: 'today',
          click: ()=> {            
            var currentDate = new Date();
            // Extract individual components of the current date
            var year = currentDate.getFullYear(); // 4-digit year
            var month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Month (0 - 11)
            var day = String(currentDate.getDate()).padStart(2, '0'); // Day of the month

            // Format the components as a string in "YYYY-MM-DD" format
            this.Date = year + '-' + month + '-' + day;
            this.currentDate=moment(this.Date);
            this.containerEl.fullCalendar('destroy');
            this.createCalendar('new');

          }
        },
      },


      eventMouseover: function (data, event, view) {

        //this.userRole = this.tokenService.getCurrentUser();
        this.tooltip = '<div class="tooltiptopicevent" ' +
          'style="width:auto;' +
          'height:auto;' +
          'background:#45B39D;' +
          'position:absolute;' +
          'z-index:10001;' +
          'padding:10px 10px 10px 10px ;' +
          '  line-height: 200%;">' ;
          if(data.bookId != null){
            this.tooltip+= 'Booking Id2: '+data.bookId + '</br>';
           }
           this.tooltip += 'Check-In: ' + moment.unix(data.firstNight).format('YYYY-MM-DD') + '</br>' +
          'Check-Out: ' + moment.unix(data.lastNight).add('days', 1 ).format('YYYY-MM-DD') + '</div>';


        if(role == "ADMINISTRATOR"){

         this.tooltip = '<div class="tooltiptopicevent" ' +
           'style="width:auto;' +
           'height:auto;' +
           'background:#45B39D;' +
           'position:absolute;' +
           'z-index:10001;' +
           'padding:10px 10px 10px 10px ;' +
           '  line-height: 200%;">' ;
          if(data.bookId != null){
           this.tooltip+= 'Booking Id: '+data.bookId + '</br>';
          }
          this.tooltip+= 'Guest Name: ' + data.title + '</br>' ;
          if(data.guestEmail != null){
            this.tooltip+=  'Guest Email: ' + data.guestEmail + '</br>' ;
           }

           if(data.guestPhone != null){
            this.tooltip += 'Guest Contact: ' + data.guestPhone + '</br>';
           }

           this.tooltip += 'Check-In: ' + moment.unix(data.firstNight).format('YYYY-MM-DD') + '</br>' +
           'Check-Out: ' + moment.unix(data.lastNight).add('days', 1 ).format('YYYY-MM-DD') + '</div>';
            //'Booking Amount: $'+ this.cp.transform(data.bookingPrice, this.currency:'symbol-narrow') + data.bookingPrice + '</div>';
        }
        $('body').append(this.tooltip);
        $(this).mouseover(function (e) {
          $(this).css('z-index', 10000);
          $('.tooltiptopicevent').fadeIn('500');
          $('.tooltiptopicevent').fadeTo(10, 1.9);
        }).mousemove(function (e) {
          $('.tooltiptopicevent').css('top', e.pageY + 10);
          $('.tooltiptopicevent').css('left', e.pageX + 20);
        });
      },

      eventMouseout: function (data, event, view) {
        $(this).css('z-index', 8);

        $('.tooltiptopicevent').remove();

      },
      dayClick: (date , allDay, jsEvent, view) => {
        this.roomData=view;
        this.userRole = this.tokenService.getCurrentUser();
        if (this.userRole === 'OWNER') {
        this.ownerService.getOwnerPermissions(this.user)
          .subscribe((response: GenericDto<any>) => {
            console.log('role: ' + this.userRole)
              if (response.code === 200 ) {
                console.log('response: ' + response.payload.ownerPermissions)
                if (response.payload.ownerPermissions.length > 0 ) {
                  response.payload.ownerPermissions.forEach((ownerPermission: String) => {
                    if (ownerPermission === 'Allow Owner Reservation' ) {
                      const current_date = moment().format('YYYY-MM-DD');
                      if (current_date <= date.format()) {
                          console.log(this.roomData)
                          const dialogRef = this.dialog.open(OwnerReservationComponent, {
                            width: '200%',
                            height: 'auto',
                            data: {
                              minDate: date.format(),
                              passedDate: date.format(),
                              roomData:this.roomData,
                              isForm:'click'
                              /* maxDate: date.format()*/
                            }
                          });
                          dialogRef.afterClosed().subscribe(() => {
                            dialogRef.close();
                            this.containerEl.fullCalendar('destroy');
                            this.createCalendar('new');
                          });
                      }
                    }
                  });

                }

              }
              },
            (error: HttpErrorResponse) => {
              // show errors here
            });
          }
      },
      select: (start, end, jsEvent, view, resource) =>{
        // let end=selectInfo.end._d

         console.log('inside select: rentalsWithId: ', this.rentalsWithId);
         /*console.log('inside select start/end date', {
           start: start.format('YYYY-MM-DD'), end: end.format('YYYY-MM-DD')
         });*/
         if(this.selectedRentals.length ==0 ||this.selectedRentals[0]=="all"){
          this.roomData = this.rentalsWithId[0];
         }else{
          this.roomData=this.selectedRentals[0];

         }

        //this.roomData=resource;
        this.userRole = this.tokenService.getCurrentUser();
        if (this.userRole === 'OWNER') {
        this.ownerService.getOwnerPermissions(this.user)
          .subscribe((response: GenericDto<any>) => {
            console.log('role: ' + this.userRole)
              if (response.code === 200 ) {
                console.log('ownerPermissions response: ' + response.payload.ownerPermissions)
                if (response.payload.ownerPermissions.length > 0 ) {
                  response.payload.ownerPermissions.forEach((ownerPermission: String) => {
                    if (ownerPermission === 'Allow Owner Reservation' ) {
                      const current_date = moment().format('YYYY-MM-DD');
                      if (current_date <= start.format()) {

                        console.log("formatted date", start.format('MM/DD/YYYY'));
                          const dialogRef = this.dialog.open(OwnerReservationComponent, {
                            width: '200%',
                            height: 'auto',
                            data: {
                              minDate: start.set({hour: 12, minute: 0}).utc().toDate(),
                              passedDate: start.set({hour: 12, minute: 0}).utc().toDate(),
                              roomData:this.roomData,
                              maxDate: end.set({hour: 0, minute: 0}).utc().toDate(),
                              isForm:'select'
                              /* maxDate: date.format()*/
                            }
                          });
                          dialogRef.afterClosed().subscribe(() => {
                            dialogRef.close();
                            this.containerEl.fullCalendar('destroy');
                            this.createCalendar('new');
                          });
                      }
                    }
                  });

                }

              }
              },
            (error: HttpErrorResponse) => {
              // show errors here
            });
          }
        return true;
      },
      timeFormat: '',
      displayEventTime: false,
      selectable: true,
      resources: this.schedulerEvents(this.events),
      events: this.events,
      slotDuration: {
        'hours': 12
      },
      eventRender: (event, element) => {
        element.find('.fc-list-item-marker').addClass('text-align'); 
        element.find('.fc-list-item-marker').removeClass('fc-list-item-marker');
        element.find('.text-align').css('text-align', 'center');

        element.find('tr.fc-list-item').hide();
       

        
        
        // element.css('margin-right','7px')
        element.css('border-radius','25px')
        if (event.jobAvailable) {
          element.find('.fc-content').prepend('<div style=\'width: 16px; background: red;  text-align: center;\' class=\'float-right\' >J</div>');
        }
        if(event.status === '0'){
          element.find('.fc-content').prepend('<span style=\'width: 16px; background-color: grey;  text-align: center;\' class=\'float-right\' >C</span>');
         

        // } else if(event.status === '1'){
        //   element.find('.fc-content').prepend('<span style=\'width: 16px; background-color: grey;  text-align: center;\' class=\'float-right\' >R</span>');
        // }
        }

        //   element.find('div.fc-bg').css('background-color', 'white');
        //   if (event.status === '0') {
        //     element.find('div.fc-bg').append().css('background-color', 'white');
        //   // }
      },
      eventClick: (event) => {
        this.jobDetails(event);
        return true;
      }
      /*eventClick(model: any) {
        this.jobDetails(model.event);
      }*/
    });

    // =====================================calandar end here =======================================================
    //Add css for mobile view
    if(isMobileScreen()){
      $('.fc-center h2')
      .addClass('custom-header-title')
      .css({
          'font-size': '18px',
          'padding-top': '14px',        
          'padding-left': '5px'
        
      }); 

    }
   

    // document.querySelector('.fc-center').innerHTML=`<div><h2>${this.getTitle()}</h2></div>`;
  }

  getTitle(){
    const months = ["January","February","March","April","May","June","July","August","September","October","November","December"];
    let currentMonth = new Date(this.Date).getMonth();
    let year = new Date(this.Date).getFullYear()
    return months[currentMonth]+" "+year;
  }

  fetchProperties(event: boolean): void {
    // const propertyInfo = thihttps://fullcalendar.io/s.bookingForm.get('property').value;

    // const roomInfo = this.bookingForm.get('room').value;
    if (event) {
      this.propertyService.getAll().subscribe((response: GenericDto<Property[]>) => {
        if (response.code === 200) {
          this.properties = response.payload || [];
        } else {
          // show errors
        }
      }, (error: HttpErrorResponse) => {
        // handle errors here
      });
    }
    /*if (propertyInfo === null ? roomInfo : this.bookingForm.get('room').value) {
      this.bookingForm.get('room').patchValue(null);
    }*/
  }

  searchRental(filterValue) {
    if (filterValue && filterValue.length != 0) {
      if(this.selectedRentals.length>0 && filterValue.trim().length == 1){
        this.selectedRentals.forEach(element=>{
          this.selectedValue.push(element);
        })
      }
      let tempProperties = new Array();
      for(let rental of this.rentalsWithId1){
        if (rental.name.trim().toLowerCase().includes(filterValue.trim().toLowerCase())) {
          tempProperties.push(rental);
        }
      }
      this.rentalsWithId = tempProperties;
    } else {
      if(this.selectedRentals.length>0){
        this.selectedValue.forEach(element=>{
          if(!this.selectedRentals.includes(element)){
            this.selectedRentals.push(element)
          }
        })}
      filterValue = null;
      this.rentalsWithId = this.rentalsWithId1;
    }
  }

  searchOwner(filterValue) {
    if ((filterValue) && (filterValue !== null)) {
      let tempOwners = new Array();
      for (let singleObject of this.initialOwnersWithProperties) {
        for (let currentOwnerObject of singleObject) {
          if (currentOwnerObject.owner) {
            let currentOwner = currentOwnerObject.owner.firstName + " " + currentOwnerObject.owner.lastName;
            if (currentOwner.trim().toLowerCase().includes(filterValue.trim().toLowerCase())) {
              tempOwners.push(currentOwnerObject.owner)
            }
          }
        }
      }
      this.owners = tempOwners;
    } else {
      filterValue = null;
      this.owners = this.owners1;
    }
  }



  roomSelected() {
    this.rentalSearchValue.setValue('');
    this.searchRental('');
    if (this.bookingForm.value.property.length!=0 && this.bookingForm.value.property !== null) {
      var rentals = new Array();
      // this.revenueForm.value.property.forEach(property => {
      let flag = false;
      for (let index = 0; index < this.bookingForm.value.property.length; index++) {
        const property = this.bookingForm.value.property[index];
        for (let index = 0; index < this.rentalsWithId.length; index++) {
          const rental = this.rentalsWithId[index];
          if (property === 'all') {
            flag = true;

            break;
          } else {
            console.log("else rental: ", rental);
            if (rental.name === property.name) {
              rentals.push(rental);
            }
          }
        }
      }
      this.containerEl.fullCalendar('destroy');
    this.createCalendar('new');
    }


  }

  fetchBookings(event: MatSelectChange): void {
    if (event.value === null) {
      // this.startDate = this.bookingForm.get('fromDate').value >= 0 ? moment(this.bookingForm.get('fromDate').value).unix() : 0;
      // this.endDate = this.bookingForm.get('toDate').value >= 0 ? moment(this.bookingForm.get('toDate').value).unix() : 0;
      this.Date = this.bookingForm.get('date').value >= 0 ? moment(this.bookingForm.get('date').value).unix() : 0;
      // this.roomId = (this.bookingForm.get('room').value as RoomType).id;
      this.propertyId = (this.bookingForm.get('property').value as Property).id;
      this.roomId = 'all';
      this.booking.bookingList = [];
      this.containerEl.fullCalendar('destroy');
      this.createCalendar('old');
    } else {
      // this.startDate = this.bookingForm.get('fromDate').value >= 0 ? moment(this.bookingForm.get('fromDate').value).unix() : 0;
      // this.endDate = this.bookingForm.get('toDate').value >= 0 ? moment(this.bookingForm.get('toDate').value).unix() : 0;
      this.Date = this.bookingForm.get('date').value >= 0 ? moment(this.bookingForm.get('date').value).unix() : 0;
      this.roomId = (this.bookingForm.get('room').value as RoomType).roomId != null ? (this.bookingForm.get('room').value as RoomType).roomId : 'all';
      this.booking.bookingList = [];
      this.containerEl.fullCalendar('destroy');
      this.createCalendar('old');
    }
  }

  fetchAllPropertyBookings(event: MatSelectChange): void {
    if (event.value === 'all') {
      // this.startDate = this.bookingForm.get('fromDate').value >= 0 ? moment(this.bookingForm.get('fromDate').value).unix() : 0;
      // this.endDate = this.bookingForm.get('toDate').value >= 0 ? moment(this.bookingForm.get('toDate').value).unix() : 0;
      this.Date= this.bookingForm.get('date').value >= 0 ? moment(this.bookingForm.get('date').value).unix() : 0;
      this.propertyId = (this.bookingForm.get('property').value as Property).id ? (this.bookingForm.get('property').value as Property).id : 'all';
      // this.selectedJobRequest = this.bookingForm.get('jobRequest').value !== '' ? this.bookingForm.get('jobRequest').value : 'all';
      this.roomId = 'all';
      this.booking.bookingList = [];
      this.containerEl.fullCalendar('destroy');
      this.createCalendar('old');
    } else {
      this.propertyId = (this.bookingForm.get('property').value as Property).id ? (this.bookingForm.get('property').value as Property).id : 'all';
      this.roomId = 'all';
      this.booking.bookingList = [];
      this.containerEl.fullCalendar('destroy');
      this.createCalendar('old');
    }
  }

  setMinDate(value: any): Date {
    return new Date(value);
  }

  lastDate(): Date {
    return new Date(this.bookingForm.get('toDate').value);
  }

  resetProperty() {
    /*  this.selectedProperty = new Object() as Property;*/
    this.booking.bookingList = [];
    // this.startDate = this.bookingForm.get('fromDate').value >= 0 ? moment(this.bookingForm.get('fromDate').value).unix() : 0;
    // this.endDate = this.bookingForm.get('toDate').value >= 0 ? moment(this.bookingForm.get('toDate').value).unix() : 0;
    this.Date = this.bookingForm.get('date').value >= 0 ? moment(this.bookingForm.get('date').value).unix() : 0;
    this.propertyId = (this.bookingForm.get('property').value as Property).id != null ? (this.bookingForm.get('property').value as Property).id : 'all';
    if (this.bookingForm.get('room').value === 'all') {
      this.roomId = 'all';
    } else {
      this.roomId = this.bookingForm.get('room').value != null ? (this.bookingForm.get('room').value as RoomType).roomId : 'all';
    }

    this.booking.bookingList = [];
    this.containerEl.fullCalendar('destroy');
    this.createCalendar('old');
  }

  /**
   *  On `fromDate` change reset `toDate` if it is before `fromDate`
   * @param {MatDatepickerInputEvent<Date>} event
   */
  fromDateChanged(event: MatDatepickerInputEvent<Date>): void {
    this.booking.bookingList = [];
    // const changedDate = new Date(event.value);
    // const toDateValue = new Date(this.bookingForm.get('toDate').value);
    // this.startDate = this.bookingForm.get('fromDate').value >= 0 ? moment(this.bookingForm.get('fromDate').value).unix() : 0;
    // this.endDate = this.bookingForm.get('toDate').value >= 0 ? moment(this.bookingForm.get('toDate').value).unix() : 0;
    this.Date = this.bookingForm.get('date').value >= 0 ? moment(this.bookingForm.get('date').value).unix() : 0;
    this.propertyId = (this.bookingForm.get('property').value as Property).id != null ? (this.bookingForm.get('property').value as Property).id : 'all';
    if (this.bookingForm.get('room').value === 'all') {
      this.roomId = 'all';
    } else {
      this.roomId = this.bookingForm.get('room').value != null ? (this.bookingForm.get('room').value as RoomType).roomId : 'all';
    }
    this.booking.bookingList = [];
    this.containerEl.fullCalendar('destroy');
    this.createCalendar('old');


    // this.resetProperty();
    // if (changedDate > toDateValue) {
    //   this.bookingForm.get('toDate').patchValue(null);
    // }
  }

  clickButton(model: any) {
    this.displayEvent = model;
  }

  eventClick(model: any) {
   this.jobDetails(model.event);
  }

  updateEvent(model: any) {
    model = {
      event: {
        id: model.event.id,
        start: model.event.start,
        end: model.event.endDate,
        endDate: model.event.endDate,
        title: model.event.title
      },
      duration: {
        _data: model.duration._data
      }
    };
    this.displayEvent = model;
  }

// someMethod() {

//     calendarApi.next(); // For next
//     calendarApi.prev(); // For prev
//     calendarApi.changeView('timeGridDay'); // changing view
//     calendarApi.changeView('timeGridDay', '2017-06-01'); // change view for specific date
//     calendarApi.changeView('timeGrid', {
//                          start: '2017-06-01',
//                          end: '2017-06-05'
//                     }); // For changing week view or month view accodring to date.
//                     console.log("!!bookingsSSSSSSSSSSSSSSSSSSSSSSSSSS..,,bookings")
//   }

  loadEvents(bookings: Array<any>): Array<any> {
    if (bookings !== null) {
      if (!!bookings) {
        return bookings.map((item, ind) => {
          let itemLastNight: any;
          if (item.lastNight > 0) {
            /*  const tz = require('moment-timezone')
            console.log('timezone: ', moment.tz().zoneName());
            */
           //itemLastNight = moment.unix(item.lastNight).add('days', 1).format('YYYY-MM-DD 12:00:00');
           itemLastNight = moment.unix(item.lastNight).set({hour: 12, minute: 0}).utc().add(1, 'day').format('YYYY-MM-DD 12:00:00');

          } else {
            itemLastNight = moment.unix(item.lastNight).set({hour: 12, minute: 0}).utc().add(1, 'day').format('YYYY-MM-DD');
          } 
           
          let bookingStartDate = moment.unix(item.firstNight).set({hour: 12, minute: 0}).utc().format('MMM D, YYYY');
          let bookingEndDate = moment.unix(item.lastNight).set({hour: 12, minute: 0}).utc().add(1, 'day').format('MMM D, YYYY');
          

        // let guestFullName = (this.userRole == 'OWNER')?item.guestFirstName : item.guestFirstName + ' ' + item.guestName;
         let bookingInfo = (this.userRole == 'OWNER')?item.guestFirstName : item.guestFirstName + ' ' + item.guestName;
         if(isMobileScreen()){
          bookingInfo = bookingInfo+", ("+item.bookId+"),"+' '+item.guestEmail+", "+bookingStartDate+" to "+bookingEndDate;

         } 
      
          
          const dataItemInfo = {
            resourceId: item.guestUnitName,
            title:   bookingInfo,
            start: moment.unix(item.firstNight).set({hour: 12, minute: 0}).utc().format('YYYY-MM-DD 12:00:00'),
            end: itemLastNight,
            endDate: moment.unix(item.lastNight).set({hour: 12, minute: 0}).utc().add(1, 'day').format('YYYY-MM-DD'),
            item
          };
         // console.log(dataItemInfo,"<>S<>",Object.assign({}, item, dataItemInfo));
          return Object.assign({}, item, dataItemInfo);
        });
      }
    }
    return [];
  }

  schedulerEvents(bookings: Array<any>): Array<any> {

//    console.log(bookings)
    if (bookings !== null && bookings.length > 0) {
      if (!!bookings) {
        const BookingMAp = [];
          bookings.map((item, ind) => {
          /*let itemLastNight: any;
          if (item.lastNight > 0) {
            itemLastNight = moment.unix(item.lastNight).format('YYYY-MM-DD 23:59:00');
          } else {
            itemLastNight = moment.unix(item.lastNight).format('YYYY-MM-DD');
          }*/

            BookingMAp.push({
              propertyId: item.guestPropertyId,
              id: item.guestUnitName,
              building: item.guestPropertyName,
              title: item.guestRoomName,
              occupancy: item.guestUnitName,
              /*start: moment.unix(item.firstNight).format('YYYY-MM-DD'),
              end: itemLastNight,
              endDate: moment.unix(item.lastNight).format('YYYY-MM-DD'),
              item*/
            });
          /*console.log('occupancy : ' , ind, ' : ', dataItemInfo.building, ' : ', dataItemInfo.title, ' : ', dataItemInfo.occupancy);*/
          // return dataItemInfo;
          // return Object.assign({}, item, dataItemInfo);
        });
        const lastVar = this.unique(BookingMAp, ['building', 'title', 'occupancy']);
        this.sendBooking = lastVar

        return lastVar;
      }
    }else{
      let BookingMAp:any = [];
      this.defaultCalenderData.forEach((element:any,idx)=>{
        element.unitItems.forEach((item)=>{
          BookingMAp.push({
          id: item.unitName,
          building: element.propertyName,
          title: element.name,
          occupancy: item.unitName,
    /*start: moment.unix(item.firstNight).format('YYYY-MM-DD'),
    end: itemLastNight,
    endDate: moment.unix(item.lastNight).format('YYYY-MM-DD'),
    item*/
  });
})
      })
      return BookingMAp;
    }
    return [];
  }

  unique(arr, keyProps) {
    const kvArray = arr.map(entry => {
      const key = keyProps.map(k => entry[k]).join('|');
      return [key, entry];
    });
    const map = new Map(kvArray);
    return Array.from(map.values());
  }

  /*
  * Show event in dialog
  */

  jobDetails(event: any) {
    const dialogRef = this.dialog.open(JobDetailsModalComponent, {
      width: '300%',
      height: 'auto',
      data: {
        event: event
      },
    });

    dialogRef.afterClosed().subscribe(() => {
      this.booking.bookingList = [];
        /*this.containerEl.fullCalendar('removeEvents');*/
      dialogRef.close();
    });
  }
  bookingDialog(booking: any): void {
    const dialogRef = this.dialog.open(OwnerBookingDialogComponent, {
      width: '140%',
      height: '40%',
      data: {
        event: booking
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      dialogRef.close();
    });
  }

  createExpense() {
    const dialogRef = this.expenseDialog.open(ExpenseComponent, {
      width: '45%',
      // height: '65%',
      data: {
        propertyId: null,
        roomId: null
      }
    });
  }

  ownerReservation(){
    const dialogRef = this.dialog.open(OwnerReservationComponent, {
      width: '200%',
      height: 'auto',
      data: {
        minDate: true,
        passedDate: 0,
        booking:this.sendBooking,
        rentalsWithId:this.rentalsWithId,
        maxDate:0,
        isForm:'select'
        /* maxDate: date.format()*/
      }
    });
    dialogRef.afterClosed().subscribe(() => {
      dialogRef.close();
      this.containerEl.fullCalendar('destroy');
      this.createCalendar('new');
    });
  }
}

