/**
 *  Define all assets API endpoints here
 */

export const getAssetById  = '/asset/get-file';

export const uploadAsset  = '/asset/uploadAsset';

export const getMaintenanceJobImages  = '/maintenanceJob/getImages';

export const uploadExpenseInvoices  = '/maintenanceJob/uploadExpenseInvoices';

export const uploadStatementInvoices  = '/statement/uploadExpenseInvoices';






