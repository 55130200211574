import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {ActivatedRoute, Router} from '@angular/router';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {ToastrService} from 'ngx-toastr';
import {Subscription} from 'rxjs/index';
import {Component, OnInit} from '@angular/core';
import {UserService} from '../../../services/user.service';
import {User} from '../../../models/user';
import {DataService} from '../../../shared/services/data.service';
import {GenericDto} from '../../../models/generic-dto';
import {MatDialog} from '@angular/material';
import {ChangePasswordComponent} from '../change-password/changePassword.component';
import { AuthGuard } from 'src/app/core/guards/auth.guard';
import { TokenService } from 'src/app/core/services/token.service';


@Component ({
  selector: 'app-user-profile',
  templateUrl: './profile-details.component.html',
  styleUrls: ['./profile-details.component.scss'],
  animations: [trigger(
    'enterAnimation', [
      transition(':enter', [
        style({transform: 'scale(0)', opacity: 0}),
        animate(50, style({transform: 'scale(1)', opacity: 1}))
      ]),
      transition(':leave', [
        style({transform: 'scale(1)', opacity: 1}),
        animate(50, style({transform: 'scale(0)', opacity: 0}))
      ])
    ]
  )]
})

export class ProfileDetailsComponent implements OnInit {
  user: User = null;
  isLoading = true;
  isLoadingImage: boolean;
  subscription: Subscription;
  images: any;
  placeholderImage: SafeUrl;
  editMode: boolean;
  isEmptyState = false;


  currency: string;

  constructor (private userService: UserService, public authGuard: AuthGuard,public tokenService: TokenService, private toastrService: ToastrService, private dataService: DataService,
               private route: ActivatedRoute, private router: Router, private sanitizer: DomSanitizer, public dialog: MatDialog) {}

  ngOnInit() {
    this.placeholderImage = this.sanitizer.bypassSecurityTrustResourceUrl('assets/images/no_images.png');

      // load User
      if (!!this.user) {
        this.user = null;
        this.isLoading = true;
      }
      this.getUserDetails();
  }

  public get image() {
    return !!this.images && this.images.length > 0 ? this.images[0] : this.placeholderImage;
  }


  updateNewPassword() {
    this.editMode = false;
    this.createNewPassword();
  }


  createNewPassword() {
    this.isEmptyState = false;
    const dialogRef = this.dialog.open(ChangePasswordComponent, {
      width: '50%',
      height: '50%'
   });
   /* dialogRef.componentInstance.reloadPlugins.subscribe(() => {
      //this.loadPlugins();
    });*/

    dialogRef.afterClosed().subscribe(() => {
   //   this.hide();
    });
  }

 
editUser(): void {
    this.router.navigate(['/dashboard/profile/edit']);
  }

  getUserDetails(): void {
    this.userService.getUser().subscribe((response: GenericDto<User>) => {
      if (response.code === 200) {
        this.user = response.payload;
      }
      this.isLoading = false;
    }, error => {
      this.isLoading = false;
    });
  }



}
