import {Injectable} from '@angular/core';
import {HttpService} from '../core/services/http.service';
import {Observable, throwError} from 'rxjs/index';
import {environment} from '../../environments/environment';
import {GenericDto} from '../models/generic-dto';
import {Owner} from '../models/owner';
import {
  findOneBy,
  findByOwnerId,
  getOwnerDetails, getOwnerPermissions,
  getOwnerYearDetails,
  getTotalRevenue,
  personalBookingOfOwner, saveOwnerPermissions,
  updateOwner,
  getOwnerDetailPermissions,
  payoutReport,
  sendReport,
  receiveReport,
  payoutReportFromToDate,
  getDashboard,
  getMonthlyOccupancyRate
  // payoutReportFromDate,
  // payoutReportToDate
} from '../constants/owner-api.constant';
import {
 syncBookingToPms
} from '../constants/booking-api.constants';
import {Month} from '../models/month';
import {
  getAllOwnerPersonalBookings,
  getMonthlyRevenueNotification,
  getOwnerPersonalBooking, updateOwnerRevenueNotification,getOwnerProperties,
  updateUser, totalBookings
} from '../constants/user-api.constatnts';
import {Booking} from "../models/booking";
import {JobAssign} from '../models/job-assign';
import {getAllJobsAssignDetails} from '../constants/job-api.constants';
import { all } from 'q';
import { PayoutReport } from '../dashboard/payout-report/payout-report.model';
import { tap, catchError } from 'rxjs/internal/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { LoaderService } from '../shared/services/loader.service';
import { AdditionaDetail } from '../models/additional-detail';

@Injectable()
export class OwnerService {

  previewOwner = null;
  constructor(private httpService: HttpService, private loaderService: LoaderService) {

  }

  setPreviewOwner(object: any): void {
    this.previewOwner = object;
  }

  getPreviewOwner(): any{
    return this.previewOwner;
  }

  getOwnerRevenue(selectedMonth: any): Observable<GenericDto<Month[]>> {
    return this.httpService.getAuth<GenericDto<Month[]>>(environment.API + getOwnerDetails + '?revenueOf=' + selectedMonth ) ;
}

  getOwnerPermissions(ownerId: any): Observable<GenericDto<any>> {
    return this.httpService.getAuth<GenericDto<any>>(environment.API + getOwnerPermissions + '?ownerId=' + ownerId) ;
  }

  findOneBy(ownerId: any): Observable<GenericDto<Owner>> {
    return this.httpService.getAuth<GenericDto<Owner>>(environment.API + findOneBy + '?ownerId=' + ownerId) ;
  }

  findOwnerByUserId(userId: string): Observable<GenericDto<Owner>> {
    return this.httpService.getAuth<GenericDto<Owner>>(environment.API + findByOwnerId + '?ownerId=' + userId);
  }

  getOwnerDetails(ownerId){
    return this.httpService.getAuth<GenericDto<Owner>>(environment.API + '/owner/getOwnerDetails?ownerId='+ ownerId);
  }


  getOwnerYearRevenue(selectedMonth: any): Observable<GenericDto<Month[]>> {
    return this.httpService.getAuth<GenericDto<Month[]>>(environment.API + getOwnerYearDetails  + '?revenueOf=' + selectedMonth) ;
  }

  getTotalOwnerRevenue(selectedMonth: any): Observable<GenericDto<number>> {
    return this.httpService.getAuth<GenericDto<number>>(environment.API + getTotalRevenue + '?revenueOf=' + selectedMonth) ;
  }

  /**
   * update owner
   */
  updateOwnerProperties(ownerId: any , roomUnitIds: any, additionalDetails: AdditionaDetail[] ): Observable<GenericDto<any>> {
    return this.httpService.putAuth(environment.API + updateOwner + '?roomUnitIds=' + roomUnitIds + '&ownerId=' + ownerId , additionalDetails);
  }

  // savePersonalBookingOfOwner(propertyId: any , firstNight: any , lastNight: any , unitName: any): Observable<GenericDto<any>> {
  //   return this.httpService.postAuth(environment.API + personalBookingOfOwner + '?propertyId=' + propertyId + '&firstNight=' + firstNight + '&lastNight=' + lastNight  + '&unitName=' + unitName , propertyId  );
  // }
   savePersonalBookingOfOwner( payload: any): Observable<GenericDto<any>> {
    return this.httpService.postAuth(environment.API + personalBookingOfOwner ,payload  );
  }

  saveOwnerPermissions(ownerId: any , ownerPermissions: Array<string> ): Observable<GenericDto<any>> {
    return this.httpService.getAuth(environment.API + saveOwnerPermissions + '?ownerId=' + ownerId + '&permission=' + ownerPermissions);
  }

  saveOwnerPermissionWithColumnPermissions(ownerId: any , ownerPermissions: any): Observable<GenericDto<any>> {
    return this.httpService.postAuth(environment.API + saveOwnerPermissions + '?ownerId=' + ownerId, ownerPermissions)
  }

  getOwnerDetailPermissions(): Observable<GenericDto<any>>{
    return this.httpService.getAuth(environment.API + getOwnerDetailPermissions);
  }

  getOwnerBookings(): Observable<GenericDto<Booking[]>> {
    return this.httpService.getAuth<GenericDto<Booking[]>>(environment.API + getOwnerPersonalBooking );
  }

  getOwnerProperties(): Observable<GenericDto<Booking[]>> {
    return this.httpService.getAuth<GenericDto<Booking[]>>(environment.API +'/property/getTotalProperties' );
  }

  getActiveJobs(): Observable<GenericDto<Booking[]>> {
    return this.httpService.getAuth<GenericDto<Booking[]>>(environment.API +'/job/get-all' );
  }

  getTotalBookings(year: any): Observable<GenericDto<Booking[]>> {
    return this.httpService.getAuth<GenericDto<Booking[]>>(environment.API + totalBookings + '?year=' + year );
  }

  getAllOwnerPersonalBookings(): Observable<GenericDto<Booking[]>> {
    return this.httpService.getAuth<GenericDto<Booking[]>>(environment.API + getAllOwnerPersonalBookings);
  }

  getMonthlyRevenueNotification(userId: string): Observable<GenericDto<string>> {
    return this.httpService.getAuth<GenericDto<string>>(environment.API + getMonthlyRevenueNotification + '?userId=' + userId);
  }

  updateMonthlyRevenueNotification(userId: string, notificationType: string): Observable<GenericDto<string>> {
    return this.httpService.getAuth<GenericDto<string>>(environment.API + updateOwnerRevenueNotification + '?ownerId=' + userId + '&notificationType=' + notificationType);
  }

  getOwnerPayoutReport(month: any):Observable<GenericDto<PayoutReport []>> {
    return this.httpService.getAuth<GenericDto<PayoutReport []>>(environment.API + payoutReport + '?month=' + month);
  }

  sendOwnerReport(dataPdF:any, ownerId: string) {
    return this.httpService.postAuth<GenericDto<any>>(environment.API + sendReport + `/${ownerId}`, dataPdF );
  }

  changeTourFlag(pageName:any){
    return this.httpService.postAuth<any>(environment.API + `/user/update-tour-flags?page_name=${pageName}`,pageName);
  }

  refreshTourFlag(){
    return this.httpService.postAuth<any>(environment.API + `/user/refresh-tours`,{});
  }

  receiveOwnerReport(ownerId: string){
    return this.httpService.getAuth<GenericDto<any>>(environment.API + receiveReport + `/${ownerId}`)
  }
  getOwnerPayoutReportFromToDate1(month: any):Observable<GenericDto<PayoutReport []>> {
    var today = new Date();
    var fromDate = new Date(2019,month-1,1);
    var toDate = new Date(2019,month,1);
    var firstDate1 = ((new Date()).getFullYear())+'-'+month+'-01';
    var toDate1 =  '2019-'+ (parseInt(month)+1) +'-01';
    if (month == '12'){
      var toDate1 =  (((new Date()).getFullYear())+1) +'-'+ '01' +'-01';
    }
    return this.httpService.getAuth<GenericDto<PayoutReport []>>(environment.API + payoutReportFromToDate + '?fromDate=' + firstDate1 + '&toDate=' + toDate1);
  }


  getYears() {
    return this.httpService.getAuth<GenericDto<any[]>>(environment.API + '/booking/getYears') ;
  }

  getDashboardInfo(time: string, duration:string) {
    this.loaderService.startLoader();
    return this.httpService.getAuth<GenericDto<any>> (environment.API + getDashboard + `/${time}` + `/${duration}` )
    .pipe(tap(() => {
      this.loaderService.stopLoader(); // Stop loader when response received
    }), catchError((error: HttpErrorResponse) => {
      this.loaderService.stopLoader(); // stop loader if any error occurred
      return throwError(error);
    }));
  }

 
  syncBookingToPms(bookingId: string): Observable<GenericDto<any>> {
    return this.httpService.postAuth(environment.API + syncBookingToPms + '?bookingId=' + bookingId,{});
  }

  getOccupancyRateInfo(userId,year) {
    this.loaderService.startLoader();
    return this.httpService.get<any>(environment.API + getMonthlyOccupancyRate + `/${userId}`+ `/${year}`    )
    .pipe(tap(() => {
      this.loaderService.stopLoader(); // Stop loader when response received
    }), catchError((error: HttpErrorResponse) => {
      this.loaderService.stopLoader(); // stop loader if any error occurred
      return throwError(error);
    }));
  }


}
