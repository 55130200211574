import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { SharedModule } from "src/app/shared/shared.module";
import { CommonModule } from "@angular/common";
import { JobInstructionComponent } from "../job-instruction/job-instruction.component";
import { PropertyService } from "src/app/services/property.service";
import { JobCommentsComponent } from "./job-comments.component";
import { MatFormFieldModule, MatInputModule } from "@angular/material";

@NgModule ({
    imports: [ReactiveFormsModule, RouterModule, SharedModule, CommonModule, MatFormFieldModule,
        MatInputModule,MatFormFieldModule   ],
    declarations: [JobCommentsComponent],
    providers: [PropertyService],
    entryComponents: [JobCommentsComponent],
    exports: [JobCommentsComponent],
  })
  export class JobCommentsModule {

  }