import {NgModule} from '@angular/core';
import {ForgotPasswordComponent} from './forgot-password.component';
import {MDBBootstrapModule} from 'angular-bootstrap-md';
import {CommonModule} from '@angular/common';
import {ReactiveFormsModule} from '@angular/forms';
import {SharedModule} from '../../shared/shared.module';

@NgModule({
  imports: [MDBBootstrapModule, ReactiveFormsModule, CommonModule, SharedModule],
  declarations: [ForgotPasswordComponent],
  exports: [ForgotPasswordComponent],
  providers: []

})
export class ForgotPasswordModule {

}
