import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { animate, state, style, transition, trigger } from '@angular/animations';
import * as jsPDF from 'jspdf';

import { GenericDto } from '../../../models/generic-dto';
import { User } from '../../../models/user';
import { UserService } from '../../../services/user.service';
import { info ,success, successUserDeleted, errorMessage } from '../../../constants/notification-messages.constant';
import { ToastrService } from 'ngx-toastr';
import { DataService } from '../../../shared/services/data.service';
import { Observable, Subscription } from 'rxjs/index';
import { JobAssign } from '../../../models/job-assign';
import { PropertyService } from '../../../services/property.service';
import { MatDialog, MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { OwnerPropertiesComponent } from '../owner/owner-properties/owner-properties.component';
import { OwnerService } from '../../../services/owner.service';
import * as moment from 'moment';
import { OwnerProperty } from '../../../models/owner-property';
import { CurrencyPipe } from '@angular/common';
import {RoomType} from "../../../models/RoomType";
import {TokenService} from "../../../core/services/token.service";
import {tokenKey} from "../../../constants/storage.constants";
import { SharedService } from 'src/app/services/shared.service';
import {OwnerDto} from "../../../models/owner-dto";
import {Owner} from "../../../models/owner";
import {ImportErrorsComponent} from "../../property/plugins/import-errors/import-errors.component";
import {SendNotificationComponent} from "./send-notification.component";
import { StatementService } from 'src/app/services/statement.service';
import { CreateStatementComponent } from '../create-statement/create-statement.component';
import { LoaderService } from 'src/app/shared/services/loader.service';


@Component({
  selector: 'app-user-details',
  templateUrl: './cleaner-details.component.html',
  styleUrls: ['./cleaner-details.component.scss'],
  providers: [OwnerService],
  animations: [trigger(
    'enterAnimation', [
      transition(':enter', [
        style({ transform: 'scale(0)', opacity: 0 }),
        animate(50, style({ transform: 'scale(1)', opacity: 1 }))
      ]),
      transition(':leave', [
        style({ transform: 'scale(1)', opacity: 1 }),
        animate(50, style({ transform: 'scale(0)', opacity: 0 }))
      ])
    ]
  )]
})

export class CleanerDetailsComponent implements OnInit, OnDestroy {
  ownerProperty: OwnerProperty;
  user: User = null;
  isLoading = true;
  x: number;
  y: number;
  statementList = []
  isActive: boolean;
  bookingDataList = new Array();
  isLoadingImage: boolean;
  subscription: Subscription;
  placeholderImage: SafeUrl;
  isCleaner: boolean;
  isAdmin: boolean = false;
  personalBookingList: any;
  cleanerJobs: Array<JobAssign>;
  displayColumns: any;
  dataSourceCompletedJob;
  automaticStatementEnabled:boolean = false;
  dataSourceRejectedJob;
  dataSourceAcceptedJob;
  dataSourcePlannedJob;
  completedjobsList: Array<JobAssign> = [];
  plannedjobsList: Array<JobAssign> = [];
  acceptedjobsList: Array<JobAssign> = [];
  rejectedjobsList: Array<JobAssign> = [];
  @Input() ownerDetail: User;
  ownerRecord: Owner;

  userId: any;
  displayedColumns = ['firstNight', 'checkoutDate', 'guestPropertyName', 'ownerBookingStatus', 'pmsSyncError'];
  propertyDisplayColumns = ['roomName', 'propertyLocation', "units"];
  dataSource;
  roomUnitDataSource;
  roomUnitList: any;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('sort1') sort1: MatSort;
  @ViewChild('paginator1') paginator1: MatPaginator;
  @ViewChild('sort2') sort2: MatSort;
  @ViewChild('paginator2') paginator2: MatPaginator;
  @ViewChild('sortAssignedJobs') sortAssignedJobs: MatSort;
  @ViewChild('paginatorAssignedJobs') paginatorAssignedJobs: MatPaginator;
  permissions: any[] = [];


  constructor(private statementService: StatementService,private sharedService: SharedService, private userService: UserService, private dialog: MatDialog, private toastrService: ToastrService, private dataService: DataService, private propertyService: PropertyService,
    private tokenService: TokenService ,private route: ActivatedRoute, private router: Router, private sanitizer: DomSanitizer, private ownerService: OwnerService, private cp: CurrencyPipe, private loaderService: LoaderService) {
      this.router.routeReuseStrategy.shouldReuseRoute = function() {
        return false;
    };
    }

  ngOnInit() {
    this.isCleaner = false;
    this.placeholderImage = this.sanitizer.bypassSecurityTrustResourceUrl('assets/images/no_images.png');
    this.subscription = this.route.params.subscribe((data: { id: string }) => {
      // load User
      if (!!this.user && this.user.id !== data.id) {
        this.user = null;
        this.isLoading = true;
      }
      this.userService.getUser().subscribe((response)=>{
        this.permissions = response.payload.permissions;
      })
      this.getUserDetails(data);
      this.userId = data.id;
      this.showPersonalBooking();
      this.showProperties();
    });


    /*this.showPropertiesByOwnerId(this.ownerDetail , 'booking');*/
  }

  editUser(): void {
    this.router.navigate(['/dashboard/users/edit/' + this.user.id]);
  }

  deleteUser(): void {
    if (confirm("Are you sure you want to delete this User ?")) {

      this.userService.deleteUser(this.user.id).subscribe((response: GenericDto<any>) => {
        if (response.code === 200) {
          this.toastrService.success(successUserDeleted, success, {
            timeOut: 3000,
            onActivateTick: true
          });
          this.dataService.customEvent.next();
          this.router.navigate(['/dashboard/users']);
        }
      }, (error: HttpErrorResponse) => {
        // console.log(error);
      });
    }
  }

  getUserDetails(data: { id: string }): void {
    this.userService.getUserDetails(data.id).subscribe((response: GenericDto<User>) => {
      if (response.code === 200) {
        this.user = response.payload;
        if (this.user.role === 'CLEANER') {
          this.isCleaner = true;
          this.isAdmin = false;
          this.isActive = response.payload.enable;
          this.getAllAssignedJobsById(this.user.id);
        } else if (this.user.role === 'OWNER') {
          this.isCleaner = false;
          this.isAdmin = false;
          this.ownerDetail = response.payload;
          this.isActive = this.ownerDetail.enable;

          this.userService.getOwnerDetails(this.user.id)
            .subscribe((responseData: GenericDto<OwnerDto> | any)=>{
              if(responseData.code === 200){
                this.ownerRecord = responseData.payload
                this.automaticStatementEnabled = this.ownerRecord.automaticStatementEnabled;
                this.getStatements(this.ownerRecord)
              }
            }, (error: HttpErrorResponse)=>{
              console.log("Error getting data from owner record", error);
            })

        } else if (this.user.role === 'ADMINISTRATOR'){
          console.log('here');
          this.isCleaner = false;

          this.isAdmin = true;
        }
      }
      this.isLoading = false;
    }, error => {
      this.isLoading = false;
    });
  }

  /**
   * @desc : Jobs In table view.
   * @param data
   */
  getAllAssignedJobsById(data: any): void {
    this.propertyService.getAssignedJobsByCleanerId(data).subscribe((response: GenericDto<JobAssign[]>) => {
      if (response.code === 200) {
        this.cleanerJobs = response.payload;
        let count = 1;
        this.displayColumns = ['count', 'cleanerName', 'propertyId', 'roomId', 'unitName', 'endingDate'];
        for (const job of this.cleanerJobs) {
          job.count = count++;
          if (job.jobRequest === 'COMPLETED') {
            this.completedjobsList.push(job);
            this.dataSourceCompletedJob = new MatTableDataSource(this.completedjobsList);
            this.dataSourceCompletedJob.sort = this.sort;
            this.dataSourceCompletedJob.paginator = this.paginator;
            this.dataSourceCompletedJob.sort = this.sort;
          } else if (job.jobRequest === 'ACCEPTED') {
            this.acceptedjobsList.push(job);
            this.dataSourceAcceptedJob = new MatTableDataSource(this.acceptedjobsList);
            this.dataSourceAcceptedJob.sort = this.sort;
            this.dataSourceAcceptedJob.paginator = this.paginator;
            this.dataSourceAcceptedJob.sort = this.sort;
          } else if (job.jobRequest === 'REJECTED') {
            this.rejectedjobsList.push(job);
            this.dataSourceRejectedJob = new MatTableDataSource(this.rejectedjobsList);
            this.dataSourceRejectedJob.sort = this.sort;
            this.dataSourceRejectedJob.paginator = this.paginator;
            this.dataSourceRejectedJob.sort = this.sort;
          } else {
            this.plannedjobsList.push(job);
            this.dataSourcePlannedJob = new MatTableDataSource(this.plannedjobsList);
            this.dataSourcePlannedJob.sort = this.sort2;
            this.dataSourcePlannedJob.paginator = this.paginator2;
          }
        }
        this.completedjobsList = null;
        this.plannedjobsList = null;
        this.acceptedjobsList = null;
        this.rejectedjobsList = null;
      } else {
      }

      this.isLoading = false;
    }, error => {
      this.isLoading = false;
    });
  }
  /* applyFilter(filterValue: string) {
     this.dataSourcePlannedJob.filter = filterValue.trim().toLowerCase();
   }*/

  showPropertiesByOwnerId(user: User, bookingSelection: any): void {
    const dialogRef = this.dialog.open(OwnerPropertiesComponent, {
      width: '200%',
      data: {
        row: user.id,
        bookingSelection: bookingSelection
      }
    });
    dialogRef.afterClosed().subscribe(() => {
      /*  this.loadJobdetails();
        this.jobIds = [];*/
    });

  }


  showPersonalBooking(): void {

    this.userService.getOwnerPersonalBooking(this.userId)
      .subscribe((response: GenericDto<any>) => {
        if (response.code === 200) {
          this.personalBookingList = [];
          response.payload.forEach((row: any) => {
            row.firstNight = moment.unix(row.firstNight).set({hour: 12, minute: 0}).utc().format('YYYY-MM-DD'); // moment.unix(row.firstNight).format('YYYY-MM-DD');
            row.checkoutDate = moment.unix(row.lastNight).add(1, "day") .set({hour: 12, minute: 0}).utc().format('YYYY-MM-DD'); // moment.unix(row.lastNight).add(1, 'days').format('YYYY-MM-DD');
            if(!row.failedSyncToPMS)
              row.pmsSyncError = "--";

            if (row.ownerBookingStatus === 'PENDING') {
              row.ownerBookingStatusPending = true;
            } else {
              row.ownerBookingStatusActive = true;
            }
            this.personalBookingList.push(row);
          });
          this.dataSource = new MatTableDataSource(this.personalBookingList);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        }
      },
        (error: HttpErrorResponse) => {
          // show errors here
        });


  }


  acceptRequest(status: any, row: any): void {
    this.propertyService.updateBookingStatus(row.id, status)
      .subscribe((response: GenericDto<any>) => {
        if (response.code === 200) {
          this.showPersonalBooking();
          this.toastrService.success(response.desc , success, {
            timeOut: 3000,
            onActivateTick: true
          });
        }
      },
        (error: HttpErrorResponse) => {
          // show errors here
        });
  }


  showProperties(): void {

    this.userService.getOwnerRoomUnits(this.userId)
      .subscribe((ownerRooms: GenericDto<Array<RoomType>>) => {
        console.log("in getOwnerRoomUnits", ownerRooms);

          if (ownerRooms.code === 200) {
            this.roomUnitList = ownerRooms.payload;
            this.roomUnitDataSource = new MatTableDataSource(this.roomUnitList);
            this.roomUnitDataSource.paginator = this.paginator1;
            this.roomUnitDataSource.sort = this.sort1;
          }
        },
        (error: HttpErrorResponse) => {
          // show errors here
          console.log("An error occurred in: ", error);
        });


  }


  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

sendReport() {


     this.ownerService.receiveOwnerReport(this.user.id)
       .subscribe(
         (response: GenericDto<any>) => {
            if(response.code === 200) {

            let currency = response.currency;
            const doc = new jsPDF();
            console.log(response);
            //jsPdf initialize -
              doc.text(65, 13, 'Property Management Invoice');
              doc.setFontSize(10);
              this.y = 20;
              this.x = 20;
              doc.text(this.x, this.y, '______________________________________________________________________________________');
              this.y +=5;
              doc.setFontType('bold');
              doc.text(this.x, this.y, 'Property Name');
              doc.text(160, this.y, 'Amount');
              doc.setFontType('normal');
              this.y +=10;
              for(let commission of response.payload) {
              //console.log(commission.propertyManagementCommission)
              this.y+=5;                      // header --
              doc.text(this.x, this.y, commission.propertyName);

              doc.text(160, this.y, this.cp.transform(commission.propertyManagementCommission.toFixed(2), currency, 'symbol-narrow'));

            }
            doc.text(this.x, this.y += 5, '______________________________________________________________________________________');

            let totalPropertyManagementCommission = 0;
            for(let commission of response.payload) {
              totalPropertyManagementCommission = totalPropertyManagementCommission + commission.propertyManagementCommission
            }
            doc.text(this.x, this.y += 5, ' Grand Total ');
            doc.text(160, this.y, this.cp.transform(totalPropertyManagementCommission.toFixed(2), currency, 'symbol-narrow'));

            const formData = new FormData();
            //doc.save('invoice.pdf');
            const dataPdf = doc.output('blob');
            formData.append('file', dataPdf);
            this.ownerService.sendOwnerReport(formData, this.user.id)
              .subscribe(
                (response: GenericDto<any>) => {
                  if(response.code === 200) {
                    this.toastrService.success(response.desc, success, {
                      timeOut: 3000,
                      onActivateTick: true
                    });
                  }
                },
                (error: HttpErrorResponse) => {
                  this.toastrService.error('Something went wrong', errorMessage, {
                    timeOut: 3000,
                    onActivateTick: true
                  });
                }
              );

            } else {
              this.toastrService.error('Something went wrong', errorMessage, {
                timeOut: 3000,
                onActivateTick: true
              });
            }
          },
          (error: HttpErrorResponse) => {

          }

       )


}
  resendOtpInvite(){
    this.userService.resendInvite(this.user.id)
    .subscribe(
      (response: GenericDto<any>) => {
        if(response.code === 200) {
          this.toastrService.success(status + ' Invitation  Sent Successfully ', success, {
            timeOut: 3000,
            onActivateTick: true
          });
          this.ngOnInit() ;
        } else {
          this.toastrService.error('Something went wrong', errorMessage, {
            timeOut: 3000,
            onActivateTick: true
          });
        }
      },
        (error: HttpErrorResponse) => {
          // show errors here
        });
      }


  previewOwner(): void {

    var editUserId = null;
    if (this.user && this.user.id != null) {
      editUserId = this.user.id;
    }

    console.log("this.user  =" , this.user  )
    console.log("editUserId =" , editUserId )

    this.userService.getOwnerRoomIdUnitIdList(this.user.id)
    .subscribe((responseData: GenericDto<Array<string>>) => {

        if (responseData.code === 200) {
          console.log("roomIdUnitIdList =" ,  responseData.payload )

          let objform = null ;
          objform = {
          email: this.user.email ,
          firstName:  this.user.firstName ,
          lastName:  this.user.lastName ,
          ownerData:  this.user.permission ,
          ownerPriceMultiplier: this.user.ownerPriceMultiplier ,
          phoneNumber:  this.user.phoneNumber ,
          role: this.user.role ,
          roomIds:   responseData.payload ,
        }

          console.log("objform =" , objform )

          this.userService.previewOwnerPortal(editUserId, objform )
            .subscribe(
              (response: GenericDto<any>) => {
                if (response.code === 200) {
                  console.log("Preview portal url", response);
                  //this.router.navigate(['/dashboard/users']);
                  this.tokenService.saveToken(response.payload);
                  this.tokenService.saveRole("OWNER");
                  this.tokenService.setPreviewLogin(true);
                  this.tokenService.setPreviewOwner(this.user);
                  localStorage.setItem(tokenKey, response.payload);

                  //this.router.navigate(['/ownerDashboard']);
                  location.replace('ownerDashboard');
                } else if (response.code === 404) {
                  this.toastrService.info('Failed to load preview portal ', info, {
                    timeOut: 3000,
                    onActivateTick: true
                  });
                }
              },
              (error: HttpErrorResponse) => {
                console.log(error);
                this.isLoading = false;
              }
            )
        }

      },
      (error: HttpErrorResponse) => {
        // show errors here
        console.log("An error occurred: ", error);
      });
  }

  openSendNotificationDialog(): void {
    const sendNotificationDialogRef = this.dialog.open(SendNotificationComponent, {
      width: '50%',
      height: '500px',
      data: this.ownerRecord
    });
  }

getStatements(owner){

  this.statementService.getStatementsByOwnerId(owner.ownerId).subscribe((response: GenericDto<any>) => {
    if (response.code === 200) {
    this.statementList = response.payload;
    } else {
      // show error and redirect to login page
    }

  }, (error: HttpErrorResponse) => {
    // show error and redirect to login page
  });
}

sendNotification(statementId){
  this.statementService.sendNotification(statementId).subscribe((response: GenericDto<any>) => {
    if (response.code === 200) {
      this.getStatements({ownerId:this.ownerDetail.id});
      this.toastrService.success(response.desc, success, {
        timeOut: 3000,
        onActivateTick: true
      });

    } else {
      this.toastrService.error(response.desc, success, {
        timeOut: 3000,
        onActivateTick: true
      });
      // show error and redirect to login page
    }

  }, (error: HttpErrorResponse) => {
    // show error and redirect to login page
  });
}
createStatement(){

  const dialogRef = this.dialog.open(CreateStatementComponent, {
    width: '40%',
    height: 'auto',
    data: {ownerDetail : this.ownerDetail}
  });
  dialogRef.afterClosed().subscribe(() => {
    this.getStatements({ownerId:this.ownerDetail.id});
  });


}
resyncBookinToPms(id:string){
  this.loaderService.startLoader();
  console.log("Booking id"+id);
  this.ownerService.syncBookingToPms(id).subscribe((response: GenericDto<any>) => {
    this.loaderService.stopLoader();
    if (response.code === 200 && response.payload) {
      this.toastrService.success(response.desc, success, {
        timeOut: 3000,
        onActivateTick: true
      });
    } else if(response.code === 200 && !response.payload){
      this.toastrService.error(response.desc, errorMessage, {
        timeOut: 3000,
        onActivateTick: true
      });
    } else {
      this.toastrService.error(response.desc, success, {
        timeOut: 3000,
        onActivateTick: true
      });
    }
    this.showPersonalBooking();

  }, (error: HttpErrorResponse) => {
    this.loaderService.stopLoader();
  });

}

}
